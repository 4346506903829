export default {
  address: '新北市三重區神農街451號',
  googleSrc:
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3614.3133495440543!2d121.47200181500673!3d25.05736648396148!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442a9a77e8f9a69%3A0xcd5d3936dafc6b79!2z56uL55G-6Yae!5e0!3m2!1szh-TW!2stw!4v1564484715208!5m2!1szh-TW!2stw',
  phone: '02-2995-8585',
  fbLink:
    'https://www.facebook.com/%E7%AB%8B%E7%91%BE%E9%86%9E-513418625854258/',
  fbMessage: 'https://m.me/513418625854258/',
  googleLink: 'https://www.google.com.tw/maps/place/%E7%AB%8B%E7%91%BE%E9%86%9E/@25.0573665,121.4720018,17z/data=!3m1!4b1!4m5!3m4!1s0x3442a9a77e8f9a69:0xcd5d3936dafc6b79!8m2!3d25.0573665!4d121.4741905',
  caseName: '立瑾醞',

  houseInfos: [
    ['投資興建', '立瑾建設股份有限公司'],
    ['建築規劃', '詹健鴻建築師事務所'],
    ['工程營造', '鴻駿營造有限公司'],
    ['建照號碼', '106重建字第00403號'],
    ['廣告銷售', '自建自售'],
    ['廣告企劃', '天彤廣告']
  ],
  gtmCode: ['TVM2B9N'], // 可放置多個
  order: {
    title: '預約賞屋',
    subTitle: ''
  }
}
