<template>
  <svg
    version="1.1"
    id="圖層_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 21 34"
    style="enable-background:new 0 0 21 34;"
    xml:space="preserve"
    class="txt"
  >
    <line class="st0" x1="18.6" y1="0.7" x2="2.4" y2="33.3" />
  </svg>
</template>
<style lang="scss" scoped>
.txt {
  width: 432px;

  .st0 {
    fill: none;
    stroke: #231815;
    stroke-width: 0.9667;
    stroke-linecap: round;
    stroke-miterlimit: 10;
  }
}
</style>

<script>
export default {
  name: 'txt-svg',
}
</script>
