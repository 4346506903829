<template>
  <svg
    version="1.1"
    id="圖層_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 131 49"
    style="enable-background:new 0 0 131 49;"
    xml:space="preserve"
    class="txt1"
  >
    <g>
      <g>
        <path
          class="st0"
          d="M16.1,16.5c0,0.8-0.1,1.4-0.3,2c-0.2,0.6-0.5,1.2-0.8,1.6c-0.3,0.5-0.8,0.9-1.2,1.2c-0.5,0.3-1,0.6-1.6,0.8
			c-0.6,0.2-1.2,0.4-1.8,0.5c-0.7,0.1-1.4,0.2-2.2,0.2H2.9c-0.3,0-0.6-0.1-0.9-0.3c-0.2-0.2-0.3-0.6-0.3-1V3c0-0.5,0.1-0.8,0.3-1
			c0.2-0.2,0.5-0.3,0.9-0.3h5c1.2,0,2.3,0.1,3.1,0.3c0.8,0.2,1.6,0.5,2.1,1c0.6,0.4,1,1,1.3,1.7c0.3,0.7,0.5,1.5,0.5,2.4
			c0,0.5-0.1,1-0.2,1.4c-0.1,0.5-0.3,0.9-0.5,1.2c-0.2,0.4-0.5,0.7-0.9,1c-0.3,0.3-0.7,0.5-1.2,0.7c0.6,0.1,1.1,0.3,1.6,0.6
			c0.5,0.3,0.9,0.6,1.2,1.1c0.4,0.4,0.6,0.9,0.8,1.5C16,15.1,16.1,15.7,16.1,16.5z M10.9,7.5c0-0.4-0.1-0.8-0.2-1.1
			c-0.1-0.3-0.3-0.6-0.6-0.8C9.9,5.3,9.6,5.1,9.2,5C8.8,4.9,8.3,4.8,7.7,4.8h-2v5.5h2.3c0.6,0,1.1-0.1,1.4-0.2
			C9.7,10,10,9.8,10.2,9.5c0.2-0.3,0.4-0.6,0.5-0.9C10.8,8.3,10.9,7.9,10.9,7.5z M11.9,16.6c0-0.5-0.1-0.9-0.2-1.3
			c-0.2-0.4-0.4-0.7-0.7-1c-0.3-0.3-0.7-0.5-1.1-0.6c-0.5-0.1-1.1-0.2-1.8-0.2H5.7v6h2.9c0.6,0,1-0.1,1.4-0.2c0.4-0.1,0.7-0.3,1-0.6
			c0.3-0.2,0.5-0.6,0.7-0.9C11.8,17.5,11.9,17.1,11.9,16.6z"
        />
        <path
          class="st1"
          d="M23.9,2.7c0,0.8-0.2,1.4-0.5,1.7c-0.3,0.3-0.9,0.5-1.8,0.5c-0.9,0-1.5-0.2-1.8-0.5c-0.3-0.3-0.5-0.9-0.5-1.7
			c0-0.8,0.2-1.4,0.5-1.7c0.3-0.3,0.9-0.5,1.8-0.5c0.9,0,1.4,0.2,1.8,0.5C23.8,1.4,23.9,1.9,23.9,2.7z M23.6,22.2
			c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0.1-0.3,0.1-0.6,0.1c-0.2,0-0.6,0-0.9,0s-0.7,0-0.9,0c-0.2,0-0.4-0.1-0.6-0.1
			c-0.1-0.1-0.3-0.1-0.3-0.2c-0.1-0.1-0.1-0.2-0.1-0.3V7.7c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.2,0.3-0.2c0.1-0.1,0.3-0.1,0.6-0.1
			c0.2,0,0.6,0,0.9,0s0.7,0,0.9,0c0.2,0,0.4,0.1,0.6,0.1s0.3,0.1,0.3,0.2c0.1,0.1,0.1,0.2,0.1,0.3V22.2z"
        />
        <path
          class="st1"
          d="M32.3,7.5c0,0.8-0.2,1.4-0.5,1.7c-0.3,0.3-0.9,0.5-1.8,0.5c-0.9,0-1.5-0.2-1.8-0.5c-0.3-0.3-0.5-0.9-0.5-1.7
			c0-0.8,0.2-1.4,0.5-1.7c0.3-0.3,0.9-0.5,1.8-0.5c0.9,0,1.4,0.2,1.8,0.5C32.1,6.1,32.3,6.7,32.3,7.5z M32,22.2c0,0.1,0,0.2-0.1,0.3
			c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0.1-0.3,0.1-0.6,0.1c-0.2,0-0.6,0-0.9,0c-0.4,0-0.7,0-0.9,0c-0.2,0-0.4-0.1-0.6-0.1
			c-0.1-0.1-0.3-0.1-0.3-0.2c-0.1-0.1-0.1-0.2-0.1-0.3v-9.8c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.2,0.3-0.2c0.1-0.1,0.3-0.1,0.6-0.1
			c0.2,0,0.6,0,0.9,0c0.4,0,0.7,0,0.9,0c0.2,0,0.4,0.1,0.6,0.1c0.1,0.1,0.3,0.1,0.3,0.2c0.1,0.1,0.1,0.2,0.1,0.3V22.2z"
        />
        <path
          class="st1"
          d="M40.7,7.5c0,0.8-0.2,1.4-0.5,1.7c-0.3,0.3-0.9,0.5-1.8,0.5c-0.9,0-1.5-0.2-1.8-0.5c-0.3-0.3-0.5-0.9-0.5-1.7
			c0-0.8,0.2-1.4,0.5-1.7c0.3-0.3,0.9-0.5,1.8-0.5c0.9,0,1.4,0.2,1.8,0.5C40.5,6.1,40.7,6.7,40.7,7.5z M40.4,22.2
			c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0.1-0.3,0.1-0.6,0.1c-0.2,0-0.6,0-0.9,0c-0.4,0-0.7,0-0.9,0
			c-0.2,0-0.4-0.1-0.6-0.1c-0.1-0.1-0.3-0.1-0.3-0.2c-0.1-0.1-0.1-0.2-0.1-0.3v-9.8c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.2,0.3-0.2
			c0.1-0.1,0.3-0.1,0.6-0.1c0.2,0,0.6,0,0.9,0c0.4,0,0.7,0,0.9,0c0.2,0,0.4,0.1,0.6,0.1c0.1,0.1,0.3,0.1,0.3,0.2
			c0.1,0.1,0.1,0.2,0.1,0.3V22.2z"
        />
        <path
          class="st1"
          d="M49,2.7c0,0.8-0.2,1.4-0.5,1.7c-0.3,0.3-0.9,0.5-1.8,0.5c-0.9,0-1.5-0.2-1.8-0.5c-0.3-0.3-0.5-0.9-0.5-1.7
			c0-0.8,0.2-1.4,0.5-1.7c0.3-0.3,0.9-0.5,1.8-0.5c0.9,0,1.4,0.2,1.8,0.5C48.9,1.4,49,1.9,49,2.7z M48.7,22.2c0,0.1,0,0.2-0.1,0.3
			c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0.1-0.3,0.1-0.6,0.1c-0.2,0-0.6,0-0.9,0c-0.4,0-0.7,0-0.9,0c-0.2,0-0.4-0.1-0.6-0.1
			c-0.1-0.1-0.3-0.1-0.3-0.2c-0.1-0.1-0.1-0.2-0.1-0.3V7.7c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.2,0.3-0.2c0.1-0.1,0.3-0.1,0.6-0.1
			c0.2,0,0.6,0,0.9,0c0.4,0,0.7,0,0.9,0c0.2,0,0.4,0.1,0.6,0.1c0.1,0.1,0.3,0.1,0.3,0.2c0.1,0.1,0.1,0.2,0.1,0.3V22.2z"
        />
        <path
          class="st0"
          d="M65.6,8.7c0,0.5-0.1,0.9-0.2,1.2c-0.1,0.3-0.3,0.4-0.4,0.4h-1.6c0.2,0.3,0.4,0.6,0.5,0.9
			c0.1,0.3,0.1,0.7,0.1,1c0,0.9-0.1,1.6-0.4,2.3c-0.3,0.7-0.6,1.2-1.1,1.7c-0.5,0.5-1.1,0.8-1.8,1c-0.7,0.2-1.5,0.4-2.3,0.4
			c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.2-0.9-0.4c-0.1,0.1-0.2,0.3-0.3,0.5c-0.1,0.2-0.1,0.4-0.1,0.7c0,0.3,0.1,0.6,0.4,0.8
			c0.3,0.2,0.6,0.3,1.1,0.3l3.3,0.1c0.8,0,1.5,0.1,2.1,0.4c0.6,0.2,1.1,0.5,1.5,0.9c0.4,0.4,0.7,0.8,1,1.3c0.2,0.5,0.3,1.1,0.3,1.8
			c0,0.7-0.1,1.4-0.4,2c-0.3,0.6-0.7,1.2-1.3,1.7c-0.6,0.5-1.3,0.8-2.2,1.1s-1.9,0.4-3.1,0.4c-1.2,0-2.2-0.1-3-0.3
			c-0.8-0.2-1.5-0.5-2-0.8c-0.5-0.3-0.9-0.8-1.2-1.3c-0.2-0.5-0.4-1-0.4-1.6c0-0.4,0-0.7,0.1-1c0.1-0.3,0.2-0.6,0.4-0.9
			c0.2-0.3,0.4-0.6,0.6-0.9c0.2-0.3,0.5-0.5,0.8-0.8c-0.4-0.3-0.8-0.6-1-1c-0.2-0.4-0.4-0.9-0.4-1.4c0-0.6,0.1-1.2,0.4-1.8
			c0.3-0.5,0.6-1,1.1-1.4c-0.4-0.4-0.6-0.8-0.9-1.4c-0.2-0.5-0.3-1.2-0.3-2c0-0.9,0.1-1.6,0.4-2.3c0.3-0.7,0.7-1.2,1.2-1.7
			c0.5-0.5,1.1-0.8,1.8-1.1c0.7-0.2,1.5-0.4,2.3-0.4c0.4,0,0.8,0,1.2,0.1C60,6.9,60.3,7,60.7,7.1H65c0.2,0,0.3,0.1,0.4,0.4
			C65.5,7.7,65.6,8.1,65.6,8.7z M61.5,23.8c0-0.5-0.2-0.9-0.6-1.1c-0.4-0.3-0.9-0.4-1.5-0.4l-2.8-0.1c-0.3,0.2-0.5,0.4-0.6,0.6
			c-0.2,0.2-0.3,0.4-0.4,0.5c-0.1,0.2-0.2,0.3-0.2,0.5c0,0.2,0,0.3,0,0.5c0,0.5,0.3,1,0.8,1.2c0.5,0.3,1.2,0.4,2.2,0.4
			c0.6,0,1.1-0.1,1.5-0.2c0.4-0.1,0.7-0.3,1-0.5c0.2-0.2,0.4-0.4,0.5-0.7C61.5,24.3,61.5,24,61.5,23.8z M60.6,12.1
			c0-0.8-0.2-1.4-0.6-1.8c-0.4-0.4-1-0.7-1.7-0.7c-0.4,0-0.7,0.1-1,0.2c-0.3,0.1-0.5,0.3-0.7,0.6c-0.2,0.2-0.3,0.5-0.4,0.8
			c-0.1,0.3-0.1,0.6-0.1,1c0,0.7,0.2,1.3,0.6,1.8c0.4,0.4,1,0.6,1.7,0.6c0.4,0,0.7-0.1,1-0.2c0.3-0.1,0.5-0.3,0.7-0.5
			c0.2-0.2,0.3-0.5,0.4-0.8C60.6,12.8,60.6,12.4,60.6,12.1z"
        />
      </g>
      <g>
        <path
          class="st0"
          d="M85.3,16.5c0,1.1-0.2,2.1-0.6,2.9c-0.4,0.8-0.9,1.5-1.6,2.1c-0.7,0.6-1.4,1-2.3,1.3C79.9,23,79,23.2,78,23.2
			c-0.7,0-1.3-0.1-1.9-0.2c-0.6-0.1-1.1-0.3-1.6-0.4c-0.5-0.2-0.8-0.3-1.1-0.5c-0.3-0.2-0.5-0.3-0.7-0.5c-0.1-0.1-0.2-0.3-0.3-0.6
			c-0.1-0.3-0.1-0.7-0.1-1.2c0-0.3,0-0.6,0-0.9c0-0.2,0.1-0.4,0.1-0.6c0-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.1,0.3-0.1
			c0.1,0,0.3,0.1,0.6,0.3c0.3,0.2,0.6,0.4,1,0.6c0.4,0.2,0.9,0.4,1.5,0.6c0.6,0.2,1.2,0.3,2,0.3c0.5,0,0.9-0.1,1.3-0.2
			c0.4-0.1,0.7-0.3,1-0.5c0.3-0.2,0.5-0.5,0.6-0.9c0.1-0.3,0.2-0.7,0.2-1.1c0-0.5-0.1-0.9-0.4-1.2c-0.2-0.3-0.6-0.6-1-0.9
			c-0.4-0.3-0.8-0.5-1.3-0.8c-0.5-0.2-1-0.5-1.6-0.7c-0.5-0.3-1.1-0.6-1.6-0.9c-0.5-0.3-1-0.7-1.3-1.2c-0.4-0.5-0.7-1-1-1.6
			c-0.2-0.6-0.4-1.4-0.4-2.3c0-1,0.2-1.9,0.5-2.7c0.4-0.8,0.8-1.4,1.4-1.9c0.6-0.5,1.3-0.9,2.1-1.1c0.8-0.3,1.7-0.4,2.6-0.4
			c0.5,0,0.9,0,1.4,0.1c0.5,0.1,0.9,0.2,1.3,0.3c0.4,0.1,0.8,0.3,1.1,0.4c0.3,0.2,0.5,0.3,0.6,0.4C83.9,2.6,84,2.7,84,2.8
			c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.3,0.1,0.5c0,0.2,0,0.4,0,0.7c0,0.3,0,0.6,0,0.8c0,0.2,0,0.4-0.1,0.5c0,0.1-0.1,0.2-0.2,0.3
			C83.9,6,83.8,6,83.7,6c-0.1,0-0.3-0.1-0.5-0.2c-0.2-0.2-0.5-0.3-0.9-0.5C81.8,5.1,81.4,5,81,4.8c-0.5-0.1-1-0.2-1.6-0.2
			c-0.4,0-0.8,0.1-1.2,0.2c-0.3,0.1-0.6,0.3-0.8,0.5C77.2,5.5,77,5.7,76.9,6c-0.1,0.3-0.2,0.6-0.2,0.9c0,0.5,0.1,0.9,0.4,1.2
			c0.2,0.3,0.6,0.6,1,0.9c0.4,0.3,0.9,0.5,1.4,0.8c0.5,0.2,1,0.5,1.6,0.7c0.5,0.3,1.1,0.6,1.6,0.9c0.5,0.3,1,0.7,1.4,1.2
			c0.4,0.5,0.7,1,1,1.6C85.2,14.9,85.3,15.6,85.3,16.5z"
        />
        <path
          class="st0"
          d="M91.7,2.7c0,0.8-0.2,1.4-0.5,1.7c-0.3,0.3-0.9,0.5-1.8,0.5c-0.9,0-1.5-0.2-1.8-0.5c-0.3-0.3-0.5-0.9-0.5-1.7
			c0-0.8,0.2-1.4,0.5-1.7c0.3-0.3,0.9-0.5,1.8-0.5c0.9,0,1.4,0.2,1.8,0.5C91.6,1.4,91.7,1.9,91.7,2.7z M91.4,22.2
			c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0.1-0.3,0.1-0.6,0.1c-0.2,0-0.6,0-0.9,0s-0.7,0-0.9,0c-0.2,0-0.4-0.1-0.6-0.1
			c-0.1-0.1-0.3-0.1-0.3-0.2c-0.1-0.1-0.1-0.2-0.1-0.3V7.7c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.2,0.3-0.2c0.1-0.1,0.3-0.1,0.6-0.1
			c0.2,0,0.6,0,0.9,0s0.7,0,0.9,0c0.2,0,0.4,0.1,0.6,0.1c0.1,0.1,0.3,0.1,0.3,0.2c0.1,0.1,0.1,0.2,0.1,0.3V22.2z"
        />
        <path
          class="st0"
          d="M104.3,21.1c0,0.3,0,0.6,0,0.8c0,0.2-0.1,0.4-0.1,0.5c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0-0.2,0.1-0.3,0.1h-9
			c-0.3,0-0.5-0.1-0.6-0.3c-0.1-0.2-0.2-0.5-0.2-1v-0.8c0-0.2,0-0.4,0-0.5c0-0.2,0-0.3,0.1-0.5c0-0.1,0.1-0.3,0.2-0.5
			c0.1-0.2,0.2-0.3,0.3-0.5l5.1-8.4h-4.9c-0.2,0-0.4-0.1-0.5-0.4C94.1,9.7,94,9.3,94,8.7c0-0.3,0-0.6,0-0.8c0-0.2,0.1-0.4,0.1-0.5
			c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.1,0.3-0.1h8.4c0.1,0,0.3,0,0.4,0c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.1,0.2,0.2,0.4
			c0,0.2,0.1,0.3,0.1,0.6v0.8c0,0.2,0,0.4,0,0.6c0,0.2-0.1,0.3-0.1,0.5c0,0.2-0.1,0.3-0.2,0.5c-0.1,0.2-0.2,0.3-0.3,0.5l-5.1,8.4
			h5.4c0.1,0,0.2,0,0.3,0.1c0.1,0,0.2,0.1,0.2,0.2c0.1,0.1,0.1,0.3,0.1,0.5C104.3,20.5,104.3,20.8,104.3,21.1z"
        />
        <path
          class="st0"
          d="M118.7,14.5c0,0.5-0.1,0.9-0.3,1.1c-0.2,0.2-0.5,0.4-0.9,0.4h-8.4c0,0.6,0.1,1.2,0.2,1.7
			c0.1,0.5,0.4,0.9,0.7,1.3c0.3,0.4,0.7,0.6,1.2,0.8c0.5,0.2,1,0.3,1.7,0.3c0.7,0,1.3-0.1,1.8-0.2c0.5-0.1,1-0.2,1.3-0.3
			c0.4-0.1,0.7-0.2,0.9-0.3c0.2-0.1,0.4-0.2,0.6-0.2c0.1,0,0.2,0,0.2,0.1s0.1,0.1,0.2,0.2c0,0.1,0.1,0.2,0.1,0.4c0,0.2,0,0.4,0,0.7
			c0,0.2,0,0.4,0,0.6c0,0.2,0,0.3,0,0.4c0,0.1-0.1,0.2-0.1,0.3c0,0.1-0.1,0.2-0.2,0.2c-0.1,0.1-0.2,0.2-0.5,0.3
			c-0.3,0.1-0.7,0.3-1.1,0.4c-0.5,0.1-1,0.2-1.6,0.3c-0.6,0.1-1.2,0.1-1.9,0.1c-1.2,0-2.3-0.2-3.2-0.5c-0.9-0.3-1.7-0.8-2.3-1.5
			c-0.6-0.7-1.1-1.5-1.4-2.5c-0.3-1-0.5-2.2-0.5-3.6c0-1.3,0.2-2.5,0.5-3.5c0.3-1,0.8-1.9,1.4-2.7c0.6-0.7,1.3-1.3,2.2-1.7
			c0.9-0.4,1.8-0.6,2.9-0.6c1.1,0,2.1,0.2,2.9,0.5c0.8,0.4,1.5,0.9,2,1.5c0.5,0.6,0.9,1.4,1.2,2.3c0.2,0.9,0.4,1.8,0.4,2.8V14.5z
			 M114.9,13.3c0-1.2-0.2-2.1-0.7-2.7c-0.5-0.7-1.2-1-2.2-1c-0.5,0-0.9,0.1-1.3,0.3c-0.4,0.2-0.7,0.5-0.9,0.8
			c-0.2,0.3-0.4,0.7-0.6,1.2c-0.1,0.5-0.2,0.9-0.2,1.4H114.9z"
        />
      </g>
      <g>
        <path
          d="M9.1,34.1v-3.7c0.1-0.4,0.5-0.6,1.1-0.7c0.8,0,1.2,0.3,1.2,0.8v3.5h7.1c0.4,0.1,0.6,0.4,0.6,1.1c0,0.8-0.2,1.1-0.7,1.1
			h-6.6c0.5,1.8,1.4,3.5,2.8,5.1c1.2,1.4,2.7,2.9,4.6,4.4c0.1,0.1,0.2,0.3,0.2,0.5c-0.1,0.8-0.4,1.3-1,1.5c-0.2,0-0.4-0.1-0.5-0.1
			c-0.7-0.2-2.1-1.5-4.2-3.9c-1.4-1.6-2.4-3.2-3.1-4.7c-0.8,1.9-1.8,3.5-3,4.7c-2.6,2.7-4.3,4-5.2,4.1c-0.3,0-0.5-0.1-0.6-0.2
			c-0.4-0.3-0.5-0.7-0.5-1.2c0-0.3,0.1-0.5,0.3-0.7c2.7-1.9,4.7-3.8,5.9-5.8C8,39,8.6,37.7,8.9,36.4H2c-0.5,0-0.8-0.4-0.8-1.2
			c0-0.6,0.2-0.9,0.6-1.1H9.1z"
        />
        <path
          d="M37.5,37.4c0.4,0.1,0.6,0.4,0.6,1.1c0,0.8-0.2,1.1-0.7,1.1h-17c-0.5,0-0.7-0.4-0.7-1.2c0-0.6,0.2-0.9,0.6-1.1H37.5z"
        />
        <path
          d="M40.9,43.4c-0.1,1.6-0.3,3-0.7,4.2c-0.1,0.3-0.4,0.4-0.9,0.4c-0.4,0-0.7-0.2-0.7-0.7c0.4-1.2,0.7-2.5,0.8-4.1
			c0.1-0.2,0.4-0.3,0.8-0.4C40.7,43,40.9,43.1,40.9,43.4z M44.2,37.2V38h3.5c0.3,0.1,0.4,0.3,0.4,0.8c0,0.6-0.2,0.8-0.5,0.8h-3.4
			v0.9c1,0,2-0.1,3.1-0.2c0.4,0,0.7,0.3,0.7,0.9c0,0.4-0.1,0.7-0.2,0.8c0,0.1-1.2,0.2-3.7,0.4c-1.6,0.1-3.2,0.2-4.8,0.3
			c-0.4,0-0.7-0.3-0.7-0.9c0-0.4,0.1-0.7,0.3-0.9c1.2,0,2.4-0.1,3.6-0.1v-1h-3.2c-0.4,0-0.6-0.3-0.6-0.9c0-0.4,0.1-0.7,0.4-0.8h3.3
			v-0.8h-2.5c-0.6,0-1-0.4-1-1v-4.8c0-0.6,0.3-1,1-1h7c0.6,0.1,1,0.4,1,1.1v4.6c0,0.7-0.4,1.1-1.1,1.1H44.2z M40.7,32.3v2.8
			c0,0.2,0.2,0.3,0.4,0.3h1.4V32h-1.5C40.9,32,40.8,32.1,40.7,32.3z M41.9,32.5c0.2,0.4,0.3,1.1,0.5,2c0,0.3-0.1,0.4-0.2,0.4
			c-0.1,0.1-0.3,0.1-0.6,0.1c-0.2,0-0.3-0.1-0.3-0.4C41.2,34,41,33.4,40.9,33c-0.1-0.2-0.1-0.4-0.1-0.5c0.1-0.2,0.3-0.3,0.5-0.3
			C41.6,32.2,41.8,32.3,41.9,32.5z M42.8,43.3c0.3,0.7,0.5,1.9,0.8,3.6c0,0.4-0.1,0.6-0.2,0.6c-0.2,0.1-0.4,0.2-0.8,0.2
			c-0.3,0-0.5-0.2-0.5-0.5c-0.2-1.4-0.4-2.4-0.6-3.1c-0.1-0.3-0.1-0.6-0.1-0.7c0.2-0.3,0.4-0.4,0.8-0.4
			C42.4,42.9,42.6,43.1,42.8,43.3z M45.2,43.2c0.3,0.6,0.6,1.8,0.8,3.4c0,0.4-0.1,0.5-0.2,0.6c-0.2,0.1-0.4,0.2-0.8,0.2
			c-0.3,0-0.5-0.2-0.5-0.5c-0.2-1.2-0.5-2.2-0.7-2.9c-0.1-0.3-0.1-0.5-0.1-0.7c0.2-0.3,0.4-0.4,0.8-0.4C44.8,42.8,45,42.9,45.2,43.2
			z M44.2,35.5H46c0.3,0,0.4-0.2,0.4-0.4v-2.7c0-0.2-0.1-0.4-0.4-0.4h-1.7V35.5z M45.8,32.4c0.1,0.1,0.2,0.2,0.2,0.4
			c-0.1,0.8-0.2,1.5-0.5,2.1c-0.1,0.2-0.3,0.3-0.5,0.3c-0.4,0-0.7-0.2-0.7-0.6c0-0.1,0-0.2,0.1-0.4c0.2-0.4,0.3-0.9,0.4-1.5
			c0.1-0.2,0.2-0.3,0.5-0.3C45.6,32.3,45.7,32.4,45.8,32.4z M47.5,42.9c0.3,0.5,0.5,1.3,0.7,2.5c0,0.3-0.1,0.5-0.2,0.6
			c-0.2,0.1-0.4,0.2-0.8,0.2c-0.3,0-0.4-0.2-0.5-0.5c-0.2-0.9-0.4-1.5-0.6-2c-0.1-0.3-0.1-0.5-0.1-0.7c0.2-0.3,0.4-0.4,0.8-0.4
			C47.1,42.5,47.3,42.6,47.5,42.9z M52.9,30.8v1.9h3.2c0.4,0,0.6,0.4,0.6,1c0,0.7-0.2,1.1-0.6,1.1h-3.1v2.9h2.3
			c0.7,0.1,1.1,0.5,1.1,1.4v7.4c0,0.9-0.4,1.3-1.2,1.3h-5.7c-0.7,0-1.1-0.4-1.1-1.2v-7.6c0-0.7,0.4-1.2,1.1-1.2H51v-6.9
			c0-0.4,0.3-0.6,0.9-0.7C52.6,30.1,52.9,30.3,52.9,30.8z M50.2,40.1v5.2c0,0.3,0.2,0.4,0.5,0.4H54c0.3,0,0.4-0.2,0.4-0.5v-5.1
			c0-0.3-0.2-0.5-0.4-0.5h-3.3C50.5,39.7,50.3,39.8,50.2,40.1z"
        />
        <path
          d="M70.2,31.5c0.2-0.4,0.4-0.9,0.6-1.3c0.2-0.2,0.5-0.3,1-0.3c0.6,0.2,0.9,0.5,0.9,1c0,0.2-0.1,0.4-0.2,0.6h8.1
			c0.3,0.1,0.5,0.4,0.5,1c0,0.7-0.2,1-0.6,1h-9.1c-0.4,0.5-0.7,1-1.1,1.5h8.6c0.6,0.1,1,0.4,1,0.9v8c0,1.3-0.1,2.2-0.3,2.9
			c-0.2,0.8-1.1,1.2-2.7,1.2c-2,0-3.1-0.4-3.2-1.1c0-0.3,0-0.5,0.1-0.7c0.1-0.3,0.4-0.5,0.9-0.6c1.2,0.3,2,0.5,2.4,0.5
			s0.6-0.6,0.7-1.7h-9.9v3.4c0,0.4-0.3,0.6-0.9,0.6c-0.6,0-1-0.2-1-0.6v-9c-0.3,0.2-0.6,0.4-0.9,0.5c-0.3,0.3-0.6,0.4-1,0.4
			c-0.5,0-0.9-0.3-1.1-0.8c0-0.3,0.1-0.6,0.2-0.8c2.5-1.3,4.4-2.8,5.9-4.5h-5c-0.4,0-0.7-0.4-0.7-1.1c0-0.5,0.2-0.8,0.5-0.9H70.2z
			 M77.7,37.2c0-0.2-0.1-0.3-0.4-0.3h-8.9c0,0-0.1,0-0.1,0c-0.2,0.2-0.4,0.4-0.6,0.5v1.2h10V37.2z M77.7,40.5h-10v1.8h10V40.5z"
        />
        <path
          d="M86.7,30.2c0.1,0.2,0.1,0.3,0.1,0.5c-0.1,0.8-0.2,1.6-0.3,2.3h2.7c0.4,0,0.7,0.3,0.8,0.9c-0.1,3.5-0.9,6.6-2.2,9
			c0.1,0.1,0.1,0.1,0.2,0.1c1,0.8,1.6,1.4,1.8,1.8c0.1,0.2,0.2,0.4,0.2,0.6c0,0.7-0.4,1.1-1,1.2c-0.3,0-0.5-0.1-0.7-0.3
			c-0.4-0.4-1-0.9-1.7-1.6c-0.9,1.2-1.9,2.1-3,3c-0.2,0.2-0.5,0.3-0.7,0.3c-0.5,0-0.9-0.4-1.1-1c0-0.4,0.1-0.7,0.3-0.9
			c1.1-0.9,2.1-1.8,2.9-2.8c-1.3-1-2.1-1.7-2.3-2.1c-0.2-0.1-0.2-0.3-0.2-0.6c0-0.1,0.1-0.3,0.2-0.6c0.6-1.7,1.1-3.4,1.5-5.1h-1.5
			c-0.5,0-0.7-0.4-0.7-1.1c0-0.6,0.2-0.9,0.6-1h2c0.2-0.9,0.3-1.8,0.4-2.6c0.1-0.3,0.4-0.4,1-0.4C86.2,30,86.5,30.1,86.7,30.2z
			 M88,35.5c0-0.3-0.1-0.5-0.4-0.5h-1.6c-0.4,1.8-0.9,3.4-1.5,5c0,0.2,0.1,0.4,0.3,0.6c0.4,0.4,0.8,0.7,1.2,1.1
			C87.2,39.9,87.8,37.8,88,35.5z M98,31c0.6,0.1,1,0.5,1,1.1c0,0.4-0.2,0.7-0.4,1.1c-0.8,1-1.9,2.1-3.1,3.3
			c-0.1,0.1-0.1,0.2-0.1,0.3c0.3,0.5,0.6,1.1,0.8,1.7h3.2c0.4,0.1,0.6,0.5,0.6,1c0,0.7-0.3,1.1-0.7,1.1h-2.4
			c0.2,0.8,0.3,1.6,0.4,2.4c0,2.5-0.5,4.1-1.6,4.8C95,47.8,94.4,48,93.6,48c-1.6-0.1-2.8-0.4-3.5-0.9c-0.1-0.2-0.2-0.4-0.2-0.6
			c0-0.4,0.1-0.7,0.3-1c0.2-0.1,0.5-0.2,0.7-0.2c1.3,0.4,2.2,0.5,2.7,0.5c0.5,0,0.8-0.1,1-0.3c0.3-0.1,0.4-1.1,0.5-3.1
			c0-0.6-0.1-1.3-0.4-2h-4.5c-0.5,0-0.7-0.4-0.7-1.1c0-0.5,0.2-0.8,0.6-1h3.7c-0.2-0.4-0.4-0.7-0.6-1.1c-0.2-0.1-0.2-0.3-0.2-0.6
			c0-0.4,0.2-0.7,0.4-0.9c1-0.9,1.8-1.8,2.5-2.5c0,0-0.1,0-0.1-0.1h-5.5c-0.5,0-0.7-0.4-0.7-1c0-0.6,0.2-1,0.6-1H98z"
        />
        <path
          d="M114.7,34.7c0.4,0.1,0.6,0.4,0.6,1c0,0.7-0.2,1-0.7,1h-0.7c0.1,0.1,0.2,0.1,0.3,0.2c2,1.7,2.9,2.9,2.9,3.5
			c0,0.3-0.1,0.6-0.4,0.8c-0.2,0.2-0.4,0.3-0.7,0.3c-0.2,0-0.4-0.1-0.6-0.4c-0.3-0.3-0.5-0.6-0.8-0.9c-1.4,0.2-2.9,0.3-4.4,0.4v1.5
			h6.3c0.4,0.1,0.6,0.5,0.6,1c0,0.7-0.3,1-0.7,1h-6.2v1.6h7.7c0.4,0.1,0.6,0.4,0.6,1c0,0.7-0.2,1-0.7,1h-16.5
			c-0.4,0-0.7-0.4-0.7-1.1c0-0.5,0.2-0.8,0.6-0.9h7v-1.6h-5.9c-0.5,0-0.7-0.4-0.7-1.1c0-0.5,0.2-0.8,0.6-1h6v-1.4
			c-2,0.1-3.9,0.2-5.9,0.2c-0.5,0-0.8-0.3-0.9-0.9c0-0.5,0.1-0.9,0.4-1c1,0,2,0,2.9-0.1c0-0.1,0.1-0.1,0.1-0.2
			c0.6-0.7,1.2-1.3,1.7-2h-2.6c-0.4,0-0.7-0.4-0.7-1.1c0-0.5,0.2-0.8,0.6-0.9H114.7z M110.4,30.7v0.8h6.6c0.9,0.1,1.4,0.7,1.4,1.7
			c-0.2,1.8-0.4,2.7-0.7,2.8c-0.1,0.2-0.3,0.2-0.6,0.2c-0.9-0.2-1.4-0.5-1.4-1c0-0.1,0.1-0.5,0.4-1.3c0-0.2-0.1-0.4-0.4-0.4h-12.6
			c-0.2,0-0.4,0.1-0.5,0.3v1.7c0,0.5-0.4,0.8-1,0.8c-0.5,0-0.9-0.2-1-0.6v-3.4c0.2-0.6,0.5-0.9,1-0.9h6.6v-0.8c0-0.4,0.3-0.7,1-0.8
			C110,29.9,110.4,30.1,110.4,30.7z M112.1,37.5c0-0.3,0-0.4,0.1-0.5c0.1-0.1,0.2-0.2,0.2-0.3h-3.7c-0.2,0.4-0.7,1-1.4,2
			c-0.1,0.1-0.1,0.1-0.1,0.2c2.1,0,4.1-0.2,5.7-0.3C112.4,38,112.1,37.7,112.1,37.5z"
        />
        <path
          d="M129.5,46.3c-0.4,0.3-0.8,0.4-1.2,0.4c-0.5,0-0.9-0.2-1.2-0.4c-0.4-0.3-0.5-0.7-0.5-1.2c0-0.5,0.2-0.8,0.5-1.2
			c0.4-0.3,0.8-0.5,1.2-0.5c0.5,0,0.9,0.2,1.2,0.5c0.4,0.3,0.5,0.7,0.5,1.2C130,45.6,129.8,46,129.5,46.3z M129,41.4
			c0,0.4-0.4,0.7-0.9,0.7c-0.5,0-0.8-0.2-0.8-0.6l-0.4-7.7c-0.1-1.2,0.4-1.9,1.3-1.9c1,0,1.4,0.6,1.3,1.8L129,41.4z"
        />
      </g>
      <g>
        <circle class="st2" cx="20.7" cy="2.7" r="0.4" />
        <circle class="st2" cx="22.6" cy="2.7" r="0.4" />
      </g>
      <g>
        <circle class="st2" cx="45.9" cy="2.7" r="0.4" />
        <circle class="st2" cx="47.7" cy="2.7" r="0.4" />
      </g>
      <g>
        <circle class="st2" cx="29.1" cy="7.5" r="0.4" />
        <circle class="st2" cx="31" cy="7.5" r="0.4" />
      </g>
      <g>
        <circle class="st2" cx="37.5" cy="7.5" r="0.4" />
        <circle class="st2" cx="39.4" cy="7.5" r="0.4" />
      </g>
    </g>
  </svg>
</template>
<style lang="scss" scoped>
.txt1 {
  width: 432px;

  .st0 {
    fill: #d33828;
  }
  .st1 {
    fill: #d33828;
    stroke: #595757;
    stroke-width: 0.8422;
    stroke-miterlimit: 10;
  }
  .st2 {
    fill: #595757;
  }
}
</style>

<script>
export default {
  name: 'txt-svg',
}
</script>
