<template>
  <div class="section9">
    <div class="bg bg-cover relative fullscreen">
      <div class="container flex-c wrap">
        <div class="relative desc">
<svg version="1.1" id="s9" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
	 y="0px" viewBox="0 0 282.6 130" style="enable-background:new 0 0 282.6 130;" xml:space="preserve">
  <g class="jump">
    <g class="st0">
      <path d="M32.2,22.5c0,0.8-0.1,1.4-0.3,2s-0.5,1.2-0.8,1.6c-0.3,0.5-0.8,0.9-1.2,1.2c-0.5,0.3-1,0.6-1.6,0.8
        c-0.6,0.2-1.2,0.4-1.8,0.5c-0.7,0.1-1.4,0.2-2.2,0.2H19c-0.3,0-0.6-0.1-0.9-0.3c-0.2-0.2-0.3-0.6-0.3-1V9c0-0.5,0.1-0.8,0.3-1
        s0.5-0.3,0.9-0.3h5c1.2,0,2.3,0.1,3.1,0.3c0.8,0.2,1.6,0.5,2.1,1c0.6,0.4,1,1,1.3,1.7c0.3,0.7,0.5,1.5,0.5,2.4c0,0.5-0.1,1-0.2,1.4
        c-0.1,0.5-0.3,0.9-0.5,1.2c-0.2,0.4-0.5,0.7-0.9,1c-0.3,0.3-0.7,0.5-1.2,0.7c0.6,0.1,1.1,0.3,1.6,0.6s0.9,0.6,1.2,1.1
        c0.4,0.4,0.6,0.9,0.8,1.5C32.1,21.1,32.2,21.7,32.2,22.5z M27,13.5c0-0.4-0.1-0.8-0.2-1.1c-0.1-0.3-0.3-0.6-0.6-0.8
        c-0.2-0.3-0.5-0.5-0.9-0.6c-0.4-0.1-0.9-0.2-1.5-0.2h-2v5.5h2.3c0.6,0,1.1-0.1,1.4-0.2s0.6-0.3,0.8-0.6c0.2-0.3,0.4-0.6,0.5-0.9
        C26.9,14.3,27,13.9,27,13.5z M28,22.6c0-0.5-0.1-0.9-0.2-1.3c-0.2-0.4-0.4-0.7-0.7-1c-0.3-0.3-0.7-0.5-1.1-0.6
        c-0.5-0.1-1.1-0.2-1.8-0.2h-2.4v6h2.9c0.6,0,1-0.1,1.4-0.2s0.7-0.3,1-0.6c0.3-0.2,0.5-0.6,0.7-0.9C27.9,23.5,28,23.1,28,22.6z"/>
      
      <g class="st1">
        <path d="M40.1,8.7c0,0.8-0.2,1.4-0.5,1.7c-0.3,0.3-0.9,0.5-1.8,0.5c-0.9,0-1.5-0.2-1.8-0.5c-0.3-0.3-0.5-0.9-0.5-1.7
          S35.7,7.3,36,7s0.9-0.5,1.8-0.5c0.9,0,1.4,0.2,1.8,0.5C40,7.4,40.1,7.9,40.1,8.7z"/>
        <path d="M38.7,28.7h-1.9c-0.6,0-1-0.4-1-1V14c0-0.6,0.4-1,1-1h1.9c0.6,0,1,0.4,1,1v13.7C39.7,28.3,39.3,28.7,38.7,28.7
          z"/>
        <path d="M47.2,28.7h-1.9c-0.6,0-1-0.4-1-1v-8.9c0-0.6,0.4-1,1-1h1.9c0.6,0,1,0.4,1,1v8.9
          C48.2,28.3,47.7,28.7,47.2,28.7z"/>
        <path d="M55.6,28.7h-1.9c-0.6,0-1-0.4-1-1v-8.9c0-0.6,0.4-1,1-1h1.9c0.6,0,1,0.4,1,1v8.9
          C56.6,28.3,56.1,28.7,55.6,28.7z"/>
        <path d="M63.9,28.7H62c-0.6,0-1-0.4-1-1V14c0-0.6,0.4-1,1-1h1.9c0.6,0,1,0.4,1,1v13.7C64.9,28.3,64.5,28.7,63.9,28.7z"
          />
        <path d="M48.5,13.5c0,0.8-0.2,1.4-0.5,1.7s-0.9,0.5-1.8,0.5c-0.9,0-1.5-0.2-1.8-0.5s-0.5-0.9-0.5-1.7s0.2-1.4,0.5-1.7
          s0.9-0.5,1.8-0.5c0.9,0,1.4,0.2,1.8,0.5C48.3,12.1,48.5,12.7,48.5,13.5z"/>
        <path d="M56.9,13.5c0,0.8-0.2,1.4-0.5,1.7s-0.9,0.5-1.8,0.5c-0.9,0-1.5-0.2-1.8-0.5s-0.5-0.9-0.5-1.7s0.2-1.4,0.5-1.7
          s0.9-0.5,1.8-0.5c0.9,0,1.4,0.2,1.8,0.5C56.7,12.1,56.9,12.7,56.9,13.5z"/>
        <path d="M65.2,8.7c0,0.8-0.2,1.4-0.5,1.7c-0.3,0.3-0.9,0.5-1.8,0.5c-0.9,0-1.5-0.2-1.8-0.5c-0.3-0.3-0.5-0.9-0.5-1.7
          s0.2-1.4,0.5-1.7s0.9-0.5,1.8-0.5c0.9,0,1.4,0.2,1.8,0.5C65.1,7.4,65.2,7.9,65.2,8.7z"/>
      </g>
      <path d="M81.7,14.7c0,0.5-0.1,0.9-0.2,1.2c-0.1,0.3-0.3,0.4-0.4,0.4h-1.6c0.2,0.3,0.4,0.6,0.5,0.9
        c0.1,0.3,0.1,0.7,0.1,1c0,0.9-0.1,1.6-0.4,2.3c-0.3,0.7-0.6,1.2-1.1,1.7s-1.1,0.8-1.8,1c-0.7,0.2-1.5,0.4-2.3,0.4
        c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.2-0.9-0.4c-0.1,0.1-0.2,0.3-0.3,0.5s-0.1,0.4-0.1,0.7c0,0.3,0.1,0.6,0.4,0.8
        c0.3,0.2,0.6,0.3,1.1,0.3l3.3,0.1c0.8,0,1.5,0.1,2.1,0.4c0.6,0.2,1.1,0.5,1.5,0.9s0.7,0.8,1,1.3c0.2,0.5,0.3,1.1,0.3,1.8
        c0,0.7-0.1,1.4-0.4,2c-0.3,0.6-0.7,1.2-1.3,1.7c-0.6,0.5-1.3,0.8-2.2,1.1c-0.9,0.3-1.9,0.4-3.1,0.4c-1.2,0-2.2-0.1-3-0.3
        c-0.8-0.2-1.5-0.5-2-0.8s-0.9-0.8-1.2-1.3c-0.2-0.5-0.4-1-0.4-1.6c0-0.4,0-0.7,0.1-1s0.2-0.6,0.4-0.9c0.2-0.3,0.4-0.6,0.6-0.9
        c0.2-0.3,0.5-0.5,0.8-0.8c-0.4-0.3-0.8-0.6-1-1c-0.2-0.4-0.4-0.9-0.4-1.4c0-0.6,0.1-1.2,0.4-1.8c0.3-0.5,0.6-1,1.1-1.4
        c-0.4-0.4-0.6-0.8-0.9-1.4c-0.2-0.5-0.3-1.2-0.3-2c0-0.9,0.1-1.6,0.4-2.3c0.3-0.7,0.7-1.2,1.2-1.7s1.1-0.8,1.8-1.1
        c0.7-0.2,1.5-0.4,2.3-0.4c0.4,0,0.8,0,1.2,0.1c0.4-0.1,0.7,0,1.1,0.1h4.3c0.2,0,0.3,0.1,0.4,0.4C81.6,13.7,81.7,14.1,81.7,14.7z
        M77.6,29.8c0-0.5-0.2-0.9-0.6-1.1c-0.4-0.3-0.9-0.4-1.5-0.4l-2.8-0.1c-0.3,0.2-0.5,0.4-0.6,0.6c-0.2,0.2-0.3,0.4-0.4,0.5
        c-0.1,0.2-0.2,0.3-0.2,0.5s0,0.3,0,0.5c0,0.5,0.3,1,0.8,1.2c0.5,0.3,1.2,0.4,2.2,0.4c0.6,0,1.1-0.1,1.5-0.2s0.7-0.3,1-0.5
        c0.2-0.2,0.4-0.4,0.5-0.7C77.6,30.3,77.6,30,77.6,29.8z M76.7,18.1c0-0.8-0.2-1.4-0.6-1.8c-0.4-0.4-1-0.7-1.7-0.7
        c-0.4,0-0.7,0.1-1,0.2c-0.3,0.1-0.5,0.3-0.7,0.6c-0.2,0.2-0.3,0.5-0.4,0.8s-0.1,0.6-0.1,1c0,0.7,0.2,1.3,0.6,1.8
        c0.4,0.4,1,0.6,1.7,0.6c0.4,0,0.7-0.1,1-0.2c0.3-0.1,0.5-0.3,0.7-0.5c0.2-0.2,0.3-0.5,0.4-0.8C76.7,18.8,76.7,18.4,76.7,18.1z"/>
      <path d="M101.4,22.5c0,1.1-0.2,2.1-0.6,2.9s-0.9,1.5-1.6,2.1s-1.4,1-2.3,1.3c-0.9,0.2-1.8,0.4-2.8,0.4
        c-0.7,0-1.3-0.1-1.9-0.2s-1.1-0.3-1.6-0.4c-0.5-0.2-0.8-0.3-1.1-0.5c-0.3-0.2-0.5-0.3-0.7-0.5c-0.1-0.1-0.2-0.3-0.3-0.6
        c-0.1-0.3-0.1-0.7-0.1-1.2c0-0.3,0-0.6,0-0.9c0-0.2,0.1-0.4,0.1-0.6c0-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.1,0.3-0.1
        c0.1,0,0.3,0.1,0.6,0.3c0.3,0.2,0.6,0.4,1,0.6s0.9,0.4,1.5,0.6s1.2,0.3,2,0.3c0.5,0,0.9-0.1,1.3-0.2s0.7-0.3,1-0.5s0.5-0.5,0.6-0.9
        c0.1-0.3,0.2-0.7,0.2-1.1c0-0.5-0.1-0.9-0.4-1.2c-0.2-0.3-0.6-0.6-1-0.9s-0.8-0.5-1.3-0.8c-0.5-0.2-1-0.5-1.6-0.7
        c-0.5-0.3-1.1-0.6-1.6-0.9c-0.5-0.3-1-0.7-1.3-1.2c-0.4-0.5-0.7-1-1-1.6c-0.2-0.6-0.4-1.4-0.4-2.3c0-1,0.2-1.9,0.5-2.7
        c0.4-0.8,0.8-1.4,1.4-1.9s1.3-0.9,2.1-1.1c0.8-0.3,1.7-0.4,2.6-0.4c0.5,0,0.9,0,1.4,0.1c0.5,0.1,0.9,0.2,1.3,0.3S98.7,8,99,8.1
        c0.3,0.2,0.5,0.3,0.6,0.4c0.4,0.1,0.5,0.2,0.5,0.3c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.3,0.1,0.5c0,0.2,0,0.4,0,0.7c0,0.3,0,0.6,0,0.8
        c0,0.2,0,0.4-0.1,0.5c0,0.1-0.1,0.2-0.2,0.3c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.3-0.1-0.5-0.2c-0.2-0.2-0.5-0.3-0.9-0.5
        c-0.5-0.2-0.9-0.3-1.3-0.5c-0.5-0.1-1-0.2-1.6-0.2c-0.4,0-0.8,0.1-1.2,0.2c-0.3,0.1-0.6,0.3-0.8,0.5S93.1,11.7,93,12
        s-0.2,0.6-0.2,0.9c0,0.5,0.1,0.9,0.4,1.2c0.2,0.3,0.6,0.6,1,0.9c0.4,0.3,0.9,0.5,1.4,0.8c0.5,0.2,1,0.5,1.6,0.7
        c0.5,0.3,1.1,0.6,1.6,0.9c0.5,0.3,1,0.7,1.4,1.2s0.7,1,1,1.6C101.3,20.9,101.4,21.6,101.4,22.5z"/>
      <path d="M107.8,8.7c0,0.8-0.2,1.4-0.5,1.7c-0.3,0.3-0.9,0.5-1.8,0.5s-1.5-0.2-1.8-0.5c-0.3-0.3-0.5-0.9-0.5-1.7
        s0.2-1.4,0.5-1.7s0.9-0.5,1.8-0.5s1.4,0.2,1.8,0.5C107.7,7.4,107.8,7.9,107.8,8.7z M107.5,28.2c0,0.1,0,0.2-0.1,0.3
        c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0.1-0.3,0.1-0.6,0.1c-0.2,0-0.6,0-0.9,0s-0.7,0-0.9,0c-0.2,0-0.4-0.1-0.6-0.1
        c-0.1-0.1-0.3-0.1-0.3-0.2c-0.1-0.1-0.1-0.2-0.1-0.3V13.7c0-0.1,0-0.2,0.1-0.3s0.2-0.2,0.3-0.2c0.1-0.1,0.3-0.1,0.6-0.1
        c0.2,0,0.6,0,0.9,0s0.7,0,0.9,0c0.2,0,0.4,0.1,0.6,0.1c0.1,0.1,0.3,0.1,0.3,0.2c0.1,0.1,0.1,0.2,0.1,0.3V28.2z"/>
      <path d="M120.4,27.1c0,0.3,0,0.6,0,0.8c0,0.2-0.1,0.4-0.1,0.5c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0-0.2,0.1-0.3,0.1h-9
        c-0.3,0-0.5-0.1-0.6-0.3c-0.1-0.2-0.2-0.5-0.2-1v-0.8c0-0.2,0-0.4,0-0.5c0-0.2,0-0.3,0.1-0.5c0-0.1,0.1-0.3,0.2-0.5
        s0.2-0.3,0.3-0.5l5.1-8.4h-4.9c-0.2,0-0.4-0.1-0.5-0.4c-0.1-0.2-0.2-0.6-0.2-1.2c0-0.3,0-0.6,0-0.8s0.1-0.4,0.1-0.5
        c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.1,0.3-0.1h8.4c0.1,0,0.3,0,0.4,0s0.2,0.1,0.3,0.2c0.1,0.1,0.1,0.2,0.2,0.4
        c0,0.2,0.1,0.3,0.1,0.6V15c0,0.2,0,0.4,0,0.6c0,0.2-0.1,0.3-0.1,0.5c0,0.2-0.1,0.3-0.2,0.5s-0.2,0.3-0.3,0.5l-5.1,8.4h5.4
        c0.1,0,0.2,0,0.3,0.1c0.1,0,0.2,0.1,0.2,0.2c0.1,0.1,0.1,0.3,0.1,0.5C120.4,26.5,120.4,26.8,120.4,27.1z"/>
      <path d="M134.8,20.5c0,0.5-0.1,0.9-0.3,1.1c-0.2,0.2-0.5,0.4-0.9,0.4h-8.4c0,0.6,0.1,1.2,0.2,1.7s0.4,0.9,0.7,1.3
        c0.3,0.4,0.7,0.6,1.2,0.8c0.5,0.2,1,0.3,1.7,0.3s1.3-0.1,1.8-0.2s1-0.2,1.3-0.3c0.4-0.1,0.7-0.2,0.9-0.3c0.2-0.1,0.4-0.2,0.6-0.2
        c0.1,0,0.2,0,0.2,0.1s0.1,0.1,0.2,0.2c0,0.1,0.1,0.2,0.1,0.4c0,0.2,0,0.4,0,0.7c0,0.2,0,0.4,0,0.6s0,0.3,0,0.4s-0.1,0.2-0.1,0.3
        c0,0.1-0.1,0.2-0.2,0.2c-0.1,0.1-0.2,0.2-0.5,0.3c-0.3,0.1-0.7,0.3-1.1,0.4c-0.5,0.1-1,0.2-1.6,0.3c-0.6,0.1-1.2,0.1-1.9,0.1
        c-1.2,0-2.3-0.2-3.2-0.5c-0.9-0.3-1.7-0.8-2.3-1.5c-0.6-0.7-1.1-1.5-1.4-2.5s-0.5-2.2-0.5-3.6c0-1.3,0.2-2.5,0.5-3.5
        s0.8-1.9,1.4-2.7c0.6-0.7,1.3-1.3,2.2-1.7c0.9-0.4,1.8-0.6,2.9-0.6c1.1,0,2.1,0.2,2.9,0.5c0.8,0.4,1.5,0.9,2,1.5
        c0.5,0.6,0.9,1.4,1.2,2.3c0.2,0.9,0.4,1.8,0.4,2.8L134.8,20.5L134.8,20.5z M131,19.3c0-1.2-0.2-2.1-0.7-2.7c-0.5-0.7-1.2-1-2.2-1
        c-0.5,0-0.9,0.1-1.3,0.3s-0.7,0.5-0.9,0.8c-0.2,0.3-0.4,0.7-0.6,1.2c-0.1,0.5-0.2,0.9-0.2,1.4H131z"/>
      
    </g>
    <path d="M25.2,40.1v-3.7c0.1-0.4,0.5-0.6,1.1-0.7c0.8,0,1.2,0.3,1.2,0.8V40h7.1c0.4,0.1,0.6,0.4,0.6,1.1c0,0.8-0.2,1.1-0.7,1.1
      h-6.6c0.5,1.8,1.4,3.5,2.8,5.1c1.2,1.4,2.7,2.9,4.6,4.4c0.1,0.1,0.2,0.3,0.2,0.5c-0.1,0.8-0.4,1.3-1,1.5c-0.2,0-0.4-0.1-0.5-0.1
      c-0.7-0.2-2.1-1.5-4.2-3.9c-1.4-1.6-2.4-3.2-3.1-4.7c-0.8,1.9-1.8,3.5-3,4.7c-2.6,2.7-4.3,4-5.2,4.1c-0.3,0-0.5-0.1-0.6-0.2
      c-0.4-0.3-0.5-0.7-0.5-1.2c0-0.3,0.1-0.5,0.3-0.7c2.7-1.9,4.7-3.8,5.9-5.8c0.5-0.9,1.1-2.2,1.4-3.5h-6.9c-0.5,0-0.8-0.4-0.8-1.2
      c0-0.6,0.2-0.9,0.6-1.1C17.9,40.1,25.2,40.1,25.2,40.1z"/>
    <path d="M53.6,43.4c0.4,0.1,0.6,0.4,0.6,1.1c0,0.8-0.2,1.1-0.7,1.1h-17c-0.5,0-0.7-0.4-0.7-1.2c0-0.6,0.2-0.9,0.6-1.1h17.2V43.4z"
      />
    <path d="M57,49.4c-0.1,1.6-0.3,3-0.7,4.2c-0.1,0.3-0.4,0.4-0.9,0.4c-0.4,0-0.7-0.2-0.7-0.7c0.4-1.2,0.7-2.5,0.8-4.1
      c0.1-0.2,0.4-0.3,0.8-0.4C56.8,49,57,49.1,57,49.4z M60.3,43.2V44h3.5c0.3,0.1,0.4,0.3,0.4,0.8c0,0.6-0.2,0.8-0.5,0.8h-3.4v0.9
      c1,0,2-0.1,3.1-0.2c0.4,0,0.7,0.3,0.7,0.9c0,0.4-0.1,0.7-0.2,0.8c0,0.1-1.2,0.2-3.7,0.4c-1.6,0.1-3.2,0.2-4.8,0.3
      c-0.4,0-0.7-0.3-0.7-0.9c0-0.4,0.1-0.7,0.3-0.9c1.2,0,2.4-0.1,3.6-0.1v-1h-3.2c-0.4,0-0.6-0.3-0.6-0.9c0-0.4,0.1-0.7,0.4-0.8h3.3
      v-0.8H56c-0.6,0-1-0.4-1-1v-4.8c0-0.6,0.3-1,1-1h7c0.6,0.1,1,0.4,1,1.1v4.6c0,0.7-0.4,1.1-1.1,1.1L60.3,43.2L60.3,43.2z M56.8,38.3
      v2.8c0,0.2,0.2,0.3,0.4,0.3h1.4V38h-1.5C57,38,56.9,38.1,56.8,38.3z M58,38.5c0.2,0.4,0.3,1.1,0.5,2c0,0.3-0.1,0.4-0.2,0.4
      C58.2,41,58,41,57.7,41c-0.2,0-0.3-0.1-0.3-0.4C57.3,40,57.1,39.4,57,39c-0.1-0.2-0.1-0.4-0.1-0.5c0.1-0.2,0.3-0.3,0.5-0.3
      C57.7,38.2,57.9,38.3,58,38.5z M58.9,49.3c0.3,0.7,0.5,1.9,0.8,3.6c0,0.4-0.1,0.6-0.2,0.6c-0.2,0.1-0.4,0.2-0.8,0.2
      c-0.3,0-0.5-0.2-0.5-0.5c-0.2-1.4-0.4-2.4-0.6-3.1c-0.1-0.3-0.1-0.6-0.1-0.7c0.2-0.3,0.4-0.4,0.8-0.4
      C58.5,48.9,58.7,49.1,58.9,49.3z M61.3,49.2c0.3,0.6,0.6,1.8,0.8,3.4c0,0.4-0.1,0.5-0.2,0.6c-0.2,0.1-0.4,0.2-0.8,0.2
      c-0.3,0-0.5-0.2-0.5-0.5c-0.2-1.2-0.5-2.2-0.7-2.9c-0.1-0.3-0.1-0.5-0.1-0.7c0.2-0.3,0.4-0.4,0.8-0.4
      C60.9,48.8,61.1,48.9,61.3,49.2z M60.3,41.5h1.8c0.3,0,0.4-0.2,0.4-0.4v-2.7c0-0.2-0.1-0.4-0.4-0.4h-1.7L60.3,41.5L60.3,41.5z
      M61.9,38.4c0.1,0.1,0.2,0.2,0.2,0.4c-0.1,0.8-0.2,1.5-0.5,2.1c-0.1,0.2-0.3,0.3-0.5,0.3c-0.4,0-0.7-0.2-0.7-0.6
      c0-0.1,0-0.2,0.1-0.4c0.2-0.4,0.3-0.9,0.4-1.5c0.1-0.2,0.2-0.3,0.5-0.3C61.7,38.3,61.8,38.4,61.9,38.4z M63.6,48.9
      c0.3,0.5,0.5,1.3,0.7,2.5c0,0.3-0.1,0.5-0.2,0.6c-0.2,0.1-0.4,0.2-0.8,0.2c-0.3,0-0.4-0.2-0.5-0.5c-0.2-0.9-0.4-1.5-0.6-2
      c-0.1-0.3-0.1-0.5-0.1-0.7c0.2-0.3,0.4-0.4,0.8-0.4C63.2,48.5,63.4,48.6,63.6,48.9z M69,36.8v1.9h3.2c0.4,0,0.6,0.4,0.6,1
      c0,0.7-0.2,1.1-0.6,1.1h-3.1v2.9h2.3c0.7,0.1,1.1,0.5,1.1,1.4v7.4c0,0.9-0.4,1.3-1.2,1.3h-5.7c-0.7,0-1.1-0.4-1.1-1.2V45
      c0-0.7,0.4-1.2,1.1-1.2h1.5v-6.9c0-0.4,0.3-0.6,0.9-0.7C68.7,36.1,69,36.3,69,36.8z M66.3,46.1v5.2c0,0.3,0.2,0.4,0.5,0.4h3.3
      c0.3,0,0.4-0.2,0.4-0.5v-5.1c0-0.3-0.2-0.5-0.4-0.5h-3.3C66.6,45.7,66.4,45.8,66.3,46.1z"/>
    <path d="M86.3,37.5c0.2-0.4,0.4-0.9,0.6-1.3c0.2-0.2,0.5-0.3,1-0.3c0.6,0.2,0.9,0.5,0.9,1c0,0.2-0.1,0.4-0.2,0.6h8.1
      c0.3,0.1,0.5,0.4,0.5,1c0,0.7-0.2,1-0.6,1h-9.1c-0.4,0.5-0.7,1-1.1,1.5H95c0.6,0.1,1,0.4,1,0.9v8c0,1.3-0.1,2.2-0.3,2.9
      C95.5,53.6,94.6,54,93,54c-2,0-3.1-0.4-3.2-1.1c0-0.3,0-0.5,0.1-0.7c0.1-0.3,0.4-0.5,0.9-0.6c1.2,0.3,2,0.5,2.4,0.5
      s0.6-0.6,0.7-1.7H84v3.4c0,0.4-0.3,0.6-0.9,0.6s-1-0.2-1-0.6v-9c-0.3,0.2-0.6,0.4-0.9,0.5c-0.3,0.3-0.6,0.4-1,0.4
      c-0.5,0-0.9-0.3-1.1-0.8c0-0.3,0.1-0.6,0.2-0.8c2.5-1.3,4.4-2.8,5.9-4.5h-5c-0.4,0-0.7-0.4-0.7-1.1c0-0.5,0.2-0.8,0.5-0.9h6.3V37.5
      z M93.8,43.2c0-0.2-0.1-0.3-0.4-0.3h-8.9h-0.1c-0.2,0.2-0.4,0.4-0.6,0.5v1.2h10V43.2z M93.8,46.5h-10v1.8h10V46.5z"/>
    <path d="M102.8,36.2c0.1,0.2,0.1,0.3,0.1,0.5c-0.1,0.8-0.2,1.6-0.3,2.3h2.7c0.4,0,0.7,0.3,0.8,0.9c-0.1,3.5-0.9,6.6-2.2,9
      C104,49,104,49,104.1,49c1,0.8,1.6,1.4,1.8,1.8c0.1,0.2,0.2,0.4,0.2,0.6c0,0.7-0.4,1.1-1,1.2c-0.3,0-0.5-0.1-0.7-0.3
      c-0.4-0.4-1-0.9-1.7-1.6c-0.9,1.2-1.9,2.1-3,3C99.5,53.9,99.2,54,99,54c-0.5,0-0.9-0.4-1.1-1c0-0.4,0.1-0.7,0.3-0.9
      c1.1-0.9,2.1-1.8,2.9-2.8c-1.3-1-2.1-1.7-2.3-2.1c-0.2-0.1-0.2-0.3-0.2-0.6c0-0.1,0.1-0.3,0.2-0.6c0.6-1.7,1.1-3.4,1.5-5.1h-1.5
      c-0.5,0-0.7-0.4-0.7-1.1c0-0.6,0.2-0.9,0.6-1h2c0.2-0.9,0.3-1.8,0.4-2.6c0.1-0.3,0.4-0.4,1-0.4C102.3,36,102.6,36.1,102.8,36.2z
      M104.1,41.5c0-0.3-0.1-0.5-0.4-0.5h-1.6c-0.4,1.8-0.9,3.4-1.5,5c0,0.2,0.1,0.4,0.3,0.6c0.4,0.4,0.8,0.7,1.2,1.1
      C103.3,45.9,103.9,43.8,104.1,41.5z M114.1,37c0.6,0.1,1,0.5,1,1.1c0,0.4-0.2,0.7-0.4,1.1c-0.8,1-1.9,2.1-3.1,3.3
      c-0.1,0.1-0.1,0.2-0.1,0.3c0.3,0.5,0.6,1.1,0.8,1.7h3.2c0.4,0.1,0.6,0.5,0.6,1c0,0.7-0.3,1.1-0.7,1.1H113c0.2,0.8,0.3,1.6,0.4,2.4
      c0,2.5-0.5,4.1-1.6,4.8c-0.7,0-1.3,0.2-2.1,0.2c-1.6-0.1-2.8-0.4-3.5-0.9c-0.1-0.2-0.2-0.4-0.2-0.6c0-0.4,0.1-0.7,0.3-1
      c0.2-0.1,0.5-0.2,0.7-0.2c1.3,0.4,2.2,0.5,2.7,0.5s0.8-0.1,1-0.3c0.3-0.1,0.4-1.1,0.5-3.1c0-0.6-0.1-1.3-0.4-2h-4.5
      c-0.5,0-0.7-0.4-0.7-1.1c0-0.5,0.2-0.8,0.6-1h3.7c-0.2-0.4-0.4-0.7-0.6-1.1c-0.2-0.1-0.2-0.3-0.2-0.6c0-0.4,0.2-0.7,0.4-0.9
      c1-0.9,1.8-1.8,2.5-2.5c0,0-0.1,0-0.1-0.1h-5.5c-0.5,0-0.7-0.4-0.7-1s0.2-1,0.6-1h7.8V37z"/>
    <path d="M130.8,40.7c0.4,0.1,0.6,0.4,0.6,1c0,0.7-0.2,1-0.7,1H130c0.1,0.1,0.2,0.1,0.3,0.2c2,1.7,2.9,2.9,2.9,3.5
      c0,0.3-0.1,0.6-0.4,0.8c-0.2,0.2-0.4,0.3-0.7,0.3c-0.2,0-0.4-0.1-0.6-0.4c-0.3-0.3-0.5-0.6-0.8-0.9c-1.4,0.2-2.9,0.3-4.4,0.4v1.5
      h6.3c0.4,0.1,0.6,0.5,0.6,1c0,0.7-0.3,1-0.7,1h-6.2v1.6h7.7c0.4,0.1,0.6,0.4,0.6,1c0,0.7-0.2,1-0.7,1h-16.5c-0.4,0-0.7-0.4-0.7-1.1
      c0-0.5,0.2-0.8,0.6-0.9h7v-1.6h-5.9c-0.5,0-0.7-0.4-0.7-1.1c0-0.5,0.2-0.8,0.6-1h6v-1.4c-2,0.1-3.9,0.2-5.9,0.2
      c-0.5,0-0.8-0.3-0.9-0.9c0-0.5,0.1-0.9,0.4-1c1,0,2,0,2.9-0.1c0-0.1,0.1-0.1,0.1-0.2c0.6-0.7,1.2-1.3,1.7-2H120
      c-0.4,0-0.7-0.4-0.7-1.1c0-0.5,0.2-0.8,0.6-0.9h10.9V40.7z M126.5,36.7v0.8h6.6c0.9,0.1,1.4,0.7,1.4,1.7c-0.2,1.8-0.4,2.7-0.7,2.8
      c-0.1,0.2-0.3,0.2-0.6,0.2c-0.9-0.2-1.4-0.5-1.4-1c0-0.1,0.1-0.5,0.4-1.3c0-0.2-0.1-0.4-0.4-0.4h-12.6c-0.2,0-0.4,0.1-0.5,0.3v1.7
      c0,0.5-0.4,0.8-1,0.8c-0.5,0-0.9-0.2-1-0.6v-3.4c0.2-0.6,0.5-0.9,1-0.9h6.6v-0.8c0-0.4,0.3-0.7,1-0.8
      C126.1,35.9,126.5,36.1,126.5,36.7z M128.2,43.5c0-0.3,0-0.4,0.1-0.5c0.1-0.1,0.2-0.2,0.2-0.3h-3.7c-0.2,0.4-0.7,1-1.4,2
      c-0.1,0.1-0.1,0.1-0.1,0.2c2.1,0,4.1-0.2,5.7-0.3C128.5,44,128.2,43.7,128.2,43.5z"/>
    <path d="M145.6,52.3c-0.4,0.3-0.8,0.4-1.2,0.4c-0.5,0-0.9-0.2-1.2-0.4c-0.4-0.3-0.5-0.7-0.5-1.2s0.2-0.8,0.5-1.2
      c0.4-0.3,0.8-0.5,1.2-0.5c0.5,0,0.9,0.2,1.2,0.5c0.4,0.3,0.5,0.7,0.5,1.2S145.9,52,145.6,52.3z M145.1,47.4c0,0.4-0.4,0.7-0.9,0.7
      s-0.8-0.2-0.8-0.6l-0.4-7.7c-0.1-1.2,0.4-1.9,1.3-1.9c1,0,1.4,0.6,1.3,1.8L145.1,47.4z"/>
    <circle class="st2" cx="36.8" cy="8.7" r="0.4"/>
    <circle class="st2" cx="38.7" cy="8.7" r="0.4"/>
    <circle class="st2" cx="62" cy="8.7" r="0.4"/>
    <circle class="st2" cx="63.8" cy="8.7" r="0.4"/>
    <circle class="st2" cx="45.2" cy="13.5" r="0.4"/>
    <circle class="st2" cx="47.1" cy="13.5" r="0.4"/>
    <circle class="st2" cx="53.6" cy="13.5" r="0.4"/>
    <circle class="st2" cx="55.5" cy="13.5" r="0.4"/>
  </g>
  <g class="mo">
  <g>
    <defs>
      <path id="SVGID_1_" d="M224,35.9C224,35.9,223.9,35.9,224,35.9h-0.1C223.8,35.9,224,35.9,224,35.9C223.9,35.9,223.9,35.9,224,35.9
        z M224.4,35.8C224.4,35.9,224.3,35.8,224.4,35.8c-0.1,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.1-0.1C224.3,35.8,224.3,35.8,224.4,35.8z
        M224,35.9h0.1C224.1,36,224,36,224,35.9z M225.6,36.1c-0.1,0-0.1,0-0.1,0s0,0.1-0.1,0l-0.3-0.1c-0.1,0,0.1,0,0.1,0l-0.1-0.1
        c-0.1,0,0,0.1-0.1,0.1c-0.2,0-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.2-0.1c0.1,0,0.3,0.1,0.5,0.1c0,0-0.1,0-0.1-0.1
        c0.1,0,0.2,0.1,0.2,0.1C225.4,36,225.6,36,225.6,36.1C225.7,36,225.7,36.1,225.6,36.1C225.7,36.1,225.6,36,225.6,36.1
        c0.1,0,0.1-0.1,0.2,0c0.1,0,0.1,0.1,0.1,0.1h-0.1c-0.1,0-0.1,0.1-0.3,0.1c0.1,0,0.1-0.1,0.1-0.1C225.8,36.2,225.7,36.2,225.6,36.1
        C225.8,36.1,225.8,36.1,225.6,36.1L225.6,36.1z M224.6,36c0,0.1-0.1,0-0.1,0C224.4,36,224.5,36,224.6,36z M225.2,36.2L225.2,36.2
        c-0.1,0-0.1-0.1-0.2-0.1c-0.3-0.1,0.1,0,0.1,0C225.2,36.1,225.2,36.1,225.2,36.2z M224.7,36c-0.1,0-0.1,0-0.1-0.1
        c0.1,0,0.1,0,0.1-0.1C224.9,36,224.8,36,224.7,36z M224.5,35.9C224.4,35.8,224.5,35.8,224.5,35.9c0.1-0.1-0.1-0.1-0.1-0.1
        C224.3,35.8,224.4,35.8,224.5,35.9c0.1,0,0.1,0,0.1,0C224.5,35.9,224.5,35.8,224.5,35.9z M223.8,35.8c0-0.1,0.3,0,0.2,0
        C223.9,35.8,223.9,35.8,223.8,35.8z M204.3,36.7c0-0.1-0.1,0-0.1,0C204.2,36.7,204.3,36.7,204.3,36.7
        C204.4,36.7,204.3,36.7,204.3,36.7c0.1-0.1,0.2-0.1,0.3-0.1v0.1c0,0,0.2-0.1,0.1,0c-0.1,0.1-0.1,0-0.2,0
        C204.5,36.6,204.4,36.7,204.3,36.7c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.6-0.1,0.8-0.2c-0.5,0.1-0.9,0.3-1.4,0.5
        C204,36.8,204.3,36.7,204.3,36.7z M203.4,37.2c0,0.1-0.1,0.1-0.2,0.1c-0.1,0,0,0-0.1,0.1C203,37.3,203.4,37.2,203.4,37.2z
        M203.7,37.2C203.7,37.2,203.7,37.1,203.7,37.2C203.6,37.2,203.6,37.2,203.7,37.2c-0.1,0.1-0.2,0-0.3,0.1l0,0
        c0.2-0.1,0.4-0.2,0.6-0.3c0.1,0,0.1,0,0.2-0.1c0.1,0,0,0,0,0.1c0.1,0,0.1,0,0.3-0.1c0.1,0.1-0.3,0.1-0.1,0.1c-0.1,0.1-0.1,0-0.1,0
        c-0.1,0.1-0.1,0-0.1,0s-0.1,0.1-0.1,0.1c0,0,0-0.1-0.1,0l0,0c0.1,0,0.1,0,0.1,0v0.1c0.1,0,0.1-0.1,0.1,0
        C204,37.1,203.9,37.1,203.7,37.2z M203.8,37.1c0.1,0,0.1,0,0.1,0C203.9,37,203.8,37,203.8,37.1z M224.2,35.8
        c-0.1,0-0.1-0.1-0.3-0.1C224.1,35.7,224.1,35.7,224.2,35.8c0.1-0.1,0.2-0.1,0.3,0s-0.1,0-0.1,0C224.3,35.8,224.3,35.8,224.2,35.8z
        M202.9,37.5c-0.1,0.1-0.3,0.1-0.1,0C202.7,37.6,202.9,37.5,202.9,37.5z M203.1,37.4l-0.1,0.1C202.9,37.4,203.1,37.3,203.1,37.4z
        M223.7,35.9L223.7,35.9L223.7,35.9L223.7,35.9C223.8,35.9,223.7,35.9,223.7,35.9z M204.6,36.9c0,0.1-0.1,0.1-0.1,0.1
        c-0.2,0-0.4,0.1-0.6,0.1c0.2-0.1,0.4-0.1,0.6-0.2C204.5,36.9,204.5,36.9,204.6,36.9z M214.4,35.8c-0.1,0.1-0.3,0.1-0.5,0.1
        c0-0.1,0.1,0,0.3,0C214.2,35.8,214.3,35.8,214.4,35.8z M226,36.3C225.9,36.2,226,36.3,226,36.3C225.9,36.3,226,36.3,226,36.3
        c-0.1,0.1-0.1,0-0.1,0s-0.2,0-0.1-0.1c0.1,0,0.1,0,0.1,0.1c0.1,0,0-0.1,0-0.1S226,36.3,226,36.3c0.1,0,0.1-0.1,0.2,0h-0.1
        C226.1,36.3,226,36.3,226,36.3z M205.2,36.7c0,0.1-0.1,0.1-0.1,0.1l0,0c-0.1,0-0.1,0.1-0.2,0.1c0,0-0.1,0.1-0.1,0
        c0.1,0,0.2-0.1,0.3-0.1C205,36.7,205.1,36.8,205.2,36.7z M207.2,44.1c0,0.1-0.3,0-0.3-0.1c0.1,0,0,0.1,0.1,0.1
        c0.1,0,0-0.1-0.1-0.1c-0.1-0.1-0.1,0.1-0.2,0c0-0.1,0.1-0.1,0.3,0C207.1,44.1,207,44.1,207.2,44.1z M204.7,43.3
        c-0.1,0-0.2-0.1-0.1-0.1C204.5,43.2,204.5,43.2,204.7,43.3c0-0.1-0.1-0.1-0.1-0.1c0.1,0.1,0.3,0.1,0.2,0.1c0.1,0,0.1,0.1,0.1,0.1
        c-0.1,0-0.1-0.1-0.1-0.1C204.8,43.2,204.8,43.2,204.7,43.3C204.8,43.3,204.8,43.3,204.7,43.3z M205.1,43.6c-0.1,0-0.3-0.1-0.4-0.1
        c0.1,0,0.1,0,0.1-0.1c0.1,0,0,0.1,0,0.1c0.1,0.1,0.1,0,0.1,0h0.1c0,0,0,0,0-0.1c0,0-0.1,0,0,0c0,0-0.1-0.1-0.1-0.1
        c0.1,0,0.1,0,0.2,0.1c0.1,0.1,0.1,0,0.3,0.1c0,0-0.1,0-0.2-0.1v0.1c0.1,0.1,0.1,0,0.2,0.1c0.1,0,0,0,0.1,0s0.1,0.1,0.1,0.1
        c0.1,0,0.1,0,0.2,0.1c0,0.1-0.1,0-0.2,0c-0.1,0-0.1,0-0.1,0.1C205,43.7,205.2,43.6,205.1,43.6C205,43.6,205,43.6,205.1,43.6z
        M206.8,43.9c0.1,0,0.1,0,0.1,0.1c0.1,0,0.1,0,0.1,0c0.1,0,0.1,0.1,0.2,0.1c-0.1,0.1-0.2-0.1-0.3-0.1
        C206.9,43.9,206.8,43.9,206.8,43.9z M206.3,43.8h0.1c0.1,0,0.1,0,0.3,0c-0.1,0-0.1,0.1,0.1,0.1C206.7,43.9,206.6,43.8,206.3,43.8
        C206.4,43.8,206.4,43.8,206.3,43.8z M203.7,42.9L203.7,42.9C203.8,43,203.8,43,203.7,42.9C203.6,42.9,203.7,43,203.7,42.9z
        M203.7,43.1c0.1,0,0.1,0.1,0.1,0.1h0.1c0,0,0,0,0,0.1C203.9,43.2,203.6,43.1,203.7,43.1z M204,43.2c0.1,0,0.1,0.1,0.3,0.1
        c0-0.1,0.2,0.1,0.3,0.1c0,0.1-0.1,0-0.3-0.1C204.2,43.3,204,43.3,204,43.2z M203.9,43.1C203.9,43.1,204,43.2,203.9,43.1
        C204,43.1,204,43.1,203.9,43.1C203.9,43,204.1,43.1,203.9,43.1c0.1-0.1,0.1,0,0.2,0c0,0,0.1,0,0.1,0.1c0,0,0,0,0,0.1
        c0.1,0,0.1,0,0.1,0.1c0.1,0,0.1,0.1,0.1,0.1c-0.1-0.1-0.2-0.1-0.3-0.1s-0.1-0.1-0.1-0.1l0.1,0.1C204.1,43.2,204.1,43.2,203.9,43.1
        z M207.2,44C207.2,43.9,207.2,43.9,207.2,44c0.1,0,0.4,0.1,0.6,0.1c0.1,0,0.1,0.1,0,0C207.6,44.1,207.4,44.1,207.2,44z M204.2,43
        c0.1,0,0.1-0.1,0.1,0C204.3,43,204.4,43.1,204.2,43c0.1,0.1,0.2,0.1,0.1,0.1c-0.1-0.1-0.3-0.1-0.4-0.2c0.1,0-0.1,0,0-0.1
        C204.1,43,204.1,43,204.2,43z M204.3,43c-0.1-0.1,0.2,0.1,0.2,0.1c0.1,0.1,0.1,0,0.2,0.1C204.5,43.1,204.4,43,204.3,43
        C204.5,43,204.4,43,204.3,43z M206.3,43.9C206.3,43.9,206.3,43.9,206.3,43.9c0.1,0,0.1,0.1,0.2,0.1c-0.1,0,0,0.1-0.1,0.1
        c0-0.1,0.1,0,0.1-0.1c-0.1,0-0.2,0-0.1,0.1l0,0h-0.1C206.2,43.9,206.3,43.9,206.3,43.9c-0.1,0-0.2,0-0.3-0.1c0-0.1,0.1,0,0.1,0
        C206.2,43.8,206.2,43.8,206.3,43.9z M209.9,44.6c-0.1-0.1,0.3,0.1,0.3,0.1C210.2,44.8,210,44.6,209.9,44.6z M208.6,44.6
        c0.1,0,0.1,0.1,0.2,0.1S208.7,44.7,208.6,44.6c0,0.1-0.1,0-0.1,0.1c0.1,0.1,0.3,0.1,0.3,0.1l0,0c0.1,0,0.1,0,0.1,0
        c0.1,0,0.1,0,0.1,0.1c0.1,0,0.2,0,0.2,0.1c0.1,0,0,0.1,0.1,0.1c0.1,0,0,0,0,0s0.1,0.1,0.3,0.1c-0.1,0-0.1,0-0.1,0l0,0
        c0.1,0,0.1,0,0.1,0c-0.1,0.1,0.2,0.1,0.3,0.1c-0.1,0-0.1,0-0.2,0c0,0,0.1,0,0.1,0.1c-0.1,0-0.5-0.1-0.6-0.1c-0.1,0-0.1,0-0.1-0.1
        c0,0,0,0-0.1,0S209,45,209,45c-0.1,0-0.1,0-0.1,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.3-0.1-0.4-0.1c-0.1,0-0.2-0.1-0.3-0.1
        c-0.1,0-0.1,0-0.1,0c-0.2-0.1-0.4-0.1-0.6-0.1c0,0-0.1,0-0.1-0.1c0,0,0.2,0.1,0.1,0c-0.1,0-0.2,0-0.3,0c-0.1-0.1-0.4-0.1-0.6-0.2
        c-0.1,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.2-0.1-0.2-0.1c0.1-0.1,0.2,0.1,0.3,0.1s0.1,0.1,0.3,0.1h0.1l0.1,0.1h0.1
        c0.1,0,0.1,0.1,0.3,0.1c0,0.1-0.1,0,0,0.1c0.1,0,0,0,0-0.1c0.1,0,0.1,0,0.2,0c0.2,0.1,0.4,0.1,0.6,0.2c0.1,0,0.2,0,0.3,0
        c0.1,0,0.1,0.1,0.3,0.1v0.1C208.6,44.6,208.6,44.6,208.6,44.6z M207.5,44.3C207.5,44.4,207.6,44.4,207.5,44.3c0.1,0,0,0-0.1,0
        C207.4,44.3,207.4,44.4,207.5,44.3C207.5,44.4,207.4,44.3,207.5,44.3z M207.7,44.4c-0.1,0-0.1,0-0.1,0h-0.1
        C207.7,44.4,207.5,44.5,207.7,44.4C207.7,44.5,207.7,44.5,207.7,44.4z M207.9,44.6c0-0.1-0.1-0.1-0.1-0.1c-0.1,0,0,0.1,0,0.1
        C207.9,44.6,207.9,44.5,207.9,44.6z M209.2,44.9C209.2,44.9,209.2,45,209.2,44.9c0.1,0.1,0.2,0.1,0.2,0
        C209.3,44.9,209.2,44.9,209.2,44.9z M208.3,44.7c-0.1,0-0.3-0.1-0.3-0.1C208.1,44.6,208.4,44.7,208.3,44.7z M208.5,44.6
        c0-0.1-0.3-0.1-0.3-0.1C208.3,44.6,208.3,44.6,208.5,44.6z M202.7,37.6c-0.1,0,0,0.1-0.1,0.1C202.5,37.6,202.6,37.6,202.7,37.6
        C202.7,37.5,202.7,37.6,202.7,37.6z M212.8,45.5c-0.1,0.1-0.1-0.1-0.3-0.1C212.4,45.4,212.7,45.5,212.8,45.5z M207.2,44.1
        c0.1,0,0.1,0.1,0.1,0.1L207.2,44.1C207,44.1,207.2,44.1,207.2,44.1z M207.4,44.3L207.4,44.3c-0.1-0.1-0.2-0.1-0.3-0.1
        C207.2,44.2,207.2,44.3,207.4,44.3z M208.1,44.2h0.1H208.1h0.1c0.1,0,0.1,0,0,0c0.1,0,0.1,0.1,0.1,0.1v-0.1
        c0.1,0.1,0.1,0.1,0.3,0.1h-0.1l-0.1-0.1h-0.1C208.3,44.3,208.3,44.3,208.1,44.2C208.1,44.2,208.1,44.2,208.1,44.2z M226.5,36.5
        c0,0.1-0.1,0-0.1,0C226.3,36.4,226.4,36.4,226.5,36.5z M207.9,44.3c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0.1,0.2,0.1
        c0.1,0.1,0.1,0,0.3,0.1c-0.1,0-0.1,0-0.3,0C208.1,44.3,207.9,44.3,207.9,44.3z M207.8,44.3c-0.1,0.1-0.3-0.1-0.4-0.1
        c0.1-0.1,0,0-0.1-0.1C207.4,44.1,207.7,44.3,207.8,44.3z M208,44.4c0.1,0,0.1,0,0.1,0C208.2,44.5,208,44.4,208,44.4
        c-0.1,0-0.3-0.1-0.5-0.1C207.5,44.3,207.9,44.4,208,44.4z M212.9,44.6c-0.1,0-0.1,0.1-0.3,0.1h-0.1c-0.1,0-0.2,0-0.3-0.1
        c-0.1,0-0.1,0-0.1,0c-0.4-0.1-0.8-0.1-1-0.3h0.1c0-0.1-0.1-0.1-0.1-0.1c0-0.1-0.1-0.1-0.2-0.1c0.1,0.1,0.1,0.1,0.3,0.1
        c-0.1,0-0.2-0.1-0.3-0.1c0.1,0-0.1-0.1,0.1-0.1c-0.1,0-0.1-0.1-0.2-0.1c0,0.1,0.1,0.1,0.1,0.1l-0.1-0.1c0.2,0,0-0.1-0.1-0.1
        c0,0,0.1,0,0.1,0.1c0.1,0.1-0.1-0.1-0.3,0c0,0-0.1,0-0.1-0.1c-0.1,0-0.2-0.1-0.3-0.1c0.1,0,0.3,0.1,0.4,0c0,0,0,0,0.1,0.1
        c0.1,0,0,0,0-0.1c0.1,0.1,0.1,0,0.3,0.1c0,0.1-0.1,0-0.1,0c-0.1,0.1,0.2,0.1,0.3,0.1s0.1,0.1,0.2,0.1h0.1h-0.1
        c-0.1,0-0.1-0.1-0.1-0.1c-0.1,0-0.1,0-0.3-0.1c0.3,0.1,0.6,0.1,0.8,0.1c-0.1,0.1-0.1,0.1-0.1,0.1c0.1,0,0.1,0,0.2,0
        c0.1,0-0.1-0.1,0-0.1l0.1,0.1c0-0.1-0.1-0.1-0.2-0.1c0.1,0,0.3,0.1,0.5,0.1h-0.1c0.1,0,0.1,0,0.1,0c0,0,0,0-0.1-0.1
        c0.1,0,0.3,0.1,0.6,0.1c-0.1,0-0.1,0,0,0.1c0.1,0,0.1,0,0.1,0c0,0,0.1,0,0.2,0.1C212.8,44.4,212.8,44.5,212.9,44.6z M210.7,44.1
        c0-0.1-0.2-0.1-0.2-0.1C210.5,44.1,210.6,44.1,210.7,44.1z M211.3,44.2c-0.1-0.1-0.1-0.1-0.1-0.1c-0.1-0.1-0.1,0.1-0.1,0.1
        c-0.1-0.1-0.3-0.1-0.3-0.1c0.1,0.1,0.1,0,0.1,0C211,44.1,211.1,44.3,211.3,44.2z M211.9,44.3c-0.1-0.1-0.1-0.1-0.2-0.1
        c-0.1,0-0.2-0.1-0.1,0l0.1,0.1C211.7,44.3,211.8,44.3,211.9,44.3z M211.8,44.4c0,0,0.1,0.1,0.1,0.1
        C211.9,44.4,211.9,44.4,211.8,44.4z M212.1,44.3c-0.1,0-0.1,0-0.1,0C211.9,44.3,212,44.3,212.1,44.3L212.1,44.3z M212.2,44.3
        C212.2,44.3,212,44.4,212.2,44.3C212.2,44.4,212.3,44.3,212.2,44.3z M212.6,44.4L212.6,44.4C212.6,44.4,212.6,44.5,212.6,44.4
        C212.7,44.4,212.6,44.4,212.6,44.4z M201.1,38.9c0-0.1,0.1-0.2,0.1-0.1C201.2,38.8,201.2,38.8,201.1,38.9
        C201.1,38.9,201.2,38.9,201.1,38.9z M201.2,38.8c0.1-0.1,0,0.1-0.1,0.1C201.2,38.9,201.2,38.9,201.2,38.8z M201,39.6
        c0,0-0.1-0.2,0-0.3c0-0.1,0-0.1,0-0.1l0,0l0,0c0.1,0.1-0.1,0.3,0,0.3C201.1,39.6,201,39.6,201,39.6z M201.3,38.7
        c0.1-0.1,0.1-0.1,0.1-0.2C201.4,38.5,201.4,38.6,201.3,38.7C201.3,38.7,201.2,38.7,201.3,38.7C201.2,38.7,201.2,38.8,201.3,38.7
        C201.2,38.7,201.2,38.6,201.3,38.7C201.3,38.6,201.3,38.6,201.3,38.7z M200.9,39.3c0.1-0.2,0.1-0.3,0.1-0.4c0.1,0.1,0,0.1,0,0.3
        c-0.1,0,0-0.1,0-0.1C201,39.1,201,39.2,200.9,39.3z M205,43.2C205,43.2,205.1,43.2,205,43.2c0.1-0.1,0.1,0,0.1,0.1L205,43.2z
        M201.5,38.3c0,0-0.1,0-0.1,0.1c0,0,0.1-0.1,0-0.1C201.4,38.3,201.5,38.3,201.5,38.3z M202.5,37.8c0,0-0.2,0.2-0.3,0.2
        s0.1,0,0.1-0.1C202.4,37.8,202.5,37.8,202.5,37.8z M200.9,39.6c0,0.2,0.1,0,0.1,0.2c-0.1,0-0.1-0.1-0.1-0.1c0-0.1,0-0.3,0.1-0.3
        C200.9,39.5,200.9,39.6,200.9,39.6z M202.1,38.1c0,0.1-0.2,0.1-0.3,0.3C201.9,38.2,202.1,38.1,202.1,38.1z M201.8,38.2
        c-0.1,0-0.1,0.1-0.1,0.1C201.6,38.3,201.7,38.2,201.8,38.2C201.8,38.1,201.8,38.1,201.8,38.2z M202.4,37.7c0,0,0.1,0,0.1,0
        c0,0.1-0.2,0.1-0.3,0.2h-0.1c-0.1,0.1,0.1,0,0,0.1c0,0-0.1,0-0.1,0.1c-0.1,0,0.1-0.1,0-0.1c0,0,0.1-0.1,0.1-0.1s0-0.1,0.1-0.1
        c0,0,0,0.1-0.1,0.1h0.1C202.3,37.8,202.3,37.7,202.4,37.7c0,0,0.1-0.1,0.1-0.1v0.1C202.5,37.7,202.5,37.6,202.4,37.7z M201.6,38.5
        c-0.1,0-0.1,0.1-0.1,0.1c0,0-0.1,0-0.1,0.1c0,0,0,0.1-0.1,0.1c-0.1,0.2-0.2,0.4-0.2,0.7c-0.1,0,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.1
        l0,0c0-0.1,0.1-0.1,0.1-0.1c0-0.3,0.1-0.3,0.2-0.5C201.6,38.5,201.6,38.5,201.6,38.5z M205.3,43.3c-0.1-0.1,0.2,0,0.3,0.1
        C205.5,43.4,205.3,43.3,205.3,43.3z M205.6,43.7c-0.1,0-0.1-0.1-0.1-0.1C205.4,43.6,205.6,43.7,205.6,43.7s0.2,0,0.3,0.1
        c0.1,0,0.1,0.1,0.2,0.1c0,0,0.1,0.1,0.1,0.1c-0.1,0-0.2-0.1-0.3-0.1C205.7,43.8,205.7,43.8,205.6,43.7z M205.4,43.4
        C205.4,43.5,205.2,43.4,205.4,43.4c-0.1,0.1-0.1,0-0.1,0C205.2,43.4,205.2,43.4,205.4,43.4z M205.2,43.4c-0.1,0,0,0.1-0.1,0
        c0-0.1,0.1,0,0-0.1C205.2,43.4,205.2,43.4,205.2,43.4z M205.2,43.7c0.1,0,0.1,0.1,0.2,0.1C205.5,43.7,205.2,43.7,205.2,43.7z
        M201.5,40.8c0.1,0.1,0,0.1,0.1,0.1C201.5,41,201.5,40.8,201.5,40.8C201.4,40.8,201.5,40.8,201.5,40.8z M204,42.5
        c0.1,0,0.3,0.1,0.5,0.2c0.1,0.1,0.2,0,0.3,0.1c0,0.1-0.1,0-0.2-0.1c-0.1,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.1,0s-0.1,0-0.1-0.1
        C204.1,42.5,204.1,42.5,204,42.5z M205.7,43.7c0,0-0.1,0-0.1,0C205.6,43.7,205.7,43.7,205.7,43.7z M206.1,43.7
        c0.1,0,0.1,0.1,0.1,0.1c-0.1,0.1-0.3-0.1-0.6-0.1c-0.1,0,0,0-0.1,0c-0.1-0.1-0.1-0.1-0.3-0.1c-0.1,0-0.1-0.1-0.1-0.1
        c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1-0.1,0.1,0h-0.1C205.9,43.6,206.1,43.7,206.1,43.7z M205.7,43.2c0.1-0.1,0.1,0,0.3,0.1
        c0.1,0,0.3,0,0.2,0.1C206,43.2,205.7,43.2,205.7,43.2z M223.6,45c0.1-0.1,0.3-0.1,0.6-0.2C224,44.8,223.7,44.9,223.6,45z
        M204.2,36.1c0,0.1-0.1,0.1-0.2,0.1C204,36.1,204.1,36.1,204.2,36.1z M204,36.3l0.3-0.1L204,36.3z M204,36.1
        c0-0.1,0.1-0.1,0.1-0.1C204.1,36,204.1,36.1,204,36.1z M205.9,35.6c0,0,0.1-0.1,0.2-0.1C206.1,35.6,206,35.6,205.9,35.6z
        M203.9,36c-0.2,0.1-0.3,0.1-0.5,0.2c0-0.1-0.1,0.1-0.1,0c0.1,0,0.2-0.1,0.3-0.1l0,0l0.1-0.1c0.1,0,0,0,0-0.1
        C203.8,36,203.9,35.9,203.9,36L203.9,36c0.1-0.1,0.1-0.1,0.2-0.1c0,0-0.1,0.1-0.1,0.1C204,36,203.9,36,203.9,36S203.9,36,203.9,36
        z M203.4,36.5c0-0.1,0.2-0.1,0.3-0.1C203.6,36.4,203.5,36.4,203.4,36.5z M206.3,35.4L206.3,35.4l0.1-0.1c0.1,0,0.1,0,0.2-0.1
        c-0.1,0.1,0,0.1-0.1,0.1c-0.1,0,0,0,0-0.1C206.5,35.2,206.6,35.3,206.3,35.4c0-0.1-0.1,0-0.2,0C206.1,35.4,206.3,35.4,206.3,35.4
        C206.4,35.4,206.4,35.4,206.3,35.4z M203.4,36.3c0,0.1-0.1,0.1-0.2,0.1C203.2,36.3,203.3,36.3,203.4,36.3z M203.6,36l0.1-0.1
        C203.8,36,203.7,36,203.6,36z M206.9,35.2c0.1,0,0.1-0.1,0.2-0.1C207.1,35.1,207,35.1,206.9,35.2z M207.8,35L207.8,35
        c0,0,0-0.1,0.1-0.1C207.9,35,207.8,35,207.8,35z M203.2,36.5c-0.3,0.1-0.4,0.2-0.9,0.3c-0.1,0,0.1-0.1,0.1-0.1
        C202.7,36.7,203.1,36.6,203.2,36.5C203.3,36.5,203.3,36.5,203.2,36.5z M209.1,34.8C209.2,34.8,209.2,34.8,209.1,34.8
        C209.1,34.9,209.1,34.8,209.1,34.8z M210.5,34.5c0.1,0,0.1,0,0.1,0C210.6,34.5,210.5,34.5,210.5,34.5z M209.2,34.6
        c0.2-0.1,0.4-0.1,0.6-0.1c0.1,0,0.1-0.1,0.2-0.1l0,0c0.1,0,0.2-0.1,0.3-0.1c-0.1,0.1,0.1,0.1,0.1,0.1c-0.1,0.1-0.3,0-0.3,0
        c0,0.1-0.1,0.1-0.3,0.1c0,0,0.1,0,0.1-0.1s-0.1,0-0.1,0s-0.1,0.1-0.2,0l0,0c-0.1,0-0.1,0-0.1,0.1c-0.1,0-0.1,0-0.1,0
        c-0.1,0-0.1,0.1-0.1,0H209.2C209.3,34.7,209.5,34.6,209.2,34.6z M209.4,34.7c0.1,0,0.3,0.1,0.3-0.1
        C209.6,34.6,209.4,34.6,209.4,34.7z M206.8,35.4c0,0.1-0.1,0.1-0.2,0.1C206.6,35.4,206.8,35.4,206.8,35.4z M206.9,35.4
        c-0.1,0.1-0.3,0.1-0.3,0.1C206.6,35.4,206.8,35.4,206.9,35.4z M211.2,34.8c0.1-0.1,0.1,0,0.3,0C211.5,34.8,211.3,34.8,211.2,34.8z
        M210.1,34.9c0.1-0.1,0.3-0.1,0.4-0.1c0.1,0,0.6-0.1,0.3,0C210.7,34.9,210.4,34.9,210.1,34.9z M220.4,35.3c0.1,0.1-0.2,0-0.3,0
        C220.1,35.3,220.2,35.2,220.4,35.3L220.4,35.3z M220.9,35.4c-0.1,0-0.2,0-0.3,0C220.6,35.3,220.9,35.3,220.9,35.4z M221.1,35.4
        C221.1,35.4,221.1,35.5,221.1,35.4c-0.1,0.1-0.1,0-0.1,0C220.9,35.4,221.1,35.5,221.1,35.4z M202.1,37c-0.1,0.1-0.3,0.2-0.4,0.3
        C201.8,37.2,202,37.1,202.1,37z M210.6,34.6c0-0.1,0.3-0.1,0.5-0.1c0.1,0,0,0,0,0c0.1-0.1,0.2,0,0.3,0c0.1,0,0,0,0,0
        c0.1,0,0.3,0,0.3,0c0.1,0,0.2,0,0.1-0.1c0.1,0,0.3,0,0.4,0c0.1,0,0.1,0.1,0.2,0.1c0.1,0-0.2,0-0.1-0.1c0.1,0,0.1,0,0.1,0
        c0.2,0,0.5,0,0.7,0c0.1,0,0.1,0,0.1,0.1c0.1-0.1,0.2-0.1,0.4-0.1c0-0.1,0.1,0,0.3-0.1h-0.1c0.1,0.1,0.2,0,0.3,0s0,0,0,0
        c0-0.1,0.1,0,0.1,0h0.1c0.1,0,0.1,0,0.1,0s0.1,0,0.1,0c0.1,0,0.1,0,0.1-0.1c0.1,0,0.1,0,0.1,0s0,0,0.1,0c0.1,0,0.2,0,0.3,0h-0.1
        c0.1,0,0.3,0,0.4,0h0.1c0,0,0.1,0,0.1,0s0,0,0,0c0.1,0,0.1,0,0.2,0c0,0-0.1,0-0.1,0c0.1,0-0.1,0.1,0,0.1c0,0,0,0,0.1,0
        c0.1,0,0,0,0-0.1c0,0,0.1,0,0.1,0h0.1c0,0,0.1,0.1,0.1,0c0.1,0,0.1,0,0.1,0c0.1,0,0.1,0,0.1,0c0.1,0,0.2,0.1,0.3,0
        c0.1,0,0.1,0.1,0.3,0.1c0,0,0,0-0.1,0c0.1,0,0.1,0,0.2,0s0-0.1,0.1-0.1c0.1,0,0.1,0,0.2,0c0,0,0.1,0,0.1,0c0.2,0,0.7,0,1,0.1
        c0.1,0,0.2,0,0.2,0c0.1,0,0.1,0,0.1,0c0.3,0,0.6,0,0.9,0.1c-0.1,0-0.3,0-0.3,0c-0.1,0.1,0.1,0,0.2,0c0.1,0,0.1,0,0.1,0
        c0.1,0,0.1,0,0.1,0c0.1,0.1,0.3,0.1,0.5,0c0.3,0,0.5,0,0.7,0.1c0.2-0.1,0.6,0,0.8,0c0.1-0.1,0.3,0,0.6,0c0.1,0,0.3,0,0.5,0
        c0.2,0,0.4,0,0.6,0.1c0.2,0,0.3,0.1,0.5,0.1c0.1,0.1,0.1,0,0.3,0.1c0.1,0,0.1,0,0.1,0c0.2,0,0.4,0.1,0.6,0.1
        c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.2,0s0.1,0.1,0.2,0.1c0.1,0,0.2,0,0.3,0.1c0,0,0,0,0.1,0s0.1,0.1,0.3,0.1
        c0.1,0.1,0.1,0.1,0.3,0.1c0.1,0.1-0.1,0-0.1,0.1s0.1,0.1,0.2,0.1s0,0,0.1-0.1c0.1,0,0.1,0.1,0.1,0.1c0.1,0.1,0.1,0,0.2,0.1v0.1
        c0.1,0.1,0.1,0,0.2,0.1h-0.1c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0,0,0,0c0.1,0,0.3,0.1,0.3,0.2c0.1,0,0.1,0,0.1,0
        c0.1,0,0.1,0.1,0.2,0.1c0,0,0,0.1,0.1,0.1c0,0,0.1,0,0.1,0.1V36c0.1,0.1,0,0,0,0.1c0.1,0.1,0.1,0,0.1,0.1c0.2,0.1,0.2,0.1,0.3,0.1
        l-0.1-0.1c-0.1-0.1,0-0.1,0-0.1c0.1,0.1,0.1,0.1,0.2,0.1l-0.1-0.1c0.1,0.1,0.3,0.2,0.4,0.2c0.1,0.1,0.3,0.2,0.2,0.2
        c0.1,0.1,0.1,0.1,0.2,0.1c0.1,0.1,0,0-0.1-0.1c0,0.1,0.1,0.2,0,0.1c0,0.1,0.1,0.1,0.2,0.1c0-0.1-0.1-0.1-0.1-0.1
        c0.1,0,0-0.1,0.1,0c0.1,0.1-0.1,0-0.1,0.1c0.1,0.1,0.1,0.1,0.1,0.1c0.1,0.1,0-0.1,0.1-0.1c0,0.1,0.1,0.1,0.2,0.3
        c0,0.1-0.1,0,0,0.1s0.1,0.1,0.1,0.1l-0.1-0.1c0-0.1,0.1,0.1,0.1,0.1c0-0.1-0.1-0.1-0.1-0.2c0.1,0,0.1,0.2,0.3,0.3l-0.1-0.1
        c0.1,0.2,0.1,0.2,0.1,0.3c0,0,0,0,0.1,0.1c0-0.1,0-0.1,0-0.2c0,0.1,0.1,0.1,0,0.1c0.1,0.1,0.1,0.1,0.1,0.1c0,0.1,0,0.1,0,0.1
        c0.1,0.1,0.1,0,0.1,0.2c0,0.1,0.1,0.1,0,0.1c0.1,0.1,0,0,0.1,0.1l-0.1-0.1c0.1,0,0.1,0.3,0.1,0.2c0.1,0.1,0.1,0.2,0.1,0.3
        c0-0.1-0.1-0.4,0-0.3c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0.1c0,0,0-0.1,0.1-0.1c0-0.1-0.1-0.1-0.1-0.1c0-0.1,0.1,0.2,0.1,0.3
        c0,0-0.1-0.1-0.1,0c0.1,0.1,0.1,0.4,0.1,0.4c0,0.1,0.1,0,0.1-0.1c0.1,0.2-0.1,0.4,0,0.6c0,0.1-0.1,0.1-0.1,0.2s0,0.1,0,0.1
        c-0.1,0.1-0.1,0.3-0.1,0.4c0,0.1,0,0.1,0,0.1c0,0.1,0,0.1,0,0.1c0,0,0.1,0,0,0.1c-0.1,0-0.1-0.1-0.1,0c0,0.1,0.1-0.1,0.1,0
        c-0.1,0.1-0.1,0.2-0.1,0.3c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1,0.1,0,0,0.1c-0.1,0.1,0,0-0.1,0l-0.1,0.1c-0.1,0.1,0,0.1,0,0.1
        c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1,0,0,0-0.1c-0.1,0-0.1,0.2-0.2,0.3c0-0.1-0.1,0,0-0.1l-0.1,0.1c-0.1,0.1,0,0.1,0.1,0.1
        c-0.1,0.1,0,0.1-0.1,0.2V43c-0.1,0.1-0.3,0.3-0.4,0.4c0,0,0,0,0,0.1c-0.1,0.1-0.2,0.1-0.3,0.2l0,0c0,0-0.1,0.1-0.1,0.1
        c0,0,0,0.1-0.1,0.1c0,0.1,0.1-0.1,0.1,0c-0.1,0.1-0.1,0-0.2,0.1v-0.1c-0.1,0.1-0.1,0.1-0.2,0.1v0.1c-0.2,0.1-0.3,0.2-0.4,0.3
        l0.1-0.1c-0.1,0-0.3,0.2-0.1,0.1c0,0-0.1,0.1-0.2,0.1h-0.1l0,0c-0.1,0.1-0.3,0.1-0.3,0.2c-0.1,0.1-0.1,0-0.2,0.1h0.1
        c0,0.1-0.1,0.1-0.2,0.1c-0.1,0,0.1,0-0.1,0.1c-0.1,0.1-0.1,0-0.1,0c-0.1,0.1-0.1,0.1-0.3,0.1h-0.1c-0.1,0-0.1,0-0.1,0.1
        c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.1,0.1-0.2,0.1c0,0-0.1,0-0.1,0.1v0.1c0,0-0.2,0-0.3,0.1
        c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.1,0.1-0.1,0.1c-0.1,0-0.1,0-0.1,0c-0.1,0-0.1,0.1-0.1,0.1l-0.1,0.1c-0.5,0.1-0.9,0.3-1.3,0.4
        h-0.1c-0.3,0.1-0.6,0.2-0.9,0.3c-0.1,0,0,0.1-0.1,0.1c0-0.1-0.1-0.1-0.1-0.1c0,0.1,0.1,0,0.1,0.1c-0.1,0-0.1,0-0.1,0
        c-0.1,0-0.1,0.1-0.1,0.1c-0.2,0.1-0.3,0.1-0.6,0.1c0.1-0.1,0.1-0.1,0.1-0.1c-0.1,0-0.1,0-0.1,0.1c-0.1,0-0.1-0.1-0.1-0.1
        c-0.1,0.1-0.3,0-0.3,0.1c-0.1,0-0.1,0-0.1,0s0,0-0.1,0c-0.1,0-0.1,0-0.2,0c0,0-0.1-0.1-0.1,0c-0.1,0.1,0.3,0,0.1,0
        c-0.1,0-0.1,0-0.1,0c-0.1,0-0.1,0.1-0.1,0.1s-0.1,0-0.1,0c-0.3,0-0.6,0.1-0.7,0.1c-0.1,0,0,0,0,0.1c-0.1,0-0.4,0-0.6,0.1
        c-0.1,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.1,0.1c-0.1,0-0.1,0-0.1,0h-0.1c-0.1,0-0.1,0-0.2,0c0,0,0.1,0,0.1,0c0.1,0-0.2,0-0.3,0.1
        c0.1-0.1-0.1,0,0-0.1c0,0-0.1,0.1-0.1,0c-0.1,0,0.1,0.1,0,0.1c-0.1,0,0,0-0.1,0s-0.1,0.1-0.1,0.1s-0.1,0-0.1,0.1
        c-0.1,0-0.3,0-0.4,0c0-0.1-0.1-0.1,0-0.1c-0.1,0-0.4,0-0.6,0.1l0,0c-0.3,0.1-0.6,0-0.7,0.1c-0.1,0-0.3,0-0.3,0.1
        c-0.1,0-0.1,0-0.3,0.1c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0.1c-0.1,0-0.3,0.1-0.4,0.1c-0.1,0-0.3,0-0.5,0.1c-0.1,0-0.1,0.1-0.3,0.1
        s-0.2,0-0.3,0.1c0-0.1-0.1-0.1-0.1-0.1c0.1,0,0.1-0.1,0.1-0.1c0,0-0.1,0-0.2,0c0.1-0.1,0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0.1
        c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.1,0.1-0.3,0.1c-0.1,0.1-0.3,0.1-0.6,0.2c-0.1,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.3,0.1-0.5,0.1
        c0-0.1-0.1,0-0.1-0.1c-0.1,0-0.2-0.1-0.3,0c0.1-0.1,0-0.1-0.1-0.1c0.1-0.1-0.1-0.1-0.1-0.1c0.1,0,0.1,0,0.1-0.1c0,0-0.1,0-0.1,0
        c0.1-0.1,0-0.1,0.1-0.1c0,0,0,0-0.1,0c0.1,0,0-0.1,0.1-0.1h0.1v-0.1h0.1c0.1,0,0.1-0.1,0.1-0.1c0.1,0,0.1-0.1,0.2-0.1
        c0,0,0,0,0-0.1c0.1,0,0.4-0.1,0.6-0.1l0.2-0.1c0-0.1-0.1-0.1-0.1-0.1c0.2-0.1-0.1-0.1,0.1-0.1h0.1c0,0-0.1,0-0.1-0.1
        c0.1,0,0.2-0.1,0.3-0.1c0,0,0.1,0,0.1,0c0.1,0,0,0,0.1-0.1h0.1v0.1c0.1-0.1,0.2,0,0.3-0.1c0,0-0.2,0.1-0.1,0
        c0.3-0.1,0.6-0.1,1-0.1c0.1,0,0.1,0,0.1-0.1c0,0-0.1,0-0.1-0.1c0.1-0.1,0.1-0.1,0.3-0.1c0-0.1-0.1,0-0.1,0c-0.1,0,0.1,0,0.1,0
        c0.2,0,0.6-0.1,0.7-0.1c0.1,0,0.1-0.1,0.3-0.1c0,0-0.1,0-0.1,0.1l0,0c0.1,0,0.1,0,0.1-0.1c0.2,0,0.4,0,0.6-0.1
        c-0.1,0.1,0.1,0,0.1,0c0.2,0,0.4-0.1,0.6-0.1c0.5-0.1,1-0.1,1.4-0.1c0.1,0,0,0,0,0.1c0.1,0,0.1,0,0.1,0c0.1,0,0.1,0,0.1-0.1
        c0.1,0,0.1,0,0.2,0c0.1,0,0.1-0.1,0.1-0.1s0.1,0,0.1,0c0.1,0,0.1-0.1,0.2-0.1h-0.1c0.1,0,0.1,0,0.2,0h0.1c0.2,0,0.5-0.1,0.6-0.1
        c0,0,0.1,0,0.1-0.1c0.1,0.1,0.3,0,0.5-0.1V46c0.1,0,0.1,0,0.2-0.1c0.1,0,0,0.1,0,0.1c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.3,0,0.4-0.1
        c0,0,0.1,0,0.1,0c0.4-0.1,0.9-0.2,1.5-0.3c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.2,0,0.3-0.1v0.1c0.1-0.1,0.3-0.1,0.4-0.1
        c0,0.1-0.1,0.1-0.2,0.1s-0.1,0.1-0.1,0.1c0,0,0.1,0,0.1,0c-0.1,0.1,0,0.1-0.1,0.1s-0.3,0.1-0.4,0.1c-0.1,0-0.1,0-0.1,0
        s-0.1,0.1-0.1,0.1c0,0,0.1,0,0.1,0c-0.1,0.1-0.3,0.1-0.3,0.1c0-0.1,0-0.1-0.1-0.1s-0.1,0.1-0.2,0.1l0,0c0,0.1,0.3-0.1,0.2,0
        c-0.1,0-0.3,0.1-0.3,0.1c-0.1,0,0,0.1-0.1,0.1c0.1,0,0.1-0.1,0.3-0.1c0,0,0,0.1-0.1,0.1h0.1c0.1-0.1-0.1,0-0.1-0.1
        c0.1,0,0.1,0,0.1-0.1c0.1,0,0.1,0,0.2,0c0,0-0.1,0-0.1,0.1c0.1,0.1,0.1-0.1,0.2-0.1c0.1,0,0.1,0,0.2,0s0-0.1,0.1-0.1
        c0.1,0,0.1,0,0.1,0.1c0.1,0,0-0.1,0.1-0.1c0.3-0.1,0.7-0.1,1-0.3c0.1,0,0-0.1,0.1-0.1c0,0-0.1,0-0.1,0c0.1-0.1-0.1-0.1-0.1-0.1
        h0.1h0.1c0,0.1,0,0.1,0.1,0.1c0-0.1-0.1,0-0.1-0.1c0.1,0,0.2-0.1,0.3-0.1c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.3,0
        c0.1,0,0.1-0.1,0-0.1c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0s0.1-0.1,0.2-0.1c0.1,0,0.1,0,0.1,0c0.1,0,0.1-0.1,0.3-0.1
        c0.2-0.1,0.6-0.2,0.7-0.3c0.1,0,0.1,0,0.1-0.1c0.1-0.1,0.1,0,0.1,0c0.1,0,0.1,0,0.1-0.1c0.1-0.1,0.1,0,0.1,0
        c0.1-0.1,0.1-0.1,0.2-0.1c0,0,0,0,0-0.1c0.1,0,0.2-0.1,0.3-0.1c0,0,0-0.1,0.1-0.1c0.3-0.1,1-0.5,1.5-0.9c0.1-0.1,0.1-0.1,0.1-0.1
        c0.1-0.1,0.3-0.2,0.4-0.3c-0.1,0.1-0.1,0.1,0-0.1c-0.1,0.1,0.4-0.4,0.6-0.6c0-0.1,0.1,0,0.1-0.1l0.1-0.1c0,0,0.1-0.1,0.1-0.1
        s0-0.1,0.1-0.2l0.1-0.1c0-0.1,0-0.1,0-0.1c0,0,0-0.1,0.1-0.1c0-0.1,0-0.1,0.1-0.2c0-0.1,0.1-0.3,0.1-0.4c0,0,0,0,0-0.1
        c0,0,0,0,0.1,0c0-0.1,0-0.1,0-0.1l0,0v-0.1c0-0.1,0-0.1,0-0.1c0,0,0-0.1,0-0.2s0-0.1,0-0.1c0-0.1-0.1-0.3-0.1-0.5
        c0-0.1-0.1-0.1-0.1-0.2s0-0.1,0-0.1c0-0.1-0.1-0.1-0.1-0.2s0-0.1-0.1-0.1c0-0.1-0.1-0.1-0.1-0.1l-0.1-0.1c0,0,0-0.1-0.1-0.1
        c0-0.1-0.1-0.1-0.1-0.1c0-0.1-0.1-0.1-0.1-0.1c0.1-0.4-0.1-0.6-0.1-0.7c-0.1,0-0.2-0.1-0.3-0.2l-0.1-0.1c-0.3-0.1-0.6-0.3-0.8-0.4
        c-0.1,0-0.1-0.1-0.1-0.1c0,0,0.1,0,0.1,0.1c-0.1,0-0.3-0.1-0.3-0.1c0,0-0.1,0-0.2-0.1c-0.1,0-0.1,0-0.3-0.1h0.1c0,0-0.1-0.1,0.1,0
        c0-0.1-0.1,0-0.1-0.1c0,0,0.1,0,0.1-0.1c-0.1-0.1-0.1,0-0.1-0.1c0.1-0.1-0.1-0.1-0.1-0.1c0,0.1,0.1,0.1,0.1,0.1
        c-0.1,0-0.1,0-0.1,0s0-0.1-0.1-0.1s-0.1,0,0,0.1c-0.1,0-0.1-0.1-0.1-0.1c-0.1,0-0.2,0-0.2,0.1c-0.1,0-0.1,0-0.1,0
        c-0.1-0.1-0.2-0.2-0.2-0.2c0.1,0,0.2,0,0.1,0.1l0.1,0.1c0.1-0.1-0.1-0.1-0.1-0.1s0.1,0,0.1,0.1c0-0.1-0.1-0.1-0.1-0.1
        c-0.1,0-0.1,0-0.2-0.1c0-0.1,0.2,0,0.2-0.1c0.1,0,0.1,0.1,0.3,0.1c-0.1,0,0,0,0,0.1c0.1,0.1,0.1-0.1,0.2,0c0.1,0,0,0,0,0.1
        c0.1,0,0.1,0,0.1,0c0.1,0,0.1,0.1,0.2,0.1c0.1,0-0.1-0.1,0-0.1l0.1,0.1c0.1,0,0-0.1-0.1-0.1c0.1,0,0.2,0.1,0.3,0.1
        c0-0.1-0.1,0-0.2-0.1c0,0-0.1-0.1,0-0.1c-0.1-0.1-0.1,0-0.2-0.1c-0.1-0.1,0-0.1-0.1-0.1c-0.1,0-0.1,0-0.1,0
        c-0.1-0.1-0.1-0.1-0.2-0.1c-0.1,0,0.1,0,0,0.1c-0.1,0-0.3-0.1-0.4-0.1s-0.1-0.1-0.2-0.1c0.1-0.1,0.1,0.1,0.2,0.1
        c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1,0,0.1,0.1,0.1,0.1c0,0.1-0.2-0.1-0.2,0c-0.1,0-0.1,0-0.1-0.1c-0.1,0-0.2,0-0.3-0.1h0.1
        c-0.1-0.1-0.1-0.1-0.2-0.1s-0.1,0-0.1,0.1c-0.1,0-0.2-0.1-0.2-0.1c0-0.1,0.1,0.1,0.2,0c-0.1-0.1-0.2,0-0.3-0.1c0,0,0,0,0,0.1
        l-0.3-0.1c-0.1-0.1,0.2,0.1,0.2,0c-0.1-0.1-0.2,0-0.3-0.1c0.1,0.1-0.1,0-0.3,0c-0.1-0.1,0.1,0,0.2,0c0-0.1-0.2-0.1-0.3-0.1
        c-0.1-0.1-0.3-0.1-0.4-0.1c0.1,0,0.2,0,0.3,0.1h-0.1c-0.1,0-0.2-0.1-0.3,0c-0.1-0.1-0.3-0.1-0.6-0.1c0,0,0,0-0.1,0s-0.1,0-0.1-0.1
        c-0.2,0-0.3-0.1-0.6,0c-0.1,0,0,0,0.1-0.1c-0.1,0-0.1,0-0.1,0c-0.1,0-0.1,0.1,0,0.1c-0.1,0.1-0.2,0-0.3,0v0.1c-0.1,0-0.1,0-0.3,0
        c0.1,0,0-0.1-0.1-0.1c0-0.1,0.1,0,0.1,0c0,0,0-0.1-0.1-0.1c-0.1,0,0,0.1-0.1,0.1c-0.1,0-0.1,0-0.3-0.1v-0.1c-0.1,0-0.2,0.1-0.3,0
        c0.1,0-0.1,0-0.1-0.1c0,0,0-0.1-0.1-0.1c-0.1,0,0.1,0.1,0.1,0.1c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0,0,0,0,0c-0.1,0-0.1,0-0.2,0
        s-0.2,0-0.3,0c-0.1,0-0.1,0-0.2,0c0,0,0,0,0-0.1c-0.1,0-0.1,0.1-0.2,0c0,0,0.1,0,0.1-0.1c-0.1,0-0.1,0-0.2,0
        c0,0.1,0.1,0.1,0.1,0.1h-0.1c0,0,0.1,0,0,0s-0.1-0.1-0.3-0.1c-0.1,0,0.1,0,0,0.1c-0.1,0-0.2,0-0.3-0.1v-0.1c-0.1,0-0.1,0-0.1,0
        c-0.1,0-0.1-0.1-0.2,0c0.1,0,0.2,0.1,0,0.1c0.1,0,0.2,0,0.3,0c0,0.1-0.1,0-0.2,0c-0.1,0-0.2,0-0.3,0v-0.1c-0.1,0-0.1,0-0.1,0.1
        h-0.2c0,0,0.1,0,0.1-0.1c-0.1,0-0.2,0-0.3,0l0,0c-0.3,0-0.3,0-0.6,0c0,0.1-0.1,0.1-0.3,0.1c0,0.1,0.2,0,0.3,0c-0.1,0-0.2,0-0.3,0
        c0,0,0,0,0.1,0c-0.1,0,0-0.1-0.1-0.1c-0.1,0,0.1,0.1-0.1,0.1c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0,0.1,0.1-0.1,0.1c-0.1,0-0.3,0-0.2,0
        c-0.1,0-0.3,0-0.4,0c-0.1,0.1,0.1,0.1,0.2,0.1c0,0.1-0.1,0-0.2,0s-0.1,0-0.2,0c0-0.1,0.1-0.1,0.1-0.1c0,0-0.1,0-0.1,0
        c0,0,0.1-0.1,0-0.1c-0.1,0,0,0.1-0.1,0.1s0.1,0.1,0,0.1c-0.1,0-0.1,0,0,0c-0.1,0-0.2,0-0.3,0c0-0.1,0.1,0,0.1-0.1
        c-0.1-0.1-0.1,0-0.2,0c-0.1,0,0,0.1,0,0.1c0,0.1-0.1,0-0.1,0.1c-0.1,0-0.2,0-0.3,0c-0.1,0.1-0.3,0-0.6,0c-0.1,0-0.1,0-0.3,0
        c0,0,0,0-0.1,0c-0.4,0-0.6,0.1-1,0c0,0,0,0-0.1,0s-0.1,0-0.1,0c0-0.1,0.1-0.1,0.1-0.1c0.1,0,0.2,0.1,0.3,0c0-0.1-0.1,0-0.2,0v-0.1
        c-0.1,0-0.1,0-0.2,0c-0.1,0.1,0.1,0,0.1,0.1c-0.1,0-0.2,0.1-0.1,0.1c0,0-0.3,0.1-0.3,0c-0.1,0.1-0.4,0-0.6,0.1
        c-0.3,0-0.6,0.1-0.9,0.1c-0.1,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.1,0c-0.1,0-0.1,0.1-0.1,0.1c-0.1-0.1,0.1,0,0.1-0.1l0.1-0.1
        c0.1,0,0.2,0,0.1,0h-0.1c0,0,0-0.1-0.1,0c0,0,0.1,0,0.1,0c-0.1,0-0.3,0-0.5,0c0,0.1,0.2,0,0.3,0c0.1,0.1-0.1,0.1-0.1,0.1
        c-0.3,0.1-0.6,0.1-0.8,0.1c-0.2,0.1-0.4,0.1-0.6,0.1c0.4,0.3,0.4,0.3,0.6,0.3c0,0-0.1,0-0.1,0.1s0.1,0,0.1,0s0.2,0,0.3,0
        c0,0,0,0,0.1,0c0.1,0,0.1,0,0.2,0c0.1,0-0.1,0,0-0.1c0.2,0,0.4-0.1,0.6-0.1c-0.1,0.1,0.2,0,0.2,0.1c0.1,0,0-0.1,0.1-0.1h-0.1
        c-0.1,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0-0.1-0.1,0-0.1,0c0-0.1,0.1,0,0.1,0c0.1,0,0.3-0.1,0.5-0.1h-0.1c0.1,0.1,0.2,0,0.2-0.1
        c0.3,0,0.3,0.1,0.5,0c0-0.1-0.1,0-0.2,0c0.2-0.1,0.6,0,0.8-0.1h0.1c0.1,0,0,0,0,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0.1,0.3,0
        c0.1,0,0,0,0-0.1c0.3,0,0.6-0.1,0.8,0c0.1,0,0,0,0,0c0.1,0,0.4,0,0.5,0c0.1,0,0.1,0,0.2-0.1c0.3,0,0.6,0.1,0.9,0.1
        c0.1,0.1,0.1,0.1,0.1,0.1c0.3,0,0.4,0,0.6,0c0,0,0.1,0,0.1-0.1c0.1,0,0.1,0,0.1,0c0.1,0,0-0.1-0.1-0.1c-0.1,0-0.2,0.1-0.3,0
        c0.1,0,0.1-0.1,0.3,0c0,0,0,0-0.1,0h0.1c0,0,0.1,0,0.1,0.1c0.1,0,0.1,0,0.1,0s0.1,0,0.1,0c0.1,0,0.1-0.1,0.1,0
        c0,0.1,0.1,0.1,0.2,0.1s-0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0.1c0.1-0.1,0.2,0.1,0.2,0s-0.1,0-0.1,0c0-0.1,0.1,0,0.2,0h0.1
        c0.1,0,0.4,0,0.5,0.1c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.2,0c-0.1-0.1-0.3,0-0.3,0c0.1-0.1,0.2,0,0.3,0c0.1,0,0.2,0.1,0.3,0
        c0,0.1-0.1,0-0.1,0.1c0.1,0,0.1,0,0.3-0.1c0.1,0.1,0.1,0,0.3,0.1c0,0-0.2,0-0.1-0.1c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.2,0.1
        c0.1,0,0.1,0,0.1-0.1c0.1,0,0.1,0.1,0.1,0c0.1,0,0.1,0.1,0.2,0.1s-0.1,0,0-0.1c0.2,0.1,0.4,0,0.6,0.1h0.1c-0.1,0.1,0.1,0,0.1,0.1
        c0.1,0,0.3,0,0.2-0.1c0-0.1,0.1,0,0.1,0c0.1,0-0.1-0.1,0.1-0.1c0.3,0,0.6,0,0.8,0c0.1,0,0.1,0,0.1,0c0.1,0,0.1,0,0.3,0
        c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.3,0c0.3,0,0.6,0.1,0.8,0.1c0,0.1,0,0.1,0.1,0.1c0,0-0.1,0-0.1,0c0.1,0-0.1,0.1,0.1,0.1
        c-0.1,0-0.1,0.1-0.1,0.1s-0.1,0-0.1,0c-0.3-0.1-0.6-0.1-1-0.1c-0.1,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.3,0c-0.1,0-0.1,0-0.1,0
        c-0.1,0-0.2,0.1-0.3,0c0,0,0,0-0.1,0s-0.1,0-0.3,0c-0.1,0-0.1,0-0.1,0h-0.1c-0.1,0-0.1,0-0.2,0s-0.1,0-0.1,0s-0.2,0-0.3,0
        c-0.1,0-0.1,0-0.1,0c-0.2,0-0.4,0-0.7,0c-0.1,0,0.1-0.1-0.1-0.1c-0.1,0,0.1,0.1-0.1,0.1c-0.3,0-0.3,0-0.6,0h0.1
        c-0.1-0.1-0.1,0-0.1,0c-0.1,0,0.1,0-0.1-0.1c-0.1,0-0.1,0-0.1,0.1h-0.2c-0.1,0,0,0,0-0.1h-0.1c-0.1,0,0.1,0.1-0.1,0.1
        c-0.1,0,0,0,0-0.1c-0.1,0-0.1,0-0.1,0s0,0.1-0.1,0.1h-0.2c0,0,0.1,0,0.1-0.1c-0.1,0-0.1,0-0.1,0.1c-0.2,0-0.3,0-0.3-0.1
        c-0.1,0-0.1,0.1-0.1,0c-0.1,0,0.1,0.1-0.1,0.1c0,0,0,0,0.1,0c0,0-0.3,0-0.2,0c-0.1,0-0.1,0.1-0.2,0v-0.1c0,0-0.1,0-0.1,0.1
        c-0.1,0-0.1,0-0.1-0.1c0,0.1-0.2,0-0.3,0h0.1c-0.1,0.1-0.4,0-0.6,0c-0.1-0.1-0.1-0.1-0.2-0.1c-0.3,0-0.2,0-0.3,0
        c0.1,0.1-0.1,0.1,0,0.1c-0.1,0-0.2,0-0.1,0.1c-0.3,0-0.8,0-1.2,0c0-0.1,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0c-0.1,0-0.1,0-0.2-0.1
        c-0.1,0-0.1,0.1-0.2,0.1l0,0c-0.1,0-0.2,0-0.3-0.1c0,0,0,0-0.1,0H214c-0.1,0,0,0-0.1-0.1c-0.1,0,0,0-0.1,0.1c-0.1,0-0.2,0-0.1,0.1
        c-0.2,0-0.5,0-0.6,0c0.1,0,0.3,0,0.5,0c0.1,0,0.1,0,0.1-0.1c-0.1,0-0.1,0-0.1,0.1c-0.1,0-0.2,0-0.3,0c0.1,0,0,0,0-0.1
        c-0.3,0-0.6,0.1-0.8,0.1c-0.1,0,0.1-0.1,0-0.1c-0.1,0,0.1,0.1-0.1,0.1c-0.1,0,0.1-0.1,0.1-0.1c0-0.1-0.2,0-0.1,0.1
        c-0.1,0-0.1,0-0.1,0c-0.6,0.1-1.5,0.1-2.1,0.2l0,0c-0.1,0-0.1,0-0.3,0.1c0-0.1,0.1,0,0-0.1c-0.1,0-0.1,0-0.1,0
        c-0.1,0,0.1,0,0.1-0.1c-0.1,0-0.3,0-0.3,0c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.1,0-0.1,0c-0.1,0.1-0.3,0.1-0.6,0.1c0,0-0.1,0-0.1-0.1
        c-0.1,0-0.1,0-0.1,0.1c-0.1,0-0.3,0.1-0.5,0.1c-0.3,0.1-0.6,0.1-0.8,0.1c0-0.1-0.1,0-0.1-0.1c-0.1,0-0.1,0-0.1,0.1
        c-0.1,0-0.1,0-0.1,0c-0.2,0.1-0.6,0.1-0.9,0.3c-0.1,0,0.1-0.1,0-0.1c-0.1,0.1-0.1,0.1-0.3,0.1l0.1-0.1c-0.1,0-0.1,0.1-0.2,0.1
        l0.1-0.1c-0.1,0-0.1,0.1-0.3,0.1c0,0,0-0.1,0.1-0.1c-0.1,0-0.1,0.1-0.2,0.1c0,0-0.1,0.1-0.1,0c-0.1,0-0.2,0.1-0.3,0.1
        c0,0,0-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0c0-0.1-0.2,0-0.2,0.1l0.1-0.1l-0.1,0.1c-0.1,0.1-0.1,0-0.1,0c0-0.1,0.3-0.3,0.4-0.2
        c0.1,0-0.1,0,0-0.1c0.1,0,0.3-0.1,0.4-0.1l0,0c0.2-0.1,0.3-0.1,0.4-0.1c0.1,0,0.4-0.1,0.5-0.1c0.1,0,0.2,0,0.3,0
        c0-0.1-0.1,0-0.2,0c0-0.1,0.1,0,0.1-0.1c0.1,0,0-0.1,0-0.1c-0.2,0.1-0.2,0.1-0.3,0.1c-0.3,0-0.5,0.1-0.8,0.1
        c0.1,0,0.1-0.1,0.1-0.1c0,0-0.1,0.1-0.2,0c0,0,0,0.1,0.1,0c0,0.1-0.1,0.1-0.2,0.1c0,0,0-0.1-0.1-0.1c-0.1,0,0,0.1-0.1,0.1
        c0.1-0.1,0.1-0.1-0.1-0.1c-0.1,0,0,0,0.1,0c0,0-0.1,0-0.1,0.1c-0.1,0.1-0.1,0.1-0.1,0.1c-0.1,0-0.1,0.1-0.3,0.1
        c-0.1,0.1-0.1,0-0.2,0s-0.2,0.1-0.1,0.1c-0.1,0.1-0.2,0.1-0.2,0.2c-0.1,0.1-0.1,0-0.1,0.1c0,0,0-0.1,0.1-0.1c0-0.1-0.1,0-0.1,0
        c-0.1,0-0.1,0-0.2,0c-0.1,0,0.1-0.1,0.1-0.1l-0.1,0.1c-0.1,0-0.1,0.1-0.3,0.1c0,0,0,0.1,0.1,0.1c0,0,0.1-0.1,0.2-0.1
        c0,0.1,0.1,0,0.2,0.1c-0.3,0.1-1,0.3-1.2,0.4c0-0.1,0.1-0.1,0.1-0.1c0.3-0.1,0.6-0.2,0.9-0.3c-0.1,0-0.2,0.1-0.3,0.1
        s0,0.1-0.1,0.1c-0.1,0-0.1,0-0.1,0c-0.1,0-0.1,0.1-0.1,0.1h-0.1c-0.1,0-0.1,0-0.1,0.1c-0.1,0.1-0.3,0.1-0.3,0.1
        c-0.1,0-0.1,0-0.1,0c-0.1,0.1,0.1,0,0,0.1s-0.1,0-0.1,0s0,0.1,0,0.1c-0.1,0.1-0.3,0.3-0.5,0.4h-0.1c0,0-0.1,0.1-0.1,0.1
        s-0.1,0.1-0.1,0.1l-0.1,0.1c-0.1,0.1-0.3,0.3-0.3,0.6c-0.1,0,0-0.1,0-0.1c0.1-0.1,0.1-0.1,0.1-0.3c0-0.1,0-0.1,0-0.1
        c-0.1,0.1-0.1,0.2-0.1,0.3c-0.1,0-0.1,0.2-0.1,0.1c0,0.1,0.1,0,0.1,0.1s-0.1,0.1-0.1,0.1s0.1-0.1,0.1-0.1c0,0.1,0,0.2-0.1,0.3
        c0,0.1,0,0.1-0.1,0.2v0.1c0,0.1,0,0.1,0,0.1c0,0.1-0.1,0.1,0,0.1c0,0.1,0,0.1,0,0.1c0,0.1,0.1,0.1,0.1,0.1c0,0,0-0.1,0-0.1
        c0,0,0,0.1-0.1,0.1c0,0.1,0,0.1,0.1,0.1c0,0,0,0.2,0.1,0.1c0,0-0.1-0.1,0-0.2c0,0,0.1,0.1,0.1,0.1c0.1,0,0-0.1,0.1-0.2
        c0-0.1,0-0.1,0-0.2c0,0-0.1,0.1,0,0.1v-0.1c0-0.1,0.1,0.1,0.1-0.1c0.1,0,0,0.2,0.1,0.2c0-0.1,0-0.1,0-0.3c0-0.1-0.1,0-0.1,0
        c0-0.1,0.1-0.1,0.1-0.1c0-0.1,0-0.1,0-0.1c0.1,0.1,0,0.3,0.1,0.4c0,0,0-0.1-0.1-0.1c0,0.1,0.1,0.1,0.1,0.1l0,0
        c0.1,0.1,0.1,0.2,0.1,0.2c0,0.1,0,0.1,0,0.1c0,0.1,0.1,0.1,0.1,0.2c0,0-0.1,0-0.1-0.1c-0.1,0.1,0.1,0.3,0,0.3
        c0.1,0.1,0.1-0.1,0.1-0.1s0.1,0.1,0.1,0.1c-0.1-0.1-0.1,0.1-0.1,0.1c0.1,0.1,0.1,0.1,0.3,0.3c0.1,0.1,0,0.1,0,0.1s0,0,0.1,0
        c0.1,0,0-0.1,0.1,0c0.1,0,0,0.1,0,0.1c0.1,0.1,0.1,0.1,0.2,0.1c0.1,0.1,0,0.1,0.1,0.1l-0.1-0.1c0.1,0,0.3,0.2,0.5,0.3
        c0.1,0.1,0,0.1,0.1,0.1c0.1,0,0,0,0,0c0.1,0.1,0.3,0.1,0.4,0.2c0,0,0,0.1,0.1,0.1c0,0-0.1-0.1,0-0.1s0,0.1,0.1,0.1
        c0.1,0.1,0.1,0,0.2,0.1c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.1,0-0.1-0.1c0.1,0-0.2-0.1-0.2-0.1h-0.1c-0.1-0.1-0.1-0.1-0.1-0.1
        c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0.1,0.1,0.1,0,0.1c-0.1-0.1-0.1-0.1-0.1-0.1c-0.1-0.1-0.1-0.1-0.1,0c0,0-0.1,0-0.1-0.1
        c-0.1-0.1-0.1-0.1-0.1-0.1c0,0-0.1,0,0,0.1c-0.1,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0.1c0.1,0,0.1,0.1,0.1,0.1c0.1,0.1,0.1,0.1,0.1,0.1
        h-0.1h0.1c0,0,0,0.1,0.1,0.1s0.1,0,0.1,0.1c0.1,0,0.1,0,0.1,0s-0.1,0,0,0.1s0.1,0,0.1,0c0.1,0.1,0.1,0.1,0.1,0.1
        c0.1,0,0.2,0.1,0.3,0.1c-0.1,0,0.1,0.1,0.1,0.1c0.1,0.1,0.1,0,0.3,0.1h-0.1l0.1,0.1c0,0,0-0.1-0.1-0.1c-0.1-0.1-0.1-0.1-0.1-0.1
        l-0.2-0.1c0.1,0,0.1,0.1,0.3,0.1c0.1,0.1,0.2,0.1,0.4,0.2c-0.1,0,0,0.1,0,0.1c0.1,0.1,0.2,0.1,0.3,0.1c0,0-0.1,0-0.1,0
        c0.1,0.1,0.1,0,0.2,0c0.1,0,0.1,0.1,0.1,0.1s0-0.1-0.1-0.1c0.1-0.1,0.1,0.1,0.1,0.1c0.1,0,0.1,0,0.1-0.1c0.1,0.1-0.1,0.1,0.1,0.1
        c0,0,0.1,0.1,0.1,0c0.1,0.1,0.3,0.1,0.1,0.1c0.1,0,0.2,0.1,0.3,0.1s0,0,0,0.1c0.1,0.1,0.1,0,0.1,0c0,0.1,0.1,0,0.2,0.1
        c0,0-0.1,0-0.1,0c-0.6-0.2-0.8-0.2-1.2-0.4c-0.1,0,0,0,0,0.1c-0.1,0-0.2-0.1-0.3-0.1l-0.1-0.1h-0.1c-0.1,0,0.1,0-0.1-0.1
        c0.1,0.1,0.2,0.2,0.3,0.2c-0.1-0.1-0.3-0.2-0.5-0.3h0.1c-0.1-0.1-0.1,0-0.1,0.1c0.1,0.1,0.1,0,0.2,0.1c-0.1,0.1-0.2,0.1,0,0.1
        c-0.2-0.1-0.6-0.2-0.8-0.3c-0.1,0-0.3-0.1-0.4-0.2c-0.1,0-0.1,0-0.1,0c-0.1-0.1,0-0.1,0-0.1c-0.1-0.1-0.1-0.1-0.3-0.1v0.1
        c0.1,0,0.1,0.1,0.2,0.1c0.1,0.1-0.1,0-0.3-0.1h0.1l-0.1-0.1c-0.1-0.1-0.4-0.3-0.6-0.5c-0.1-0.1-0.2-0.3-0.3-0.4
        c-0.1-0.1-0.1-0.1-0.1-0.2c-0.1-0.1-0.1-0.2-0.1-0.3c-0.1,0,0,0.1,0,0.2c0.1,0.1,0.1,0.3,0.1,0.3c0.1,0,0.1,0.1,0.2,0.2
        c0.1,0,0-0.1,0.1-0.1c0.1,0.1,0,0.1-0.1,0.1c0,0,0.1,0.1,0.1,0.2c0.1,0.1,0-0.1,0.1,0c0,0-0.1-0.1-0.1-0.1
        c0.1-0.1,0.1-0.1,0.1-0.1c0.1,0.2,0.3,0.3,0.5,0.6c0.1,0.1-0.1-0.1-0.1-0.1c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0,0,0.1,0.1
        c-0.1,0,0,0.1,0.1,0.1c0.1,0,0.1-0.1,0.1,0s0.1,0.1,0.1,0.1c0-0.1-0.1-0.1-0.1-0.1c0.1,0.1,0.1,0.1,0.1,0.1c0,0,0,0,0.1,0
        s-0.1-0.1-0.1-0.1c0-0.1,0.1,0.1,0.1,0.1c0.1,0,0.1,0,0.1,0.1c0.1,0.1,0.3,0.1,0.4,0.3c-0.1,0-0.1-0.1-0.2-0.1
        c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0.1,0-0.1-0.1,0-0.1c0.1,0.1,0.1,0.1,0.1,0.2s0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.1,0
        c0.1,0.1,0.1,0.1,0.3,0.1c-0.1,0.1,0.1,0.1,0,0.1c-0.1-0.1-0.2-0.1-0.3-0.1c0,0,0.1,0.1,0.1,0.1h-0.1c0-0.1,0-0.1,0-0.1
        c-0.1,0-0.1,0-0.1,0c0-0.1,0-0.1,0.1,0c-0.1-0.1,0-0.1,0.1-0.1c0-0.1-0.1,0-0.2-0.1v-0.1c-0.1-0.1-0.1,0.1-0.1,0l0.1,0.1
        c-0.1,0-0.1-0.1-0.1-0.1c-0.1,0-0.1,0-0.2-0.1c0.1,0,0.1-0.1-0.1-0.1c-0.1-0.1,0.1,0,0-0.1c-0.1,0-0.1,0-0.1-0.1c0,0,0,0-0.1-0.1
        l0.1-0.1c-0.1,0-0.1,0-0.1-0.1c0-0.1,0.1,0,0.1-0.1c-0.1-0.1-0.1,0-0.1,0c-0.1,0,0-0.1-0.1-0.1c0,0-0.1,0.1-0.1,0
        c0,0.1,0.1,0,0.1,0.1c-0.1,0,0.1,0.1,0.1,0.1l0,0c0,0,0.1,0.1,0,0.1c-0.1,0-0.1,0-0.1-0.1h0.1c-0.1-0.1-0.1-0.1-0.1-0.1
        c0,0.1,0.1,0.1,0.2,0.1c0,0.1-0.1,0-0.1-0.1c0,0.1,0.1,0.1-0.1,0.1l0.1,0.1c0.1,0.1,0.1,0,0.1,0.1c-0.1,0.1-0.3-0.1-0.4-0.2
        c-0.1-0.1-0.1-0.1-0.3-0.1c0,0,0.1,0.1,0.1,0.1c-0.1-0.1-0.1-0.1-0.1-0.1c-0.1,0-0.1-0.1-0.1-0.1c-0.1,0-0.1,0-0.1-0.1
        c-0.1,0-0.1-0.1-0.1-0.1c0,0.1-0.1,0-0.1-0.1c0.1,0,0.1,0.1,0.3,0.2c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.1-0.1,0-0.1,0
        c-0.1-0.1-0.1-0.1-0.1-0.1c-0.1-0.1-0.1-0.1-0.1-0.1c0,0,0.1,0.1,0.1,0.1c-0.1-0.1-0.2-0.3-0.3-0.4l-0.1-0.1
        c-0.1-0.1-0.1-0.3-0.2-0.4c-0.1-0.2-0.1-0.5-0.1-0.7l0,0c0,0-0.1-0.1,0-0.1c0.1,0.1,0-0.1,0-0.2c0-0.1,0,0-0.1,0
        c0-0.1,0-0.3,0.1-0.3c0-0.1,0-0.1,0-0.2c0.1,0,0.1,0,0,0.1c0.1,0,0.1-0.1,0.1-0.2h-0.1c0.1-0.4,0.3-0.8,0.6-1.1
        c0.4-0.3,0.7-0.6,1-0.8c0.3-0.2,0.7-0.3,1-0.5c0.3-0.1,0.7-0.3,1-0.3c0.2-0.1,0.4-0.2,0.6-0.3c0.1,0,0.1,0,0.1,0
        c0.1,0,0.3-0.1,0.5-0.1c0.1,0,0.2-0.1,0.3-0.1c0.1-0.1,0.6-0.1,0.8-0.2c0.1-0.1,0.3-0.1,0.6-0.1c0.1-0.1,0.3-0.1,0.4-0.1h-0.1
        c0.2,0,0.4-0.1,0.7-0.1c0,0-0.1,0-0.1,0.1c0.1,0,0.1-0.1,0.1-0.1c0.1,0,0,0.1,0,0.1c0.1,0,0.1-0.1,0.2-0.1s0.1,0,0.3-0.1
        c0.1,0,0.1,0,0.1,0c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0c0.1,0,0.1-0.1,0.1,0c0.1,0,0.3-0.1,0.5-0.1c0.1,0,0.1,0,0.1,0
        c0.1,0,0.1,0,0.1,0s0.1,0,0.1,0h0.1c0.1,0,0.3,0,0.5,0c0.1,0,0.1,0,0.1-0.1h0.1c0.1,0,0.2,0,0.3-0.1c0.1,0,0.1-0.1,0.2-0.1
        s0.1,0,0.1,0c0.2,0,0.5-0.1,0.7-0.1c0.1,0,0.1,0,0.1,0c0.2,0,0.4-0.1,0.6-0.1c0.1,0,0.1,0,0.1,0c0.1,0,0.3-0.1,0.5-0.1
        c0,0,0.1,0,0.1,0.1c0.1,0,0,0,0-0.1c0.2,0,0.3-0.1,0.5,0c0.1,0,0.1,0,0.3,0c0.1,0,0.1,0,0.1,0.1c0.1,0,0.1,0,0.1-0.1
        c0.1,0,0.1,0,0.3,0c0.1,0,0-0.1,0.1-0.1c0.1,0,0.3,0,0.6,0c0.1,0,0.1,0,0.2,0c0.2,0,0.6-0.1,0.7,0c0,0,0.1,0,0.1-0.1
        c0.2,0.1,0.3,0,0.6,0c0.1-0.1,0.1,0,0.3,0l-0.1-0.1h-0.1c-0.1,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0s-0.2,0-0.3,0
        c-0.1,0-0.1,0-0.1,0s-0.1,0-0.2,0c-0.1,0-0.1,0-0.3,0c-0.1,0-0.3,0-0.4,0c0,0,0,0,0-0.1c-0.1,0-0.2,0-0.2-0.1c-0.1,0,0,0,0,0.1
        h0.1c-0.1,0.1-0.3,0-0.5,0c-0.1,0-0.1,0.1-0.1,0c-0.1,0,0,0,0.1-0.1c0,0,0,0,0.1,0c0,0,0,0,0.1,0h-0.1c0.1,0,0.2,0,0.3,0
        c0.1-0.1-0.3,0-0.2-0.1c-0.2,0-0.2,0.1-0.4,0.1c-0.1,0.1,0.1,0,0.1,0.1c-0.1,0-0.3,0-0.3,0c0.1,0,0.2,0,0.1-0.1
        c-0.1,0,0,0.1-0.1,0.1c0-0.1-0.2-0.1-0.3,0c0,0,0.1,0,0.2,0c0,0.1-0.1,0-0.3,0c0.1-0.1-0.1-0.1-0.1-0.1c-0.1,0.1,0.1,0.1,0,0.1
        c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0,0.1,0,0.1,0c-0.3,0.1-0.8,0.1-1.2,0.1c-0.1,0-0.2,0-0.2,0c-0.2,0-0.6,0-0.7,0
        c0.1-0.1,0.2-0.1,0.3-0.1c0.2,0,0.3,0,0.5-0.1c0-0.1-0.1,0,0-0.1c-0.1,0-0.1,0-0.2,0c0.1,0,0.1,0,0.1,0c-0.1,0.1-0.2,0-0.3,0
        c0,0,0.1,0,0.1,0c-0.8,0-1.7,0.1-2.5,0.2h-0.1c-0.1,0-0.1,0-0.2,0.1c-0.1,0-0.2,0-0.3,0h-0.1c-0.1,0,0,0,0,0
        c-0.6,0.1-1.2,0.1-1.6,0.3c-0.1,0,0,0,0,0c-0.1,0.1-0.2,0.1-0.3,0.1l0.1-0.1c-0.1,0-0.2,0.1-0.3,0.1c0-0.1,0.1,0,0.1-0.1
        c0.1,0,0.1,0,0.2,0c0,0,0,0,0.1,0c0,0,0-0.1-0.1-0.1c0.1-0.1,0.1-0.1,0.1-0.1c-0.1-0.1-0.2,0.1-0.3,0c0-0.1,0.1,0,0.1-0.1l0,0
        c0.1-0.1,0.1-0.1,0.3-0.1c0.1,0,0.1,0.1,0,0.1h0.1c0.1,0-0.1,0.1-0.1,0.1c0.1,0,0.1,0,0.1-0.1c0.1,0,0.1,0,0.2,0
        c-0.1,0.1-0.2,0-0.3,0.1c-0.1,0-0.3,0-0.3,0.1c0,0.1,0.3-0.1,0.1,0c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0
        c0.1-0.1,0.4-0.1,0.6-0.1c0.4,0.4,0.6,0.3,0.7,0.3c0.1,0,0.1-0.1,0.3-0.1c0,0-0.1,0,0,0.1c0.1,0,0.1-0.1,0.1-0.1
        c0.1,0,0.3,0,0.3-0.1c0,0-0.1,0-0.1,0c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.3,0c0.1,0,0,0,0-0.1c0.1,0,0.1,0,0.3,0c0,0,0,0,0.1,0
        c0,0,0,0-0.1,0C210.4,34.7,210.6,34.7,210.6,34.6C210.7,34.7,210.6,34.7,210.6,34.6c0.1,0,0.1,0.1,0.2,0.1
        C210.7,34.6,210.7,34.6,210.6,34.6c0.2,0.1,0.2,0,0.3,0v0.1c0,0,0.1-0.1,0,0c0-0.1,0.2,0,0.1-0.1c-0.1,0-0.3,0.1-0.3,0
        c-0.1,0,0,0.1,0,0.1L210.6,34.6z M207.3,35c0,0.1,0.1,0.1,0.1,0.1V35H207.3z M207.3,35.1L207.3,35.1c0,0,0-0.1-0.1-0.1
        C207.2,35.1,207.4,35.1,207.3,35.1z M209.7,34.8c-0.1,0,0-0.1-0.2,0c0,0.1,0.1,0,0.1,0.1C209.6,34.8,209.7,34.8,209.7,34.8z
        M203,42.6c0.1,0,0.2,0.1,0.2,0C203,42.6,203.1,42.6,203,42.6C203,42.6,203.1,42.5,203,42.6L203,42.6z M201.7,41.9L201.7,41.9
        c-0.1,0-0.1-0.1-0.1,0C201.6,41.9,201.6,41.9,201.7,41.9z M201.7,42c-0.1,0-0.1,0-0.2,0C201.6,42,201.8,42.1,201.7,42z
        M202.2,42.2C202.2,42.2,202.1,42.2,202.2,42.2c0,0,0.1,0.1,0.1,0.1C202.3,42.3,202.3,42.3,202.2,42.2z M202.2,42.1L202.2,42.1
        L202.2,42.1C202.2,42.1,202.2,42.1,202.2,42.1z M202,42.2C202,42.2,201.9,42.2,202,42.2c0,0.1,0,0.1,0.1,0.1
        C202.1,42.3,202.1,42.2,202,42.2z M201.6,41.7c0.1,0.1,0.1,0.1,0.3,0.2V42h-0.1c0.1,0.1,0,0.1,0.1,0.1c0.1,0,0.1,0,0.1,0.1
        c0-0.1,0-0.1-0.1-0.2c0,0,0,0.1,0.1,0.1c0,0,0.1,0.1,0,0.1c-0.1-0.1-0.1-0.2-0.1-0.2C201.8,41.9,201.8,41.9,201.6,41.7z
        M201.8,41.5c0.1,0.1,0.1,0.1,0.1,0.1C201.8,41.5,201.8,41.5,201.8,41.5L201.8,41.5z M201.6,41c0,0,0.1,0.2,0.1,0.1
        C201.6,41.1,201.6,41,201.6,41z M201.3,40.8c0.1,0.1,0.1,0.2,0.1,0.3c0.1,0,0-0.1-0.1-0.1C201.4,40.9,201.4,40.8,201.3,40.8z
        M201.2,40.5c0,0.1,0.1,0.2,0.1,0.3C201.3,40.7,201.2,40.5,201.2,40.5z M201.3,40.3c0,0.1,0,0.3,0.1,0.3
        C201.3,40.5,201.3,40.4,201.3,40.3z M201.2,41.5L201.2,41.5c-0.1,0-0.1,0.1,0,0.1C201.3,41.6,201.3,41.6,201.2,41.5z M201.4,41.4
        c-0.1,0,0.1,0.1,0.1,0.2c-0.1,0-0.1,0.1,0.1,0.1C201.6,41.6,201.4,41.4,201.4,41.4z M201.3,41.4c0.1,0.1,0.1,0.1,0.1,0.1
        c0.1,0.1,0.1,0.1,0.1,0.1c-0.1-0.1-0.1-0.1,0-0.1C201.4,41.5,201.3,41.4,201.3,41.4z M201.2,41.5c-0.1-0.1,0-0.1-0.1-0.2
        c-0.1,0,0,0.1,0,0.1l-0.1-0.1C201,41.3,201,41.3,201.2,41.5C201.1,41.4,201.1,41.5,201.2,41.5z M200.9,40.8
        c0.1-0.1,0.1,0.2,0.1,0.1c0.1,0.1,0.1,0.1,0.1,0.1c0,0.1,0.1,0.2,0.1,0.3h0.1c-0.1-0.1-0.1-0.2-0.1-0.1c-0.1-0.1-0.1-0.2-0.2-0.3
        C200.9,41,200.8,40.6,200.9,40.8z M200.5,40.5c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.3,0.2,0.4c0,0,0.1,0,0.1-0.1
        c-0.1-0.1-0.1-0.1-0.1-0.2c0,0.1,0.1,0.1,0.1,0.1c-0.1-0.1-0.1-0.2-0.1-0.3c0.1,0.1,0.1,0,0.1,0.1c0,0.1,0,0.1,0.1,0.1
        c0,0,0-0.1,0-0.1c0,0,0.1,0.1,0.1,0.1c-0.1-0.1-0.1-0.1-0.1-0.2c0,0.1-0.1,0-0.1-0.1c0,0.1-0.1,0-0.1-0.1c0.1,0-0.1-0.2,0-0.3
        L200.5,40.5c0.1,0.1,0.1,0.1,0.1,0.2C200.6,40.7,200.6,40.5,200.5,40.5z M200.7,40.3c0-0.1,0-0.1,0-0.1
        C200.7,40.2,200.7,40.4,200.7,40.3z M200.6,40.2C200.6,40.3,200.7,40.2,200.6,40.2c0.1,0.1,0.1,0.3,0.1,0.3
        C200.7,40.3,200.7,40.3,200.6,40.2C200.6,40.3,200.6,40.2,200.6,40.2z M200.5,40c0,0.1,0,0.1,0,0.2v-0.1
        C200.5,40.1,200.5,40,200.5,40z M200.5,39.8c0-0.1,0.1,0,0-0.1c0-0.1-0.1,0,0-0.1c-0.1-0.1,0,0.1-0.1,0.1c0-0.1,0.1-0.1,0-0.2
        c0,0.1-0.1,0.1-0.1,0.2c0,0.1,0.1,0.2,0.1,0.3h0.1C200.5,40.1,200.5,39.8,200.5,39.8C200.5,39.8,200.5,39.8,200.5,39.8
        C200.5,39.8,200.5,39.8,200.5,39.8z M200.6,39.4c0,0.1,0,0.1,0,0.2c0,0,0-0.1,0-0.1s0,0.1,0,0.2s0,0,0.1,0
        C200.7,39.6,200.7,39.5,200.6,39.4C200.6,39.6,200.6,39.4,200.6,39.4z M200.5,39.3c0,0,0.1-0.1,0.1-0.1c0,0,0,0.1,0,0V39
        C200.5,39.2,200.5,39.2,200.5,39.3c0,0.1-0.1,0.3,0,0.1V39.3z M200.7,39.4c0-0.1,0.1-0.3,0.1-0.2
        C200.7,39.2,200.7,39.4,200.7,39.4z M200.8,39.2c0,0.1,0.1,0,0,0.1c0,0.1-0.1,0-0.1,0.1h0.1C200.8,39.2,200.8,39.2,200.8,39.2
        C200.8,39.2,200.8,39.2,200.8,39.2z M200.7,38.9c-0.1-0.1-0.1,0.3-0.1,0.2C200.7,38.9,200.7,39,200.7,38.9z M200.9,38.8
        c-0.1,0.1-0.1,0.1-0.1,0.2C200.8,39.1,200.9,38.9,200.9,38.8z M201,38.5c-0.1,0.1-0.2,0.1-0.1,0.2C200.9,38.7,201,38.5,201,38.5z
        M200.9,38.3c0,0.1-0.1,0.1-0.1,0.1C200.9,38.5,201,38.4,200.9,38.3z M200.9,38.3c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.1,0.1-0.1,0.1-0.1
        c0,0.1,0,0.1,0.1,0c0,0-0.1,0-0.1-0.1C200.8,38.4,200.9,38.3,200.9,38.3z M201.8,37.5c0.1-0.1,0.1,0,0.2-0.1
        C202,37.4,201.8,37.5,201.8,37.5z M201.2,38.4C201.2,38.3,201.2,38.3,201.2,38.4C201.1,38.4,201.2,38.5,201.2,38.4L201.2,38.4
        C201.2,38.3,201.2,38.4,201.2,38.4z M201,38.1c0,0-0.1,0-0.1,0.1v0.1L201,38.1z M201.5,38.1c-0.1,0.1-0.1,0.1-0.2,0.2
        c0,0,0.1-0.1,0.1-0.1c-0.1,0-0.1,0.1-0.1,0.2C201.4,38.2,201.4,38.1,201.5,38.1C201.5,38.1,201.6,38.1,201.5,38.1z M201.3,37.9
        c0.3-0.2-0.1,0.1-0.1,0.1C201.3,38,201.3,38,201.3,37.9z M201.6,37.7L201.6,37.7c0-0.1,0.1-0.2,0.1-0.1c-0.1,0.1-0.2,0.1-0.3,0.1
        C201.4,37.8,201.6,37.6,201.6,37.7c-0.1,0.1-0.1,0.1-0.1,0.1c0-0.1-0.3,0.1-0.3,0.2c0.1-0.1,0.2-0.1,0.2-0.2c0.1,0-0.1,0.1,0,0.1
        C201.5,37.8,201.5,37.8,201.6,37.7z M202.1,37.6c-0.1,0.1-0.1,0-0.1,0.1c0,0.1,0.1,0,0.1,0.1c0,0,0,0-0.1,0.1
        c-0.1-0.1,0-0.1-0.1-0.1c-0.1,0-0.1,0-0.1,0.1l0,0c-0.1,0.1-0.1,0.1-0.2,0.1c0.1,0.1,0,0.1,0.1,0.1c0.1-0.1,0.1-0.1,0.2-0.1
        c0,0,0,0,0.1-0.1c0-0.1,0.1,0,0.1-0.1C202.1,37.6,202.2,37.6,202.1,37.6z M202.1,37.5c-0.1,0.1-0.1,0-0.1,0.1c0-0.1-0.1,0-0.1,0
        c-0.1,0-0.1,0.1,0,0.1l0.1-0.1l0,0c-0.1,0.1-0.2,0.1-0.2,0.1c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.1-0.1,0.3-0.1
        C202.2,37.5,202.1,37.5,202.1,37.5z M202.3,37.3c-0.1,0-0.1,0.1-0.1,0.1L202.3,37.3c0.1-0.1,0.1-0.1,0.1-0.1
        c-0.2,0.1-0.2,0.1-0.3,0.2h0.1l-0.1,0.1h0.1c0,0-0.1,0.1,0.1,0C202.1,37.4,202.3,37.3,202.3,37.3z M202.6,37.2
        c-0.1,0-0.2,0.1-0.3,0.1C202.4,37.4,202.5,37.3,202.6,37.2z M202.7,37.2C202.7,37.2,202.7,37.2,202.7,37.2c0.1,0,0.1,0,0.1,0
        l0.1-0.1C202.9,37.1,202.8,37.2,202.7,37.2c0.2-0.1,0.1-0.1,0.2-0.1c0.1,0,0.1,0,0.2-0.1C203,36.9,202.8,37,202.7,37.2
        c0.1-0.1,0.1-0.1,0.1-0.1C202.7,37,202.7,37,202.7,37.2C202.5,37.2,202.7,37,202.7,37.2h-0.1C202.5,37.2,202.7,37.1,202.7,37.2
        C202.7,37.2,202.7,37.2,202.7,37.2L202.7,37.2z M203.2,37C203,37.1,203.1,37.1,203.2,37c-0.2,0.1-0.3,0.1-0.4,0.2
        c0.1,0,0.1,0,0.1,0.1C202.9,37.2,203,37.2,203.2,37L203.2,37z M204,36.7C204,36.7,203.9,36.7,204,36.7c-0.1,0-0.1,0-0.1,0.1
        C203.9,36.8,203.9,36.7,204,36.7z M204.3,36.5c-0.1,0-0.2,0-0.2,0.1C204.1,36.6,204.3,36.5,204.3,36.5z M204.9,36.2
        c-0.1,0-0.1,0-0.1,0.1C204.8,36.3,204.9,36.3,204.9,36.2z M205,36.3c-0.1,0-0.3,0-0.3,0.1C204.8,36.4,204.9,36.4,205,36.3z
        M205.6,36.3c-0.1,0-0.1,0-0.1,0v0.1C205.5,36.3,205.6,36.3,205.6,36.3z M205.8,36.2c-0.1,0-0.2,0.1-0.2,0.1
        C205.6,36.3,205.7,36.3,205.8,36.2C205.7,36.3,205.7,36.3,205.8,36.2C205.8,36.3,205.8,36.3,205.8,36.2z M206.2,36.1
        c0-0.1-0.1,0-0.2,0.1c-0.1,0-0.2,0-0.2,0.1C205.9,36.2,206.1,36.2,206.2,36.1z M206.4,35.9c-0.1,0-0.2,0-0.2,0.1
        C206.3,36,206.4,36,206.4,35.9z M204.9,36.7c-0.1,0.1-0.1,0-0.3,0.1C204.6,36.8,204.8,36.7,204.9,36.7z M205.2,36.6
        c-0.1,0.1-0.1,0-0.2,0.1c0.1,0,0.1,0,0,0.1C205.1,36.7,205.2,36.7,205.2,36.6z M205.6,36.6c-0.1,0,0-0.1-0.1-0.1
        C205.4,36.6,205.5,36.6,205.6,36.6z M205.7,36.5c-0.1,0,0.1-0.1-0.1-0.1C205.5,36.5,205.6,36.6,205.7,36.5z M206.4,36.3
        C206.5,36.3,206.5,36.3,206.4,36.3c0.1,0,0.1,0,0.1,0C206.6,36.2,206.5,36.3,206.4,36.3z M206.7,36.4
        C206.7,36.3,206.7,36.3,206.7,36.4h-0.1C206.6,36.4,206.6,36.4,206.7,36.4c-0.2-0.1-0.3,0-0.5,0.1c-0.1,0-0.1,0-0.2,0
        c0.1,0,0.3-0.1,0.2-0.1s-0.3,0.1-0.3,0.1h0.1c-0.1,0-0.1,0.1-0.1,0.1s0.1,0,0.1-0.1c-0.1,0-0.1,0-0.1,0s0-0.1-0.1,0l-0.1,0.1
        c0.1,0,0.1-0.1,0.1,0l-0.1,0.1l0.1-0.1c0-0.1-0.1,0-0.1-0.1c0,0,0.1-0.1,0.1,0h-0.1c0.1,0,0.1-0.1,0.3-0.1c0.1,0,0.2,0,0.2-0.1
        c-0.1,0.1-0.2,0-0.3,0c0.1-0.1,0.2,0,0.3,0S206.6,36.3,206.7,36.4c-0.1,0-0.3-0.1-0.2,0C206.6,36.4,206.6,36.4,206.7,36.4z
        M207.2,36c0,0-0.1-0.1-0.2,0c0.1,0,0.1,0,0.1,0c-0.1,0-0.1,0.1-0.2,0.1c0-0.1-0.1,0-0.1-0.1c-0.1,0-0.1,0.1-0.2,0.1
        c0,0,0,0,0,0.1C206.8,36,207,36,207.2,36z M208.1,35.7c-0.1,0-0.2,0.1-0.3,0.1c0,0,0.1,0,0.1-0.1h-0.1h-0.1v0.1
        c-0.1-0.1-0.1,0-0.3,0.1v-0.1c-0.1,0.1-0.3,0-0.3,0.1c0.1-0.1,0.2,0,0.1,0c0.1,0,0.2-0.1,0.3-0.1C207.7,35.8,208,35.8,208.1,35.7z
        M208.8,36c-0.1,0-0.1,0-0.1,0.1C208.7,36,208.7,36,208.8,36L208.8,36z M208.9,35.7c-0.1,0-0.1,0-0.2,0
        C208.7,35.8,208.9,35.8,208.9,35.7z M208.9,35.6C208.7,35.6,209,35.6,208.9,35.6c-0.2-0.1-0.3,0-0.4,0c-0.1,0,0.1,0,0,0.1
        c-0.1,0-0.1,0-0.3,0c-0.1,0-0.1,0.1-0.1,0.1c0.1,0,0.3-0.1,0.1-0.1c0.1,0,0.1,0,0.3,0C208.6,35.6,208.8,35.6,208.9,35.6z
        M209,35.6L209,35.6c0.1,0,0.1,0.1,0.1,0.1C209.2,35.6,209,35.5,209,35.6z M222.9,35.9C222.9,35.9,222.9,35.9,222.9,35.9
        C222.8,35.8,223,35.8,222.9,35.9C222.9,35.8,222.9,35.8,222.9,35.9z M221.4,35.5c0.1,0,0.2,0,0.3,0
        C221.6,35.5,221.4,35.5,221.4,35.5z M219.8,35.8c0.1,0,0.1,0,0.2,0C220,35.8,219.8,35.7,219.8,35.8z M219.3,35.8
        c0.1,0,0.2,0,0.3,0C219.6,35.8,219.3,35.7,219.3,35.8z M216.4,35.6L216.4,35.6L216.4,35.6C216.3,35.6,216.3,35.6,216.4,35.6z
        M214.9,35.4c0.1,0,0.1,0.1,0.2,0.1c0-0.1-0.1-0.1,0-0.1C215.1,35.4,215,35.4,214.9,35.4z M214.4,35.7c0.1,0,0.1,0,0.1,0
        C214.6,35.7,214.5,35.7,214.4,35.7C214.5,35.6,214.6,35.6,214.4,35.7C214.4,35.6,214.4,35.6,214.4,35.7z M212.7,35.7
        c0.1,0,0.1,0,0.2,0C212.8,35.7,212.7,35.6,212.7,35.7z M211.9,35.5h0.1C212.1,35.4,211.9,35.4,211.9,35.5z M211.6,35.7
        c0.1,0,0.2,0,0.2-0.1C211.7,35.6,211.6,35.6,211.6,35.7z M211.1,35.7c0.1,0,0.3-0.1,0.2-0.1C211.3,35.6,211.1,35.6,211.1,35.7z
        M210.4,35.6c0,0.1,0.1,0.1,0.2,0C210.7,35.5,210.6,35.6,210.4,35.6z M209.9,35.9c0.1,0,0.2,0,0.3,0c0-0.1-0.1,0-0.1,0
        C210,35.8,209.9,35.8,209.9,35.9z M209.3,35.6c0.1,0,0.2-0.1,0.3-0.1c-0.1,0-0.2,0-0.3,0C209.4,35.5,209.4,35.6,209.3,35.6z
        M210.1,35.4c0-0.1-0.1,0-0.3,0c-0.1,0-0.1,0-0.1,0c0-0.1,0-0.1-0.1,0c0,0.1,0.1,0,0.1,0.1c-0.1,0-0.1,0.1-0.2,0.1
        C209.7,35.5,209.9,35.4,210.1,35.4z M211.7,35.3L211.7,35.3c-0.1,0-0.1,0-0.2,0C211.5,35.3,211.7,35.3,211.7,35.3z M212.4,35.2
        c-0.1,0-0.1,0-0.1,0C212.2,35.3,212.3,35.3,212.4,35.2L212.4,35.2z M212.6,35.2c-0.1-0.1-0.1-0.1-0.2-0.1c0,0.1,0.1,0,0.1,0.1
        c0,0-0.1,0,0,0H212.6z M214.6,35.1c-0.1,0-0.1,0-0.2,0C214.6,35.1,214.6,35.1,214.6,35.1L214.6,35.1z M226.3,36.2c0,0-0.1,0-0.1,0
        C226.2,36.2,226.1,36.3,226.3,36.2C226.3,36.3,226.4,36.2,226.3,36.2z M227,36.4C226.9,36.4,226.9,36.4,227,36.4
        c-0.1-0.1-0.1-0.1-0.2-0.1C226.8,36.3,226.9,36.4,227,36.4z M222.9,45.4c0.1,0,0.1,0,0.2,0C223.1,45.4,222.9,45.4,222.9,45.4z
        M222.5,45.4C222.5,45.4,222.5,45.4,222.5,45.4c0.1,0,0.1,0,0.3,0C222.7,45.4,222.6,45.4,222.5,45.4
        C222.5,45.4,222.5,45.4,222.5,45.4C222.5,45.4,222.4,45.4,222.5,45.4z M221.9,45.6c0,0.1,0.1,0,0.2,0
        C222,45.6,222,45.6,221.9,45.6z M212.8,46.8C212.6,46.8,212.6,46.8,212.8,46.8L212.8,46.8z M213.6,46.6c0-0.1-0.1,0-0.2,0
        C213.4,46.6,213.5,46.6,213.6,46.6z M214.1,47.5C214,47.5,214.1,47.5,214.1,47.5C214,47.5,214,47.5,214.1,47.5
        C214,47.5,214,47.5,214.1,47.5z M214.9,47.5C214.9,47.4,214.9,47.4,214.9,47.5c-0.1-0.1,0-0.1-0.1-0.1c0,0,0,0,0,0.1
        c-0.1,0-0.1,0-0.1,0.1C214.8,47.4,214.9,47.5,214.9,47.5z M214.9,46.2c-0.1,0-0.3,0-0.3,0.1c0,0.1,0.1-0.1,0.1,0
        C214.7,46.3,214.7,46.3,214.9,46.2C214.7,46.2,214.9,46.2,214.9,46.2z M215.1,47.3c-0.1,0-0.2,0.1-0.1,0.1c0.1,0,0.1-0.1,0.3-0.1
        C215.3,47.3,215.2,47.3,215.1,47.3z M215.5,47.2c-0.1,0-0.1,0.1-0.1,0.1C215.5,47.3,215.6,47.2,215.5,47.2z M217.3,46
        c0.1,0,0.1,0.1,0.1,0C217.5,45.9,217.3,45.9,217.3,46z M219.6,46.1L219.6,46.1L219.6,46.1C219.8,46.1,219.8,46.1,219.6,46.1z
        M223.6,45.5c-0.1,0-0.3,0.1-0.2,0.1C223.4,45.5,223.6,45.5,223.6,45.5z M224,45.4c-0.1,0-0.1,0-0.2,0
        C223.8,45.5,223.9,45.5,224,45.4z M224.2,45.2c0,0.1,0.2-0.1,0.2-0.1C224.3,45.1,224.3,45.1,224.2,45.2z M225.3,44.9
        C225.2,45,225.2,44.9,225.3,44.9C225.1,45,225,45,225,45h-0.1c-0.1,0.1-0.4,0.1-0.6,0.2c0.1,0-0.1,0.1-0.1,0.1
        c0.1,0,0.1,0,0.1-0.1c0,0,0.1,0,0.2,0c0.1,0,0.1-0.1,0.1-0.1s0.2-0.1,0.3-0.1s0.1,0,0.1-0.1C225.1,45,225.4,45,225.3,44.9z
        M228.6,43.1c-0.1,0.1-0.1,0.1-0.2,0.1s-0.1,0.1-0.1,0.1l0,0l0,0c-0.1,0.1-0.2,0.1-0.2,0.2c0.1-0.1,0.2-0.2,0.3-0.3
        C228.5,43.2,228.6,43.2,228.6,43.1z M229.9,39.4c0,0.1,0,0.1,0,0.1C230,39.6,229.9,39.4,229.9,39.4z M230,39.8
        C230.1,39.8,229.9,39.8,230,39.8C230,39.8,230,39.9,230,39.8z M230.1,39.7c0,0.1-0.1,0.1-0.1,0.1C230.1,40,230.1,39.8,230.1,39.7z
        M228.6,43C228.6,43,228.7,43,228.6,43L228.6,43C228.8,43,228.8,42.9,228.6,43z M230.4,42.1c-0.1,0.1-0.1,0.2-0.1,0.2
        C230.3,42.2,230.4,42.1,230.4,42.1z M230.8,41.2c0,0.1-0.1,0.1-0.1,0.2c0,0,0,0.1,0.1,0C230.7,41.4,230.8,41.2,230.8,41.2z
        M230.9,39.2c0,0.1,0,0.2,0.1,0.2C231,39.2,231,39.2,230.9,39.2z M230.3,39.4c0,0.1,0,0.1,0.1,0.2c-0.1,0,0,0.1,0,0.1
        c0,0.1,0,0.1-0.1,0.1c0.1,0,0,0.1,0.1,0.2c0,0.1,0,0.1-0.1,0.1c0-0.1,0.1-0.1,0-0.1c0,0.1,0,0.1,0,0.1s0.1,0.3,0,0.3
        c0,0,0-0.1,0,0s-0.1,0.1-0.1,0.2c0,0,0-0.1,0.1-0.1c0,0.1-0.1,0.2-0.1,0.3c0,0-0.1,0-0.1,0.1l-0.1,0.2l0,0c0,0.1,0,0.1-0.1,0.1
        c0,0,0,0-0.1,0c0,0.1,0.1,0,0.1,0c-0.1,0.1,0,0.1,0,0.2c0,0.1-0.1,0-0.1,0.1c0,0,0,0.1-0.1,0.1c-0.1,0.1-0.2,0.3-0.3,0.5
        c0,0,0,0.1-0.1,0.1c-0.2,0.3-0.3,0.3-0.6,0.6c0.1,0.1,0.2-0.1,0.3-0.2c0.1-0.1,0.3-0.3,0.4-0.3v-0.1c0.2-0.2,0.3-0.3,0.3-0.6
        c0.1,0,0,0.1,0.1,0c0-0.1,0.1-0.1,0.1-0.1c0.1-0.2,0.2-0.4,0.3-0.5c0-0.1,0.1-0.3,0.1-0.3c-0.1,0,0-0.1,0-0.2v0.1
        c0-0.1,0.1-0.1,0.1-0.3c-0.1-0.1,0,0.1-0.1,0.1c-0.1,0,0-0.1,0-0.3c0,0,0,0,0-0.1c0.1,0.1,0,0,0-0.1c0-0.1,0-0.2,0-0.3h0.1
        c0-0.1,0-0.1-0.1-0.1c0-0.2-0.1-0.3,0-0.4c0,0,0.1,0.1,0.1,0s-0.1-0.1-0.1-0.1c0-0.1,0-0.1-0.1-0.2c0,0.1,0.1,0.2,0.1,0.3
        c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.1,0-0.1,0-0.1C230.1,38.7,230.2,39.4,230.3,39.4z M229.7,38.1c0.1,0,0-0.1-0.1-0.1L229.7,38.1z
        M228.9,37.6c0.1,0.1,0.1,0.1,0.1,0.2C229,37.8,229,37.7,228.9,37.6L228.9,37.6L228.9,37.6z M228.8,37.4c0,0.1,0.1,0.1,0.1,0.1
        C229,37.5,228.9,37.4,228.8,37.4z M228.8,37.3c-0.1,0.1,0.1,0.1,0.1,0.1C229,37.4,228.8,37.4,228.8,37.3z M229.2,36.7
        c0.1,0.1,0.1,0.1,0.2,0.1C229.3,36.7,229.2,36.7,229.2,36.7z M229.2,36.6c0.1,0,0-0.1-0.1-0.1C229,36.5,229.1,36.5,229.2,36.6z
        M228.8,36.4c0.1,0.1,0.1,0.1,0.1,0.1C228.9,36.4,228.8,36.3,228.8,36.4z M228.1,36.7c0.1,0.1,0.1,0,0.1,0.1
        c0.1,0.1,0.2,0.2,0.3,0.2c-0.1-0.1-0.2-0.1-0.2-0.1s0.1,0.1,0,0C228.3,36.8,228.1,36.7,228.1,36.7z M228.4,36.2
        c0.1,0.1,0.1,0.1,0.1,0.1v-0.1C228.5,36.2,228.4,36.2,228.4,36.2z M227.2,36.3c0.1,0.1,0.2,0.1,0.3,0.1c-0.1-0.1-0.1-0.1-0.1-0.1
        c-0.1,0-0.1,0,0,0.1C227.3,36.3,227.2,36.3,227.2,36.3z M227,36.1c0.1,0,0.1,0,0.1,0C227,36.1,227,36.1,227,36.1z M225.4,35.6
        C225.4,35.6,225.4,35.6,225.4,35.6c0.1,0.1,0.2,0.1,0.2,0.1L225.4,35.6c0.1,0.1,0.2,0.1,0.3,0.1c0-0.1,0-0.1,0-0.1
        C225.6,35.6,225.4,35.6,225.4,35.6z M223.5,35.4c0.1,0,0.1,0,0.1,0c0-0.1,0.1,0,0.1-0.1C223.6,35.4,223.6,35.4,223.5,35.4z
        M223.1,35.4c0.1,0,0.1,0.1,0.2,0.1c0,0,0,0,0-0.1c0.1,0,0.2,0.1,0.3,0c0-0.1-0.2-0.1-0.3-0.1c0,0,0,0.1-0.1,0.1
        S223.1,35.4,223.1,35.4C222.9,35.4,223,35.4,223.1,35.4z M219,34.4c0.1,0,0.1,0,0.3,0c-0.1,0,0,0,0,0
        C219.1,34.3,219.1,34.2,219,34.4z M218.9,34.4C218.9,34.4,219,34.4,218.9,34.4C218.9,34.4,218.9,34.3,218.9,34.4
        C218.9,34.4,218.9,34.3,218.9,34.4z M218.5,34.3c0.1,0,0.2,0,0.2,0C218.7,34.3,218.6,34.3,218.5,34.3z M217.9,34.7
        c0.1,0,0.2,0,0.2,0C218,34.7,218,34.7,217.9,34.7z M217.8,34.3c0.1,0,0.1,0,0.2,0C217.9,34.2,217.8,34.3,217.8,34.3z M215.6,34.7
        c0.1,0,0.1,0,0.2,0c0,0,0-0.1-0.1-0.1C215.8,34.7,215.6,34.6,215.6,34.7z M215.4,34.6c0,0,0.1,0.1,0.1,0c-0.1-0.1,0.1,0,0.1,0
        C215.7,34.5,215.5,34.6,215.4,34.6z M214.1,34.4c0.1,0,0.2,0,0.2,0C214.2,34.5,214.2,34.4,214.1,34.4z M212.9,34.5L212.9,34.5
        C213,34.4,212.9,34.4,212.9,34.5C212.9,34.4,212.9,34.4,212.9,34.5C212.8,34.5,212.8,34.5,212.9,34.5z M212.6,34.4L212.6,34.4
        C212.7,34.5,212.8,34.4,212.6,34.4z M212.6,34.7c0.1,0,0.3,0,0.3-0.1c-0.1,0-0.1,0-0.1,0C212.8,34.6,212.6,34.6,212.6,34.7z
        M211.5,34.6c-0.1,0-0.1,0-0.1,0.1C211.5,34.7,211.5,34.7,211.5,34.6L211.5,34.6z M211.7,34.5c0.1,0,0.1,0,0.1,0H211.7
        C211.9,34.5,211.7,34.5,211.7,34.5z M211.2,34.5c0.1,0,0.3,0,0.3-0.1C211.4,34.5,211.2,34.5,211.2,34.5z M211.2,34.5
        c0,0.1,0.3,0,0.4,0c0,0,0.1,0,0.1-0.1C211.5,34.5,211.4,34.5,211.2,34.5z M230,41.1c0,0,0,0.1,0,0.2v-0.1l0,0
        C229.9,41.2,230,41.2,230,41.1z M202.1,41.9c-0.1,0-0.1-0.1-0.1-0.1C202,41.8,202.1,41.9,202.1,41.9z M222.5,35.4
        c-0.1,0-0.3,0-0.4,0c0-0.1,0.1,0,0.1,0C222.3,35.4,222.5,35.4,222.5,35.4z M218.2,35.2c-0.1,0-0.2,0-0.4,0
        C217.8,35.1,218,35.1,218.2,35.2z M200.1,39.8c-0.1-0.1,0-0.3,0-0.5c0-0.1,0-0.3,0.1-0.3C200.1,39.3,200.1,39.6,200.1,39.8z
        M202.7,42.6c-0.1,0-0.1,0-0.1-0.1c0-0.1,0-0.1,0-0.1C202.7,42.5,202.7,42.5,202.7,42.6z M200.2,40.5c-0.1-0.1-0.1-0.3-0.1-0.5
        C200.2,40.1,200.2,40.4,200.2,40.5z M201.2,37.6c-0.1,0.1-0.3,0.3-0.5,0.4C200.9,37.8,201,37.7,201.2,37.6
        c0.1-0.1,0.2-0.1,0.3-0.3C201.6,37.3,201.4,37.5,201.2,37.6z M202.9,42.8c-0.1-0.1-0.2-0.1-0.3-0.1
        C202.6,42.6,202.9,42.7,202.9,42.8z M203.6,43.1c-0.1,0-0.1,0-0.2-0.1C203.6,43,203.6,43,203.6,43.1z M203.2,42.9
        c-0.1,0-0.2-0.1-0.3-0.1C203,42.8,203.1,42.8,203.2,42.9C203.2,42.9,203.2,42.9,203.2,42.9z M201.2,41c-0.1-0.1-0.1-0.1-0.1-0.2
        c0.1-0.1-0.1-0.2-0.1-0.3c0,0,0,0,0.1,0c0,0.1,0.1,0.1,0.1,0.2C201.1,40.8,201.2,41,201.2,41z M225.9,44.1
        c0.2-0.1,0.3-0.1,0.6-0.3C226.3,43.9,226.1,44,225.9,44.1L225.9,44.1z M226.4,35.1l0.2,0.1L226.4,35.1z M220.7,46.8
        c0,0-0.1,0.1-0.2,0.1s-0.2,0-0.1,0C220.5,46.8,220.6,46.8,220.7,46.8z M228,35.6c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.1,0.3,0.2
        C228.4,35.8,228,35.7,228,35.6z M229.7,43c0.1-0.1-0.1,0.2-0.2,0.2c0,0-0.1,0-0.1,0.1C229.4,43.2,229.7,43,229.7,43z M229.7,36.9
        c0.1,0.1,0.1,0.1,0.1,0.1C229.7,37,229.7,36.9,229.7,36.9z M227.7,35.6c0-0.1,0.1,0.1,0.1,0.1c0,0.1,0.3,0.1,0.2,0.1
        C227.9,35.8,227.9,35.7,227.7,35.6z M226.7,35.3c0.2,0,0.2,0.1,0.3,0.1C227,35.4,226.7,35.3,226.7,35.3z M227.8,35.7
        c0.1,0.1,0.2,0.1,0.2,0.1C227.9,35.8,227.7,35.8,227.8,35.7z M228.1,36C228.2,36,228,35.9,228.1,36c0,0,0,0,0.1,0.1
        C228.3,36,228.2,36,228.1,36z M224.5,44.7c0.1-0.1,0.2-0.1,0.3-0.1c0.3-0.1,0.5-0.2,0.7-0.3c0.1-0.1,0.3-0.1,0.3-0.1
        c-0.2,0.1-0.5,0.2-0.7,0.3S224.8,44.6,224.5,44.7z M227,43.6c0.1,0,0.2-0.1,0.3-0.1C227.2,43.5,227,43.6,227,43.6z M228.4,42.7
        c0-0.1,0.1-0.1,0.1-0.1C228.6,42.5,228.4,42.6,228.4,42.7z M227.2,43.4c0-0.1,0.3-0.2,0.3-0.2C227.5,43.3,227.4,43.4,227.2,43.4z
        M222.3,45.2c0.1-0.1,0.1-0.1,0.3-0.1C222.6,45.2,222.4,45.2,222.3,45.2z M219.9,45.7c0-0.1,0.1,0,0.2,0c0.3-0.1,0.6-0.1,0.9-0.1
        c-0.1,0.1-0.2,0.1-0.3,0.1C220.5,45.6,220.2,45.7,219.9,45.7z M224.1,44.8c0-0.1,0.3-0.1,0.3-0.1
        C224.4,44.7,224.3,44.8,224.1,44.8z M222.7,45.2c0-0.1,0.1-0.1,0.2-0.1C222.9,45.2,222.7,45.2,222.7,45.2z M223.5,34.5
        c0.1,0,0.3,0,0.4,0C223.8,34.5,223.6,34.5,223.5,34.5z M206.3,35.4c0.1,0,0.1-0.1,0.2-0.1c0,0,0,0-0.1,0c0.1-0.1,0.1,0,0.1,0
        C206.5,35.3,206.3,35.4,206.3,35.4z M205.5,35.5c0-0.1,0.1-0.1,0.3-0.1C205.7,35.4,205.6,35.4,205.5,35.5z M206.5,35.1
        c0.1,0,0.2-0.1,0.2,0C206.7,35.1,206.5,35.1,206.5,35.1z M205.8,35.3c0.1-0.1,0.2-0.1,0.2-0.1c0.1,0,0.1-0.1,0.1-0.1
        C206.1,35.3,205.9,35.3,205.8,35.3z M206.5,35.2c0-0.1,0.1,0,0.1-0.1l0,0c0.1,0,0.1,0,0.1,0s0.1-0.1,0.2-0.1
        c0.1,0-0.1,0.1-0.1,0.1c0,0.1-0.1,0.1,0.1,0.1c0,0.1-0.1,0-0.1,0c-0.1,0,0,0,0,0C206.7,35.2,206.6,35.1,206.5,35.2
        C206.6,35.2,206.6,35.2,206.5,35.2z M208.3,34.8c0.1,0,0.2-0.1,0.3-0.1h0.1C208.6,34.8,208.4,34.8,208.3,34.8
        C208.3,34.8,208.4,34.8,208.3,34.8c0.2,0.1,0.6,0,0.6,0.1c-0.1,0-0.2,0-0.3,0c-0.1,0-0.1,0.1,0,0.1c-0.2,0.1-0.6,0.1-0.7,0.1
        c0.1-0.1,0.2-0.1,0.3-0.1c0,0-0.1-0.1-0.1,0c-0.1,0,0.1-0.1,0.2-0.1s0,0.1,0,0.1c0.1,0,0.2,0,0.3,0c0-0.1-0.1,0-0.1,0s0.1,0,0,0
        C208.3,34.9,208.2,34.9,208.3,34.8C208.3,34.9,208.3,34.8,208.3,34.8z M206.5,35c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0
        c0.1-0.1,0.4-0.1,0.8-0.2h0.1c0,0.1-0.2,0.1-0.1,0.1c-0.1,0.1-0.1,0-0.3,0.1c0,0.1,0.2,0,0.3,0c0,0-0.1,0.1,0,0.1
        c-0.1,0-0.3,0.1-0.6,0.1c0,0,0.1,0,0.1-0.1c0.1,0,0.1-0.1,0.1-0.1c-0.1,0-0.1,0-0.1,0.1c-0.3,0.1-0.5,0.1-0.7,0.1
        C206.6,35,206.6,35,206.5,35z M207,34.9L207,34.9c0.1-0.1-0.1,0-0.1,0C206.7,34.9,207,34.9,207,34.9z M208,34.9
        C208,34.9,207.9,34.9,208,34.9c-0.1,0.1,0.1,0,0.1,0C208.1,34.9,208.1,34.9,208,34.9C208,34.8,208.1,34.8,208,34.9
        c0.1-0.1,0.1-0.1,0.2-0.1h-0.1c0,0,0.2,0,0.2-0.1c0,0-0.1,0-0.1,0.1c-0.1,0.1-0.3,0.1-0.5,0.1c0,0,0,0,0-0.1
        c-0.1,0-0.1,0.1-0.2,0.1c0-0.1,0-0.1-0.1,0c0-0.1,0.2-0.1,0.3-0.1c0,0-0.1,0.1,0,0.1C207.9,34.9,207.9,34.9,208,34.9z M205.4,35.6
        C205.4,35.6,205.5,35.6,205.4,35.6c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.2-0.1,0.3,0c-0.1,0-0.1,0-0.1,0v0.1c0.1,0,0.2-0.1,0.3-0.1
        c0.1,0.1-0.1,0.1-0.1,0.1l-0.1,0.1C205.8,35.6,205.6,35.6,205.4,35.6C205.4,35.7,205.6,35.6,205.4,35.6c0.1,0.1,0.3,0,0.4-0.1
        c0,0-0.1-0.1-0.2,0c0,0.1,0,0.1,0.1,0C205.7,35.6,205.6,35.6,205.4,35.6z M208.8,34.7c0.1-0.1,0.1,0,0.3-0.1
        c-0.1,0-0.1-0.1,0.1-0.1c0,0.1,0.1,0,0.2,0.1c0,0-0.1,0.1,0,0.1c0,0.1-0.1,0-0.3,0.1c0,0,0.1,0,0.1-0.1l0,0H209
        c-0.1,0,0.1,0.1-0.1,0.1C208.8,34.7,209,34.7,208.8,34.7z M204.5,35.8c-0.1,0-0.1,0-0.2,0.1C204.3,35.8,204.5,35.7,204.5,35.8z
        M211.6,45.2c0.1,0,0.3,0.1,0.4,0.1C211.9,45.3,211.7,45.2,211.6,45.2z M204.5,35.8c0.1-0.1,0.1-0.1,0.1-0.1c0.1,0,0.2,0,0.3,0
        c-0.1,0-0.2,0.1-0.3,0.1h0.1C204.6,35.8,204.5,35.8,204.5,35.8z M211.9,45.9c0,0-0.1,0-0.1,0c0,0,0.1,0.1,0,0.1
        c-0.1,0-0.2-0.1-0.3-0.1c-0.2-0.1-0.5-0.1-0.6-0.1c-0.1,0-0.1,0-0.1-0.1c-0.3-0.1-0.6-0.1-0.9-0.1c-0.1,0-0.1-0.1-0.2-0.1
        c0,0-0.1,0-0.1,0l-0.1-0.1c-0.2-0.1-0.5-0.1-0.7-0.1c0.1,0,0-0.1,0-0.1c-0.1,0,0,0,0,0.1c-0.2-0.1-0.3-0.1-0.4-0.1s-0.1,0-0.1-0.1
        c-0.1,0-0.1,0-0.2-0.1c0,0.1-0.1,0-0.1,0c0.1,0,0.1,0.1,0.1,0.1s0-0.1,0.1-0.1s0.1,0.1,0.2,0.1v0.1c0.1,0,0.1-0.1,0.2-0.1
        c0,0-0.1,0-0.1,0.1c0.1,0.1,0.1,0,0.3,0.1c0.1,0,0.2,0,0.3,0c-0.1,0-0.1,0-0.2,0c0,0,0.1,0.1,0.1,0.1s0,0,0,0h0.1
        c0.1,0,0.1-0.1,0.1,0c0,0.1-0.1,0-0.1,0s0,0.1-0.1,0.1c0.1,0.1,0.2,0.1,0.3,0.1c0-0.1-0.1-0.1-0.1-0.1c0-0.1,0.1,0,0.1,0
        c0.1,0.1,0,0,0,0.1c0.1,0.1,0.1,0.1,0.2,0.1c0,0.1-0.1,0.1-0.1,0c-0.1,0,0.1,0.1,0,0.1s-0.1,0-0.2-0.1c0,0-0.1,0-0.1,0
        c-0.1-0.1-0.3-0.1-0.5-0.1c-0.1,0-0.1,0-0.1,0c-0.2-0.1-0.5-0.1-0.7-0.2c-0.1,0-0.1-0.1-0.3-0.1c-0.5-0.1-1-0.3-1.6-0.5
        c-0.1,0-0.1-0.1-0.1-0.1c-0.1,0-0.3,0-0.3-0.1c-0.1,0-0.1-0.1-0.3-0.1c-0.1,0-0.1-0.1-0.1-0.1c-0.1,0-0.3-0.1-0.5-0.1
        c-0.1,0,0-0.1,0.1,0c-0.1-0.1-0.3-0.1-0.3-0.1c-0.1-0.1-0.3-0.1-0.3-0.1c-0.1,0-0.3-0.2-0.6-0.2c-0.1,0-0.1-0.1-0.1-0.1h-0.1
        c-0.1-0.1-0.3-0.2-0.6-0.3c-0.1,0-0.1-0.1-0.1-0.1s0,0,0-0.1c-0.1-0.1-0.3-0.1-0.4-0.2c0,0,0,0-0.1,0c-0.1-0.1-0.1-0.1-0.2-0.1
        c-0.1-0.1-0.1,0-0.1-0.1c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.2-0.4-0.5-0.6-0.7
        c-0.1-0.2-0.3-0.4-0.3-0.6c0-0.1-0.1-0.1-0.1-0.1s0-0.1,0-0.1c0-0.1-0.1-0.2-0.1-0.3c-0.1-0.2-0.1-0.4-0.1-0.7c0,0,0-0.1,0-0.1
        c0-0.1,0-0.1,0-0.2s0-0.1,0-0.1s0-0.1,0-0.1c0-0.1,0-0.1,0-0.1c0.1-0.3,0.2-0.7,0.3-1c0.1-0.1,0.1-0.1,0.1-0.1
        c0-0.2,0.1-0.4,0.3-0.6c0.1-0.1,0.3-0.3,0.5-0.4l-0.1,0.1c0.1-0.1,0.1-0.1,0.1-0.1c0.1-0.1,0.2-0.1,0.3-0.2c0.1-0.1,0-0.1,0.1-0.1
        c0.3-0.2,0.7-0.4,0.9-0.5c0.1-0.1,0.1-0.1,0.2-0.1c0.2-0.1,0.4-0.1,0.6-0.2c0.1,0,0.1-0.1,0.2-0.1s0.1,0,0.1,0
        c0.1,0,0.1-0.1,0.1-0.1c0.2-0.1,0.5-0.2,0.8-0.3c0,0-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.1,0.1-0.1,0.1
        c0,0,0,0-0.1,0c0,0-0.1,0.1-0.2,0.1c0,0.1,0.1,0,0.1,0c-0.2,0.1-0.3,0.1-0.5,0.2c0,0.1,0.1,0,0.1,0.1c-0.2,0-0.3,0.1-0.6,0.1
        c-0.1,0-0.1,0.1,0,0.1c-0.1,0.1-0.1,0-0.1,0.1c-0.1,0,0-0.1,0-0.1c-0.2,0.1-0.3,0.1-0.6,0.3h0.1c-0.1,0-0.4,0.2-0.6,0.3
        c-0.1,0.1-0.1,0.1-0.1,0.1c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.1,0.1-0.1,0.1s-0.1,0.1-0.1,0.1c0.1,0,0.1-0.1,0.2-0.1
        c0,0,0.1,0.1,0.1,0c0,0,0,0,0.1-0.1h-0.1c0.3-0.2,0.6-0.3,0.8-0.5c0.3-0.1,0.5-0.2,0.8-0.3c0.1,0,0,0,0,0.1
        c0.3-0.1,0.4-0.2,0.6-0.2c0,0,0,0,0.1-0.1c0.1,0,0.2-0.1,0.1,0c0.1-0.1,0.1-0.1,0.1-0.1h0.1c0,0.1-0.1,0.1-0.1,0.1
        c-0.1,0-0.1,0-0.3,0.1c-0.1,0,0.1-0.1,0.1-0.1s-0.2,0.1-0.1,0.1c-0.1,0.1-0.1,0.1-0.2,0.1c0,0,0-0.1,0.1-0.1
        c-0.1,0-0.2,0.1-0.1,0.1c-0.2,0.1-0.3,0.1-0.6,0.2c-0.1,0-0.1,0-0.2,0.1c0,0,0,0-0.1,0.1c-0.3,0.1-0.7,0.3-0.9,0.5
        c-0.1,0,0.1-0.1,0.1-0.1c-0.1,0-0.2,0.1-0.2,0.2c-0.3,0.1-0.6,0.4-0.9,0.8c-0.1,0-0.1,0.1-0.1,0c-0.1,0.1,0,0.1,0,0.1
        c-0.2,0.3-0.3,0.6-0.3,0.8c0,0.1,0,0.3,0,0.5c0,0.1,0,0.2,0,0.3c0,0,0,0.1,0,0.2s0,0.1,0,0.1s0.1,0.3,0.1,0.4c0,0,0,0,0.1,0.1
        c0,0.1,0,0.1,0.1,0.2c0,0.1,0,0.2,0.1,0.2c0.1,0.1,0,0.1,0.1,0.2c0,0,0-0.1-0.1-0.1c0.1,0,0.1,0.1,0.1,0.2
        c0.1,0.2,0.3,0.3,0.4,0.6c0.1,0.1,0.2,0.1,0.3,0.3c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.3,0.6,0.3c0.1,0,0.1,0.1,0.1,0.1
        c0.1,0.1,0.3,0.1,0.3,0.2c0.1-0.1,0.4,0.1,0.4,0.1h-0.1l0.1,0.1c0.1,0.1,0.1,0.1,0.2,0.1c0,0,0,0-0.1-0.1c0.1,0,0.3,0.1,0.6,0.2
        c0.1,0.1,0.2,0.1,0.4,0.2c0,0-0.1-0.1-0.1-0.1c0.1,0.1,0.2,0.1,0.4,0.1c0.1,0.1,0.2,0.1,0.3,0.1h-0.1c0.1,0.1,0.2,0.1,0.1,0
        c0.1,0,0.1,0.1,0.1,0.1c0.2,0,0.5,0.1,0.7,0.2c-0.1,0,0.1,0.1,0.2,0.1c0-0.1-0.1,0-0.1-0.1c0.3,0.1,0.6,0.2,0.8,0.3c0,0,0,0,0,0.1
        h0.1c0.1,0.1,0.3,0.1,0.4,0.1c0.2,0.1,0.5,0.1,0.7,0.2c0.1,0,0.1,0,0.2,0c0.3,0.1,0.7,0.2,1,0.3c0.1,0,0.2,0,0.3,0.1
        c0.2,0.1,0.4,0.1,0.6,0.1c0.2,0.1,0.5,0.1,0.7,0.1c0.1,0,0.1,0,0.1,0.1h0.1c0.1,0,0.1,0.1,0.3,0.1
        C211.5,45.8,211.5,45.8,211.9,45.9C211.9,45.9,211.8,45.9,211.9,45.9l-0.3,0c-0.1,0,0,0.1-0.1,0.1c0.1,0,0.1,0,0.1,0
        c0.1,0,0,0,0.1,0.1c0.1,0,0.1,0,0.1,0C211.8,45.9,211.8,45.9,211.9,45.9z M201.7,37.1c0.1-0.1,0.1,0,0.2-0.1
        C201.8,37,201.8,37,201.7,37.1z M203,36.3c0.1,0,0.1-0.1,0.2-0.1C203.2,36.2,203,36.3,203,36.3C203,36.3,203,36.3,203,36.3z
        M202.6,36.3c0,0,0.2-0.1,0.3-0.1C202.7,36.3,202.7,36.3,202.6,36.3z M201.5,36.9c0.1-0.1,0.3-0.1,0.3-0.2
        C201.7,36.7,201.6,36.8,201.5,36.9z M201,37.6c0.1,0,0.3-0.2,0.2-0.2S201,37.6,201,37.6z M199.8,40c0-0.1,0-0.2,0-0.3
        C199.8,39.8,199.8,40,199.8,40z M200.1,41.1L200.1,41.1c0,0.1,0,0.1,0.1,0.3C200.3,41.3,200.2,41.2,200.1,41.1z M203.5,43.7
        c-0.1-0.1-0.1-0.1-0.1,0C203.4,43.7,203.5,43.7,203.5,43.7z M203.7,43.7c-0.1-0.1-0.3-0.2-0.4-0.1c0.1,0.1,0.1,0,0.1,0
        C203.5,43.5,203.6,43.7,203.7,43.7z M204.4,43.9c-0.1-0.1-0.2-0.1-0.2,0C204.3,43.9,204.3,43.9,204.4,43.9z M204.4,43.9
        c0.1,0,0.1,0.1,0.1,0.1C204.7,44,204.5,43.9,204.4,43.9z M205.7,44.6h-0.1C205.4,44.6,205.7,44.7,205.7,44.6z M206.1,44.8
        c-0.1,0-0.1-0.1-0.2-0.1C205.9,44.7,205.9,44.7,206.1,44.8c-0.1,0.1-0.1,0-0.1,0C206,44.8,206.1,44.8,206.1,44.8z M207,45.2h0.1
        C207.2,45.1,207,45.1,207,45.2z M207.2,45.1c-0.1,0-0.1-0.1-0.2,0c0,0,0.1,0,0.1,0.1C207.2,45.1,207.2,45.1,207.2,45.1z
        M208.8,45.6c0.1,0.1,0.1,0.1,0.1,0.1C209,45.7,208.9,45.7,208.8,45.6C208.9,45.6,208.9,45.6,208.8,45.6
        C208.9,45.6,208.8,45.6,208.8,45.6s-0.1-0.1-0.1-0.1C208.7,45.5,208.8,45.5,208.8,45.6z M208.6,45.5l-0.1-0.1
        C208.4,45.5,208.6,45.6,208.6,45.5z M208.3,45.4c0,0.1,0.1,0.1,0.2,0.1h0.1C208.6,45.5,208.5,45.5,208.3,45.4z M208.1,45.5
        c0.1,0.1,0.1,0,0.1,0c0,0,0.1,0.1,0.1,0C208.3,45.5,208.1,45.4,208.1,45.5z M207.6,45.3c0.1,0,0.1,0,0.1,0
        C207.7,45.3,207.6,45.2,207.6,45.3z M210.8,45.7C210.8,45.7,210.8,45.6,210.8,45.7c-0.1-0.1-0.1-0.1-0.1,0
        C210.8,45.7,210.8,45.7,210.8,45.7z M211.3,45.7C211.3,45.7,211.2,45.7,211.3,45.7C211.3,45.7,211.3,45.7,211.3,45.7
        c-0.1,0-0.1-0.1-0.2-0.1C211,45.7,211.1,45.7,211.3,45.7C211.2,45.7,211.3,45.7,211.3,45.7z M205,35.8c0.1-0.1,0.1-0.1,0.3-0.1
        c0.1,0,0.1,0,0.2,0c0,0.1-0.1,0.1-0.3,0.2c0.1-0.1,0.1-0.1,0.2-0.1c0-0.1-0.1,0-0.1,0c0,0.1-0.3,0.3-0.4,0.1
        C204.9,35.8,204.9,35.8,205,35.8c-0.1,0-0.3,0.2-0.5,0.1V36c0.1,0,0.1-0.1,0.2-0.1c0.1,0.1-0.3,0.1-0.3,0.1s-0.1,0-0.2,0.1
        l0.1-0.1c0.1,0,0,0,0.1,0c0.1,0,0-0.1,0.1-0.1c0,0-0.1,0-0.1,0c0-0.1,0.1-0.1,0.3-0.1C204.7,35.8,204.8,35.8,205,35.8z
        M204.9,35.6L204.9,35.6c0,0,0.1-0.1,0.1,0C205,35.6,204.9,35.6,204.9,35.6z M204.4,35.8c-0.1,0-0.1,0.1-0.2,0.1
        C204.2,35.9,204.4,35.8,204.4,35.8z"/>
    </defs>
    <clipPath id="SVGID_2_">
      <use xlink:href="#SVGID_1_"  style="overflow:visible;"/>
    </clipPath>
    <path class="st3" d="M212.2,45.6c-16.4-2.9-15.6-9.5,2-10.5c21.1-1.2,22.7,10.4-2.8,12.2"/>
  </g>
  <g>
    <defs>
      <path id="SVGID_3_" d="M258.4,35.9C258.4,35.9,258.3,35.9,258.4,35.9h-0.1C258.3,35.9,258.4,35.9,258.4,35.9
        C258.3,35.9,258.3,35.9,258.4,35.9z M258.8,35.8C258.8,35.9,258.7,35.8,258.8,35.8c-0.1,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.1-0.1
        C258.7,35.8,258.7,35.8,258.8,35.8z M258.4,35.9h0.1C258.5,36,258.4,36,258.4,35.9z M260.1,36.1c-0.1,0-0.1,0-0.1,0
        c-0.1,0,0,0.1-0.1,0l-0.3-0.1c-0.1,0,0.1,0,0.1,0l-0.1-0.1c-0.1,0,0,0.1-0.1,0.1c-0.2,0-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.2-0.1
        c0.1,0,0.3,0.1,0.5,0.1c0,0-0.1,0-0.1-0.1c0.1,0,0.2,0.1,0.2,0.1C259.9,36,260,36,260.1,36.1C260.1,36,260.1,36.1,260.1,36.1
        C260.1,36.1,260.1,36,260.1,36.1c0.1,0,0.1-0.1,0.2,0c0.1,0,0.1,0.1,0.1,0.1h-0.1c-0.1,0-0.1,0.1-0.3,0.1c0.1,0,0.1-0.1,0.1-0.1
        C260.2,36.2,260.1,36.2,260.1,36.1C260.2,36.1,260.2,36.1,260.1,36.1L260.1,36.1z M259,36c0,0.1-0.1,0-0.1,0
        C258.8,36,258.9,36,259,36z M259.6,36.2L259.6,36.2c-0.1,0-0.1-0.1-0.2-0.1c-0.3-0.1,0.1,0,0.1,0
        C259.6,36.1,259.6,36.1,259.6,36.2z M259.2,36c-0.1,0-0.1,0-0.1-0.1c0.1,0,0.1,0,0.1-0.1C259.3,36,259.2,36,259.2,36z M258.9,35.9
        C258.8,35.8,258.9,35.8,258.9,35.9c0.1-0.1-0.1-0.1-0.1-0.1C258.7,35.8,258.8,35.8,258.9,35.9c0.1,0,0.1,0,0.1,0
        C259,35.9,258.9,35.8,258.9,35.9z M258.3,35.8c0-0.1,0.3,0,0.2,0C258.3,35.8,258.3,35.8,258.3,35.8z M238.8,36.7
        c0-0.1-0.1,0-0.1,0C238.6,36.7,238.7,36.7,238.8,36.7C238.8,36.7,238.8,36.7,238.8,36.7c0.1-0.1,0.2-0.1,0.3-0.1v0.1
        c0,0,0.2-0.1,0.1,0c-0.1,0.1-0.1,0-0.2,0C238.9,36.6,238.8,36.7,238.8,36.7c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.6-0.1,0.8-0.2
        c-0.5,0.1-0.9,0.3-1.4,0.5C238.4,36.8,238.7,36.7,238.8,36.7z M237.9,37.2c0,0.1-0.1,0.1-0.2,0.1c-0.1,0,0,0-0.1,0.1
        C237.4,37.3,237.9,37.2,237.9,37.2z M238.1,37.2C238.1,37.2,238.1,37.1,238.1,37.2C238.1,37.2,238.1,37.2,238.1,37.2
        c-0.1,0.1-0.2,0-0.3,0.1l0,0c0.2-0.1,0.4-0.2,0.6-0.3c0.1,0,0.1,0,0.2-0.1c0.1,0,0,0,0,0.1c0.1,0,0.1,0,0.3-0.1
        c0.1,0.1-0.3,0.1-0.1,0.1c-0.1,0.1-0.1,0-0.1,0c-0.1,0.1-0.1,0-0.1,0s-0.1,0.1-0.1,0.1c0,0,0-0.1-0.1,0l0,0c0.1,0,0.1,0,0.1,0v0.1
        c0.1,0,0.1-0.1,0.1,0C238.4,37.1,238.3,37.1,238.1,37.2z M238.2,37.1c0.1,0,0.1,0,0.1,0C238.3,37,238.2,37,238.2,37.1z
        M258.6,35.8c-0.1,0-0.1-0.1-0.3-0.1C258.5,35.7,258.5,35.7,258.6,35.8c0.1-0.1,0.2-0.1,0.3,0s-0.1,0-0.1,0S258.7,35.8,258.6,35.8
        z M237.3,37.5c-0.1,0.1-0.3,0.1-0.1,0C237.1,37.6,237.3,37.5,237.3,37.5z M237.5,37.4l-0.1,0.1C237.3,37.4,237.5,37.3,237.5,37.4z
        M258.1,35.9L258.1,35.9L258.1,35.9L258.1,35.9C258.2,35.9,258.1,35.9,258.1,35.9z M239,36.9c0,0.1-0.1,0.1-0.1,0.1
        c-0.2,0-0.4,0.1-0.6,0.1c0.2-0.1,0.4-0.1,0.6-0.2C239,36.9,239,36.9,239,36.9z M248.9,35.8c-0.1,0.1-0.3,0.1-0.5,0.1
        c0-0.1,0.1,0,0.3,0C248.6,35.8,248.7,35.8,248.9,35.8z M260.4,36.3C260.3,36.2,260.4,36.3,260.4,36.3
        C260.3,36.3,260.4,36.3,260.4,36.3c-0.1,0.1-0.1,0-0.1,0s-0.2,0-0.1-0.1c0.1,0,0.1,0,0.1,0.1c0.1,0,0-0.1,0-0.1
        S260.4,36.3,260.4,36.3c0.1,0,0.1-0.1,0.2,0h-0.1C260.5,36.3,260.4,36.3,260.4,36.3z M239.6,36.7c0,0.1-0.1,0.1-0.1,0.1l0,0
        c-0.1,0-0.1,0.1-0.2,0.1c0,0-0.1,0.1-0.1,0c0.1,0,0.2-0.1,0.3-0.1C239.4,36.7,239.5,36.8,239.6,36.7z M241.6,44.1
        c0,0.1-0.3,0-0.3-0.1c0.1,0,0,0.1,0.1,0.1c0.1,0,0-0.1-0.1-0.1c-0.1-0.1-0.1,0.1-0.2,0c0-0.1,0.1-0.1,0.3,0
        C241.5,44.1,241.5,44.1,241.6,44.1z M239.1,43.3c-0.1,0-0.2-0.1-0.1-0.1C239,43.2,239,43.2,239.1,43.3c0-0.1-0.1-0.1-0.1-0.1
        c0.1,0.1,0.3,0.1,0.2,0.1c0.1,0,0.1,0.1,0.1,0.1c-0.1,0-0.1-0.1-0.1-0.1C239.2,43.2,239.2,43.2,239.1,43.3
        C239.2,43.3,239.2,43.3,239.1,43.3z M239.5,43.6c-0.1,0-0.3-0.1-0.4-0.1c0.1,0,0.1,0,0.1-0.1c0.1,0,0,0.1,0,0.1
        c0.1,0.1,0.1,0,0.1,0h0.1c0,0,0,0,0-0.1c0,0-0.1,0,0,0c0,0-0.1-0.1-0.1-0.1c0.1,0,0.1,0,0.2,0.1c0.1,0.1,0.1,0,0.3,0.1
        c0,0-0.1,0-0.2-0.1v0.1c0.1,0.1,0.1,0,0.2,0.1c0.1,0,0,0,0.1,0s0.1,0.1,0.1,0.1c0.1,0,0.1,0,0.2,0.1c0,0.1-0.1,0-0.2,0
        c-0.1,0-0.1,0-0.1,0.1C239.4,43.7,239.6,43.6,239.5,43.6C239.4,43.6,239.4,43.6,239.5,43.6z M241.2,43.9c0.1,0,0.1,0,0.1,0.1
        c0.1,0,0.1,0,0.1,0c0.1,0,0.1,0.1,0.2,0.1c-0.1,0.1-0.2-0.1-0.3-0.1C241.3,43.9,241.2,43.9,241.2,43.9z M240.8,43.8h0.1
        c0.1,0,0.1,0,0.3,0c-0.1,0-0.1,0.1,0.1,0.1C241.1,43.9,241,43.8,240.8,43.8C240.8,43.8,240.8,43.8,240.8,43.8z M238.1,42.9
        L238.1,42.9C238.2,43,238.2,43,238.1,42.9C238.1,42.9,238.1,43,238.1,42.9z M238.1,43.1c0.1,0,0.1,0.1,0.1,0.1h0.1c0,0,0,0,0,0.1
        C238.3,43.2,238.1,43.1,238.1,43.1z M238.4,43.2c0.1,0,0.1,0.1,0.3,0.1c0-0.1,0.2,0.1,0.3,0.1c0,0.1-0.1,0-0.3-0.1
        C238.6,43.3,238.4,43.3,238.4,43.2z M238.3,43.1C238.3,43.1,238.4,43.2,238.3,43.1C238.4,43.1,238.4,43.1,238.3,43.1
        C238.3,43,238.5,43.1,238.3,43.1c0.1-0.1,0.1,0,0.2,0c0,0,0.1,0,0.1,0.1c0,0,0,0,0,0.1c0.1,0,0.1,0,0.1,0.1c0.1,0,0.1,0.1,0.1,0.1
        c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.1-0.1-0.1-0.1l0.1,0.1C238.5,43.2,238.5,43.2,238.3,43.1z M241.6,44
        C241.6,43.9,241.6,43.9,241.6,44c0.1,0,0.4,0.1,0.6,0.1c0.1,0,0.1,0.1,0,0C242,44.1,241.8,44.1,241.6,44z M238.6,43
        c0.1,0,0.1-0.1,0.1,0C238.7,43,238.8,43.1,238.6,43c0.1,0.1,0.2,0.1,0.1,0.1c-0.1-0.1-0.3-0.1-0.4-0.2c0.1,0-0.1,0,0-0.1
        C238.5,43,238.5,43,238.6,43z M238.7,43c-0.1-0.1,0.2,0.1,0.2,0.1c0.1,0.1,0.1,0,0.2,0.1C239,43.1,238.8,43,238.7,43
        C238.9,43,238.8,43,238.7,43z M240.7,43.9C240.8,43.9,240.8,43.9,240.7,43.9c0.1,0,0.1,0.1,0.2,0.1c-0.1,0,0,0.1-0.1,0.1
        c0-0.1,0.1,0,0.1-0.1c-0.1,0-0.2,0-0.1,0.1l0,0h-0.1C240.6,43.9,240.7,43.9,240.7,43.9c-0.1,0-0.2,0-0.3-0.1c0-0.1,0.1,0,0.1,0
        C240.6,43.8,240.6,43.8,240.7,43.9z M244.4,44.6c-0.1-0.1,0.3,0.1,0.3,0.1C244.6,44.8,244.4,44.6,244.4,44.6z M243,44.6
        c0.1,0,0.1,0.1,0.2,0.1C243.3,44.7,243.1,44.7,243,44.6c0,0.1-0.1,0-0.1,0.1c0.1,0.1,0.3,0.1,0.3,0.1l0,0c0.1,0,0.1,0,0.1,0
        s0.1,0,0.1,0.1c0.1,0,0.2,0,0.2,0.1c0.1,0,0,0.1,0.1,0.1c0.1,0,0,0,0,0s0.1,0.1,0.3,0.1c-0.1,0-0.1,0-0.1,0l0,0c0.1,0,0.1,0,0.1,0
        c-0.1,0.1,0.2,0.1,0.3,0.1c-0.1,0-0.1,0-0.2,0c0,0,0.1,0,0.1,0.1c-0.1,0-0.5-0.1-0.6-0.1c-0.1,0-0.1,0-0.1-0.1c0,0,0,0-0.1,0
        c-0.1,0-0.1-0.1-0.1-0.1c-0.1,0-0.1,0-0.1,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.3-0.1-0.4-0.1c-0.1,0-0.2-0.1-0.3-0.1
        c-0.1,0-0.1,0-0.1,0c-0.2-0.1-0.4-0.1-0.6-0.1c0,0-0.1,0-0.1-0.1c0,0,0.2,0.1,0.1,0c-0.1,0-0.2,0-0.3,0c-0.1-0.1-0.4-0.1-0.6-0.2
        c-0.1,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.2-0.1-0.2-0.1c0.1-0.1,0.2,0.1,0.3,0.1c0.1,0,0.1,0.1,0.3,0.1h0.1l0.1,0.1h0.1
        c0.1,0,0.1,0.1,0.3,0.1c0,0.1-0.1,0,0,0.1c0.1,0,0,0,0-0.1c0.1,0,0.1,0,0.2,0c0.2,0.1,0.4,0.1,0.6,0.2c0.1,0,0.2,0,0.3,0
        s0.1,0.1,0.3,0.1v0.1C243,44.6,243,44.6,243,44.6z M241.9,44.3C241.9,44.4,242,44.4,241.9,44.3c0.1,0,0,0-0.1,0
        C241.8,44.3,241.8,44.4,241.9,44.3C241.9,44.4,241.9,44.3,241.9,44.3z M242.1,44.4c-0.1,0-0.1,0-0.1,0h-0.1
        C242.1,44.4,241.9,44.5,242.1,44.4C242.1,44.5,242.1,44.5,242.1,44.4z M242.4,44.6c0-0.1-0.1-0.1-0.1-0.1c-0.1,0,0,0.1,0,0.1
        C242.3,44.6,242.3,44.5,242.4,44.6z M243.7,44.9C243.7,44.9,243.7,45,243.7,44.9c0.1,0.1,0.2,0.1,0.2,0
        C243.7,44.9,243.7,44.9,243.7,44.9z M242.8,44.7c-0.1,0-0.3-0.1-0.3-0.1C242.6,44.6,242.8,44.7,242.8,44.7z M242.9,44.6
        c0-0.1-0.3-0.1-0.3-0.1C242.7,44.6,242.7,44.6,242.9,44.6z M237.1,37.6c-0.1,0,0,0.1-0.1,0.1C237,37.6,237,37.6,237.1,37.6
        C237.1,37.5,237.1,37.6,237.1,37.6z M247.2,45.5c-0.1,0.1-0.1-0.1-0.3-0.1C246.8,45.4,247.1,45.5,247.2,45.5z M241.6,44.1
        c0.1,0,0.1,0.1,0.1,0.1L241.6,44.1C241.5,44.1,241.7,44.1,241.6,44.1z M241.8,44.3L241.8,44.3c-0.1-0.1-0.2-0.1-0.3-0.1
        C241.6,44.2,241.7,44.3,241.8,44.3z M242.6,44.2h0.1H242.6h0.1c0.1,0,0.1,0,0,0c0.1,0,0.1,0.1,0.1,0.1v-0.1
        c0.1,0.1,0.1,0.1,0.3,0.1H243l-0.1-0.1h-0.1C242.8,44.3,242.7,44.3,242.6,44.2C242.6,44.2,242.5,44.2,242.6,44.2z M260.9,36.5
        c0,0.1-0.1,0-0.1,0C260.8,36.4,260.8,36.4,260.9,36.5z M242.3,44.3c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0.1,0.2,0.1
        c0.1,0.1,0.1,0,0.3,0.1c-0.1,0-0.1,0-0.3,0C242.6,44.3,242.3,44.3,242.3,44.3z M242.2,44.3c-0.1,0.1-0.3-0.1-0.4-0.1
        c0.1-0.1,0,0-0.1-0.1C241.9,44.1,242.1,44.3,242.2,44.3z M242.4,44.4c0.1,0,0.1,0,0.1,0C242.6,44.5,242.4,44.4,242.4,44.4
        c-0.1,0-0.3-0.1-0.5-0.1C241.9,44.3,242.4,44.4,242.4,44.4z M247.3,44.6c-0.1,0-0.1,0.1-0.3,0.1h-0.1c-0.1,0-0.2,0-0.3-0.1
        c-0.1,0-0.1,0-0.1,0c-0.4-0.1-0.8-0.1-1-0.3h0.1c0-0.1-0.1-0.1-0.1-0.1c0-0.1-0.1-0.1-0.2-0.1c0.1,0.1,0.1,0.1,0.3,0.1
        c-0.1,0-0.2-0.1-0.3-0.1c0.1,0-0.1-0.1,0.1-0.1c-0.1,0-0.1-0.1-0.2-0.1c0,0.1,0.1,0.1,0.1,0.1l-0.1-0.1c0.2,0,0-0.1-0.1-0.1
        c0,0,0.1,0,0.1,0.1c0.1,0.1-0.1-0.1-0.3,0c0,0-0.1,0-0.1-0.1c-0.1,0-0.2-0.1-0.3-0.1c0.1,0,0.3,0.1,0.4,0c0,0,0,0,0.1,0.1
        c0.1,0,0,0,0-0.1c0.1,0.1,0.1,0,0.3,0.1c0,0.1-0.1,0-0.1,0C244.7,44,245,44,245,44s0.1,0.1,0.2,0.1h0.1h-0.1
        c-0.1,0-0.1-0.1-0.1-0.1c-0.1,0-0.1,0-0.3-0.1c0.3,0.1,0.6,0.1,0.8,0.1c-0.1,0.1-0.1,0.1-0.1,0.1c0.1,0,0.1,0,0.2,0
        c0.1,0-0.1-0.1,0-0.1l0.1,0.1c0-0.1-0.1-0.1-0.2-0.1c0.1,0,0.3,0.1,0.5,0.1h-0.1c0.1,0,0.1,0,0.1,0c0,0,0,0-0.1-0.1
        c0.1,0,0.3,0.1,0.6,0.1c-0.1,0-0.1,0,0,0.1c0.1,0,0.1,0,0.1,0c0,0,0.1,0,0.2,0.1C247.3,44.4,247.3,44.5,247.3,44.6z M245.1,44.1
        c0-0.1-0.2-0.1-0.2-0.1C244.9,44.1,245,44.1,245.1,44.1z M245.7,44.2c-0.1-0.1-0.1-0.1-0.1-0.1c-0.1-0.1-0.1,0.1-0.1,0.1
        c-0.1-0.1-0.3-0.1-0.3-0.1c0.1,0.1,0.1,0,0.1,0C245.5,44.1,245.5,44.3,245.7,44.2z M246.3,44.3c-0.1-0.1-0.1-0.1-0.2-0.1
        s-0.2-0.1-0.1,0l0.1,0.1C246.2,44.3,246.2,44.3,246.3,44.3z M246.2,44.4c0,0,0.1,0.1,0.1,0.1C246.4,44.4,246.3,44.4,246.2,44.4z
        M246.5,44.3c-0.1,0-0.1,0-0.1,0C246.4,44.3,246.4,44.3,246.5,44.3L246.5,44.3z M246.6,44.3C246.6,44.3,246.4,44.4,246.6,44.3
        C246.6,44.4,246.7,44.3,246.6,44.3z M247.1,44.4L247.1,44.4C247,44.4,247.1,44.5,247.1,44.4C247.1,44.4,247,44.4,247.1,44.4z
        M235.5,38.9c0-0.1,0.1-0.2,0.1-0.1C235.6,38.8,235.6,38.8,235.5,38.9C235.5,38.9,235.6,38.9,235.5,38.9z M235.6,38.8
        c0.1-0.1,0,0.1-0.1,0.1C235.6,38.9,235.6,38.9,235.6,38.8z M235.4,39.6c0,0-0.1-0.2,0-0.3c0-0.1,0-0.1,0-0.1l0,0l0,0
        c0.1,0.1-0.1,0.3,0,0.3C235.5,39.6,235.4,39.6,235.4,39.6z M235.7,38.7c0.1-0.1,0.1-0.1,0.1-0.2C235.8,38.5,235.8,38.6,235.7,38.7
        C235.7,38.7,235.6,38.7,235.7,38.7C235.6,38.7,235.6,38.8,235.7,38.7C235.6,38.7,235.6,38.6,235.7,38.7
        C235.7,38.6,235.7,38.6,235.7,38.7z M235.4,39.3c0.1-0.2,0.1-0.3,0.1-0.4c0.1,0.1,0,0.1,0,0.3c-0.1,0,0-0.1,0-0.1
        C235.4,39.1,235.4,39.2,235.4,39.3z M239.4,43.2C239.4,43.2,239.5,43.2,239.4,43.2c0.1-0.1,0.1,0,0.1,0.1L239.4,43.2z M235.9,38.3
        c0,0-0.1,0-0.1,0.1c0,0,0.1-0.1,0-0.1C235.8,38.3,235.9,38.3,235.9,38.3z M237,37.8c0,0-0.2,0.2-0.3,0.2s0.1,0,0.1-0.1
        C236.8,37.8,236.9,37.8,237,37.8z M235.4,39.6c0,0.2,0.1,0,0.1,0.2c-0.1,0-0.1-0.1-0.1-0.1c0-0.1,0-0.3,0.1-0.3
        C235.4,39.5,235.4,39.6,235.4,39.6z M236.5,38.1c0,0.1-0.2,0.1-0.3,0.3C236.3,38.2,236.5,38.1,236.5,38.1z M236.2,38.2
        c-0.1,0-0.1,0.1-0.1,0.1C236.1,38.3,236.1,38.2,236.2,38.2C236.2,38.1,236.2,38.1,236.2,38.2z M236.8,37.7c0,0,0.1,0,0.1,0
        c0,0.1-0.2,0.1-0.3,0.2h-0.1c-0.1,0.1,0.1,0,0,0.1c0,0-0.1,0-0.1,0.1c-0.1,0,0.1-0.1,0-0.1c0,0,0.1-0.1,0.1-0.1
        c0.1,0,0-0.1,0.1-0.1c0,0,0,0.1-0.1,0.1h0.1C236.7,37.8,236.7,37.7,236.8,37.7c0,0,0.1-0.1,0.1-0.1v0.1
        C236.9,37.7,236.9,37.6,236.8,37.7z M236.1,38.5c-0.1,0-0.1,0.1-0.1,0.1c0,0-0.1,0-0.1,0.1c0,0,0,0.1-0.1,0.1
        c-0.1,0.2-0.2,0.4-0.2,0.7c-0.1,0,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.1l0,0c0-0.1,0.1-0.1,0.1-0.1c0-0.3,0.1-0.3,0.2-0.5
        C236,38.5,236,38.5,236.1,38.5z M239.7,43.3c-0.1-0.1,0.2,0,0.3,0.1C239.9,43.4,239.7,43.3,239.7,43.3z M240,43.7
        c-0.1,0-0.1-0.1-0.1-0.1C239.8,43.6,240,43.7,240,43.7s0.2,0,0.3,0.1c0.1,0,0.1,0.1,0.2,0.1c0,0,0.1,0.1,0.1,0.1
        c-0.1,0-0.2-0.1-0.3-0.1C240.1,43.8,240.1,43.8,240,43.7z M239.8,43.4C239.8,43.5,239.7,43.4,239.8,43.4c-0.1,0.1-0.1,0-0.1,0
        C239.7,43.4,239.7,43.4,239.8,43.4z M239.7,43.4c-0.1,0,0,0.1-0.1,0c0-0.1,0.1,0,0-0.1C239.6,43.4,239.7,43.4,239.7,43.4z
        M239.7,43.7c0.1,0,0.1,0.1,0.2,0.1C239.9,43.7,239.6,43.7,239.7,43.7z M235.9,40.8c0.1,0.1,0,0.1,0.1,0.1
        C235.9,41,235.9,40.8,235.9,40.8C235.8,40.8,235.9,40.8,235.9,40.8z M238.4,42.5c0.1,0,0.3,0.1,0.5,0.2c0.1,0.1,0.2,0,0.3,0.1
        c0,0.1-0.1,0-0.2-0.1c-0.1,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.1,0s-0.1,0-0.1-0.1C238.5,42.5,238.5,42.5,238.4,42.5z M240.1,43.7
        c0,0-0.1,0-0.1,0C240,43.7,240.1,43.7,240.1,43.7z M240.5,43.7c0.1,0,0.1,0.1,0.1,0.1c-0.1,0.1-0.3-0.1-0.6-0.1c-0.1,0,0,0-0.1,0
        c-0.1-0.1-0.1-0.1-0.3-0.1c-0.1,0-0.1-0.1-0.1-0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1-0.1,0.1,0H240
        C240.3,43.6,240.5,43.7,240.5,43.7z M240.1,43.2c0.1-0.1,0.1,0,0.3,0.1c0.1,0,0.3,0,0.2,0.1C240.4,43.2,240.1,43.2,240.1,43.2z
        M258,45c0.1-0.1,0.3-0.1,0.6-0.2C258.4,44.8,258.1,44.9,258,45z M238.6,36.1c0,0.1-0.1,0.1-0.2,0.1
        C238.4,36.1,238.5,36.1,238.6,36.1z M238.4,36.3l0.3-0.1L238.4,36.3z M238.4,36.1c0-0.1,0.1-0.1,0.1-0.1
        C238.5,36,238.5,36.1,238.4,36.1z M240.3,35.6c0,0,0.1-0.1,0.2-0.1C240.5,35.6,240.4,35.6,240.3,35.6z M238.3,36
        c-0.2,0.1-0.3,0.1-0.5,0.2c0-0.1-0.1,0.1-0.1,0c0.1,0,0.2-0.1,0.3-0.1l0,0l0.1-0.1c0.1,0,0,0,0-0.1C238.2,36,238.3,35.9,238.3,36
        L238.3,36c0.1-0.1,0.1-0.1,0.2-0.1c0,0-0.1,0.1-0.1,0.1C238.4,36,238.3,36,238.3,36S238.3,36,238.3,36z M237.8,36.5
        c0-0.1,0.2-0.1,0.3-0.1C238.1,36.4,237.9,36.4,237.8,36.5z M240.8,35.4L240.8,35.4l0.1-0.1c0.1,0,0.1,0,0.2-0.1
        c-0.1,0.1,0,0.1-0.1,0.1c-0.1,0,0,0,0-0.1C240.9,35.2,241,35.3,240.8,35.4c0-0.1-0.1,0-0.2,0C240.6,35.4,240.7,35.4,240.8,35.4
        S240.8,35.4,240.8,35.4z M237.8,36.3c0,0.1-0.1,0.1-0.2,0.1C237.6,36.3,237.7,36.3,237.8,36.3z M238.1,36l0.1-0.1
        C238.2,36,238.1,36,238.1,36z M241.3,35.2c0.1,0,0.1-0.1,0.2-0.1C241.5,35.1,241.5,35.1,241.3,35.2z M242.2,35L242.2,35
        c0,0,0-0.1,0.1-0.1C242.3,35,242.2,35,242.2,35z M237.6,36.5c-0.3,0.1-0.4,0.2-0.9,0.3c-0.1,0,0.1-0.1,0.1-0.1
        C237.2,36.7,237.5,36.6,237.6,36.5C237.7,36.5,237.7,36.5,237.6,36.5z M243.5,34.8C243.7,34.8,243.7,34.8,243.5,34.8
        C243.5,34.9,243.5,34.8,243.5,34.8z M244.9,34.5c0.1,0,0.1,0,0.1,0C245,34.5,244.9,34.5,244.9,34.5z M243.7,34.6
        c0.2-0.1,0.4-0.1,0.6-0.1c0.1,0,0.1-0.1,0.2-0.1l0,0c0.1,0,0.2-0.1,0.3-0.1c-0.1,0.1,0.1,0.1,0.1,0.1c-0.1,0.1-0.3,0-0.3,0
        c0,0.1-0.1,0.1-0.3,0.1c0,0,0.1,0,0.1-0.1s-0.1,0-0.1,0s-0.1,0.1-0.2,0l0,0c-0.1,0-0.1,0-0.1,0.1c-0.1,0-0.1,0-0.1,0
        c-0.1,0-0.1,0.1-0.1,0H243.7C243.7,34.7,243.9,34.6,243.7,34.6z M243.8,34.7c0.1,0,0.3,0.1,0.3-0.1
        C244,34.6,243.8,34.6,243.8,34.7z M241.2,35.4c0,0.1-0.1,0.1-0.2,0.1C241,35.4,241.2,35.4,241.2,35.4z M241.3,35.4
        c-0.1,0.1-0.3,0.1-0.3,0.1C241,35.4,241.2,35.4,241.3,35.4z M245.6,34.8c0.1-0.1,0.1,0,0.3,0C245.9,34.8,245.7,34.8,245.6,34.8z
        M244.5,34.9c0.1-0.1,0.3-0.1,0.4-0.1c0.1,0,0.6-0.1,0.3,0C245.1,34.9,244.8,34.9,244.5,34.9z M254.8,35.3c0.1,0.1-0.2,0-0.3,0
        C254.5,35.3,254.7,35.2,254.8,35.3L254.8,35.3z M255.3,35.4c-0.1,0-0.2,0-0.3,0C255,35.3,255.3,35.3,255.3,35.4z M255.5,35.4
        C255.6,35.4,255.5,35.5,255.5,35.4c-0.1,0.1-0.1,0-0.1,0C255.4,35.4,255.5,35.5,255.5,35.4z M236.5,37c-0.1,0.1-0.3,0.2-0.4,0.3
        C236.2,37.2,236.4,37.1,236.5,37z M245,34.6c0-0.1,0.3-0.1,0.5-0.1c0.1,0,0,0,0,0c0.1-0.1,0.2,0,0.3,0s0,0,0,0c0.1,0,0.3,0,0.3,0
        c0.1,0,0.2,0,0.1-0.1c0.1,0,0.3,0,0.4,0c0.1,0,0.1,0.1,0.2,0.1c0.1,0-0.2,0-0.1-0.1c0.1,0,0.1,0,0.1,0c0.2,0,0.5,0,0.7,0
        c0.1,0,0.1,0,0.1,0.1c0.1-0.1,0.2-0.1,0.4-0.1c0-0.1,0.1,0,0.3-0.1h-0.1c0.1,0.1,0.2,0,0.3,0c0.1,0,0,0,0,0c0-0.1,0.1,0,0.1,0h0.1
        c0.1,0,0.1,0,0.1,0c0.1,0,0.1,0,0.1,0c0.1,0,0.1,0,0.1-0.1c0.1,0,0.1,0,0.1,0s0,0,0.1,0c0.1,0,0.2,0,0.3,0h-0.1c0.1,0,0.3,0,0.4,0
        h0.1c0,0,0.1,0,0.1,0c0.1,0,0,0,0,0c0.1,0,0.1,0,0.2,0c0,0-0.1,0-0.1,0c0.1,0-0.1,0.1,0,0.1c0,0,0,0,0.1,0s0,0,0-0.1
        c0,0,0.1,0,0.1,0h0.1c0,0,0.1,0.1,0.1,0c0.1,0,0.1,0,0.1,0c0.1,0,0.1,0,0.1,0c0.1,0,0.2,0.1,0.3,0c0.1,0,0.1,0.1,0.3,0.1
        c0,0,0,0-0.1,0c0.1,0,0.1,0,0.2,0s0-0.1,0.1-0.1s0.1,0,0.2,0c0,0,0.1,0,0.1,0c0.2,0,0.7,0,1,0.1c0.1,0,0.2,0,0.2,0
        c0.1,0,0.1,0,0.1,0c0.3,0,0.6,0,0.9,0.1c-0.1,0-0.3,0-0.3,0c-0.1,0.1,0.1,0,0.2,0s0.1,0,0.1,0c0.1,0,0.1,0,0.1,0
        c0.1,0.1,0.3,0.1,0.5,0c0.3,0,0.5,0,0.7,0.1c0.2-0.1,0.6,0,0.8,0c0.1-0.1,0.3,0,0.6,0c0.1,0,0.3,0,0.5,0c0.2,0,0.4,0,0.6,0.1
        c0.2,0,0.3,0.1,0.5,0.1c0.1,0.1,0.1,0,0.3,0.1c0.1,0,0.1,0,0.1,0c0.2,0,0.4,0.1,0.6,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.2,0
        s0.1,0.1,0.2,0.1c0.1,0,0.2,0,0.3,0.1c0,0,0,0,0.1,0s0.1,0.1,0.3,0.1c0.1,0.1,0.1,0.1,0.3,0.1c0.1,0.1-0.1,0-0.1,0.1
        s0.1,0.1,0.2,0.1s0,0,0.1-0.1c0.1,0,0.1,0.1,0.1,0.1c0.1,0.1,0.1,0,0.2,0.1v0.1c0.1,0.1,0.1,0,0.2,0.1h-0.1
        c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0,0,0,0c0.1,0,0.3,0.1,0.3,0.2c0.1,0,0.1,0,0.1,0c0.1,0,0.1,0.1,0.2,0.1c0,0,0,0.1,0.1,0.1
        c0,0,0.1,0,0.1,0.1V36c0.1,0.1,0,0,0,0.1c0.1,0.1,0.1,0,0.1,0.1c0.2,0.1,0.2,0.1,0.3,0.1l-0.1-0.1c-0.1-0.1,0-0.1,0-0.1
        c0.1,0.1,0.1,0.1,0.2,0.1l-0.1-0.1c0.1,0.1,0.3,0.2,0.4,0.2c0.1,0.1,0.3,0.2,0.2,0.2c0.1,0.1,0.1,0.1,0.2,0.1c0.1,0.1,0,0-0.1-0.1
        c0,0.1,0.1,0.2,0,0.1c0,0.1,0.1,0.1,0.2,0.1c0-0.1-0.1-0.1-0.1-0.1c0.1,0,0-0.1,0.1,0c0.1,0.1-0.1,0-0.1,0.1
        c0.1,0.1,0.1,0.1,0.1,0.1c0.1,0.1,0-0.1,0.1-0.1c0,0.1,0.1,0.1,0.2,0.3c0,0.1-0.1,0,0,0.1s0.1,0.1,0.1,0.1l-0.1-0.1
        c0-0.1,0.1,0.1,0.1,0.1c0-0.1-0.1-0.1-0.1-0.2c0.1,0,0.1,0.2,0.3,0.3l-0.1-0.1c0.1,0.2,0.1,0.2,0.1,0.3c0,0,0,0,0.1,0.1
        c0-0.1,0-0.1,0-0.2c0,0.1,0.1,0.1,0,0.1C265,37.9,265,38,265,38c0,0.1,0,0.1,0,0.1c0.1,0.1,0.1,0,0.1,0.2c0,0.1,0.1,0.1,0,0.1
        c0.1,0.1,0,0,0.1,0.1l-0.1-0.1c0.1,0,0.1,0.3,0.1,0.2c0.1,0.1,0.1,0.2,0.1,0.3c0-0.1-0.1-0.4,0-0.3c0,0.1,0,0.1,0,0.2
        c0,0,0,0,0,0.1c0,0,0-0.1,0.1-0.1c0-0.1-0.1-0.1-0.1-0.1c0-0.1,0.1,0.2,0.1,0.3c0,0-0.1-0.1-0.1,0c0.1,0.1,0.1,0.4,0.1,0.4
        c0,0.1,0.1,0,0.1-0.1c0.1,0.2-0.1,0.4,0,0.6c0,0.1-0.1,0.1-0.1,0.2s0,0.1,0,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c0,0.1,0,0.1,0,0.1
        c0,0.1,0,0.1,0,0.1c0,0,0.1,0,0,0.1c-0.1,0-0.1-0.1-0.1,0c0,0.1,0.1-0.1,0.1,0c-0.1,0.1-0.1,0.2-0.1,0.3c-0.1,0.1-0.1,0.2-0.2,0.3
        c-0.1,0.1,0.1,0,0,0.1c-0.1,0.1,0,0-0.1,0l-0.1,0.1c-0.1,0.1,0,0.1,0,0.1c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1,0,0,0-0.1
        c-0.1,0-0.1,0.2-0.2,0.3c0-0.1-0.1,0,0-0.1l-0.1,0.1c-0.1,0.1,0,0.1,0.1,0.1c-0.1,0.1,0,0.1-0.1,0.2V43c-0.1,0.1-0.3,0.3-0.4,0.4
        c0,0,0,0,0,0.1c-0.1,0.1-0.2,0.1-0.3,0.2l0,0c0,0-0.1,0.1-0.1,0.1c0,0,0,0.1-0.1,0.1c0,0.1,0.1-0.1,0.1,0c-0.1,0.1-0.1,0-0.2,0.1
        v-0.1c-0.1,0.1-0.1,0.1-0.2,0.1v0.1c-0.2,0.1-0.3,0.2-0.4,0.3l0.1-0.1c-0.1,0-0.3,0.2-0.1,0.1c0,0-0.1,0.1-0.2,0.1h-0.1l0,0
        c-0.1,0.1-0.3,0.1-0.3,0.2c-0.1,0.1-0.1,0-0.2,0.1h0.1c0,0.1-0.1,0.1-0.2,0.1s0.1,0-0.1,0.1c-0.1,0.1-0.1,0-0.1,0
        c-0.1,0.1-0.1,0.1-0.3,0.1h-0.1c-0.1,0-0.1,0-0.1,0.1c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.1,0.1-0.2,0.1s-0.1,0.1-0.2,0.1
        c0,0-0.1,0-0.1,0.1v0.1c0,0-0.2,0-0.3,0.1c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.1,0.1-0.1,0.1c-0.1,0-0.1,0-0.1,0
        c-0.1,0-0.1,0.1-0.1,0.1l-0.1,0.1c-0.5,0.1-0.9,0.3-1.3,0.4h-0.1c-0.3,0.1-0.6,0.2-0.9,0.3c-0.1,0,0,0.1-0.1,0.1
        c0-0.1-0.1-0.1-0.1-0.1c0,0.1,0.1,0,0.1,0.1c-0.1,0-0.1,0-0.1,0c-0.1,0-0.1,0.1-0.1,0.1c-0.2,0.1-0.3,0.1-0.6,0.1
        C256,47,256,47,256,46.9c-0.1,0-0.1,0-0.1,0.1c-0.1,0-0.1-0.1-0.1-0.1c-0.1,0.1-0.3,0-0.3,0.1c-0.1,0-0.1,0-0.1,0s0,0-0.1,0
        c-0.1,0-0.1,0-0.2,0c0,0-0.1-0.1-0.1,0c-0.1,0.1,0.3,0,0.1,0c-0.1,0-0.1,0-0.1,0c-0.1,0-0.1,0.1-0.1,0.1c-0.1,0-0.1,0-0.1,0
        c-0.3,0-0.6,0.1-0.7,0.1c-0.1,0,0,0,0,0.1c-0.1,0-0.4,0-0.6,0.1c-0.1,0-0.1,0-0.1,0s-0.1,0-0.1,0.1c-0.1,0-0.1,0-0.1,0h-0.1
        c-0.1,0-0.1,0-0.2,0c0,0,0.1,0,0.1,0s-0.2,0-0.3,0.1c0.1-0.1-0.1,0,0-0.1c0,0-0.1,0.1-0.1,0c-0.1,0,0.1,0.1,0,0.1
        c-0.1,0,0,0-0.1,0c-0.1,0-0.1,0.1-0.1,0.1s-0.1,0-0.1,0.1c-0.1,0-0.3,0-0.4,0c0-0.1-0.1-0.1,0-0.1c-0.1,0-0.4,0-0.6,0.1l0,0
        c-0.3,0.1-0.6,0-0.7,0.1c-0.1,0-0.3,0-0.3,0.1c-0.1,0-0.1,0-0.3,0.1c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0.1c-0.1,0-0.3,0.1-0.4,0.1
        c-0.1,0-0.3,0-0.5,0.1c-0.1,0-0.1,0.1-0.3,0.1c-0.1,0-0.2,0-0.3,0.1c0-0.1-0.1-0.1-0.1-0.1c0.1,0,0.1-0.1,0.1-0.1c0,0-0.1,0-0.2,0
        c0.1-0.1,0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0.1c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.1,0.1-0.3,0.1c-0.1,0.1-0.3,0.1-0.6,0.2
        c-0.1,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.3,0.1-0.5,0.1c0-0.1-0.1,0-0.1-0.1c-0.1,0-0.2-0.1-0.3,0c0.1-0.1,0-0.1-0.1-0.1
        c0.1-0.1-0.1-0.1-0.1-0.1c0.1,0,0.1,0,0.1-0.1c0,0-0.1,0-0.1,0c0.1-0.1,0-0.1,0.1-0.1c0,0,0,0-0.1,0c0.1,0,0-0.1,0.1-0.1h0.1v-0.1
        h0.1c0.1,0,0.1-0.1,0.1-0.1c0.1,0,0.1-0.1,0.2-0.1c0,0,0,0,0-0.1c0.1,0,0.4-0.1,0.6-0.1l0.2-0.1c0-0.1-0.1-0.1-0.1-0.1
        c0.2-0.1-0.1-0.1,0.1-0.1h0.1c0,0-0.1,0-0.1-0.1c0.1,0,0.2-0.1,0.3-0.1c0,0,0.1,0,0.1,0c0.1,0,0,0,0.1-0.1h0.1v0.1
        c0.1-0.1,0.2,0,0.3-0.1c0,0-0.2,0.1-0.1,0c0.3-0.1,0.6-0.1,1-0.1c0.1,0,0.1,0,0.1-0.1c0,0-0.1,0-0.1-0.1c0.1-0.1,0.1-0.1,0.3-0.1
        c0-0.1-0.1,0-0.1,0s0.1,0,0.1,0c0.2,0,0.6-0.1,0.7-0.1c0.1,0,0.1-0.1,0.3-0.1c0,0-0.1,0-0.1,0.1l0,0c0.1,0,0.1,0,0.1-0.1
        c0.2,0,0.4,0,0.6-0.1c-0.1,0.1,0.1,0,0.1,0c0.2,0,0.4-0.1,0.6-0.1c0.5-0.1,1-0.1,1.4-0.1c0.1,0,0,0,0,0.1c0.1,0,0.1,0,0.1,0
        c0.1,0,0.1,0,0.1-0.1c0.1,0,0.1,0,0.2,0s0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0c0.1,0,0.1-0.1,0.2-0.1h-0.1c0.1,0,0.1,0,0.2,0h0.1
        c0.2,0,0.5-0.1,0.6-0.1c0,0,0.1,0,0.1-0.1c0.1,0.1,0.3,0,0.5-0.1V46c0.1,0,0.1,0,0.2-0.1c0.1,0,0,0.1,0,0.1c0.1,0,0.1-0.1,0.2-0.1
        c0.1,0,0.3,0,0.4-0.1c0,0,0.1,0,0.1,0c0.4-0.1,0.9-0.2,1.5-0.3c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.2,0,0.3-0.1v0.1
        c0.1-0.1,0.3-0.1,0.4-0.1c0,0.1-0.1,0.1-0.2,0.1s-0.1,0.1-0.1,0.1c0,0,0.1,0,0.1,0c-0.1,0.1,0,0.1-0.1,0.1s-0.3,0.1-0.4,0.1
        c-0.1,0-0.1,0-0.1,0s-0.1,0.1-0.1,0.1c0,0,0.1,0,0.1,0c-0.1,0.1-0.3,0.1-0.3,0.1c0-0.1,0-0.1-0.1-0.1s-0.1,0.1-0.2,0.1l0,0
        c0,0.1,0.3-0.1,0.2,0c-0.1,0-0.3,0.1-0.3,0.1c-0.1,0,0,0.1-0.1,0.1c0.1,0,0.1-0.1,0.3-0.1c0,0,0,0.1-0.1,0.1h0.1
        c0.1-0.1-0.1,0-0.1-0.1c0.1,0,0.1,0,0.1-0.1c0.1,0,0.1,0,0.2,0c0,0-0.1,0-0.1,0.1c0.1,0.1,0.1-0.1,0.2-0.1c0.1,0,0.1,0,0.2,0
        s0-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0.1c0.1,0,0-0.1,0.1-0.1c0.3-0.1,0.7-0.1,1-0.3c0.1,0,0-0.1,0.1-0.1c0,0-0.1,0-0.1,0
        c0.1-0.1-0.1-0.1-0.1-0.1h0.1h0.1c0,0.1,0,0.1,0.1,0.1c0-0.1-0.1,0-0.1-0.1c0.1,0,0.2-0.1,0.3-0.1c-0.1,0-0.1,0.1-0.2,0.1
        c-0.1,0-0.2,0-0.3,0c0.1,0,0.1-0.1,0-0.1c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0s0.1-0.1,0.2-0.1s0.1,0,0.1,0
        c0.1,0,0.1-0.1,0.3-0.1c0.2-0.1,0.6-0.2,0.7-0.3c0.1,0,0.1,0,0.1-0.1c0.1-0.1,0.1,0,0.1,0c0.1,0,0.1,0,0.1-0.1
        c0.1-0.1,0.1,0,0.1,0c0.1-0.1,0.1-0.1,0.2-0.1c0,0,0,0,0-0.1c0.1,0,0.2-0.1,0.3-0.1c0,0,0-0.1,0.1-0.1c0.3-0.1,1-0.5,1.5-0.9
        c0.1-0.1,0.1-0.1,0.1-0.1c0.1-0.1,0.3-0.2,0.4-0.3c-0.1,0.1-0.1,0.1,0-0.1c-0.1,0.1,0.4-0.4,0.6-0.6c0-0.1,0.1,0,0.1-0.1l0.1-0.1
        c0,0,0.1-0.1,0.1-0.1s0-0.1,0.1-0.2l0.1-0.1c0-0.1,0-0.1,0-0.1c0,0,0-0.1,0.1-0.1c0-0.1,0-0.1,0.1-0.2c0-0.1,0.1-0.3,0.1-0.4
        c0,0,0,0,0-0.1c0,0,0,0,0.1,0c0-0.1,0-0.1,0-0.1l0,0v-0.1c0-0.1,0-0.1,0-0.1c0,0,0-0.1,0-0.2s0-0.1,0-0.1c0-0.1-0.1-0.3-0.1-0.5
        c0-0.1-0.1-0.1-0.1-0.2s0-0.1,0-0.1c0-0.1-0.1-0.1-0.1-0.2s0-0.1-0.1-0.1c0-0.1-0.1-0.1-0.1-0.1l-0.1-0.1c0,0,0-0.1-0.1-0.1
        c0-0.1-0.1-0.1-0.1-0.1c0-0.1-0.1-0.1-0.1-0.1c0.1-0.4-0.1-0.6-0.1-0.7c-0.1,0-0.2-0.1-0.3-0.2l-0.1-0.1c-0.3-0.1-0.6-0.3-0.8-0.4
        c-0.1,0-0.1-0.1-0.1-0.1c0,0,0.1,0,0.1,0.1c-0.1,0-0.3-0.1-0.3-0.1c0,0-0.1,0-0.2-0.1c-0.1,0-0.1,0-0.3-0.1h0.1c0,0-0.1-0.1,0.1,0
        c0-0.1-0.1,0-0.1-0.1c0,0,0.1,0,0.1-0.1c-0.1-0.1-0.1,0-0.1-0.1c0.1-0.1-0.1-0.1-0.1-0.1c0,0.1,0.1,0.1,0.1,0.1
        c-0.1,0-0.1,0-0.1,0s0-0.1-0.1-0.1s-0.1,0,0,0.1c-0.1,0-0.1-0.1-0.1-0.1c-0.1,0-0.2,0-0.2,0.1c-0.1,0-0.1,0-0.1,0
        c-0.1-0.1-0.2-0.2-0.2-0.2c0.1,0,0.2,0,0.1,0.1l0.1,0.1c0.1-0.1-0.1-0.1-0.1-0.1s0.1,0,0.1,0.1c0-0.1-0.1-0.1-0.1-0.1
        c-0.1,0-0.1,0-0.2-0.1c0-0.1,0.2,0,0.2-0.1c0.1,0,0.1,0.1,0.3,0.1c-0.1,0,0,0,0,0.1c0.1,0.1,0.1-0.1,0.2,0c0.1,0,0,0,0,0.1
        c0.1,0,0.1,0,0.1,0c0.1,0,0.1,0.1,0.2,0.1c0.1,0-0.1-0.1,0-0.1l0.1,0.1c0.1,0,0-0.1-0.1-0.1c0.1,0,0.2,0.1,0.3,0.1
        c0-0.1-0.1,0-0.2-0.1c0,0-0.1-0.1,0-0.1c-0.1-0.1-0.1,0-0.2-0.1c-0.1-0.1,0-0.1-0.1-0.1c-0.1,0-0.1,0-0.1,0
        c-0.1-0.1-0.1-0.1-0.2-0.1c-0.1,0,0.1,0,0,0.1c-0.1,0-0.3-0.1-0.4-0.1s-0.1-0.1-0.2-0.1c0.1-0.1,0.1,0.1,0.2,0.1
        c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1,0,0.1,0.1,0.1,0.1c0,0.1-0.2-0.1-0.2,0c-0.1,0-0.1,0-0.1-0.1c-0.1,0-0.2,0-0.3-0.1h0.1
        c-0.1-0.1-0.1-0.1-0.2-0.1s-0.1,0-0.1,0.1c-0.1,0-0.2-0.1-0.2-0.1c0-0.1,0.1,0.1,0.2,0c-0.1-0.1-0.2,0-0.3-0.1c0,0,0,0,0,0.1
        l-0.3-0.1c-0.1-0.1,0.2,0.1,0.2,0c-0.1-0.1-0.2,0-0.3-0.1c0.1,0.1-0.1,0-0.3,0c-0.1-0.1,0.1,0,0.2,0c0-0.1-0.2-0.1-0.3-0.1
        c-0.1-0.1-0.3-0.1-0.4-0.1c0.1,0,0.2,0,0.3,0.1h-0.1c-0.1,0-0.2-0.1-0.3,0c-0.1-0.1-0.3-0.1-0.6-0.1c0,0,0,0-0.1,0
        c-0.1,0-0.1,0-0.1-0.1c-0.2,0-0.3-0.1-0.6,0c-0.1,0,0,0,0.1-0.1c-0.1,0-0.1,0-0.1,0c-0.1,0-0.1,0.1,0,0.1c-0.1,0.1-0.2,0-0.3,0
        v0.1c-0.1,0-0.1,0-0.3,0c0.1,0,0-0.1-0.1-0.1c0-0.1,0.1,0,0.1,0c0,0,0-0.1-0.1-0.1c-0.1,0,0,0.1-0.1,0.1c-0.1,0-0.1,0-0.3-0.1
        v-0.1c-0.1,0-0.2,0.1-0.3,0c0.1,0-0.1,0-0.1-0.1c0,0,0-0.1-0.1-0.1c-0.1,0,0.1,0.1,0.1,0.1c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0,0,0,0,0
        c-0.1,0-0.1,0-0.2,0s-0.2,0-0.3,0s-0.1,0-0.2,0c0,0,0,0,0-0.1c-0.1,0-0.1,0.1-0.2,0c0,0,0.1,0,0.1-0.1c-0.1,0-0.1,0-0.2,0
        c0,0.1,0.1,0.1,0.1,0.1h-0.1c0,0,0.1,0,0,0c-0.1,0-0.1-0.1-0.3-0.1c-0.1,0,0.1,0,0,0.1c-0.1,0-0.2,0-0.3-0.1v-0.1
        c-0.1,0-0.1,0-0.1,0c-0.1,0-0.1-0.1-0.2,0c0.1,0,0.2,0.1,0,0.1c0.1,0,0.2,0,0.3,0c0,0.1-0.1,0-0.2,0s-0.2,0-0.3,0v-0.1
        c-0.1,0-0.1,0-0.1,0.1h-0.2c0,0,0.1,0,0.1-0.1c-0.1,0-0.2,0-0.3,0l0,0c-0.3,0-0.3,0-0.6,0c0,0.1-0.1,0.1-0.3,0.1
        c0,0.1,0.2,0,0.3,0c-0.1,0-0.2,0-0.3,0c0,0,0,0,0.1,0c-0.1,0,0-0.1-0.1-0.1c-0.1,0,0.1,0.1-0.1,0.1c-0.1,0-0.1-0.1-0.2-0.1
        c-0.1,0,0.1,0.1-0.1,0.1c-0.1,0-0.3,0-0.2,0c-0.1,0-0.3,0-0.4,0c-0.1,0.1,0.1,0.1,0.2,0.1c0,0.1-0.1,0-0.2,0s-0.1,0-0.2,0
        c0-0.1,0.1-0.1,0.1-0.1c0,0-0.1,0-0.1,0c0,0,0.1-0.1,0-0.1s0,0.1-0.1,0.1s0.1,0.1,0,0.1c-0.1,0-0.1,0,0,0c-0.1,0-0.2,0-0.3,0
        c0-0.1,0.1,0,0.1-0.1c-0.1-0.1-0.1,0-0.2,0c-0.1,0,0,0.1,0,0.1c0,0.1-0.1,0-0.1,0.1c-0.1,0-0.2,0-0.3,0c-0.1,0.1-0.3,0-0.6,0
        c-0.1,0-0.1,0-0.3,0c0,0,0,0-0.1,0c-0.4,0-0.6,0.1-1,0c0,0,0,0-0.1,0c-0.1,0-0.1,0-0.1,0c0-0.1,0.1-0.1,0.1-0.1
        c0.1,0,0.2,0.1,0.3,0c0-0.1-0.1,0-0.2,0v-0.1c-0.1,0-0.1,0-0.2,0c-0.1,0.1,0.1,0,0.1,0.1c-0.1,0-0.2,0.1-0.1,0.1
        c0,0-0.3,0.1-0.3,0c-0.1,0.1-0.4,0-0.6,0.1c-0.3,0-0.6,0.1-0.9,0.1c-0.1,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.1,0c-0.1,0-0.1,0.1-0.1,0.1
        c-0.1-0.1,0.1,0,0.1-0.1l0.1-0.1c0.1,0,0.2,0,0.1,0H245c0,0,0-0.1-0.1,0c0,0,0.1,0,0.1,0c-0.1,0-0.3,0-0.5,0c0,0.1,0.2,0,0.3,0
        c0.1,0.1-0.1,0.1-0.1,0.1c-0.3,0.1-0.6,0.1-0.8,0.1c-0.2,0.1-0.4,0.1-0.6,0.1c0.4,0.3,0.4,0.3,0.6,0.3c0,0-0.1,0-0.1,0.1
        s0.1,0,0.1,0c0.1,0,0.2,0,0.3,0c0,0,0,0,0.1,0c0.1,0,0.1,0,0.2,0s-0.1,0,0-0.1c0.2,0,0.4-0.1,0.6-0.1c-0.1,0.1,0.2,0,0.2,0.1
        c0.1,0,0-0.1,0.1-0.1h-0.1c-0.1,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0-0.1-0.1,0-0.1,0c0-0.1,0.1,0,0.1,0c0.1,0,0.3-0.1,0.5-0.1H246
        c0.1,0.1,0.2,0,0.2-0.1c0.3,0,0.3,0.1,0.5,0c0-0.1-0.1,0-0.2,0c0.2-0.1,0.6,0,0.8-0.1h0.1c0.1,0,0,0,0,0c0.1,0,0.1,0,0.2,0
        c0.1,0,0.1,0.1,0.3,0c0.1,0,0,0,0-0.1c0.3,0,0.6-0.1,0.8,0c0.1,0,0,0,0,0c0.1,0,0.4,0,0.5,0s0.1,0,0.2-0.1c0.3,0,0.6,0.1,0.9,0.1
        c0.1,0.1,0.1,0.1,0.1,0.1c0.3,0,0.4,0,0.6,0c0,0,0.1,0,0.1-0.1c0.1,0,0.1,0,0.1,0c0.1,0,0-0.1-0.1-0.1c-0.1,0-0.2,0.1-0.3,0
        c0.1,0,0.1-0.1,0.3,0c0,0,0,0-0.1,0h0.1c0,0,0.1,0,0.1,0.1c0.1,0,0.1,0,0.1,0c0.1,0,0.1,0,0.1,0c0.1,0,0.1-0.1,0.1,0
        c0,0.1,0.1,0.1,0.2,0.1s-0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0.1c0.1-0.1,0.2,0.1,0.2,0s-0.1,0-0.1,0c0-0.1,0.1,0,0.2,0h0.1
        c0.1,0,0.4,0,0.5,0.1c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.2,0c-0.1-0.1-0.3,0-0.3,0c0.1-0.1,0.2,0,0.3,0s0.2,0.1,0.3,0
        c0,0.1-0.1,0-0.1,0.1c0.1,0,0.1,0,0.3-0.1c0.1,0.1,0.1,0,0.3,0.1c0,0-0.2,0-0.1-0.1c0.1,0,0.2,0,0.3,0s0.2,0,0.2,0.1
        c0.1,0,0.1,0,0.1-0.1c0.1,0,0.1,0.1,0.1,0c0.1,0,0.1,0.1,0.2,0.1s-0.1,0,0-0.1c0.2,0.1,0.4,0,0.6,0.1h0.1c-0.1,0.1,0.1,0,0.1,0.1
        c0.1,0,0.3,0,0.2-0.1c0-0.1,0.1,0,0.1,0c0.1,0-0.1-0.1,0.1-0.1c0.3,0,0.6,0,0.8,0c0.1,0,0.1,0,0.1,0c0.1,0,0.1,0,0.3,0
        c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.3,0c0.3,0,0.6,0.1,0.8,0.1c0,0.1,0,0.1,0.1,0.1c0,0-0.1,0-0.1,0c0.1,0-0.1,0.1,0.1,0.1
        c-0.1,0-0.1,0.1-0.1,0.1s-0.1,0-0.1,0c-0.3-0.1-0.6-0.1-1-0.1c-0.1,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.3,0c-0.1,0-0.1,0-0.1,0
        c-0.1,0-0.2,0.1-0.3,0c0,0,0,0-0.1,0s-0.1,0-0.3,0c-0.1,0-0.1,0-0.1,0h-0.1c-0.1,0-0.1,0-0.2,0s-0.1,0-0.1,0c-0.1,0-0.2,0-0.3,0
        c-0.1,0-0.1,0-0.1,0c-0.2,0-0.4,0-0.7,0c-0.1,0,0.1-0.1-0.1-0.1c-0.1,0,0.1,0.1-0.1,0.1c-0.3,0-0.3,0-0.6,0h0.1
        c-0.1-0.1-0.1,0-0.1,0c-0.1,0,0.1,0-0.1-0.1c-0.1,0-0.1,0-0.1,0.1h-0.2c-0.1,0,0,0,0-0.1h-0.1c-0.1,0,0.1,0.1-0.1,0.1
        c-0.1,0,0,0,0-0.1c-0.1,0-0.1,0-0.1,0s0,0.1-0.1,0.1h-0.2c0,0,0.1,0,0.1-0.1c-0.1,0-0.1,0-0.1,0.1c-0.2,0-0.3,0-0.3-0.1
        c-0.1,0-0.1,0.1-0.1,0c-0.1,0,0.1,0.1-0.1,0.1c0,0,0,0,0.1,0c0,0-0.3,0-0.2,0c-0.1,0-0.1,0.1-0.2,0v-0.1c0,0-0.1,0-0.1,0.1
        c-0.1,0-0.1,0-0.1-0.1c0,0.1-0.2,0-0.3,0h0.1c-0.1,0.1-0.4,0-0.6,0c-0.1-0.1-0.1-0.1-0.2-0.1c-0.3,0-0.2,0-0.3,0
        c0.1,0.1-0.1,0.1,0,0.1c-0.1,0-0.2,0-0.1,0.1c-0.3,0-0.8,0-1.2,0c0-0.1,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0c-0.1,0-0.1,0-0.2-0.1
        c-0.1,0-0.1,0.1-0.2,0.1l0,0c-0.1,0-0.2,0-0.3-0.1c0,0,0,0-0.1,0h-0.2c-0.1,0,0,0-0.1-0.1c-0.1,0,0,0-0.1,0.1
        c-0.1,0-0.2,0-0.1,0.1c-0.2,0-0.5,0-0.6,0c0.1,0,0.3,0,0.5,0c0.1,0,0.1,0,0.1-0.1c-0.1,0-0.1,0-0.1,0.1c-0.1,0-0.2,0-0.3,0
        c0.1,0,0,0,0-0.1c-0.3,0-0.6,0.1-0.8,0.1c-0.1,0,0.1-0.1,0-0.1c-0.1,0,0.1,0.1-0.1,0.1c-0.1,0,0.1-0.1,0.1-0.1
        c0-0.1-0.2,0-0.1,0.1c-0.1,0-0.1,0-0.1,0c-0.6,0.1-1.5,0.1-2.1,0.2l0,0c-0.1,0-0.1,0-0.3,0.1c0-0.1,0.1,0,0-0.1
        c-0.1,0-0.1,0-0.1,0c-0.1,0,0.1,0,0.1-0.1c-0.1,0-0.3,0-0.3,0c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.1,0-0.1,0c-0.1,0.1-0.3,0.1-0.6,0.1
        c0,0-0.1,0-0.1-0.1c-0.1,0-0.1,0-0.1,0.1c-0.1,0-0.3,0.1-0.5,0.1c-0.3,0.1-0.6,0.1-0.8,0.1c0-0.1-0.1,0-0.1-0.1
        c-0.1,0-0.1,0-0.1,0.1c-0.1,0-0.1,0-0.1,0c-0.2,0.1-0.6,0.1-0.9,0.3c-0.1,0,0.1-0.1,0-0.1c-0.1,0.1-0.1,0.1-0.3,0.1l0.1-0.1
        c-0.1,0-0.1,0.1-0.2,0.1l0.1-0.1c-0.1,0-0.1,0.1-0.3,0.1c0,0,0-0.1,0.1-0.1c-0.1,0-0.1,0.1-0.2,0.1c0,0-0.1,0.1-0.1,0
        c-0.1,0-0.2,0.1-0.3,0.1c0,0,0-0.1,0.1-0.1s0.1,0,0.1,0c0-0.1-0.2,0-0.2,0.1l0.1-0.1l-0.1,0.1c-0.1,0.1-0.1,0-0.1,0
        c0-0.1,0.3-0.3,0.4-0.2c0.1,0-0.1,0,0-0.1c0.1,0,0.3-0.1,0.4-0.1l0,0c0.2-0.1,0.3-0.1,0.4-0.1c0.1,0,0.4-0.1,0.5-0.1
        c0.1,0,0.2,0,0.3,0c0-0.1-0.1,0-0.2,0c0-0.1,0.1,0,0.1-0.1c0.1,0,0-0.1,0-0.1c-0.2,0.1-0.2,0.1-0.3,0.1c-0.3,0-0.5,0.1-0.8,0.1
        c0.1,0,0.1-0.1,0.1-0.1c0,0-0.1,0.1-0.2,0c0,0,0,0.1,0.1,0c0,0.1-0.1,0.1-0.2,0.1c0,0,0-0.1-0.1-0.1c-0.1,0,0,0.1-0.1,0.1
        c0.1-0.1,0.1-0.1-0.1-0.1c-0.1,0,0,0,0.1,0c0,0-0.1,0-0.1,0.1c-0.1,0.1-0.1,0.1-0.1,0.1c-0.1,0-0.1,0.1-0.3,0.1
        c-0.1,0.1-0.1,0-0.2,0s-0.2,0.1-0.1,0.1c-0.1,0.1-0.2,0.1-0.2,0.2c-0.1,0.1-0.1,0-0.1,0.1c0,0,0-0.1,0.1-0.1c0-0.1-0.1,0-0.1,0
        s-0.1,0-0.2,0c-0.1,0,0.1-0.1,0.1-0.1l-0.1,0.1c-0.1,0-0.1,0.1-0.3,0.1c0,0,0,0.1,0.1,0.1c0,0,0.1-0.1,0.2-0.1
        c0,0.1,0.1,0,0.2,0.1c-0.3,0.1-1,0.3-1.2,0.4c0-0.1,0.1-0.1,0.1-0.1c0.3-0.1,0.6-0.2,0.9-0.3c-0.1,0-0.2,0.1-0.3,0.1
        c-0.1,0,0,0.1-0.1,0.1c-0.1,0-0.1,0-0.1,0c-0.1,0-0.1,0.1-0.1,0.1h-0.1c-0.1,0-0.1,0-0.1,0.1c-0.1,0.1-0.3,0.1-0.3,0.1
        c-0.1,0-0.1,0-0.1,0c-0.1,0.1,0.1,0,0,0.1s-0.1,0-0.1,0c-0.1,0,0,0.1,0,0.1c-0.1,0.1-0.3,0.3-0.5,0.4h-0.1c0,0-0.1,0.1-0.1,0.1
        c-0.1,0.1-0.1,0.1-0.1,0.1l-0.1,0.1c-0.1,0.1-0.3,0.3-0.3,0.6c-0.1,0,0-0.1,0-0.1c0.1-0.1,0.1-0.1,0.1-0.3c0-0.1,0-0.1,0-0.1
        c-0.1,0.1-0.1,0.2-0.1,0.3c-0.1,0-0.1,0.2-0.1,0.1c0,0.1,0.1,0,0.1,0.1s-0.1,0.1-0.1,0.1s0.1-0.1,0.1-0.1c0,0.1,0,0.2-0.1,0.3
        c0,0.1,0,0.1-0.1,0.2v0.1c0,0.1,0,0.1,0,0.1c0,0.1-0.1,0.1,0,0.1c0,0.1,0,0.1,0,0.1c0,0.1,0.1,0.1,0.1,0.1c0,0,0-0.1,0-0.1
        c0,0,0,0.1-0.1,0.1c0,0.1,0,0.1,0.1,0.1c0,0,0,0.2,0.1,0.1c0,0-0.1-0.1,0-0.2c0,0,0.1,0.1,0.1,0.1c0.1,0,0-0.1,0.1-0.2
        c0-0.1,0-0.1,0-0.2c0,0-0.1,0.1,0,0.1v-0.1c0-0.1,0.1,0.1,0.1-0.1c0.1,0,0,0.2,0.1,0.2c0-0.1,0-0.1,0-0.3c0-0.1-0.1,0-0.1,0
        c0-0.1,0.1-0.1,0.1-0.1c0-0.1,0-0.1,0-0.1c0.1,0.1,0,0.3,0.1,0.4c0,0,0-0.1-0.1-0.1c0,0.1,0.1,0.1,0.1,0.1l0,0
        c0.1,0.1,0.1,0.2,0.1,0.2c0,0.1,0,0.1,0,0.1c0,0.1,0.1,0.1,0.1,0.2c0,0-0.1,0-0.1-0.1c-0.1,0.1,0.1,0.3,0,0.3
        c0.1,0.1,0.1-0.1,0.1-0.1s0.1,0.1,0.1,0.1c-0.1-0.1-0.1,0.1-0.1,0.1c0.1,0.1,0.1,0.1,0.3,0.3c0.1,0.1,0,0.1,0,0.1s0,0,0.1,0
        s0-0.1,0.1,0c0.1,0,0,0.1,0,0.1c0.1,0.1,0.1,0.1,0.2,0.1s0,0.1,0.1,0.1l-0.1-0.1c0.1,0,0.3,0.2,0.5,0.3c0.1,0.1,0,0.1,0.1,0.1
        c0.1,0,0,0,0,0c0.1,0.1,0.3,0.1,0.4,0.2c0,0,0,0.1,0.1,0.1c0,0-0.1-0.1,0-0.1c0.1,0,0,0.1,0.1,0.1c0.1,0.1,0.1,0,0.2,0.1
        c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.1,0-0.1-0.1c0.1,0-0.2-0.1-0.2-0.1h-0.1c-0.1-0.1-0.1-0.1-0.1-0.1c-0.1,0-0.1-0.1-0.2-0.1
        c-0.1,0.1,0.1,0.1,0,0.1c-0.1-0.1-0.1-0.1-0.1-0.1c-0.1-0.1-0.1-0.1-0.1,0c0,0-0.1,0-0.1-0.1c-0.1-0.1-0.1-0.1-0.1-0.1
        c0,0-0.1,0,0,0.1c-0.1,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0.1c0.1,0,0.1,0.1,0.1,0.1c0.1,0.1,0.1,0.1,0.1,0.1h-0.1h0.1
        c0,0,0,0.1,0.1,0.1s0.1,0,0.1,0.1c0.1,0,0.1,0,0.1,0s-0.1,0,0,0.1c0.1,0.1,0.1,0,0.1,0c0.1,0.1,0.1,0.1,0.1,0.1
        c0.1,0,0.2,0.1,0.3,0.1c-0.1,0,0.1,0.1,0.1,0.1c0.1,0.1,0.1,0,0.3,0.1h-0.1l0.1,0.1c0,0,0-0.1-0.1-0.1c-0.1-0.1-0.1-0.1-0.1-0.1
        l-0.2-0.1c0.1,0,0.1,0.1,0.3,0.1c0.1,0.1,0.2,0.1,0.4,0.2c-0.1,0,0,0.1,0,0.1c0.1,0.1,0.2,0.1,0.3,0.1c0,0-0.1,0-0.1,0
        c0.1,0.1,0.1,0,0.2,0s0.1,0.1,0.1,0.1c0.1,0,0-0.1-0.1-0.1c0.1-0.1,0.1,0.1,0.1,0.1c0.1,0,0.1,0,0.1-0.1c0.1,0.1-0.1,0.1,0.1,0.1
        c0,0,0.1,0.1,0.1,0c0.1,0.1,0.3,0.1,0.1,0.1c0.1,0,0.2,0.1,0.3,0.1s0,0,0,0.1c0.1,0.1,0.1,0,0.1,0c0,0.1,0.1,0,0.2,0.1
        c0,0-0.1,0-0.1,0c-0.6-0.2-0.8-0.2-1.2-0.4c-0.1,0,0,0,0,0.1c-0.1,0-0.2-0.1-0.3-0.1l-0.1-0.1h-0.1c-0.1,0,0.1,0-0.1-0.1
        c0.1,0.1,0.2,0.2,0.3,0.2c-0.1-0.1-0.3-0.2-0.5-0.3h0.1c-0.1-0.1-0.1,0-0.1,0.1c0.1,0.1,0.1,0,0.2,0.1c-0.1,0.1-0.2,0.1,0,0.1
        c-0.2-0.1-0.6-0.2-0.8-0.3c-0.1,0-0.3-0.1-0.4-0.2c-0.1,0-0.1,0-0.1,0c-0.1-0.1,0-0.1,0-0.1c-0.1-0.1-0.1-0.1-0.3-0.1v0.1
        c0.1,0,0.1,0.1,0.2,0.1c0.1,0.1-0.1,0-0.3-0.1h0.1l-0.1-0.1c-0.1-0.1-0.4-0.3-0.6-0.5c-0.1-0.1-0.2-0.3-0.3-0.4
        c-0.1-0.1-0.1-0.1-0.1-0.2s-0.1-0.2-0.1-0.3c-0.1,0,0,0.1,0,0.2c0.1,0.1,0.1,0.3,0.1,0.3c0.1,0,0.1,0.1,0.2,0.2
        c0.1,0,0-0.1,0.1-0.1c0.1,0.1,0,0.1-0.1,0.1c0,0,0.1,0.1,0.1,0.2c0.1,0.1,0-0.1,0.1,0c0,0-0.1-0.1-0.1-0.1
        c0.1-0.1,0.1-0.1,0.1-0.1c0.1,0.2,0.3,0.3,0.5,0.6c0.1,0.1-0.1-0.1-0.1-0.1c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0,0,0.1,0.1
        c-0.1,0,0,0.1,0.1,0.1s0.1-0.1,0.1,0s0.1,0.1,0.1,0.1c0-0.1-0.1-0.1-0.1-0.1c0.1,0.1,0.1,0.1,0.1,0.1c0,0,0,0,0.1,0
        s-0.1-0.1-0.1-0.1c0-0.1,0.1,0.1,0.1,0.1c0.1,0,0.1,0,0.1,0.1c0.1,0.1,0.3,0.1,0.4,0.3c-0.1,0-0.1-0.1-0.2-0.1
        c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0.1,0-0.1-0.1,0-0.1c0.1,0.1,0.1,0.1,0.1,0.2s0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.1,0
        c0.1,0.1,0.1,0.1,0.3,0.1c-0.1,0.1,0.1,0.1,0,0.1c-0.1-0.1-0.2-0.1-0.3-0.1c0,0,0.1,0.1,0.1,0.1h-0.1c0-0.1,0-0.1,0-0.1
        c-0.1,0-0.1,0-0.1,0c0-0.1,0-0.1,0.1,0c-0.1-0.1,0-0.1,0.1-0.1c0-0.1-0.1,0-0.2-0.1v-0.1c-0.1-0.1-0.1,0.1-0.1,0l0.1,0.1
        c-0.1,0-0.1-0.1-0.1-0.1c-0.1,0-0.1,0-0.2-0.1c0.1,0,0.1-0.1-0.1-0.1c-0.1-0.1,0.1,0,0-0.1c-0.1,0-0.1,0-0.1-0.1c0,0,0,0-0.1-0.1
        l0.1-0.1c-0.1,0-0.1,0-0.1-0.1c0-0.1,0.1,0,0.1-0.1c-0.1-0.1-0.1,0-0.1,0s0-0.1-0.1-0.1c0,0-0.1,0.1-0.1,0c0,0.1,0.1,0,0.1,0.1
        c-0.1,0,0.1,0.1,0.1,0.1l0,0c0,0,0.1,0.1,0,0.1s-0.1,0-0.1-0.1h0.1c-0.1-0.1-0.1-0.1-0.1-0.1c0,0.1,0.1,0.1,0.2,0.1
        c0,0.1-0.1,0-0.1-0.1c0,0.1,0.1,0.1-0.1,0.1l0.1,0.1c0.1,0.1,0.1,0,0.1,0.1c-0.1,0.1-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.1-0.3-0.1
        c0,0,0.1,0.1,0.1,0.1c-0.1-0.1-0.1-0.1-0.1-0.1s-0.1-0.1-0.1-0.1c-0.1,0-0.1,0-0.1-0.1c-0.1,0-0.1-0.1-0.1-0.1
        c0,0.1-0.1,0-0.1-0.1c0.1,0,0.1,0.1,0.3,0.2c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.1-0.1,0-0.1,0c-0.1-0.1-0.1-0.1-0.1-0.1
        c-0.1-0.1-0.1-0.1-0.1-0.1c0,0,0.1,0.1,0.1,0.1c-0.1-0.1-0.2-0.3-0.3-0.4l-0.1-0.1c-0.1-0.1-0.1-0.3-0.2-0.4
        c-0.1-0.2-0.1-0.5-0.1-0.7l0,0c0,0-0.1-0.1,0-0.1c0.1,0.1,0-0.1,0-0.2c0-0.1,0,0-0.1,0c0-0.1,0-0.3,0.1-0.3c0-0.1,0-0.1,0-0.2
        c0.1,0,0.1,0,0,0.1c0.1,0,0.1-0.1,0.1-0.2h-0.1c0.1-0.4,0.3-0.8,0.6-1.1c0.4-0.3,0.7-0.6,1-0.8c0.3-0.2,0.7-0.3,1-0.5
        c0.3-0.1,0.7-0.3,1-0.3c0.2-0.1,0.4-0.2,0.6-0.3c0.1,0,0.1,0,0.1,0c0.1,0,0.3-0.1,0.5-0.1c0.1,0,0.2-0.1,0.3-0.1
        c0.1-0.1,0.6-0.1,0.8-0.2c0.1-0.1,0.3-0.1,0.6-0.1c0.1-0.1,0.3-0.1,0.4-0.1h-0.1c0.2,0,0.4-0.1,0.7-0.1c0,0-0.1,0-0.1,0.1
        c0.1,0,0.1-0.1,0.1-0.1s0,0.1,0,0.1c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.1,0,0.3-0.1c0.1,0,0.1,0,0.1,0c0.1,0,0.2-0.1,0.3-0.1
        c0.1,0,0.1,0,0.1,0c0.1,0,0.1-0.1,0.1,0c0.1,0,0.3-0.1,0.5-0.1c0.1,0,0.1,0,0.1,0c0.1,0,0.1,0,0.1,0s0.1,0,0.1,0h0.1
        c0.1,0,0.3,0,0.5,0c0.1,0,0.1,0,0.1-0.1h0.1c0.1,0,0.2,0,0.3-0.1c0.1,0,0.1-0.1,0.2-0.1s0.1,0,0.1,0c0.2,0,0.5-0.1,0.7-0.1
        c0.1,0,0.1,0,0.1,0c0.2,0,0.4-0.1,0.6-0.1c0.1,0,0.1,0,0.1,0c0.1,0,0.3-0.1,0.5-0.1c0,0,0.1,0,0.1,0.1c0.1,0,0,0,0-0.1
        c0.2,0,0.3-0.1,0.5,0c0.1,0,0.1,0,0.3,0c0.1,0,0.1,0,0.1,0.1c0.1,0,0.1,0,0.1-0.1c0.1,0,0.1,0,0.3,0c0.1,0,0-0.1,0.1-0.1
        c0.1,0,0.3,0,0.6,0c0.1,0,0.1,0,0.2,0c0.2,0,0.6-0.1,0.7,0c0,0,0.1,0,0.1-0.1c0.2,0.1,0.3,0,0.6,0c0.1-0.1,0.1,0,0.3,0l-0.1-0.1
        h-0.1c-0.1,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.1,0s-0.1,0-0.2,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.1,0-0.1,0s-0.1,0-0.2,0
        c-0.1,0-0.1,0-0.3,0c-0.1,0-0.3,0-0.4,0c0,0,0,0,0-0.1c-0.1,0-0.2,0-0.2-0.1c-0.1,0,0,0,0,0.1h0.1c-0.1,0.1-0.3,0-0.5,0
        c-0.1,0-0.1,0.1-0.1,0c-0.1,0,0,0,0.1-0.1c0,0,0,0,0.1,0c0,0,0,0,0.1,0H249c0.1,0,0.2,0,0.3,0c0.1-0.1-0.3,0-0.2-0.1
        c-0.2,0-0.2,0.1-0.4,0.1c-0.1,0.1,0.1,0,0.1,0.1c-0.1,0-0.3,0-0.3,0c0.1,0,0.2,0,0.1-0.1c-0.1,0,0,0.1-0.1,0.1
        c0-0.1-0.2-0.1-0.3,0c0,0,0.1,0,0.2,0c0,0.1-0.1,0-0.3,0c0.1-0.1-0.1-0.1-0.1-0.1c-0.1,0.1,0.1,0.1,0,0.1c-0.1,0-0.1-0.1-0.2-0.1
        c-0.1,0,0.1,0,0.1,0c-0.3,0.1-0.8,0.1-1.2,0.1c-0.1,0-0.2,0-0.2,0c-0.2,0-0.6,0-0.7,0c0.1-0.1,0.2-0.1,0.3-0.1
        c0.2,0,0.3,0,0.5-0.1c0-0.1-0.1,0,0-0.1c-0.1,0-0.1,0-0.2,0c0.1,0,0.1,0,0.1,0c-0.1,0.1-0.2,0-0.3,0c0,0,0.1,0,0.1,0
        c-0.8,0-1.7,0.1-2.5,0.2h-0.1c-0.1,0-0.1,0-0.2,0.1c-0.1,0-0.2,0-0.3,0H243c-0.1,0,0,0,0,0c-0.6,0.1-1.2,0.1-1.6,0.3
        c-0.1,0,0,0,0,0c-0.1,0.1-0.2,0.1-0.3,0.1l0.1-0.1c-0.1,0-0.2,0.1-0.3,0.1c0-0.1,0.1,0,0.1-0.1c0.1,0,0.1,0,0.2,0c0,0,0,0,0.1,0
        c0,0,0-0.1-0.1-0.1c0.1-0.1,0.1-0.1,0.1-0.1c-0.1-0.1-0.2,0.1-0.3,0c0-0.1,0.1,0,0.1-0.1l0,0c0.1-0.1,0.1-0.1,0.3-0.1
        c0.1,0,0.1,0.1,0,0.1h0.1c0.1,0-0.1,0.1-0.1,0.1c0.1,0,0.1,0,0.1-0.1c0.1,0,0.1,0,0.2,0c-0.1,0.1-0.2,0-0.3,0.1
        c-0.1,0-0.3,0-0.3,0.1c0,0.1,0.3-0.1,0.1,0c0.1-0.1,0.2-0.1,0.3-0.1s0.1,0,0.1,0c0.1-0.1,0.4-0.1,0.6-0.1c0.4,0.4,0.6,0.3,0.7,0.3
        c0.1,0,0.1-0.1,0.3-0.1c0,0-0.1,0,0,0.1c0.1,0,0.1-0.1,0.1-0.1c0.1,0,0.3,0,0.3-0.1c0,0-0.1,0-0.1,0c0.1,0,0.2-0.1,0.3-0.1
        c0.1,0,0.2,0,0.3,0c0.1,0,0,0,0-0.1c0.1,0,0.1,0,0.3,0c0,0,0,0,0.1,0c0,0,0,0-0.1,0C244.8,34.7,245,34.7,245,34.6
        C245.1,34.7,245,34.7,245,34.6c0.1,0,0.1,0.1,0.2,0.1C245.1,34.6,245.1,34.6,245,34.6c0.2,0.1,0.2,0,0.3,0v0.1c0,0,0.1-0.1,0,0
        c0-0.1,0.2,0,0.1-0.1c-0.1,0-0.3,0.1-0.3,0c-0.1,0,0,0.1,0,0.1L245,34.6z M241.7,35c0,0.1,0.1,0.1,0.1,0.1V35H241.7z M241.7,35.1
        L241.7,35.1c0,0,0-0.1-0.1-0.1C241.6,35.1,241.8,35.1,241.7,35.1z M244.2,34.8c-0.1,0,0-0.1-0.2,0c0,0.1,0.1,0,0.1,0.1
        C244,34.8,244.2,34.8,244.2,34.8z M237.4,42.6c0.1,0,0.2,0.1,0.2,0C237.4,42.6,237.5,42.6,237.4,42.6
        C237.4,42.6,237.5,42.5,237.4,42.6L237.4,42.6z M236.1,41.9L236.1,41.9c-0.1,0-0.1-0.1-0.1,0C236.1,41.9,236.1,41.9,236.1,41.9z
        M236.1,42c-0.1,0-0.1,0-0.2,0C236,42,236.2,42.1,236.1,42z M236.6,42.2C236.6,42.2,236.5,42.2,236.6,42.2c0,0,0.1,0.1,0.1,0.1
        C236.7,42.3,236.7,42.3,236.6,42.2z M236.6,42.1L236.6,42.1L236.6,42.1C236.6,42.1,236.6,42.1,236.6,42.1z M236.4,42.2
        C236.4,42.2,236.3,42.2,236.4,42.2c0,0.1,0,0.1,0.1,0.1C236.5,42.3,236.5,42.2,236.4,42.2z M236.1,41.7c0.1,0.1,0.1,0.1,0.3,0.2
        V42h-0.1c0.1,0.1,0,0.1,0.1,0.1c0.1,0,0.1,0,0.1,0.1c0-0.1,0-0.1-0.1-0.2c0,0,0,0.1,0.1,0.1c0,0,0.1,0.1,0,0.1
        c-0.1-0.1-0.1-0.2-0.1-0.2C236.3,41.9,236.3,41.9,236.1,41.7z M236.2,41.5c0.1,0.1,0.1,0.1,0.1,0.1
        C236.2,41.5,236.3,41.5,236.2,41.5L236.2,41.5z M236,41c0,0,0.1,0.2,0.1,0.1C236.1,41.1,236,41,236,41z M235.7,40.8
        c0.1,0.1,0.1,0.2,0.1,0.3c0.1,0,0-0.1-0.1-0.1C235.8,40.9,235.8,40.8,235.7,40.8z M235.6,40.5c0,0.1,0.1,0.2,0.1,0.3
        C235.7,40.7,235.6,40.5,235.6,40.5z M235.7,40.3c0,0.1,0,0.3,0.1,0.3C235.7,40.5,235.7,40.4,235.7,40.3z M235.6,41.5L235.6,41.5
        c-0.1,0-0.1,0.1,0,0.1C235.7,41.6,235.7,41.6,235.6,41.5z M235.8,41.4c-0.1,0,0.1,0.1,0.1,0.2c-0.1,0-0.1,0.1,0.1,0.1
        C236.1,41.6,235.8,41.4,235.8,41.4z M235.7,41.4c0.1,0.1,0.1,0.1,0.1,0.1c0.1,0.1,0.1,0.1,0.1,0.1c-0.1-0.1-0.1-0.1,0-0.1
        C235.8,41.5,235.7,41.4,235.7,41.4z M235.6,41.5c-0.1-0.1,0-0.1-0.1-0.2c-0.1,0,0,0.1,0,0.1l-0.1-0.1
        C235.4,41.3,235.4,41.3,235.6,41.5C235.5,41.4,235.5,41.5,235.6,41.5z M235.3,40.8c0.1-0.1,0.1,0.2,0.1,0.1
        c0.1,0.1,0.1,0.1,0.1,0.1c0,0.1,0.1,0.2,0.1,0.3h0.1c-0.1-0.1-0.1-0.2-0.1-0.1c-0.1-0.1-0.1-0.2-0.2-0.3
        C235.4,41,235.2,40.6,235.3,40.8z M234.9,40.5c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.3,0.2,0.4c0,0,0.1,0,0.1-0.1
        c-0.1-0.1-0.1-0.1-0.1-0.2c0,0.1,0.1,0.1,0.1,0.1c-0.1-0.1-0.1-0.2-0.1-0.3c0.1,0.1,0.1,0,0.1,0.1c0,0.1,0,0.1,0.1,0.1
        c0,0,0-0.1,0-0.1c0,0,0.1,0.1,0.1,0.1c-0.1-0.1-0.1-0.1-0.1-0.2c0,0.1-0.1,0-0.1-0.1c0,0.1-0.1,0-0.1-0.1c0.1,0-0.1-0.2,0-0.3
        L234.9,40.5c0.1,0.1,0.1,0.1,0.1,0.2C235,40.7,235,40.5,234.9,40.5z M235.2,40.3c0-0.1,0-0.1,0-0.1
        C235.1,40.2,235.2,40.4,235.2,40.3z M235,40.2C235,40.3,235.1,40.2,235,40.2c0.1,0.1,0.1,0.3,0.1,0.3
        C235.2,40.3,235.1,40.3,235,40.2C235,40.3,235,40.2,235,40.2z M234.9,40c0,0.1,0,0.1,0,0.2v-0.1C234.9,40.1,234.9,40,234.9,40z
        M234.9,39.8c0-0.1,0.1,0,0-0.1c0-0.1-0.1,0,0-0.1c-0.1-0.1,0,0.1-0.1,0.1c0-0.1,0.1-0.1,0-0.2c0,0.1-0.1,0.1-0.1,0.2
        c0,0.1,0.1,0.2,0.1,0.3h0.1C234.9,40.1,234.9,39.8,234.9,39.8C234.9,39.8,234.9,39.8,234.9,39.8C234.9,39.8,234.9,39.8,234.9,39.8
        z M235,39.4c0,0.1,0,0.1,0,0.2c0,0,0-0.1,0-0.1s0,0.1,0,0.2s0,0,0.1,0C235.1,39.6,235.1,39.5,235,39.4C235,39.6,235,39.4,235,39.4
        z M234.9,39.3c0,0,0.1-0.1,0.1-0.1c0,0,0,0.1,0,0V39C234.9,39.2,234.9,39.2,234.9,39.3c0,0.1-0.1,0.3,0,0.1V39.3z M235.1,39.4
        c0-0.1,0.1-0.3,0.1-0.2C235.2,39.2,235.1,39.4,235.1,39.4z M235.2,39.2c0,0.1,0.1,0,0,0.1c0,0.1-0.1,0-0.1,0.1h0.1
        C235.2,39.2,235.2,39.2,235.2,39.2C235.2,39.2,235.2,39.2,235.2,39.2z M235.2,38.9c-0.1-0.1-0.1,0.3-0.1,0.2
        C235.1,38.9,235.2,39,235.2,38.9z M235.3,38.8c-0.1,0.1-0.1,0.1-0.1,0.2C235.2,39.1,235.3,38.9,235.3,38.8z M235.4,38.5
        c-0.1,0.1-0.2,0.1-0.1,0.2C235.3,38.7,235.4,38.5,235.4,38.5z M235.4,38.3c0,0.1-0.1,0.1-0.1,0.1
        C235.3,38.5,235.4,38.4,235.4,38.3z M235.3,38.3c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.1,0.1-0.1,0.1-0.1c0,0.1,0,0.1,0.1,0
        c0,0-0.1,0-0.1-0.1C235.2,38.4,235.3,38.3,235.3,38.3z M236.2,37.5c0.1-0.1,0.1,0,0.2-0.1C236.4,37.4,236.2,37.5,236.2,37.5z
        M235.6,38.4C235.6,38.3,235.6,38.3,235.6,38.4C235.5,38.4,235.6,38.5,235.6,38.4L235.6,38.4C235.6,38.3,235.6,38.4,235.6,38.4z
        M235.4,38.1c0,0-0.1,0-0.1,0.1v0.1L235.4,38.1z M235.9,38.1c-0.1,0.1-0.1,0.1-0.2,0.2c0,0,0.1-0.1,0.1-0.1
        c-0.1,0-0.1,0.1-0.1,0.2C235.8,38.2,235.8,38.1,235.9,38.1C235.9,38.1,236,38.1,235.9,38.1z M235.7,37.9c0.3-0.2-0.1,0.1-0.1,0.1
        C235.7,38,235.7,38,235.7,37.9z M236.1,37.7L236.1,37.7c0-0.1,0.1-0.2,0.1-0.1c-0.1,0.1-0.2,0.1-0.3,0.1
        C235.8,37.8,236,37.6,236.1,37.7c-0.1,0.1-0.1,0.1-0.1,0.1c0-0.1-0.3,0.1-0.3,0.2c0.1-0.1,0.2-0.1,0.2-0.2c0.1,0-0.1,0.1,0,0.1
        C235.9,37.8,235.9,37.8,236.1,37.7z M236.5,37.6c-0.1,0.1-0.1,0-0.1,0.1c0,0.1,0.1,0,0.1,0.1c0,0,0,0-0.1,0.1
        c-0.1-0.1,0-0.1-0.1-0.1c-0.1,0-0.1,0-0.1,0.1l0,0c-0.1,0.1-0.1,0.1-0.2,0.1c0.1,0.1,0,0.1,0.1,0.1c0.1-0.1,0.1-0.1,0.2-0.1
        c0,0,0,0,0.1-0.1c0-0.1,0.1,0,0.1-0.1C236.5,37.6,236.6,37.6,236.5,37.6z M236.5,37.5c-0.1,0.1-0.1,0-0.1,0.1c0-0.1-0.1,0-0.1,0
        c-0.1,0-0.1,0.1,0,0.1l0.1-0.1l0,0c-0.1,0.1-0.2,0.1-0.2,0.1c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.1-0.1,0.3-0.1
        C236.6,37.5,236.5,37.5,236.5,37.5z M236.7,37.3c-0.1,0-0.1,0.1-0.1,0.1L236.7,37.3c0.1-0.1,0.1-0.1,0.1-0.1
        c-0.2,0.1-0.2,0.1-0.3,0.2h0.1l-0.1,0.1h0.1c0,0-0.1,0.1,0.1,0C236.5,37.4,236.7,37.3,236.7,37.3z M237,37.2
        c-0.1,0-0.2,0.1-0.3,0.1C236.8,37.4,237,37.3,237,37.2z M237.1,37.2C237.1,37.2,237.2,37.2,237.1,37.2c0.1,0,0.1,0,0.1,0l0.1-0.1
        C237.3,37.1,237.2,37.2,237.1,37.2c0.2-0.1,0.1-0.1,0.2-0.1c0.1,0,0.1,0,0.2-0.1C237.4,36.9,237.2,37,237.1,37.2
        c0.1-0.1,0.1-0.1,0.1-0.1C237.1,37,237.1,37,237.1,37.2C237,37.2,237.2,37,237.1,37.2H237C237,37.2,237.1,37.1,237.1,37.2
        C237.2,37.2,237.2,37.2,237.1,37.2L237.1,37.2z M237.6,37C237.4,37.1,237.5,37.1,237.6,37c-0.2,0.1-0.3,0.1-0.4,0.2
        c0.1,0,0.1,0,0.1,0.1C237.3,37.2,237.4,37.2,237.6,37L237.6,37z M238.4,36.7C238.4,36.7,238.3,36.7,238.4,36.7
        c-0.1,0-0.1,0-0.1,0.1C238.3,36.8,238.3,36.7,238.4,36.7z M238.7,36.5c-0.1,0-0.2,0-0.2,0.1C238.5,36.6,238.7,36.5,238.7,36.5z
        M239.3,36.2c-0.1,0-0.1,0-0.1,0.1C239.2,36.3,239.3,36.3,239.3,36.2z M239.4,36.3c-0.1,0-0.3,0-0.3,0.1
        C239.2,36.4,239.3,36.4,239.4,36.3z M240,36.3c-0.1,0-0.1,0-0.1,0v0.1C239.9,36.3,240,36.3,240,36.3z M240.2,36.2
        c-0.1,0-0.2,0.1-0.2,0.1C240,36.3,240.1,36.3,240.2,36.2C240.1,36.3,240.1,36.3,240.2,36.2C240.2,36.3,240.2,36.3,240.2,36.2z
        M240.6,36.1c0-0.1-0.1,0-0.2,0.1c-0.1,0-0.2,0-0.2,0.1C240.3,36.2,240.6,36.2,240.6,36.1z M240.8,35.9c-0.1,0-0.2,0-0.2,0.1
        C240.7,36,240.8,36,240.8,35.9z M239.3,36.7c-0.1,0.1-0.1,0-0.3,0.1C239,36.8,239.2,36.7,239.3,36.7z M239.6,36.6
        c-0.1,0.1-0.1,0-0.2,0.1c0.1,0,0.1,0,0,0.1C239.5,36.7,239.6,36.7,239.6,36.6z M240,36.6c-0.1,0,0-0.1-0.1-0.1
        C239.9,36.6,239.9,36.6,240,36.6z M240.1,36.5c-0.1,0,0.1-0.1-0.1-0.1C239.9,36.5,240,36.6,240.1,36.5z M240.8,36.3
        C240.9,36.3,240.9,36.3,240.8,36.3c0.1,0,0.1,0,0.1,0C241,36.2,240.9,36.3,240.8,36.3z M241.1,36.4
        C241.1,36.3,241.1,36.3,241.1,36.4H241C241,36.4,241,36.4,241.1,36.4c-0.2-0.1-0.3,0-0.5,0.1c-0.1,0-0.1,0-0.2,0
        c0.1,0,0.3-0.1,0.2-0.1c-0.1,0-0.3,0.1-0.3,0.1h0.1c-0.1,0-0.1,0.1-0.1,0.1s0.1,0,0.1-0.1c-0.1,0-0.1,0-0.1,0s0-0.1-0.1,0
        l-0.1,0.1c0.1,0,0.1-0.1,0.1,0l-0.1,0.1l0.1-0.1c0-0.1-0.1,0-0.1-0.1c0,0,0.1-0.1,0.1,0h-0.1c0.1,0,0.1-0.1,0.3-0.1
        c0.1,0,0.2,0,0.2-0.1c-0.1,0.1-0.2,0-0.3,0c0.1-0.1,0.2,0,0.3,0S241,36.3,241.1,36.4c-0.1,0-0.3-0.1-0.2,0
        C241,36.4,241,36.4,241.1,36.4z M241.7,36c0,0-0.1-0.1-0.2,0c0.1,0,0.1,0,0.1,0c-0.1,0-0.1,0.1-0.2,0.1c0-0.1-0.1,0-0.1-0.1
        c-0.1,0-0.1,0.1-0.2,0.1c0,0,0,0,0,0.1C241.2,36,241.5,36,241.7,36z M242.5,35.7c-0.1,0-0.2,0.1-0.3,0.1c0,0,0.1,0,0.1-0.1h-0.1
        h-0.1v0.1c-0.1-0.1-0.1,0-0.3,0.1v-0.1c-0.1,0.1-0.3,0-0.3,0.1c0.1-0.1,0.2,0,0.1,0c0.1,0,0.2-0.1,0.3-0.1
        C242.1,35.8,242.4,35.8,242.5,35.7z M243.2,36c-0.1,0-0.1,0-0.1,0.1C243.1,36,243.1,36,243.2,36L243.2,36z M243.3,35.7
        c-0.1,0-0.1,0-0.2,0C243.1,35.8,243.3,35.8,243.3,35.7z M243.3,35.6C243.1,35.6,243.4,35.6,243.3,35.6c-0.2-0.1-0.3,0-0.4,0
        c-0.1,0,0.1,0,0,0.1c-0.1,0-0.1,0-0.3,0c-0.1,0-0.1,0.1-0.1,0.1c0.1,0,0.3-0.1,0.1-0.1c0.1,0,0.1,0,0.3,0
        C243,35.6,243.3,35.6,243.3,35.6z M243.4,35.6L243.4,35.6c0.1,0,0.1,0.1,0.1,0.1C243.6,35.6,243.5,35.5,243.4,35.6z M257.3,35.9
        C257.4,35.9,257.4,35.9,257.3,35.9C257.2,35.8,257.4,35.8,257.3,35.9C257.3,35.8,257.3,35.8,257.3,35.9z M255.8,35.5
        c0.1,0,0.2,0,0.3,0C256.1,35.5,255.8,35.5,255.8,35.5z M254.3,35.8c0.1,0,0.1,0,0.2,0C254.5,35.8,254.3,35.7,254.3,35.8z
        M253.8,35.8c0.1,0,0.2,0,0.3,0C254,35.8,253.8,35.7,253.8,35.8z M250.8,35.6L250.8,35.6L250.8,35.6
        C250.7,35.6,250.7,35.6,250.8,35.6z M249.3,35.4c0.1,0,0.1,0.1,0.2,0.1c0-0.1-0.1-0.1,0-0.1C249.5,35.4,249.4,35.4,249.3,35.4z
        M248.9,35.7c0.1,0,0.1,0,0.1,0C249,35.7,248.9,35.7,248.9,35.7C248.9,35.6,249,35.6,248.9,35.7C248.8,35.6,248.9,35.6,248.9,35.7
        z M247.1,35.7c0.1,0,0.1,0,0.2,0C247.2,35.7,247.1,35.6,247.1,35.7z M246.3,35.5h0.1C246.5,35.4,246.3,35.4,246.3,35.5z M246,35.7
        c0.1,0,0.2,0,0.2-0.1C246.2,35.6,246,35.6,246,35.7z M245.5,35.7c0.1,0,0.3-0.1,0.2-0.1C245.7,35.6,245.5,35.6,245.5,35.7z
        M244.8,35.6c0,0.1,0.1,0.1,0.2,0C245.1,35.5,245,35.6,244.8,35.6z M244.3,35.9c0.1,0,0.2,0,0.3,0c0-0.1-0.1,0-0.1,0
        C244.4,35.8,244.3,35.8,244.3,35.9z M243.7,35.6c0.1,0,0.2-0.1,0.3-0.1c-0.1,0-0.2,0-0.3,0C243.8,35.5,243.8,35.6,243.7,35.6z
        M244.5,35.4c0-0.1-0.1,0-0.3,0c-0.1,0-0.1,0-0.1,0c0-0.1,0-0.1-0.1,0c0,0.1,0.1,0,0.1,0.1c-0.1,0-0.1,0.1-0.2,0.1
        C244.2,35.5,244.4,35.4,244.5,35.4z M246.2,35.3L246.2,35.3c-0.1,0-0.1,0-0.2,0C245.9,35.3,246.2,35.3,246.2,35.3z M246.8,35.2
        c-0.1,0-0.1,0-0.1,0C246.6,35.3,246.7,35.3,246.8,35.2L246.8,35.2z M247,35.2c-0.1-0.1-0.1-0.1-0.2-0.1c0,0.1,0.1,0,0.1,0.1
        c0,0-0.1,0,0,0H247z M249.1,35.1c-0.1,0-0.1,0-0.2,0C249,35.1,249,35.1,249.1,35.1L249.1,35.1z M260.7,36.2c0,0-0.1,0-0.1,0
        C260.6,36.2,260.5,36.3,260.7,36.2C260.7,36.3,260.8,36.2,260.7,36.2z M261.4,36.4C261.3,36.4,261.3,36.4,261.4,36.4
        c-0.1-0.1-0.1-0.1-0.2-0.1C261.2,36.3,261.3,36.4,261.4,36.4z M257.3,45.4c0.1,0,0.1,0,0.2,0C257.5,45.4,257.4,45.4,257.3,45.4z
        M256.9,45.4C256.9,45.4,256.9,45.4,256.9,45.4c0.1,0,0.1,0,0.3,0C257.2,45.4,257,45.4,256.9,45.4
        C256.9,45.4,256.9,45.4,256.9,45.4C256.9,45.4,256.8,45.4,256.9,45.4z M256.3,45.6c0,0.1,0.1,0,0.2,0
        C256.5,45.6,256.4,45.6,256.3,45.6z M247.2,46.8C247.1,46.8,247.1,46.8,247.2,46.8L247.2,46.8z M248,46.6c0-0.1-0.1,0-0.2,0
        C247.8,46.6,248,46.6,248,46.6z M248.5,47.5C248.4,47.5,248.5,47.5,248.5,47.5C248.4,47.5,248.4,47.5,248.5,47.5
        C248.4,47.5,248.4,47.5,248.5,47.5z M249.3,47.5C249.3,47.4,249.3,47.4,249.3,47.5c-0.1-0.1,0-0.1-0.1-0.1c0,0,0,0,0,0.1
        c-0.1,0-0.1,0-0.1,0.1C249.2,47.4,249.3,47.5,249.3,47.5z M249.3,46.2c-0.1,0-0.3,0-0.3,0.1c0,0.1,0.1-0.1,0.1,0
        C249.1,46.3,249.1,46.3,249.3,46.2C249.1,46.2,249.3,46.2,249.3,46.2z M249.5,47.3c-0.1,0-0.2,0.1-0.1,0.1c0.1,0,0.1-0.1,0.3-0.1
        C249.8,47.3,249.6,47.3,249.5,47.3z M249.9,47.2c-0.1,0-0.1,0.1-0.1,0.1C249.9,47.3,250,47.2,249.9,47.2z M251.7,46
        c0.1,0,0.1,0.1,0.1,0C251.9,45.9,251.8,45.9,251.7,46z M254,46.1L254,46.1L254,46.1C254.2,46.1,254.2,46.1,254,46.1z M258,45.5
        c-0.1,0-0.3,0.1-0.2,0.1C257.8,45.5,258.1,45.5,258,45.5z M258.4,45.4c-0.1,0-0.1,0-0.2,0C258.2,45.5,258.3,45.5,258.4,45.4z
        M258.6,45.2c0,0.1,0.2-0.1,0.2-0.1C258.7,45.1,258.7,45.1,258.6,45.2z M259.7,44.9C259.6,45,259.6,44.9,259.7,44.9
        c-0.2,0.1-0.3,0.1-0.3,0.1h-0.1c-0.1,0.1-0.4,0.1-0.6,0.2c0.1,0-0.1,0.1-0.1,0.1c0.1,0,0.1,0,0.1-0.1c0,0,0.1,0,0.2,0
        s0.1-0.1,0.1-0.1s0.2-0.1,0.3-0.1s0.1,0,0.1-0.1C259.5,45,259.8,45,259.7,44.9z M263,43.1c-0.1,0.1-0.1,0.1-0.2,0.1
        s-0.1,0.1-0.1,0.1l0,0l0,0c-0.1,0.1-0.2,0.1-0.2,0.2c0.1-0.1,0.2-0.2,0.3-0.3C262.9,43.2,263,43.2,263,43.1z M264.4,39.4
        c0,0.1,0,0.1,0,0.1C264.4,39.6,264.4,39.4,264.4,39.4z M264.4,39.8C264.5,39.8,264.4,39.8,264.4,39.8
        C264.4,39.8,264.4,39.9,264.4,39.8z M264.5,39.7c0,0.1-0.1,0.1-0.1,0.1C264.5,40,264.6,39.8,264.5,39.7z M263,43
        C263,43,263.1,43,263,43L263,43C263.2,43,263.2,42.9,263,43z M264.8,42.1c-0.1,0.1-0.1,0.2-0.1,0.2
        C264.7,42.2,264.8,42.1,264.8,42.1z M265.2,41.2c0,0.1-0.1,0.1-0.1,0.2c0,0,0,0.1,0.1,0C265.1,41.4,265.3,41.2,265.2,41.2z
        M265.3,39.2c0,0.1,0,0.2,0.1,0.2C265.4,39.2,265.4,39.2,265.3,39.2z M264.7,39.4c0,0.1,0,0.1,0.1,0.2c-0.1,0,0,0.1,0,0.1
        c0,0.1,0,0.1-0.1,0.1c0.1,0,0,0.1,0.1,0.2c0,0.1,0,0.1-0.1,0.1c0-0.1,0.1-0.1,0-0.1c0,0.1,0,0.1,0,0.1s0.1,0.3,0,0.3
        c0,0,0-0.1,0,0s-0.1,0.1-0.1,0.2c0,0,0-0.1,0.1-0.1c0,0.1-0.1,0.2-0.1,0.3c0,0-0.1,0-0.1,0.1l-0.1,0.2l0,0c0,0.1,0,0.1-0.1,0.1
        c0,0,0,0-0.1,0c0,0.1,0.1,0,0.1,0c-0.1,0.1,0,0.1,0,0.2c0,0.1-0.1,0-0.1,0.1c0,0,0,0.1-0.1,0.1c-0.1,0.1-0.2,0.3-0.3,0.5
        c0,0,0,0.1-0.1,0.1c-0.2,0.3-0.3,0.3-0.6,0.6c0.1,0.1,0.2-0.1,0.3-0.2c0.1-0.1,0.3-0.3,0.4-0.3v-0.1c0.2-0.2,0.3-0.3,0.3-0.6
        c0.1,0,0,0.1,0.1,0c0-0.1,0.1-0.1,0.1-0.1c0.1-0.2,0.2-0.4,0.3-0.5c0-0.1,0.1-0.3,0.1-0.3c-0.1,0,0-0.1,0-0.2v0.1
        c0-0.1,0.1-0.1,0.1-0.3c-0.1-0.1,0,0.1-0.1,0.1s0-0.1,0-0.3c0,0,0,0,0-0.1c0.1,0.1,0,0,0-0.1c0-0.1,0-0.2,0-0.3h0.1
        c0-0.1,0-0.1-0.1-0.1c0-0.2-0.1-0.3,0-0.4c0,0,0.1,0.1,0.1,0s-0.1-0.1-0.1-0.1c0-0.1,0-0.1-0.1-0.2c0,0.1,0.1,0.2,0.1,0.3
        c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.1,0-0.1,0-0.1C264.5,38.7,264.6,39.4,264.7,39.4z M264.1,38.1c0.1,0,0-0.1-0.1-0.1L264.1,38.1z
        M263.3,37.6c0.1,0.1,0.1,0.1,0.1,0.2C263.5,37.8,263.5,37.7,263.3,37.6L263.3,37.6L263.3,37.6z M263.2,37.4
        c0,0.1,0.1,0.1,0.1,0.1C263.4,37.5,263.3,37.4,263.2,37.4z M263.2,37.3c-0.1,0.1,0.1,0.1,0.1,0.1
        C263.4,37.4,263.2,37.4,263.2,37.3z M263.7,36.7c0.1,0.1,0.1,0.1,0.2,0.1C263.7,36.7,263.7,36.7,263.7,36.7z M263.7,36.6
        c0.1,0,0-0.1-0.1-0.1C263.5,36.5,263.5,36.5,263.7,36.6z M263.2,36.4c0.1,0.1,0.1,0.1,0.1,0.1C263.3,36.4,263.2,36.3,263.2,36.4z
        M262.6,36.7c0.1,0.1,0.1,0,0.1,0.1c0.1,0.1,0.2,0.2,0.3,0.2c-0.1-0.1-0.2-0.1-0.2-0.1s0.1,0.1,0,0S262.6,36.7,262.6,36.7z
        M262.8,36.2c0.1,0.1,0.1,0.1,0.1,0.1v-0.1C262.9,36.2,262.8,36.2,262.8,36.2z M261.7,36.3c0.1,0.1,0.2,0.1,0.3,0.1
        c-0.1-0.1-0.1-0.1-0.1-0.1c-0.1,0-0.1,0,0,0.1C261.7,36.3,261.7,36.3,261.7,36.3z M261.4,36.1c0.1,0,0.1,0,0.1,0
        C261.4,36.1,261.4,36.1,261.4,36.1z M259.8,35.6C259.8,35.6,259.9,35.6,259.8,35.6c0.1,0.1,0.2,0.1,0.2,0.1L259.8,35.6
        c0.1,0.1,0.2,0.1,0.3,0.1c0-0.1,0-0.1,0-0.1C260,35.6,259.9,35.6,259.8,35.6z M257.9,35.4c0.1,0,0.1,0,0.1,0c0-0.1,0.1,0,0.1-0.1
        C258,35.4,258,35.4,257.9,35.4z M257.5,35.4c0.1,0,0.1,0.1,0.2,0.1c0,0,0,0,0-0.1c0.1,0,0.2,0.1,0.3,0c0-0.1-0.2-0.1-0.3-0.1
        c0,0,0,0.1-0.1,0.1S257.5,35.4,257.5,35.4C257.3,35.4,257.4,35.4,257.5,35.4z M253.4,34.4c0.1,0,0.1,0,0.3,0c-0.1,0,0,0,0,0
        C253.6,34.3,253.5,34.2,253.4,34.4z M253.4,34.4C253.4,34.4,253.4,34.4,253.4,34.4C253.4,34.4,253.4,34.3,253.4,34.4
        C253.3,34.4,253.4,34.3,253.4,34.4z M252.9,34.3c0.1,0,0.2,0,0.2,0C253.1,34.3,253,34.3,252.9,34.3z M252.3,34.7
        c0.1,0,0.2,0,0.2,0C252.4,34.7,252.4,34.7,252.3,34.7z M252.2,34.3c0.1,0,0.1,0,0.2,0C252.3,34.2,252.2,34.3,252.2,34.3z
        M250,34.7c0.1,0,0.1,0,0.2,0c0,0,0-0.1-0.1-0.1C250.2,34.7,250,34.6,250,34.7z M249.8,34.6c0,0,0.1,0.1,0.1,0
        c-0.1-0.1,0.1,0,0.1,0C250.1,34.5,249.9,34.6,249.8,34.6z M248.5,34.4c0.1,0,0.2,0,0.2,0C248.6,34.5,248.6,34.4,248.5,34.4z
        M247.3,34.5L247.3,34.5C247.4,34.4,247.3,34.4,247.3,34.5C247.3,34.4,247.3,34.4,247.3,34.5C247.2,34.5,247.3,34.5,247.3,34.5z
        M247.1,34.4L247.1,34.4C247.1,34.5,247.2,34.4,247.1,34.4z M247.1,34.7c0.1,0,0.3,0,0.3-0.1c-0.1,0-0.1,0-0.1,0
        C247.2,34.6,247,34.6,247.1,34.7z M245.9,34.6c-0.1,0-0.1,0-0.1,0.1C245.9,34.7,245.9,34.7,245.9,34.6L245.9,34.6z M246.1,34.5
        c0.1,0,0.1,0,0.1,0H246.1C246.3,34.5,246.2,34.5,246.1,34.5z M245.6,34.5c0.1,0,0.3,0,0.3-0.1C245.8,34.5,245.6,34.5,245.6,34.5z
        M245.6,34.5c0,0.1,0.3,0,0.4,0c0,0,0.1,0,0.1-0.1C245.9,34.5,245.8,34.5,245.6,34.5z M264.4,41.1c0,0,0,0.1,0,0.2v-0.1l0,0
        C264.4,41.2,264.4,41.2,264.4,41.1z M236.5,41.9c-0.1,0-0.1-0.1-0.1-0.1C236.4,41.8,236.5,41.9,236.5,41.9z M256.9,35.4
        c-0.1,0-0.3,0-0.4,0c0-0.1,0.1,0,0.1,0C256.7,35.4,256.9,35.4,256.9,35.4z M252.6,35.2c-0.1,0-0.2,0-0.4,0
        C252.2,35.1,252.5,35.1,252.6,35.2z M234.5,39.8c-0.1-0.1,0-0.3,0-0.5c0-0.1,0-0.3,0.1-0.3C234.5,39.3,234.5,39.6,234.5,39.8z
        M237.2,42.6c-0.1,0-0.1,0-0.1-0.1c0-0.1,0-0.1,0-0.1C237.1,42.5,237.2,42.5,237.2,42.6z M234.6,40.5c-0.1-0.1-0.1-0.3-0.1-0.5
        C234.6,40.1,234.6,40.4,234.6,40.5z M235.6,37.6c-0.1,0.1-0.3,0.3-0.5,0.4C235.3,37.8,235.4,37.7,235.6,37.6
        c0.1-0.1,0.2-0.1,0.3-0.3C236.1,37.3,235.8,37.5,235.6,37.6z M237.3,42.8c-0.1-0.1-0.2-0.1-0.3-0.1
        C237,42.6,237.3,42.7,237.3,42.8z M238.1,43.1c-0.1,0-0.1,0-0.2-0.1C238,43,238,43,238.1,43.1z M237.6,42.9
        c-0.1,0-0.2-0.1-0.3-0.1C237.4,42.8,237.5,42.8,237.6,42.9C237.6,42.9,237.6,42.9,237.6,42.9z M235.6,41c-0.1-0.1-0.1-0.1-0.1-0.2
        c0.1-0.1-0.1-0.2-0.1-0.3c0,0,0,0,0.1,0c0,0.1,0.1,0.1,0.1,0.2C235.5,40.8,235.6,41,235.6,41z M260.3,44.1
        c0.2-0.1,0.3-0.1,0.6-0.3C260.8,43.9,260.5,44,260.3,44.1L260.3,44.1z M260.8,35.1l0.2,0.1L260.8,35.1z M255.2,46.8
        c0,0-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.1,0C254.9,46.8,255,46.8,255.2,46.8z M262.4,35.6c0.1,0.1,0.2,0.1,0.3,0.2
        c0.1,0.1,0.3,0.1,0.3,0.2C262.8,35.8,262.4,35.7,262.4,35.6z M264.1,43c0.1-0.1-0.1,0.2-0.2,0.2c0,0-0.1,0-0.1,0.1
        C263.8,43.2,264.1,43,264.1,43z M264.1,36.9c0.1,0.1,0.1,0.1,0.1,0.1C264.1,37,264.1,36.9,264.1,36.9z M262.1,35.6
        c0-0.1,0.1,0.1,0.1,0.1c0,0.1,0.3,0.1,0.2,0.1C262.3,35.8,262.3,35.7,262.1,35.6z M261.1,35.3c0.2,0,0.2,0.1,0.3,0.1
        C261.4,35.4,261.1,35.3,261.1,35.3z M262.2,35.7c0.1,0.1,0.2,0.1,0.2,0.1C262.3,35.8,262.1,35.8,262.2,35.7z M262.6,36
        C262.6,36,262.4,35.9,262.6,36c0,0,0,0,0.1,0.1C262.7,36,262.6,36,262.6,36z M259,44.7c0.1-0.1,0.2-0.1,0.3-0.1
        c0.3-0.1,0.5-0.2,0.7-0.3c0.1-0.1,0.3-0.1,0.3-0.1c-0.2,0.1-0.5,0.2-0.7,0.3S259.2,44.6,259,44.7z M261.4,43.6
        c0.1,0,0.2-0.1,0.3-0.1C261.6,43.5,261.4,43.6,261.4,43.6z M262.8,42.7c0-0.1,0.1-0.1,0.1-0.1C263,42.5,262.8,42.6,262.8,42.7z
        M261.7,43.4c0-0.1,0.3-0.2,0.3-0.2C261.9,43.3,261.8,43.4,261.7,43.4z M256.7,45.2c0.1-0.1,0.1-0.1,0.3-0.1
        C257,45.2,256.8,45.2,256.7,45.2z M254.3,45.7c0-0.1,0.1,0,0.2,0c0.3-0.1,0.6-0.1,0.9-0.1c-0.1,0.1-0.2,0.1-0.3,0.1
        C254.9,45.6,254.7,45.7,254.3,45.7z M258.5,44.8c0-0.1,0.3-0.1,0.3-0.1C258.8,44.7,258.7,44.8,258.5,44.8z M257.1,45.2
        c0-0.1,0.1-0.1,0.2-0.1C257.3,45.2,257.2,45.2,257.1,45.2z M257.9,34.5c0.1,0,0.3,0,0.4,0C258.2,34.5,258.1,34.5,257.9,34.5z
        M240.7,35.4c0.1,0,0.1-0.1,0.2-0.1c0,0,0,0-0.1,0c0.1-0.1,0.1,0,0.1,0C240.9,35.3,240.8,35.4,240.7,35.4z M239.9,35.5
        c0-0.1,0.1-0.1,0.3-0.1C240.1,35.4,240,35.4,239.9,35.5z M240.9,35.1c0.1,0,0.2-0.1,0.2,0C241.1,35.1,240.9,35.1,240.9,35.1z
        M240.2,35.3c0.1-0.1,0.2-0.1,0.2-0.1c0.1,0,0.1-0.1,0.1-0.1C240.5,35.3,240.3,35.3,240.2,35.3z M240.9,35.2c0-0.1,0.1,0,0.1-0.1
        l0,0c0.1,0,0.1,0,0.1,0s0.1-0.1,0.2-0.1c0.1,0-0.1,0.1-0.1,0.1c0,0.1-0.1,0.1,0.1,0.1c0,0.1-0.1,0-0.1,0c-0.1,0,0,0,0,0
        C241.1,35.2,241,35.1,240.9,35.2C241,35.2,241,35.2,240.9,35.2z M242.7,34.8c0.1,0,0.2-0.1,0.3-0.1h0.1
        C243,34.8,242.8,34.8,242.7,34.8C242.8,34.8,242.8,34.8,242.7,34.8c0.2,0.1,0.6,0,0.6,0.1c-0.1,0-0.2,0-0.3,0
        c-0.1,0-0.1,0.1,0,0.1c-0.2,0.1-0.6,0.1-0.7,0.1c0.1-0.1,0.2-0.1,0.3-0.1c0,0-0.1-0.1-0.1,0c-0.1,0,0.1-0.1,0.2-0.1
        c0.1,0,0,0.1,0,0.1c0.1,0,0.2,0,0.3,0c0-0.1-0.1,0-0.1,0s0.1,0,0,0C242.7,34.9,242.6,34.9,242.7,34.8
        C242.7,34.9,242.7,34.8,242.7,34.8z M240.9,35c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0c0.1-0.1,0.4-0.1,0.8-0.2h0.1
        c0,0.1-0.2,0.1-0.1,0.1c-0.1,0.1-0.1,0-0.3,0.1c0,0.1,0.2,0,0.3,0c0,0-0.1,0.1,0,0.1c-0.1,0-0.3,0.1-0.6,0.1c0,0,0.1,0,0.1-0.1
        c0.1,0,0.1-0.1,0.1-0.1c-0.1,0-0.1,0-0.1,0.1c-0.3,0.1-0.5,0.1-0.7,0.1C241,35,241,35,240.9,35z M241.5,34.9L241.5,34.9
        c0.1-0.1-0.1,0-0.1,0C241.1,34.9,241.4,34.9,241.5,34.9z M242.4,34.9C242.4,34.9,242.4,34.9,242.4,34.9c-0.1,0.1,0.1,0,0.1,0
        C242.5,34.9,242.5,34.9,242.4,34.9C242.4,34.8,242.6,34.8,242.4,34.9c0.1-0.1,0.1-0.1,0.2-0.1h-0.1c0,0,0.2,0,0.2-0.1
        c0,0-0.1,0-0.1,0.1c-0.1,0.1-0.3,0.1-0.5,0.1c0,0,0,0,0-0.1c-0.1,0-0.1,0.1-0.2,0.1c0-0.1,0-0.1-0.1,0c0-0.1,0.2-0.1,0.3-0.1
        c0,0-0.1,0.1,0,0.1C242.4,34.9,242.3,34.9,242.4,34.9z M239.9,35.6C239.9,35.6,239.9,35.6,239.9,35.6c0.1-0.1,0.2-0.1,0.3-0.1
        c0.1,0,0.2-0.1,0.3,0c-0.1,0-0.1,0-0.1,0v0.1c0.1,0,0.2-0.1,0.3-0.1c0.1,0.1-0.1,0.1-0.1,0.1l-0.1,0.1
        C240.2,35.6,240,35.6,239.9,35.6C239.9,35.7,240,35.6,239.9,35.6c0.1,0.1,0.3,0,0.4-0.1c0,0-0.1-0.1-0.2,0c0,0.1,0,0.1,0.1,0
        C240.1,35.6,240,35.6,239.9,35.6z M243.2,34.7c0.1-0.1,0.1,0,0.3-0.1c-0.1,0-0.1-0.1,0.1-0.1c0,0.1,0.1,0,0.2,0.1
        c0,0-0.1,0.1,0,0.1c0,0.1-0.1,0-0.3,0.1c0,0,0.1,0,0.1-0.1l0,0h-0.2c-0.1,0,0.1,0.1-0.1,0.1C243.3,34.7,243.4,34.7,243.2,34.7z
        M238.9,35.8c-0.1,0-0.1,0-0.2,0.1C238.7,35.8,238.9,35.7,238.9,35.8z M246,45.2c0.1,0,0.3,0.1,0.4,0.1
        C246.4,45.3,246.2,45.2,246,45.2z M238.9,35.8c0.1-0.1,0.1-0.1,0.1-0.1c0.1,0,0.2,0,0.3,0c-0.1,0-0.2,0.1-0.3,0.1h0.1
        C239,35.8,239,35.8,238.9,35.8z M246.3,45.9c0,0-0.1,0-0.1,0c0,0,0.1,0.1,0,0.1c-0.1,0-0.2-0.1-0.3-0.1c-0.2-0.1-0.5-0.1-0.6-0.1
        c-0.1,0-0.1,0-0.1-0.1c-0.3-0.1-0.6-0.1-0.9-0.1c-0.1,0-0.1-0.1-0.2-0.1c0,0-0.1,0-0.1,0l-0.1-0.1c-0.2-0.1-0.5-0.1-0.7-0.1
        c0.1,0,0-0.1,0-0.1c-0.1,0,0,0,0,0.1c-0.2-0.1-0.3-0.1-0.4-0.1c-0.1,0-0.1,0-0.1-0.1c-0.1,0-0.1,0-0.2-0.1c0,0.1-0.1,0-0.1,0
        c0.1,0,0.1,0.1,0.1,0.1c0.1,0,0-0.1,0.1-0.1s0.1,0.1,0.2,0.1v0.1c0.1,0,0.1-0.1,0.2-0.1c0,0-0.1,0-0.1,0.1c0.1,0.1,0.1,0,0.3,0.1
        c0.1,0,0.2,0,0.3,0c-0.1,0-0.1,0-0.2,0c0,0,0.1,0.1,0.1,0.1c0.1,0,0,0,0,0h0.1c0.1,0,0.1-0.1,0.1,0c0,0.1-0.1,0-0.1,0
        s0,0.1-0.1,0.1c0.1,0.1,0.2,0.1,0.3,0.1c0-0.1-0.1-0.1-0.1-0.1c0-0.1,0.1,0,0.1,0c0.1,0.1,0,0,0,0.1c0.1,0.1,0.1,0.1,0.2,0.1
        c0,0.1-0.1,0.1-0.1,0c-0.1,0,0.1,0.1,0,0.1c-0.1,0-0.1,0-0.2-0.1c0,0-0.1,0-0.1,0c-0.1-0.1-0.3-0.1-0.5-0.1c-0.1,0-0.1,0-0.1,0
        c-0.2-0.1-0.5-0.1-0.7-0.2c-0.1,0-0.1-0.1-0.3-0.1c-0.5-0.1-1-0.3-1.6-0.5c-0.1,0-0.1-0.1-0.1-0.1c-0.1,0-0.3,0-0.3-0.1
        c-0.1,0-0.1-0.1-0.3-0.1c-0.1,0-0.1-0.1-0.1-0.1c-0.1,0-0.3-0.1-0.5-0.1c-0.1,0,0-0.1,0.1,0c-0.1-0.1-0.3-0.1-0.3-0.1
        c-0.1-0.1-0.3-0.1-0.3-0.1c-0.1,0-0.3-0.2-0.6-0.2c-0.1,0-0.1-0.1-0.1-0.1h-0.1c-0.1-0.1-0.3-0.2-0.6-0.3c-0.1,0-0.1-0.1-0.1-0.1
        s0,0,0-0.1c-0.1-0.1-0.3-0.1-0.4-0.2c0,0,0,0-0.1,0c-0.1-0.1-0.1-0.1-0.2-0.1c-0.1-0.1-0.1,0-0.1-0.1c-0.1-0.1-0.2-0.1-0.3-0.2
        c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.2-0.4-0.5-0.6-0.7c-0.1-0.2-0.3-0.4-0.3-0.6c0-0.1-0.1-0.1-0.1-0.1
        s0-0.1,0-0.1c0-0.1-0.1-0.2-0.1-0.3c-0.1-0.2-0.1-0.4-0.1-0.7c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2s0-0.1,0-0.1s0-0.1,0-0.1
        c0-0.1,0-0.1,0-0.1c0.1-0.3,0.2-0.7,0.3-1c0.1-0.1,0.1-0.1,0.1-0.1c0-0.2,0.1-0.4,0.3-0.6c0.1-0.1,0.3-0.3,0.5-0.4l-0.1,0.1
        c0.1-0.1,0.1-0.1,0.1-0.1c0.1-0.1,0.2-0.1,0.3-0.2s0-0.1,0.1-0.1c0.3-0.2,0.7-0.4,0.9-0.5c0.1-0.1,0.1-0.1,0.2-0.1
        c0.2-0.1,0.4-0.1,0.6-0.2c0.1,0,0.1-0.1,0.2-0.1s0.1,0,0.1,0c0.1,0,0.1-0.1,0.1-0.1c0.2-0.1,0.5-0.2,0.8-0.3c0,0-0.1,0.1-0.2,0.1
        c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.1,0.1-0.1,0.1c0,0,0,0-0.1,0c0,0-0.1,0.1-0.2,0.1c0,0.1,0.1,0,0.1,0c-0.2,0.1-0.3,0.1-0.5,0.2
        c0,0.1,0.1,0,0.1,0.1c-0.2,0-0.3,0.1-0.6,0.1c-0.1,0-0.1,0.1,0,0.1c-0.1,0.1-0.1,0-0.1,0.1c-0.1,0,0-0.1,0-0.1
        c-0.2,0.1-0.3,0.1-0.6,0.3h0.1c-0.1,0-0.4,0.2-0.6,0.3c-0.1,0.1-0.1,0.1-0.1,0.1c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.1,0.1-0.1,0.1
        s-0.1,0.1-0.1,0.1c0.1,0,0.1-0.1,0.2-0.1c0,0,0.1,0.1,0.1,0c0,0,0,0,0.1-0.1h-0.1c0.3-0.2,0.6-0.3,0.8-0.5
        c0.3-0.1,0.5-0.2,0.8-0.3c0.1,0,0,0,0,0.1c0.3-0.1,0.4-0.2,0.6-0.2c0,0,0,0,0.1-0.1c0.1,0,0.2-0.1,0.1,0c0.1-0.1,0.1-0.1,0.1-0.1
        h0.1c0,0.1-0.1,0.1-0.1,0.1c-0.1,0-0.1,0-0.3,0.1c-0.1,0,0.1-0.1,0.1-0.1s-0.2,0.1-0.1,0.1c-0.1,0.1-0.1,0.1-0.2,0.1
        c0,0,0-0.1,0.1-0.1c-0.1,0-0.2,0.1-0.1,0.1c-0.2,0.1-0.3,0.1-0.6,0.2c-0.1,0-0.1,0-0.2,0.1c0,0,0,0-0.1,0.1
        c-0.3,0.1-0.7,0.3-0.9,0.5c-0.1,0,0.1-0.1,0.1-0.1c-0.1,0-0.2,0.1-0.2,0.2c-0.3,0.1-0.6,0.4-0.9,0.8c-0.1,0-0.1,0.1-0.1,0
        c-0.1,0.1,0,0.1,0,0.1c-0.2,0.3-0.3,0.6-0.3,0.8c0,0.1,0,0.3,0,0.5c0,0.1,0,0.2,0,0.3c0,0,0,0.1,0,0.2s0,0.1,0,0.1
        s0.1,0.3,0.1,0.4c0,0,0,0,0.1,0.1c0,0.1,0,0.1,0.1,0.2c0,0.1,0,0.2,0.1,0.2c0.1,0.1,0,0.1,0.1,0.2c0,0,0-0.1-0.1-0.1
        c0.1,0,0.1,0.1,0.1,0.2c0.1,0.2,0.3,0.3,0.4,0.6c0.1,0.1,0.2,0.1,0.3,0.3c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.3,0.6,0.3
        c0.1,0,0.1,0.1,0.1,0.1c0.1,0.1,0.3,0.1,0.3,0.2c0.1-0.1,0.4,0.1,0.4,0.1h-0.1l0.1,0.1c0.1,0.1,0.1,0.1,0.2,0.1c0,0,0,0-0.1-0.1
        c0.1,0,0.3,0.1,0.6,0.2c0.1,0.1,0.2,0.1,0.4,0.2c0,0-0.1-0.1-0.1-0.1c0.1,0.1,0.2,0.1,0.4,0.1c0.1,0.1,0.2,0.1,0.3,0.1h-0.1
        c0.1,0.1,0.2,0.1,0.1,0c0.1,0,0.1,0.1,0.1,0.1c0.2,0,0.5,0.1,0.7,0.2c-0.1,0,0.1,0.1,0.2,0.1c0-0.1-0.1,0-0.1-0.1
        c0.3,0.1,0.6,0.2,0.8,0.3c0,0,0,0,0,0.1h0.1c0.1,0.1,0.3,0.1,0.4,0.1c0.2,0.1,0.5,0.1,0.7,0.2c0.1,0,0.1,0,0.2,0
        c0.3,0.1,0.7,0.2,1,0.3c0.1,0,0.2,0,0.3,0.1c0.2,0.1,0.4,0.1,0.6,0.1c0.2,0.1,0.5,0.1,0.7,0.1c0.1,0,0.1,0,0.1,0.1h0.1
        c0.1,0,0.1,0.1,0.3,0.1C245.9,45.8,245.9,45.8,246.3,45.9C246.3,45.9,246.2,45.9,246.3,45.9l-0.3,0c-0.1,0,0,0.1-0.1,0.1
        c0.1,0,0.1,0,0.1,0c0.1,0,0,0,0.1,0.1c0.1,0,0.1,0,0.1,0C246.2,45.9,246.2,45.9,246.3,45.9z M236.1,37.1c0.1-0.1,0.1,0,0.2-0.1
        C236.3,37,236.2,37,236.1,37.1z M237.4,36.3c0.1,0,0.1-0.1,0.2-0.1C237.6,36.2,237.4,36.3,237.4,36.3
        C237.4,36.3,237.4,36.3,237.4,36.3z M237,36.3c0,0,0.2-0.1,0.3-0.1C237.2,36.3,237.1,36.3,237,36.3z M235.9,36.9
        c0.1-0.1,0.3-0.1,0.3-0.2C236.1,36.7,236,36.8,235.9,36.9z M235.4,37.6c0.1,0,0.3-0.2,0.2-0.2C235.6,37.4,235.4,37.6,235.4,37.6z
        M234.3,40c0-0.1,0-0.2,0-0.3C234.2,39.8,234.2,40,234.3,40z M234.5,41.1L234.5,41.1c0,0.1,0,0.1,0.1,0.3
        C234.7,41.3,234.6,41.2,234.5,41.1z M237.9,43.7c-0.1-0.1-0.1-0.1-0.1,0C237.9,43.7,237.9,43.7,237.9,43.7z M238.1,43.7
        c-0.1-0.1-0.3-0.2-0.4-0.1c0.1,0.1,0.1,0,0.1,0C237.9,43.5,238.1,43.7,238.1,43.7z M238.8,43.9c-0.1-0.1-0.2-0.1-0.2,0
        C238.7,43.9,238.8,43.9,238.8,43.9z M238.8,43.9c0.1,0,0.1,0.1,0.1,0.1C239.1,44,238.9,43.9,238.8,43.9z M240.1,44.6h-0.1
        C239.9,44.6,240.1,44.7,240.1,44.6z M240.5,44.8c-0.1,0-0.1-0.1-0.2-0.1C240.3,44.7,240.3,44.7,240.5,44.8c-0.1,0.1-0.1,0-0.1,0
        C240.4,44.8,240.5,44.8,240.5,44.8z M241.5,45.2h0.1C241.7,45.1,241.5,45.1,241.5,45.2z M241.7,45.1c-0.1,0-0.1-0.1-0.2,0
        c0,0,0.1,0,0.1,0.1C241.7,45.1,241.7,45.1,241.7,45.1z M243.3,45.6c0.1,0.1,0.1,0.1,0.1,0.1C243.5,45.7,243.3,45.7,243.3,45.6
        C243.3,45.6,243.3,45.6,243.3,45.6C243.3,45.6,243.3,45.6,243.3,45.6s-0.1-0.1-0.1-0.1C243.1,45.5,243.2,45.5,243.3,45.6z
        M243,45.5l-0.1-0.1C242.8,45.5,243,45.6,243,45.5z M242.8,45.4c0,0.1,0.1,0.1,0.2,0.1h0.1C243,45.5,242.9,45.5,242.8,45.4z
        M242.5,45.5c0.1,0.1,0.1,0,0.1,0c0,0,0.1,0.1,0.1,0C242.8,45.5,242.5,45.4,242.5,45.5z M242,45.3c0.1,0,0.1,0,0.1,0
        C242.1,45.3,242,45.2,242,45.3z M245.3,45.7C245.3,45.7,245.3,45.6,245.3,45.7c-0.1-0.1-0.1-0.1-0.1,0
        C245.2,45.7,245.2,45.7,245.3,45.7z M245.7,45.7C245.7,45.7,245.6,45.7,245.7,45.7C245.7,45.7,245.7,45.7,245.7,45.7
        c-0.1,0-0.1-0.1-0.2-0.1S245.5,45.7,245.7,45.7C245.6,45.7,245.7,45.7,245.7,45.7z M239.4,35.8c0.1-0.1,0.1-0.1,0.3-0.1
        c0.1,0,0.1,0,0.2,0c0,0.1-0.1,0.1-0.3,0.2c0.1-0.1,0.1-0.1,0.2-0.1c0-0.1-0.1,0-0.1,0c0,0.1-0.3,0.3-0.4,0.1
        C239.3,35.8,239.3,35.8,239.4,35.8c-0.1,0-0.3,0.2-0.5,0.1V36c0.1,0,0.1-0.1,0.2-0.1c0.1,0.1-0.3,0.1-0.3,0.1s-0.1,0-0.2,0.1
        l0.1-0.1c0.1,0,0,0,0.1,0s0-0.1,0.1-0.1c0,0-0.1,0-0.1,0c0-0.1,0.1-0.1,0.3-0.1C239.1,35.8,239.2,35.8,239.4,35.8z M239.3,35.6
        L239.3,35.6c0,0,0.1-0.1,0.1,0C239.4,35.6,239.3,35.6,239.3,35.6z M238.8,35.8c-0.1,0-0.1,0.1-0.2,0.1
        C238.6,35.9,238.8,35.8,238.8,35.8z"/>
    </defs>
    <clipPath id="SVGID_4_">
      <use xlink:href="#SVGID_3_"  style="overflow:visible;"/>
    </clipPath>
    <path class="st4" d="M246.6,45.6c-16.4-2.9-15.6-9.5,2-10.5c21.1-1.2,22.7,10.4-2.8,12.2"/>
  </g>
  <text class="op1" transform="matrix(1 0 0 1 152.8796 30.3895)"><tspan x="0" y="0" class="st5 st6">揭開偽居住正義的鳥籠</tspan><tspan x="0" y="13" class="st5 st6">真正夠住的　大兩房　大三房</tspan></text>
  <g class="op2">
		<path class="st7" d="M208.3,55.4l-1.5-6.8l7,5.5L216,52l1.7,2.3l7.1-3.4v5.9l6,2.1l-5.4,2l5.2,3.6l-5.1,1.7l1.7,4.5l-5.6-1.3v7.2l-6.6-5.9
			l-8.5,4.6l3.5-7.9l-11.3,1.8L206,64l-9.6-3.7l9.1-0.9l-2.8-4.6L208.3,55.4z"/>
		<text transform="matrix(1 0 0 1 207.3395 64.4648)" class="st8 st9 st10">完售</text>
  </g>
	<line class="st11" x1="202" y1="33.5" x2="233.5" y2="45.6"/>
  </g>
</svg>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
#s9{margin: auto;
width:55vw ;
	.st0{fill:#D33828;}
	.st1{stroke:#575757;stroke-width:0.7;stroke-miterlimit:10;}
	.st2{fill:#595757;}
	
		.st3{clip-path:url(#SVGID_2_);fill:none;stroke:#D33828;stroke-width:2.7675;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;
   // animation: dash 3s linear forwards 2.2s;
    //stroke-dasharray: 70;
  //  stroke-dashoffset: 70;
  }
	
		.st4{clip-path:url(#SVGID_4_);fill:none;stroke:#D33828;stroke-width:2.7675;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;
   // animation: dash 3s linear forwards 2.7s;
   // stroke-dasharray: 70;
   // stroke-dashoffset: 70;
    }
	.st6{font-size:8.7px;font-family:""\5FAE\8EDF\6B63\9ED1\9AD4", "Microsoft JhengHei", "Helvetica Neue", Helvetica, Arial";}
	.st7{fill-rule:evenodd;clip-rule:evenodd;fill:#D10000;}
	.st8{fill:#FFFFFF;}
	.st10{font-size:8.7px;font-family:""\5FAE\8EDF\6B63\9ED1\9AD4", "Microsoft JhengHei", "Helvetica Neue", Helvetica, Arial";}
  .st11{fill:none;stroke:#D10000;stroke-miterlimit:10;}
  .op1{
   opacity:0;
   animation: op1 0.5s ease-in-out forwards 2s;
   }
  .op2{
   opacity:0;
    transform: scale(1.2);
    transform-origin: 70% 70%;
   animation: op2 0.5s ease-in-out forwards 3s;}
  .jump{
    animation: jump 2.3s ease-in-out forwards;}
  }
@keyframes jump {
  0% {
    opacity: 0;
    left: -200px;
  }
  10% {
    transform: scale(1.2, 0.8);
  }
  24%,
  26% {
    transform: translateY(-20vh) scale(1, 1.001);
    opacity: 1;
  }
  40% {
    transform: translateY(0) scale(1, 0.999);
  }
  44% {
    transform: translateY(0) scale(1.05, 0.97);
  }
  56% {
    transform: translateY(-6vh) scale(1.01, 1);
  }
  70% {
    transform: translateY(0) scale(1, 1);
  }

  100% {
    opacity: 1;
    left: 0px;
  }
}
@keyframes op1 {
  to {
    opacity: 1;
  }
}
@keyframes op2{
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@media screen and (max-width: 767px) {

#s9{
width:160vw ;
  transform: translate(-3% , 20%);
.mo{
  transform: translate(-50% , 40%);
}
}
}
</style>
<style lang="scss" scoped>
.bg {
  width: 100vw;
  background-image: url('./s9_bg.jpg');
  padding-top: calc(50vh - 22vw);
}

.container {
  width: 980px;
  margin: 0 auto;
}

.title {
  margin-right: 30px;
  width: calc(100vh * (432 / 900));
  opacity: 0;
  position: relative;

  &[data-aos='jump'] {
    animation: jump 2.3s ease-in-out forwards;
  }
  svg {
    width: 100%;
  }
}

.txt2 {
 font-size: 2vw;
 line-height: 1.6;
 text-align: left;
}

.circle1 {
  right: 108px;
  bottom: -7px;
}

.circle2 {
  right: -4px;
  bottom: -7px;
}

/* 螢幕尺寸標準 */
/* 平板尺寸 */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .bg {
    width: 100vw;
    background-image: url('./s9_bg.jpg');
    padding-top: 70px;
  }

  .container {
    width: 900px;
    margin-top: 0px;
    margin-left: 50px;
  }
}

/* 手機尺寸 */
@media screen and (max-width: 767px) {
  .section9 {
    padding-top: 0;
    background-color: #dcdcda;
  }
  .bg {
    width: 100vw;
    background-image: url('./s9_bg_m.jpg');
    background-size: auto;
    background-position: 50% 100%;
    padding-top: 0px;
    overflow: initial;
  }

  .container {
    width: 100vw;
    margin-left: 0;
  }

  .title {
    width: 317px;
    margin: 0 auto;
    margin-top: 5vh;
    margin-bottom: 10px;

    svg {
      width: 100%;
    }
  }

  .desc {
    width: 299px;
    margin: 0 auto;
    svg {
      width: 100%;
    }
  }

  .footer {
    font-size: 24px;
  }
  .circle1,
  .circle2 {
    width: 87px;
    svg {
      width: 100%;
    }
  }
  .circle1 {
    right: 88px;
  }
}
</style>

<script>
import { isMobile } from '@/utils'
import txt1 from './s9_txt_1.vue'
import txt2 from '@/assets/svg/s9_txt2.svg'
import txt3 from './s9_txt_3.vue'

export default {
  name: 'section9',
  components: {
    txt1,
    txt3,
  },

  data() {
    return {
      isMobile,
      txt2,
      txt3,
      slideList: [
        {
          src: require('./s8_img_1.jpg'),
          title: '麗韻門廳，如同飯店宴會Bar',
          desc: '典雅的灰藍色階，鋪陳出飯店般的情緒，崗石與玻璃材質的完美設計',
          btn: '健身空間，喚醒身體的能量',
          goToIndex: 1,
        },
        {
          src: require('./s8_img_2.jpg'),
          title: '健身空間，喚醒身體的能量',
          desc: '兼具健身房與閱覽空間，陪伴孩子閱讀時也鍛鍊自己的身材與線條',
          btn: '麗韻門廳，如同飯店宴會Bar',
          goToIndex: 0,
        },
      ],
    }
  },

  methods: {},
}
</script>
