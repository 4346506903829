<template>
  <svg
    version="1.1"
    class="txt3"
    id="圖層_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 49 23"
    style="enable-background:new 0 0 49 23;"
    xml:space="preserve"
  >
    <path
      class="path"
      stroke="#d33828"
      fill="transparent"
      stroke-width="1"
      d="M36.8,4.4C36.8,4.4,36.7,4.4,36.8,4.4l-0.2,0c0,0,0.2,0,0.1,0C36.7,4.4,36.7,4.4,36.8,4.4z M37.4,4.3
	C37.4,4.4,37.3,4.3,37.4,4.3c-0.2,0-0.1,0-0.2,0c-0.1,0-0.3,0-0.2-0.1C37.2,4.3,37.3,4.3,37.4,4.3z M36.8,4.4l0.2,0
	C37,4.5,36.8,4.5,36.8,4.4z M39.2,4.7c-0.1,0-0.1,0-0.2,0c-0.1,0,0,0.1-0.1,0l-0.4-0.1c-0.1,0,0.1,0,0.1,0c0,0-0.1-0.1-0.1-0.1
	c-0.1,0,0,0.1-0.1,0.1c-0.3,0-0.3-0.1-0.4-0.2c-0.1,0-0.3,0-0.3-0.1c0.2,0,0.5,0.1,0.7,0.2c0,0-0.1,0-0.1-0.1c0.2,0,0.3,0.1,0.3,0.2
	C38.9,4.6,39.1,4.6,39.2,4.7C39.3,4.6,39.3,4.7,39.2,4.7c0.1,0,0-0.1,0-0.1c0.1,0.1,0.2,0,0.3,0.1c0.1,0,0.1,0.1,0.2,0.1
	c0,0-0.1,0-0.1,0c-0.2,0-0.2,0.1-0.4,0.1c0.1,0,0.1-0.1,0.2-0.1c0,0-0.1,0-0.1-0.1C39.4,4.7,39.4,4.7,39.2,4.7
	C39.2,4.7,39.2,4.7,39.2,4.7z M37.7,4.5c0,0.1-0.2,0-0.2,0C37.4,4.5,37.5,4.5,37.7,4.5z M38.6,4.8L38.6,4.8c-0.2,0-0.2-0.1-0.3-0.1
	c-0.4-0.1,0.1,0,0.1,0C38.5,4.7,38.6,4.7,38.6,4.8z M37.9,4.6c-0.1,0-0.1,0-0.2-0.1c0.1,0,0.2,0,0.2-0.1C38.1,4.6,38,4.6,37.9,4.6z
	 M37.5,4.4C37.4,4.3,37.5,4.3,37.5,4.4c0.1-0.1-0.2-0.1-0.2-0.1C37.3,4.2,37.4,4.3,37.5,4.4c0.1,0,0.2,0,0.2,0
	C37.6,4.4,37.5,4.3,37.5,4.4z M36.6,4.3c0-0.1,0.4,0,0.3,0C36.7,4.3,36.7,4.3,36.6,4.3z M8.4,5.6c0-0.1-0.1,0-0.2,0
	C8.2,5.6,8.3,5.5,8.4,5.6c0.1-0.1,0-0.1,0.1-0.1c0.1,0,0.2-0.1,0.3-0.1c0,0,0,0.1,0,0.1c0,0,0.3-0.1,0.2,0c-0.2,0.1-0.2,0-0.3,0
	C8.6,5.4,8.5,5.5,8.4,5.6c0.2,0,0.2-0.1,0.3-0.1c0.2,0,0.8-0.2,1.1-0.3c-0.7,0.2-1.3,0.4-2,0.7C7.9,5.7,8.3,5.6,8.4,5.6z M7.1,6.3
	c0,0.1-0.2,0.1-0.3,0.2c-0.1,0,0,0-0.2,0.1C6.5,6.4,7.1,6.3,7.1,6.3z M7.5,6.2C7.5,6.2,7.5,6.1,7.5,6.2C7.4,6.2,7.4,6.2,7.5,6.2
	C7.4,6.3,7.2,6.2,7.1,6.3l0,0C7.4,6.1,7.7,6,8,5.9c0.1,0,0.2,0,0.3-0.1c0.1,0,0,0,0,0.1c0.1,0,0.2,0,0.4-0.1
	c0.1,0.1-0.4,0.1-0.2,0.1c-0.1,0.1-0.1,0-0.2,0c-0.1,0.1-0.1,0-0.1,0c0,0-0.1,0.1-0.2,0.1c0,0,0-0.1-0.1,0c0,0,0,0,0,0
	c0.1,0,0.1,0,0.2,0c0,0,0,0.1,0,0.1c0.1,0,0.2-0.1,0.2,0C7.9,6.1,7.7,6.1,7.5,6.2z M7.6,6.1c0.1,0,0.1,0,0.2,0C7.8,6,7.6,6,7.6,6.1z
	 M37.1,4.2c-0.2,0-0.2-0.1-0.4-0.1C36.9,4.1,37,4.1,37.1,4.2c0.1-0.1,0.3-0.1,0.4,0c0.1,0.1-0.1,0-0.2,0C37.2,4.2,37.2,4.2,37.1,4.2
	z M6.3,6.7c-0.2,0.1-0.4,0.1-0.2,0C6,6.8,6.3,6.7,6.3,6.7z M6.6,6.5L6.4,6.6C6.3,6.5,6.6,6.4,6.6,6.5z M36.4,4.4l0.1,0l0,0.1
	L36.4,4.4C36.5,4.4,36.4,4.4,36.4,4.4z M8.8,5.8c0,0.1-0.2,0.1-0.2,0.2C8.3,6,8,6.2,7.7,6.2C8,6.1,8.3,6,8.5,5.9
	C8.7,5.9,8.7,5.8,8.8,5.8z M23,4.2c-0.1,0.1-0.4,0.1-0.7,0.1c0-0.1,0.2,0,0.4,0C22.7,4.2,22.8,4.2,23,4.2z M39.7,4.9
	c-0.2-0.1,0,0.1-0.1,0c0,0,0.1,0,0.1,0.1c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.3,0-0.2-0.1c0.1,0,0.2,0,0.2,0.1c0.1,0,0-0.1,0-0.1
	c0,0,0.2,0.1,0.1,0c0.2,0.1,0.2,0,0.4,0.1c0,0-0.1,0-0.1,0C39.8,4.9,39.7,4.9,39.7,4.9z M9.6,5.6C9.6,5.7,9.5,5.7,9.4,5.7
	c0,0,0,0,0,0c-0.1,0-0.2,0.1-0.3,0.1c0,0-0.2,0.1-0.2,0c0.2,0,0.3-0.1,0.4-0.2C9.4,5.6,9.5,5.7,9.6,5.6z M12.5,16.2
	c0,0.1-0.4,0-0.4-0.1c0.1,0,0,0.1,0.2,0.1c0.1,0,0-0.1-0.1-0.1c-0.2-0.1-0.1,0.1-0.3,0c0-0.1,0.2-0.1,0.4,0
	C12.4,16.2,12.3,16.2,12.5,16.2z M8.9,15.1c-0.1,0-0.3-0.1-0.2-0.1C8.7,15,8.7,15,8.9,15.1c0-0.1-0.2-0.1-0.1-0.2
	C8.9,15,9.2,15.1,9.1,15c0.1,0,0.2,0.1,0.2,0.1c-0.1,0-0.1-0.1-0.2-0.1c0,0,0,0-0.1,0C9,15.1,9,15.1,8.9,15.1z M9.5,15.5
	c-0.1,0-0.4-0.1-0.6-0.2c0.1,0,0.1,0,0.1-0.1c0.1,0,0,0.1,0,0.1c0.1,0.1,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0-0.1c0,0-0.1,0,0,0
	c0,0-0.1-0.1-0.2-0.1c0.1,0,0.2,0,0.3,0.1c0.2,0.1,0.2,0,0.4,0.1c0,0-0.2,0-0.3-0.1l0,0.1c0.2,0.1,0.2,0,0.3,0.1c0.1,0,0,0,0.1,0
	c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.3,0.1c0,0.1-0.1,0-0.3,0c-0.2,0-0.2,0-0.2,0.1C9.4,15.6,9.6,15.5,9.5,15.5
	C9.4,15.5,9.4,15.5,9.5,15.5z M12,15.9c0.1,0,0.1,0,0.2,0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0.1,0.3,0.1c-0.1,0.1-0.3-0.1-0.5-0.1
	C12.1,16,12,15.9,12,15.9z M11.3,15.8c0,0,0.2,0,0.2,0c0.1,0,0.2,0,0.4,0c-0.2,0-0.1,0.1,0.1,0.1C11.8,16,11.6,15.8,11.3,15.8
	C11.4,15.8,11.4,15.8,11.3,15.8z M7.5,14.5l0.1,0.1C7.6,14.6,7.6,14.6,7.5,14.5C7.4,14.5,7.5,14.6,7.5,14.5z M7.5,14.8
	c0.1,0,0.1,0.1,0.2,0.1c0,0,0.1,0,0.1,0c0,0,0,0,0,0.1C7.7,14.9,7.4,14.8,7.5,14.8z M7.9,15c0.1,0,0.2,0.1,0.4,0.1
	c0-0.1,0.3,0.1,0.4,0.1c0,0.1-0.2,0-0.4-0.1C8.2,15.1,7.9,15.1,7.9,15z M7.8,14.8C7.8,14.8,7.9,14.9,7.8,14.8
	C7.9,14.8,7.9,14.8,7.8,14.8c0-0.1,0.3,0,0.1-0.1c0-0.1,0.1,0.1,0.2,0.1c0,0,0.1,0,0.2,0.1c0,0,0,0,0,0.1c0.1,0,0.2,0,0.1,0.1
	c0.1,0,0.2,0.1,0.2,0.1C8.5,15.1,8.3,15,8.2,15c-0.1,0-0.1-0.2-0.2-0.1C8,14.9,8.2,15,8.2,15C8,15,8,14.9,7.8,14.8z M12.5,16.1
	C12.5,16,12.5,16,12.5,16.1c0.2,0,0.6,0.1,0.9,0.2c0.2,0,0.1,0.1,0,0C13.1,16.2,12.8,16.2,12.5,16.1z M8.2,14.7c0.1,0,0.1-0.1,0.2,0
	c-0.1,0,0.1,0.1-0.1,0.1c0,0,0.2,0.1,0.1,0.1c-0.1-0.1-0.4-0.1-0.6-0.3c0.1,0-0.1,0,0-0.1C8.1,14.6,8,14.7,8.2,14.7z M8.3,14.6
	c-0.1-0.1,0.3,0.1,0.3,0.1c0.1,0.1,0.2,0,0.3,0.1c-0.2,0-0.4-0.1-0.5-0.1C8.6,14.7,8.5,14.6,8.3,14.6z M11.2,15.9c0.1,0,0.1,0,0.1,0
	c0.1,0,0,0.1,0.2,0.1c-0.1,0,0,0.1-0.2,0.1c0-0.1,0.1,0,0.1-0.1c-0.1,0-0.3,0-0.2,0.1c0,0,0,0,0,0l-0.1,0
	C11.1,15.9,11.2,16,11.2,15.9c-0.2,0-0.3,0-0.4-0.1c0-0.1,0.1,0,0.2,0C11.1,15.8,11.1,15.8,11.2,15.9z M16.5,17
	c-0.1-0.1,0.4,0.1,0.5,0.1C16.9,17.2,16.6,17,16.5,17z M14.6,17c0.1,0,0.2,0.1,0.3,0.1C15,17.1,14.7,17.1,14.6,17
	c0,0.1-0.2,0-0.2,0.1c0.1,0.1,0.4,0.1,0.5,0.1c0,0,0,0,0,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.1,0.1c0.1,0,0.3,0,0.3,0.1
	c0.2,0,0,0.1,0.2,0.1c0.1,0,0,0,0,0c0,0,0.2,0.1,0.4,0.1c-0.1,0-0.2,0-0.2,0c0,0,0,0,0,0c0.1,0,0.2,0,0.2,0
	c-0.1,0.1,0.3,0.1,0.4,0.2c-0.1,0-0.2,0-0.3,0c0,0,0.1,0,0.2,0.1c-0.2,0-0.7-0.1-0.9-0.1c-0.1,0-0.1,0-0.1-0.1c0,0,0,0-0.1,0
	c-0.1,0-0.1-0.1-0.1-0.1c-0.1,0-0.1,0-0.1,0c-0.1,0-0.2-0.1-0.3-0.1c-0.2,0-0.4-0.1-0.6-0.1c-0.2,0-0.3-0.1-0.5-0.2
	c-0.1,0-0.1,0-0.1,0c-0.3-0.1-0.6-0.2-0.8-0.2c0,0-0.1,0-0.1-0.1c0,0,0.3,0.1,0.2,0c-0.1,0-0.3,0-0.4,0c-0.2-0.1-0.6-0.2-0.9-0.3
	c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.3-0.1-0.3-0.1c0.1-0.1,0.3,0.1,0.4,0.1c0.1,0,0.2,0.1,0.4,0.1c0,0,0.1,0,0.1,0
	c0,0,0.1,0.1,0.1,0.1c0,0,0.2,0,0.2,0c0.2,0,0.2,0.1,0.4,0.1c0,0.1-0.2,0,0,0.1c0.1,0,0,0,0-0.1c0.1,0,0.2,0,0.3,0
	c0.3,0.1,0.6,0.2,0.8,0.3c0.2,0,0.3,0,0.4,0c0.1,0,0.2,0.1,0.4,0.1l0,0.1C14.6,17,14.6,17,14.6,17z M13,16.6
	C13,16.7,13.1,16.7,13,16.6c0.1,0,0,0-0.2,0c0,0,0,0.1,0.1,0.1C13,16.7,12.9,16.6,13,16.6z M13.3,16.7c-0.1,0-0.1,0-0.2,0
	c0,0-0.1,0-0.1,0c0.2,0,0,0.1,0.2,0.1C13.2,16.8,13.3,16.8,13.3,16.7z M13.6,16.9c0-0.1-0.2-0.1-0.2-0.1c-0.1,0,0,0.1,0,0.1
	C13.5,16.9,13.5,16.8,13.6,16.9z M15.5,17.4C15.5,17.4,15.5,17.5,15.5,17.4c0.1,0.1,0.3,0.1,0.3,0C15.6,17.4,15.5,17.4,15.5,17.4z
	 M14.2,17.1c-0.1,0-0.4-0.2-0.4-0.1C13.9,17,14.3,17.1,14.2,17.1z M14.4,17c0-0.1-0.4-0.1-0.5-0.1C14.1,17,14.1,16.9,14.4,17z
	 M6,6.8c-0.1,0,0,0.1-0.2,0.1c0-0.1,0.1-0.1,0.1-0.2C6,6.7,6,6.8,6,6.8z M20.6,18.3c-0.1,0.1-0.2-0.1-0.4-0.1
	C20.1,18.1,20.5,18.3,20.6,18.3z M12.5,16.3c0.2,0,0.1,0.1,0.2,0.1C12.7,16.4,12.5,16.3,12.5,16.3C12.3,16.2,12.6,16.3,12.5,16.3z
	 M12.8,16.5c0,0-0.1,0-0.1,0c-0.1-0.1-0.2-0.1-0.3-0.1C12.5,16.4,12.6,16.5,12.8,16.5z M13.9,16.4l0.2,0c0,0-0.1,0-0.1,0l0.1,0
	c0.1,0,0.1,0,0,0c0.1,0,0.2,0.1,0.2,0.1c0,0,0-0.1,0-0.1c0.1,0.1,0.2,0.1,0.4,0.1c0,0-0.2,0-0.2,0c0,0-0.1-0.1-0.1-0.1
	c0,0-0.1,0-0.1,0C14.2,16.5,14.1,16.5,13.9,16.4C13.9,16.4,13.8,16.4,13.9,16.4z M40.4,5.2c0,0.1-0.2,0-0.2,0
	C40.2,5.1,40.3,5.1,40.4,5.2z M13.5,16.5c0.2,0,0.3,0.2,0.4,0.1c0.2,0,0.1,0.1,0.3,0.1c0.2,0.1,0.2,0,0.4,0.1c-0.1,0-0.2,0-0.4,0
	C13.9,16.6,13.5,16.6,13.5,16.5z M13.4,16.5c-0.1,0.1-0.4-0.1-0.6-0.1c0.1-0.1,0,0-0.1-0.1C12.9,16.3,13.2,16.5,13.4,16.5z
	 M13.7,16.7c0.1,0,0.1,0,0.2,0C14,16.8,13.7,16.7,13.7,16.7c-0.2,0-0.4-0.1-0.7-0.2C13,16.5,13.6,16.7,13.7,16.7z M20.8,16.9
	c-0.1,0-0.2,0.1-0.4,0.1c0,0-0.2,0-0.2,0c-0.1,0-0.3,0-0.4-0.1c-0.1,0-0.2,0-0.2,0c-0.6-0.2-1.1-0.2-1.5-0.4c0,0,0.1,0,0.1,0
	c0-0.1-0.1-0.1-0.2-0.1c0-0.1-0.2-0.2-0.3-0.2c0.1,0.1,0.2,0.2,0.4,0.2c-0.2,0-0.3-0.1-0.5-0.1c0.1,0-0.1-0.1,0.1-0.1
	c-0.1,0-0.2-0.1-0.3-0.1c0,0.1,0.2,0.1,0.1,0.2l-0.2-0.1c0.3,0,0-0.1-0.2-0.1c0,0,0.1,0,0.1,0.1c0.1,0.1-0.2-0.1-0.4,0
	c0,0-0.1,0-0.1-0.1c-0.1,0-0.3-0.1-0.5-0.2c0.2,0,0.5,0.1,0.6,0c0,0,0,0,0.1,0.1c0.1,0,0,0,0-0.1c0.2,0.1,0.2,0,0.4,0.1
	c0,0.1-0.1,0-0.2,0c-0.1,0.1,0.3,0.1,0.4,0.1c0.1,0,0.1,0.1,0.3,0.1l0.1,0c0,0-0.1,0-0.1,0c-0.1,0-0.1-0.1-0.1-0.1
	c-0.1,0-0.2,0-0.4-0.1c0.4,0.1,0.8,0.2,1.2,0.2c-0.1,0.1-0.1,0.1-0.1,0.2c0.1,0,0.1,0,0.3,0c0.1,0-0.1-0.1,0-0.1l0.2,0.1
	c0-0.1-0.1-0.1-0.3-0.1c0.1,0,0.5,0.1,0.7,0.1c0,0-0.1,0-0.1,0c0.1,0,0.1,0,0.2,0c0,0,0,0-0.1-0.1c0.2,0,0.5,0.1,0.8,0.1
	c-0.1,0-0.1,0,0,0.1c0.1,0,0.2,0,0.1,0c0,0,0.2,0,0.3,0.1C20.7,16.7,20.7,16.8,20.8,16.9z M17.6,16.3c0-0.1-0.3-0.2-0.3-0.1
	C17.3,16.2,17.4,16.2,17.6,16.3z M18.4,16.4c-0.1-0.1-0.2-0.1-0.1-0.2c-0.2-0.1-0.1,0.1-0.2,0.1c-0.1-0.1-0.4-0.2-0.4-0.1
	c0.1,0.1,0.1,0,0.2,0C18.1,16.3,18.2,16.5,18.4,16.4z M19.3,16.6c-0.2-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.3-0.1-0.2,0
	c0,0,0.2,0.1,0.2,0.1C19.1,16.5,19.2,16.6,19.3,16.6z M19.2,16.7c0,0,0.1,0.1,0.2,0.1C19.4,16.7,19.3,16.7,19.2,16.7z M19.6,16.6
	c-0.1,0-0.1,0-0.2,0C19.4,16.6,19.5,16.6,19.6,16.6L19.6,16.6z M19.7,16.6c0,0-0.2,0.1,0,0.1C19.8,16.7,19.9,16.6,19.7,16.6z
	 M20.4,16.7C20.4,16.7,20.4,16.7,20.4,16.7c-0.1,0,0,0.1,0.1,0.1C20.5,16.7,20.3,16.7,20.4,16.7z M3.7,8.7c0-0.1,0.1-0.3,0.2-0.2
	C3.9,8.6,3.8,8.6,3.7,8.7C3.7,8.8,3.8,8.7,3.7,8.7z M3.9,8.6c0.1-0.1,0,0.2-0.1,0.2C3.8,8.8,3.9,8.7,3.9,8.6z M3.6,9.8
	c0,0-0.1-0.3,0-0.5c0-0.1,0-0.1,0-0.2c0,0,0,0,0,0c0,0,0,0,0,0c0.1,0.1-0.2,0.5,0,0.5C3.7,9.7,3.6,9.7,3.6,9.8z M4,8.4
	c0.1-0.1,0.1-0.2,0.2-0.3C4.2,8.1,4.1,8.3,4,8.4C4,8.4,3.9,8.5,4,8.4c-0.1,0-0.1,0.2-0.1,0.1c-0.1,0,0-0.2,0.1-0.2
	C4,8.3,4,8.3,4,8.4z M3.5,9.3C3.6,9,3.6,8.9,3.7,8.7c0.1,0.1,0,0.2,0,0.4c-0.1,0,0-0.2,0-0.2C3.6,9,3.6,9.2,3.5,9.3z M9.4,15
	C9.4,15,9.5,15,9.4,15c0.1-0.1,0.2,0,0.2,0.1L9.4,15z M4.3,7.9C4.3,7.9,4.2,7.9,4.1,8.1c0,0,0.1-0.1,0-0.2C4.2,7.9,4.3,7.8,4.3,7.9z
	 M5.8,7.1c0,0-0.3,0.3-0.4,0.3c-0.1,0,0.1,0,0.1-0.1C5.6,7.2,5.7,7.1,5.8,7.1z M3.5,9.7c0,0.3,0.1,0,0.1,0.3c-0.1,0-0.1-0.1-0.1-0.1
	c0-0.2,0-0.4,0.1-0.4C3.5,9.6,3.5,9.8,3.5,9.7z M5.2,7.5C5.2,7.6,4.9,7.7,4.8,7.9C4.9,7.7,5.1,7.6,5.2,7.5z M4.7,7.7
	c-0.1,0-0.1,0.1-0.2,0.1c0,0,0.1-0.1,0.1-0.2C4.7,7.6,4.7,7.6,4.7,7.7z M5.6,7c0,0,0.1,0,0.2,0c0,0.1-0.3,0.1-0.4,0.3
	c0,0-0.1,0-0.1,0c-0.1,0.1,0.1,0,0,0.1c0,0-0.1,0-0.2,0.1c-0.1,0,0.1-0.1,0-0.1c0,0,0.1-0.2,0.2-0.2c0.1,0,0-0.1,0.1-0.1
	c0,0,0,0.1-0.1,0.1c0,0,0.1,0,0.1,0C5.5,7.1,5.5,7,5.7,6.9C5.6,7,5.8,6.9,5.8,6.8c0,0,0,0.1,0,0.1C5.7,7,5.7,6.9,5.6,7z M4.5,8.1
	c-0.1,0-0.1,0.1-0.2,0.2c0,0-0.1,0-0.1,0.1c0,0,0,0.1-0.1,0.1C4,8.8,3.8,9.1,3.8,9.5c-0.1,0,0-0.2,0-0.3c0-0.1,0-0.2,0.1-0.2
	c0,0,0,0,0,0c0-0.1,0.1-0.1,0.1-0.1C4,8.5,4.2,8.4,4.3,8.2C4.4,8.2,4.4,8.1,4.5,8.1z M9.8,15.1c-0.1-0.1,0.3,0,0.4,0.1
	C10.1,15.2,9.8,15.1,9.8,15.1z M10.2,15.7c-0.1,0-0.2-0.1-0.2-0.1C9.9,15.5,10.2,15.7,10.2,15.7c0,0,0.3,0,0.4,0.1
	c0.1,0,0.2,0.1,0.3,0.1c0,0,0.1,0.1,0.2,0.1c-0.2,0-0.3-0.1-0.5-0.2C10.4,15.8,10.3,15.8,10.2,15.7z M9.9,15.3c0,0.1-0.2,0-0.1,0.1
	c-0.1,0-0.1-0.1-0.1-0.1C9.7,15.2,9.7,15.2,9.9,15.3z M9.7,15.2c-0.1,0,0,0.1-0.2,0c0-0.1,0.2,0,0-0.1C9.6,15.2,9.7,15.2,9.7,15.2z
	 M9.7,15.6c0.1,0,0.1,0.1,0.3,0.1C10.1,15.7,9.6,15.6,9.7,15.6z M4.3,11.5c0.1,0.1,0,0.1,0.1,0.2c-0.1,0.1-0.1-0.2-0.2-0.1
	C4.2,11.5,4.3,11.5,4.3,11.5z M7.9,13.9c0.2,0,0.5,0.2,0.7,0.3c0.2,0.1,0.3,0,0.4,0.1c0,0.1-0.2,0-0.3-0.1c-0.1,0-0.2,0-0.2,0
	c-0.1,0-0.1,0-0.1,0c0,0-0.2,0-0.2-0.1C8.1,14,8.1,14,7.9,13.9z M10.4,15.7c0,0-0.1,0-0.2,0C10.2,15.6,10.4,15.6,10.4,15.7z
	 M10.9,15.6c0.1,0,0.2,0.1,0.2,0.1c-0.2,0.1-0.5-0.1-0.8-0.2c-0.1,0,0,0-0.1,0c-0.1-0.1-0.2-0.1-0.4-0.1c-0.1,0-0.1-0.1-0.2-0.1
	c0.2,0,0.3,0.1,0.5,0.1c0.1,0,0.1-0.1,0.2,0c0,0-0.1,0-0.1,0C10.6,15.5,10.9,15.7,10.9,15.6z M10.3,14.9c0.1-0.1,0.2,0,0.4,0.1
	c0.1,0,0.4,0,0.3,0.1C10.8,15,10.4,15,10.3,14.9z M36.2,17.5c0.2-0.1,0.5-0.2,0.8-0.3C36.8,17.3,36.4,17.4,36.2,17.5z M8.2,4.7
	c0,0.1-0.2,0.1-0.3,0.1C7.9,4.7,8.1,4.7,8.2,4.7z M7.9,4.9l0.4-0.1C8.3,4.8,7.9,4.9,7.9,4.9z M7.9,4.7c0-0.1,0.1-0.1,0.2-0.1
	C8.1,4.6,8,4.7,7.9,4.7z M10.6,4c0,0,0.2-0.1,0.3-0.1C10.9,4,10.8,4,10.6,4z M7.8,4.5C7.5,4.7,7.3,4.7,7.1,4.8c0-0.1-0.2,0.1-0.2,0
	c0.2,0,0.3-0.1,0.4-0.1c0,0,0,0,0,0c0,0,0.2-0.1,0.2-0.1c0.1,0,0,0,0-0.1c0.1,0,0.2-0.1,0.4-0.1c0,0,0,0,0,0c0-0.1,0.1,0,0.2,0
	c0,0-0.2,0.1-0.1,0.1c-0.1,0.1-0.2,0-0.2,0C7.8,4.5,7.7,4.5,7.8,4.5z M7,5.2C7,5.1,7.3,5.1,7.5,5C7.4,5.1,7.2,5.1,7,5.2z M11.3,3.6
	c0,0,0.1,0,0.1,0c0,0,0.1-0.1,0.1-0.1c0.1,0,0.2,0,0.3-0.1c-0.1,0.1,0,0.1-0.1,0.1c-0.2,0,0,0,0-0.1c-0.2,0-0.1,0.1-0.4,0.2
	c0-0.1-0.1,0-0.3,0C11,3.6,11.2,3.6,11.3,3.6C11.4,3.6,11.4,3.6,11.3,3.6z M7,4.9C7,5,6.8,5,6.7,5.1C6.7,5,6.9,4.9,7,4.9z M7.4,4.5
	c0,0,0.2-0.1,0.2-0.1C7.6,4.5,7.5,4.5,7.4,4.5z M12.1,3.4c0.1,0,0.1-0.1,0.3-0.1C12.4,3.3,12.3,3.3,12.1,3.4z M13.4,3.1
	c0,0-0.1,0-0.1,0c0.1,0,0.1-0.1,0.3-0.1C13.5,3.1,13.4,3.1,13.4,3.1z M6.8,5.3C6.3,5.5,6.2,5.6,5.5,5.8c-0.1,0,0.1-0.1,0.2-0.1
	C6.1,5.6,6.6,5.4,6.8,5.3C6.9,5.2,6.9,5.3,6.8,5.3z M15.3,2.8c0.2,0,0.2,0,0.1,0.1C15.3,2.9,15.3,2.8,15.3,2.8z M17.3,2.3
	c0.1,0,0.2,0,0.2,0C17.4,2.4,17.3,2.4,17.3,2.3z M15.5,2.5c0.3-0.1,0.6-0.1,0.9-0.1c0.1,0,0.1-0.1,0.3-0.1c0,0,0,0,0,0
	c0.2,0,0.3-0.1,0.5-0.1c-0.1,0.1,0.1,0.1,0.2,0.1c-0.1,0.1-0.4,0-0.5,0c0,0.1-0.2,0.2-0.4,0.2c0,0,0.2,0,0.2-0.1c0-0.1-0.2,0-0.2,0
	c0,0-0.2,0.1-0.3,0l0,0c-0.1,0-0.1,0-0.2,0.1c-0.1,0-0.1,0-0.1,0c-0.1,0-0.1,0.1-0.2,0c0,0-0.1,0-0.1,0C15.6,2.6,15.8,2.5,15.5,2.5z
	 M15.7,2.6c0.2,0,0.4,0.1,0.4-0.1C16,2.5,15.7,2.5,15.7,2.6z M12,3.7c0,0.1-0.2,0.1-0.3,0.1C11.7,3.7,11.9,3.7,12,3.7z M12.1,3.6
	c-0.1,0.1-0.4,0.1-0.5,0.1C11.6,3.7,11.9,3.6,12.1,3.6z M18.3,2.8c0.1-0.1,0.2,0,0.4,0C18.8,2.8,18.4,2.8,18.3,2.8z M16.7,2.9
	c0.2-0.1,0.4-0.1,0.6-0.1c0.2,0,0.9-0.1,0.5,0C17.6,2.9,17.1,2.9,16.7,2.9z M31.6,3.5c0.1,0.1-0.3,0-0.5,0c0.1,0,0.3-0.1,0.4,0
	C31.5,3.5,31.6,3.5,31.6,3.5z M32.3,3.6c-0.2,0-0.3,0-0.5,0C31.9,3.5,32.3,3.5,32.3,3.6z M32.6,3.7C32.7,3.7,32.6,3.8,32.6,3.7
	c-0.1,0.1-0.1,0-0.2,0C32.4,3.7,32.6,3.8,32.6,3.7z M5.1,6C5,6.1,4.6,6.3,4.5,6.4C4.7,6.2,5,6.1,5.1,6z M17.5,2.5
	c0-0.2,0.4-0.2,0.7-0.2c0.1,0,0,0,0,0c0.1-0.1,0.3,0,0.4,0c0.1,0,0,0,0,0c0.1,0,0.4,0,0.5,0c0.1,0,0.3,0,0.2-0.1c0.2,0,0.4,0,0.6,0
	c0.1,0,0.1,0.1,0.3,0.1c0.1,0-0.3,0-0.1-0.1c0.1,0,0.1,0,0.1,0c0.3,0,0.7,0,1,0c0.1,0,0.1,0,0.1,0.1c0.1-0.1,0.3-0.1,0.6-0.1
	c0-0.1,0.2,0,0.4-0.1c0,0-0.1,0-0.1,0c0.1,0.1,0.3,0,0.4,0c0.1,0,0,0,0,0c0-0.1,0.1,0,0.1,0c0,0,0.2,0,0.2,0c0.1,0,0.1,0,0.2,0
	c0.1,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2-0.1c0.1,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0.2,0,0.3,0,0.4,0c0,0-0.1,0-0.1,0c0.2,0,0.4,0,0.6,0
	c0,0,0.2,0,0.2,0c0,0,0.1,0,0.2,0c0.1,0,0,0,0,0c0.1,0,0.2,0,0.3,0c0,0-0.1,0-0.2,0c0.2,0-0.1,0.1,0,0.1c0,0,0,0,0.1,0
	c0.1,0,0,0,0-0.1c0,0,0.1,0,0.2,0l0.1,0c0,0,0.2,0.1,0.2,0c0.1,0,0.1,0,0.1,0c0.1,0,0.1,0,0.1,0c0.2,0,0.3,0.1,0.5,0
	c0.1,0,0.2,0.1,0.4,0.1c0,0,0,0-0.1,0c0.1,0,0.2,0,0.3,0c0.1,0,0-0.1,0.1-0.1c0.1,0,0.2,0,0.3,0c0,0,0.1,0,0.2,0c0.3,0,1,0,1.5,0.1
	c0.1,0,0.3,0,0.3,0c0.1,0,0.1,0,0.1,0c0.5,0,0.9,0,1.3,0.1c-0.2,0-0.4,0-0.5,0c-0.1,0.1,0.2,0,0.3,0c0.1,0,0.1,0,0.1,0
	c0.1,0,0.2,0,0.2,0c0.2,0.1,0.5,0.1,0.7,0c0.4,0,0.7,0,1,0.1c0.3-0.1,0.8,0,1.1,0c0.1-0.1,0.5,0,0.8,0c0.2,0,0.5,0,0.7,0
	c0.3,0,0.6,0,0.8,0.1c0.3,0,0.5,0.1,0.7,0.1c0.1,0.1,0.2,0,0.4,0.1c0.1,0,0.1,0,0.1,0c0.3,0,0.6,0.1,0.9,0.1c0.2,0,0.3,0.1,0.5,0.1
	c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0.1,0.3,0.1c0.2,0,0.3,0,0.5,0.1c0,0,0,0,0.1,0c0.1,0,0.2,0.1,0.4,0.1c0.1,0.1,0.2,0.2,0.5,0.2
	c0.1,0.1-0.2,0-0.2,0.1c0,0.1,0.2,0.1,0.3,0.1c0.1,0,0,0,0.1-0.1c0.2,0,0.1,0.1,0.2,0.2c0.2,0.1,0.1,0,0.3,0.1c0,0,0,0.1,0,0.1
	c0.1,0.1,0.2,0,0.3,0.1c0,0-0.1,0-0.1,0c0.2,0.1,0.3,0.1,0.4,0.2c0.1,0,0,0,0,0c0.1,0,0.4,0.2,0.5,0.3c0.1,0,0.1,0,0.1,0
	c0.1,0,0.2,0.1,0.3,0.1c0,0,0,0.1,0.1,0.1c0,0,0.1,0,0.1,0.1c0,0,0-0.1,0-0.1c0.1,0.1,0,0,0,0.1c0.1,0.1,0.1,0,0.2,0.1
	C43.5,4.9,43.5,5,43.7,5c0,0-0.1-0.1-0.1-0.1c-0.1-0.1,0-0.1,0-0.1c0.1,0.1,0.2,0.1,0.3,0.2c0,0-0.2-0.1-0.2-0.1
	c0.1,0.1,0.5,0.3,0.6,0.3c0.1,0.1,0.4,0.3,0.3,0.3c0.1,0.1,0.1,0.1,0.3,0.2c0.1,0.1,0,0-0.1-0.1c0,0.1,0.2,0.3,0,0.2
	c0,0.1,0.2,0.2,0.3,0.2c0-0.1-0.1-0.1-0.2-0.2c0.1,0,0-0.1,0.2,0c0.2,0.2-0.1,0-0.1,0.1c0.1,0.1,0.2,0.1,0.2,0.2
	c0.1,0.1,0-0.1,0.1-0.1c0,0.1,0.2,0.2,0.3,0.4c0,0.1-0.1,0,0,0.1c0.1,0.1,0.1,0.1,0.1,0.1c0,0-0.1-0.1-0.1-0.1
	c0-0.1,0.1,0.1,0.2,0.2c0-0.1-0.1-0.2-0.2-0.3c0.1,0,0.2,0.3,0.4,0.5c0,0-0.1-0.1-0.1-0.1c0.2,0.3,0.1,0.3,0.2,0.4c0,0,0,0,0.1,0.1
	c0-0.1,0-0.2,0-0.3c0,0.1,0.1,0.2,0,0.2c0.1,0.1,0.1,0.2,0.2,0.2c0,0.1,0,0.1,0,0.1c0.1,0.1,0.1,0,0.2,0.3c0,0.1,0.1,0.2,0,0.2
	c0.1,0.2,0,0,0.1,0.1l-0.1-0.1c0.1,0,0.1,0.4,0.1,0.3c0.1,0.2,0.1,0.3,0.1,0.5c0-0.1-0.1-0.6,0-0.4c0,0.1,0,0.1,0,0.3c0,0,0,0,0,0.1
	c0,0,0-0.1,0.1-0.1c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0.1,0.3,0.1,0.4c0,0-0.1-0.1-0.1,0c0.1,0.2,0.1,0.6,0.1,0.6c0,0.1,0.1,0,0.1-0.1
	c0.1,0.3-0.1,0.6,0,0.9c0,0.2-0.1,0.1-0.1,0.3c0,0.2,0,0.1,0,0.2c-0.1,0.2-0.1,0.4-0.2,0.6c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2
	c0,0,0.1,0,0,0.1c-0.1,0-0.1-0.2-0.1,0c0,0.1,0.1-0.1,0.1,0c-0.1,0.2-0.1,0.3-0.1,0.5c-0.1,0.2-0.2,0.3-0.3,0.5
	c-0.1,0.1,0.1,0,0,0.2c-0.1,0.1,0,0-0.1,0c0,0-0.1,0.1-0.1,0.1c-0.1,0.2,0,0.1,0,0.2c-0.1,0.1-0.2,0.3-0.3,0.5c-0.1,0.1,0,0,0-0.1
	c-0.1,0-0.2,0.3-0.3,0.4c0-0.1-0.1,0,0-0.2c0,0-0.1,0.1-0.1,0.1c-0.1,0.1,0,0.1,0.1,0.1c-0.1,0.1,0,0.1-0.2,0.3c0,0,0,0.1,0,0.1
	c-0.2,0.2-0.4,0.4-0.6,0.6c0,0,0,0,0,0.1c-0.2,0.1-0.3,0.2-0.4,0.3c0,0,0,0,0,0c0,0-0.1,0.1-0.2,0.2c0,0,0,0.1-0.1,0.1
	c0,0.1,0.1-0.1,0.1,0c-0.1,0.2-0.2,0-0.3,0.2c0,0,0-0.1,0-0.1c-0.1,0.1-0.2,0.2-0.3,0.2c0,0,0,0.1,0,0.1c-0.3,0.1-0.4,0.3-0.6,0.4
	c0,0,0.1-0.1,0.1-0.1c-0.1,0-0.4,0.3-0.1,0.2c0,0-0.2,0.1-0.3,0.2c0,0-0.1,0-0.1,0c0,0,0,0,0,0c-0.1,0.1-0.4,0.2-0.5,0.3
	c-0.1,0.1-0.1,0-0.3,0.1c0,0,0.1,0,0.1,0c0,0.1-0.2,0.1-0.3,0.1c-0.1,0,0.1,0-0.1,0.1c-0.1,0.1-0.1,0-0.1,0
	c-0.1,0.1-0.2,0.2-0.4,0.2c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.1,0.1c-0.1,0-0.3,0.1-0.5,0.2c-0.1,0-0.2,0.1-0.3,0.1
	c-0.1,0-0.2,0.1-0.3,0.1c0,0-0.1,0-0.2,0.1c0,0,0,0.1,0,0.1c0,0-0.3,0-0.4,0.2c-0.2,0.1-0.3,0.1-0.5,0.2c-0.1,0-0.2,0.1-0.2,0.1
	c-0.1,0-0.1,0-0.1,0c-0.1,0-0.1,0.1-0.2,0.1l-0.1,0.1c-0.7,0.2-1.3,0.4-1.9,0.6c0,0-0.1,0-0.1,0c-0.4,0.2-0.9,0.3-1.3,0.4
	c-0.1,0,0,0.1-0.2,0.1c0-0.1-0.1-0.1-0.2-0.1c0,0.1,0.1,0,0.1,0.1c-0.1,0-0.2,0-0.2,0c-0.1,0-0.1,0.1-0.1,0.1
	c-0.3,0.1-0.4,0.1-0.8,0.1c0.1-0.1,0.1-0.1,0.1-0.2c-0.1,0-0.1,0-0.1,0.1c-0.1,0-0.1-0.1-0.2-0.1c-0.2,0.1-0.4,0-0.5,0.1
	c-0.1,0-0.1,0-0.1,0c0,0,0,0-0.1,0c-0.2,0-0.2,0-0.3,0c0,0-0.1-0.1-0.2,0c-0.1,0.1,0.4,0,0.1,0c-0.1,0-0.2,0-0.2,0
	c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.1,0-0.2,0c-0.4,0-0.8,0.1-1,0.1c-0.1,0,0,0,0,0.1c-0.2,0-0.6,0-0.8,0.1c-0.1,0-0.1,0-0.2,0
	c-0.1,0-0.1,0-0.1,0.1c-0.1,0-0.1,0-0.1,0c0,0-0.2,0-0.2,0c-0.1,0-0.1,0-0.3,0c0,0,0.1,0,0.2,0c0.1,0-0.3,0-0.4,0.1
	c0.1-0.1-0.1,0,0-0.1c0,0-0.1,0.1-0.2,0c-0.1,0,0.1,0.1,0,0.1c-0.2,0,0,0-0.1,0c-0.1,0-0.1,0.1-0.1,0.1c0,0-0.2,0-0.2,0.1
	c-0.2,0-0.4,0-0.6,0c0-0.1-0.1-0.1,0-0.1c-0.2,0-0.6,0-0.8,0.1c0,0,0,0,0,0c-0.4,0.1-0.8,0-1,0.1c-0.2,0-0.5,0-0.5,0.1
	c-0.1,0-0.2,0-0.4,0.1c-0.1,0-0.2,0-0.3,0c0,0-0.1,0-0.1,0.1c-0.2,0-0.4,0.1-0.6,0.1c-0.1,0-0.4,0-0.7,0.1c-0.1,0-0.2,0.1-0.4,0.1
	c-0.2,0-0.3,0-0.5,0.1c0-0.1-0.1-0.1-0.1-0.1c0.2,0,0.1-0.1,0.2-0.1c0,0-0.1,0-0.3,0c0.1-0.1,0.1-0.2-0.1-0.2c0,0-0.1,0-0.1,0.1
	c-0.1,0-0.3,0-0.5,0.1c-0.2,0-0.2,0.1-0.4,0.1c-0.1,0.1-0.5,0.2-0.8,0.3c-0.1,0-0.1,0.1-0.2,0.1c-0.2,0.1-0.5,0.1-0.7,0.2
	c0-0.1-0.1,0-0.2-0.1c-0.2,0-0.3-0.1-0.5,0c0.1-0.1,0-0.1-0.2-0.1c0.1-0.1-0.1-0.1-0.2-0.2c0.1,0,0.1,0,0.1-0.1c0,0-0.1,0-0.2,0
	c0.1-0.1,0-0.1,0.1-0.1c0,0,0,0-0.1,0c0.1,0,0-0.1,0.1-0.1c0,0,0.1,0,0.1,0c0,0,0-0.1,0-0.1c0,0,0.1,0,0.1,0c0.1,0,0.1-0.1,0.1-0.1
	c0.2,0,0.2-0.1,0.3-0.1c0,0,0,0,0-0.1c0.2,0,0.6-0.1,0.8-0.1l0.3-0.1c0-0.1-0.2-0.1-0.2-0.2c0.3-0.1-0.1-0.1,0.1-0.2l0.2,0
	c0,0-0.1,0-0.1-0.1c0.2,0,0.3-0.1,0.5-0.1c0,0,0.1,0,0.2,0c0.2,0,0,0,0.1-0.1l0.2,0l0,0.1c0.2-0.1,0.3,0,0.4-0.1c0,0-0.3,0.1-0.2,0
	c0.5-0.1,0.9-0.2,1.4-0.2c0.1,0,0.1,0,0.1-0.1c0,0-0.1,0-0.1-0.1c0.2-0.1,0.2-0.1,0.4-0.1c0-0.1-0.1,0-0.2,0c-0.1,0,0.1,0,0.1,0
	c0.3,0,0.8-0.2,1-0.1c0.1,0,0.1-0.1,0.4-0.1c0,0-0.1,0-0.1,0.1c0,0,0,0,0,0c0.1,0,0.2,0,0.2-0.1c0.3,0,0.6,0,0.8-0.1
	c-0.2,0.1,0.2,0,0.2,0c0.3,0,0.6-0.1,0.9-0.1c0.7-0.1,1.5-0.1,2-0.2c0.1,0,0,0,0,0.1c0.1,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2-0.1
	c0.1,0,0.2,0,0.3,0c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.1,0,0.1,0c0.1,0,0.1-0.1,0.3-0.1c0,0-0.1,0-0.1,0c0.1,0,0.2,0,0.3,0
	c0,0,0.1,0,0.1,0c0.3,0,0.7-0.1,0.9-0.1c0,0,0.1,0,0.1-0.1c0.2,0.1,0.5,0,0.7-0.1l0,0.1c0.1,0,0.2,0,0.3-0.1c0.1,0,0,0.1,0,0.1
	c0.2,0,0.2-0.1,0.3-0.1c0.2,0,0.5,0,0.6-0.1c0,0,0.1,0,0.2,0c0.6-0.2,1.3-0.3,2.1-0.4c0.1,0,0.2-0.1,0.3-0.1c0.2,0,0.3,0,0.5-0.1
	l0,0.1c0.1-0.1,0.4-0.2,0.6-0.2c0,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.1,0.1-0.2,0.1c0,0,0.1,0,0.2,0c-0.1,0.1,0,0.1-0.1,0.2
	c-0.1,0.1-0.4,0.1-0.6,0.2c-0.1,0-0.2,0-0.2,0c0,0-0.1,0.1-0.2,0.1c0,0,0.1,0,0.2,0c-0.1,0.1-0.4,0.1-0.5,0.1c0-0.1,0-0.1-0.1-0.1
	c-0.1,0-0.1,0.1-0.3,0.1c0,0,0,0,0,0c0,0.1,0.4-0.1,0.3,0c-0.2,0-0.4,0.1-0.5,0.1c-0.2,0,0,0.1-0.1,0.1c0.1,0,0.2-0.1,0.4-0.1
	c0,0,0,0.1-0.1,0.1c0,0,0.1,0,0.1,0c0.2-0.1-0.1,0-0.1-0.1c0.1,0,0.1,0,0.1-0.1c0.1,0,0.2,0,0.3,0c0,0-0.1,0-0.2,0.1
	c0.1,0.1,0.2-0.1,0.3-0.1c0.2,0,0.2,0,0.3,0c0.1,0,0-0.1,0.2-0.1c0.1,0,0.1,0,0.1,0.1c0.1,0,0-0.1,0.1-0.1c0.4-0.1,1-0.2,1.5-0.4
	c0.1,0,0-0.1,0.1-0.1c0,0-0.1,0-0.2,0c0.1-0.1-0.1-0.1-0.1-0.1l0.1,0l0.1,0c0,0.1,0,0.1,0.2,0.1c0-0.1-0.2,0-0.1-0.1
	c0.2,0,0.3-0.1,0.4-0.1c-0.1,0-0.2,0.1-0.3,0.1c-0.2,0-0.3,0-0.4,0c0.1,0,0.1-0.1,0-0.1c0.1-0.1,0.3-0.1,0.4-0.2c0.1,0,0.1,0,0.2,0
	c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0c0.1,0,0.2-0.1,0.4-0.2c0.3-0.1,0.8-0.3,1-0.4c0.1,0,0.2,0,0.2-0.1c0.1-0.1,0.1,0,0.2,0
	c0.1,0,0.1,0,0.1-0.1c0.1-0.1,0.1,0,0.2,0c0.2-0.1,0.2-0.1,0.3-0.1c0,0,0,0,0-0.1c0.1,0,0.3-0.1,0.5-0.2c0,0,0-0.1,0.1-0.1
	c0.4-0.2,1.5-0.7,2.1-1.3c0.1-0.1,0.1-0.2,0.2-0.2c0.2-0.2,0.4-0.3,0.6-0.5c-0.2,0.1-0.1,0.1,0-0.1c-0.1,0.1,0.6-0.6,0.8-0.9
	c0-0.1,0.1,0,0.1-0.1c0,0,0.1-0.2,0.1-0.2c0,0,0.1-0.1,0.1-0.2c0-0.1,0-0.2,0.1-0.3c0,0,0.1-0.1,0.1-0.1c0-0.1,0-0.1,0-0.2
	c0,0,0-0.1,0.1-0.1c0-0.1,0-0.2,0.1-0.3c0-0.1,0.2-0.5,0.2-0.6c0,0,0,0,0-0.1c0,0,0,0,0.1,0c0-0.1,0-0.1,0-0.2c0,0,0,0,0,0
	c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0,0,0-0.2,0-0.3c0-0.1,0-0.1,0-0.2c0-0.2-0.1-0.5-0.1-0.7c0-0.1-0.1-0.2-0.1-0.3
	c0-0.1,0-0.1,0-0.1c0-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.1-0.1-0.2c0-0.1-0.1-0.2-0.1-0.2c0,0-0.1-0.1-0.1-0.1c0,0,0-0.1-0.1-0.1
	c0-0.1-0.1-0.1-0.1-0.1c0-0.1-0.1-0.1-0.1-0.2C44.3,7.4,44,7.2,43.9,7c-0.1,0-0.3-0.2-0.5-0.3c0,0-0.1-0.1-0.1-0.1
	c-0.4-0.2-0.8-0.4-1.1-0.6c-0.1,0-0.1-0.1-0.2-0.1c0,0,0.1,0,0.1,0.1c-0.1,0-0.5-0.2-0.4-0.2c0,0-0.1,0-0.3-0.1
	c-0.1,0-0.2,0-0.4-0.1c0,0,0.1,0,0.1,0c0,0-0.1-0.1,0.1,0c0-0.1-0.1,0-0.2-0.1c0,0,0.1,0,0.1-0.1c-0.1-0.1-0.1,0-0.2-0.1
	c0.1-0.1-0.1-0.1-0.2-0.1c0,0.1,0.2,0.2,0.2,0.2c-0.1,0-0.2,0-0.2,0c0,0,0-0.1-0.1-0.1c-0.1,0-0.1,0,0,0.1c-0.2,0-0.2-0.1-0.2-0.2
	c-0.1,0-0.3,0-0.3,0.1c-0.1,0-0.1,0-0.1,0C39.9,5.1,39.7,5,39.7,5C39.9,5,40,5,39.9,5.1l0.2,0.1C40.2,5.1,39.9,5,40,5
	c0.1,0,0.1,0,0.2,0.1C40.2,5,40,5,40,4.9c-0.1,0-0.2,0-0.3-0.1c0-0.1,0.3,0,0.3-0.1c0.1,0,0.2,0.1,0.4,0.1c-0.1,0,0,0,0,0.1
	c0.2,0.1,0.1-0.1,0.3,0c0.1,0,0,0,0,0.1c0.1,0,0.1,0,0.1,0c0.2,0,0.1,0.1,0.3,0.2c0.1,0-0.1-0.1,0-0.1c0,0,0.2,0.1,0.2,0.1
	c0.1,0,0-0.1-0.1-0.1c0.1,0,0.3,0.1,0.4,0.1c0-0.1-0.2,0-0.3-0.1c0,0-0.2-0.1,0-0.1c-0.2-0.1-0.2,0-0.3-0.1c-0.1-0.1,0-0.1-0.2-0.1
	c-0.1,0-0.1,0-0.2,0c-0.1-0.1-0.2-0.1-0.3-0.2c-0.2,0,0.1,0,0,0.1c-0.2,0-0.5-0.2-0.6-0.2c-0.1,0-0.1-0.1-0.3-0.1
	c0.1-0.1,0.2,0.1,0.3,0.1c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2,0,0.1,0.1,0.2,0.2c0,0.1-0.3-0.1-0.3,0c-0.1,0-0.1,0-0.1-0.1
	c-0.1,0-0.3,0-0.5-0.1c0,0,0.2,0,0.2,0c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.1,0-0.1,0.1c-0.1,0-0.3-0.1-0.3-0.1c0-0.1,0.2,0.1,0.3,0
	c-0.1-0.1-0.3,0-0.4-0.1c0,0,0,0,0,0.1l-0.4-0.1c-0.1-0.1,0.3,0.1,0.3,0c-0.1-0.1-0.3,0-0.5-0.1c0.2,0.1-0.2,0-0.4,0
	c-0.1-0.1,0.2,0,0.3,0c0-0.1-0.3-0.1-0.4-0.1c-0.1-0.1-0.4-0.1-0.6-0.1c0.1,0,0.3,0,0.4,0.1c0,0-0.2,0-0.2,0c-0.2,0-0.3-0.1-0.4,0
	c-0.2-0.1-0.4-0.1-0.8-0.1c0,0,0,0-0.1,0c-0.1,0-0.1,0-0.1-0.1c-0.3,0-0.5-0.1-0.8,0c-0.1,0,0,0,0.1-0.1c-0.1,0-0.1,0-0.1,0
	c-0.1,0-0.1,0.1,0,0.1c-0.1,0.1-0.3,0-0.5,0c0,0,0,0.1,0,0.1c-0.1,0-0.2,0-0.4,0c0.1,0,0-0.1-0.1-0.1c0-0.1,0.1,0,0.2,0
	c0,0,0-0.1-0.1-0.1c-0.2,0,0,0.1-0.1,0.1c-0.2,0-0.2,0-0.5-0.1l0-0.1c-0.2,0-0.3,0.1-0.5,0c0.1,0-0.1,0-0.1-0.1c0,0,0-0.1-0.1-0.1
	c-0.2,0,0.2,0.1,0.1,0.1c-0.2,0-0.1-0.1-0.3-0.1c-0.1,0,0,0,0,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.3,0-0.4,0c-0.1,0-0.2,0-0.3,0
	c0,0,0,0,0-0.1c-0.1,0-0.2,0.1-0.3,0c0,0,0.1,0,0.1-0.1c-0.2,0-0.1,0-0.3,0c0,0.1,0.2,0.1,0.2,0.1c0,0-0.2,0-0.2,0c0,0,0.1,0,0,0
	c-0.1,0-0.2-0.1-0.4-0.1c-0.1,0,0.1,0,0,0.1c-0.1,0-0.3,0-0.4-0.1l0-0.1c-0.1,0-0.1,0-0.2,0c-0.2,0-0.2-0.1-0.3,0
	c0.1,0,0.3,0.1,0,0.1c0.1,0,0.3,0,0.5,0c0,0.1-0.2,0-0.3,0c-0.1,0-0.3,0-0.4,0l0-0.1c-0.2,0-0.2,0-0.2,0.1l-0.3,0c0,0,0.1,0,0.1-0.1
	c-0.2,0-0.3,0-0.5,0c0,0,0,0,0,0c-0.5,0-0.4,0-0.9,0c0,0.1-0.2,0.1-0.4,0.1c0,0.1,0.3,0,0.4,0c-0.1,0-0.3,0-0.5,0c0,0,0,0,0.1,0
	c-0.2,0,0-0.1-0.1-0.1c-0.2,0,0.1,0.1-0.1,0.1c-0.2,0-0.1-0.1-0.3-0.1c-0.1,0,0.1,0.1-0.1,0.1c-0.1,0-0.4,0-0.3,0
	c-0.2,0-0.4,0-0.6,0c-0.1,0.1,0.2,0.1,0.3,0.1c0,0.1-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0c0-0.1,0.1-0.1,0.2-0.1c0,0-0.1,0-0.2,0
	c0,0,0.1-0.1,0-0.1c-0.1,0,0,0.1-0.1,0.1c-0.1,0,0.2,0.1,0,0.1c-0.1,0-0.1,0,0,0c-0.2,0-0.3,0-0.5,0c0-0.1,0.2,0,0.2-0.1
	c-0.1-0.1-0.1,0-0.3,0c-0.1,0,0,0.1,0,0.1c0,0.1-0.2,0-0.1,0.1c-0.2,0-0.3,0-0.4,0c-0.1,0.1-0.5,0-0.9,0c-0.2,0-0.2,0-0.4,0
	c0,0,0,0-0.1,0c-0.6,0-0.9,0.1-1.4,0c0,0,0,0-0.1,0c-0.1,0-0.1,0-0.2,0c0-0.1,0.1-0.1,0.1-0.1c0.1,0,0.3,0.1,0.4,0
	c0-0.1-0.2,0-0.3,0c0,0,0-0.1,0-0.1c-0.1,0-0.2,0-0.3,0c-0.1,0.1,0.1,0,0.1,0.1c-0.1,0-0.3,0.1-0.1,0.1c0,0-0.4,0.1-0.4,0
	c-0.2,0.1-0.6,0-0.9,0.1c-0.4,0-0.9,0.1-1.3,0.1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.2,0c-0.1,0-0.1,0.1-0.2,0.1
	c-0.1-0.1,0.1,0,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0.1,0,0.3,0,0.2,0c0,0-0.1,0-0.1,0c0,0,0-0.1-0.2,0c0,0,0.2,0,0.1,0
	c-0.2,0-0.5,0-0.7,0c0,0.1,0.3,0,0.4,0c0.1,0.1-0.1,0.1-0.1,0.1c-0.4,0.1-0.8,0.1-1.2,0.1c-0.3,0.1-0.6,0.1-0.8,0.1
	C15.6,4,15.6,4,15.8,4c0,0-0.2,0-0.2,0.1c0,0.1,0.1,0,0.2,0c0.1,0,0.3,0,0.5,0c0,0,0,0,0.1,0c0.2,0,0.2,0,0.3,0c0.1,0-0.1,0,0-0.1
	c0.3,0,0.6-0.1,0.9-0.1c-0.1,0.1,0.3,0,0.3,0.1c0.1,0,0-0.1,0.1-0.1c0,0-0.1,0-0.1,0c-0.1,0,0.1,0,0.1,0c0.1,0,0.2,0,0.3,0
	c0-0.1-0.1,0-0.2,0c0-0.1,0.1,0,0.2,0c0.2,0,0.5-0.1,0.7-0.1c0,0-0.1,0-0.1,0c0.1,0.1,0.3,0,0.3-0.1c0.4,0,0.4,0.1,0.7,0
	c0-0.1-0.2,0-0.3,0c0.3-0.1,0.8,0,1.1-0.1c0,0,0.1,0,0.1,0c0.1,0,0,0,0,0c0.1,0,0.2,0,0.3,0c0.2,0,0.2,0.1,0.4,0c0.1,0,0,0,0-0.1
	c0.4,0,0.8-0.1,1.1,0c0.1,0,0,0,0,0c0.2,0,0.6,0,0.7,0c0.1,0,0.2,0,0.3-0.1c0.4,0,0.9,0.1,1.3,0.1c0.1,0.1,0.2,0.1,0.2,0.2
	c0.4,0,0.6,0,0.9,0c0,0,0.1,0,0.1-0.1c0.1,0,0.2,0,0.2,0c0.1,0,0-0.1-0.1-0.1c-0.2,0-0.3,0.1-0.4,0c0.1,0,0.2-0.1,0.4,0
	c0,0,0,0-0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.1,0c0.1,0,0.1-0.1,0.2,0c0,0.1,0.2,0.1,0.3,0.1
	c0.1,0-0.1-0.1,0.1-0.1c0.1,0,0.2,0,0.1,0.1c0.1-0.1,0.3,0.1,0.3,0c0-0.1-0.2,0-0.2,0c0-0.1,0.2,0,0.3,0c0,0,0.1,0,0.1,0
	c0.2,0,0.6,0,0.7,0.1c0.1,0,0.3,0,0.5,0c0.1,0,0.2,0,0.3,0c-0.2-0.1-0.4,0-0.5,0c0.1-0.1,0.3,0,0.4,0c0.1,0,0.3,0.1,0.5,0
	c0,0.1-0.1,0-0.1,0.1c0.1,0,0.2,0,0.4-0.1c0.1,0.1,0.2,0,0.4,0.1c0,0-0.3,0-0.2-0.1c0.1,0,0.3,0,0.4,0c0.1,0,0.3,0,0.3,0.1
	c0.1,0,0.2,0,0.1-0.1c0.1,0,0.2,0.1,0.2,0c0.2,0,0.2,0.1,0.3,0.1c0.1,0-0.1,0,0-0.1c0.3,0.1,0.6,0,0.9,0.1c0,0,0.1,0,0.1,0
	c-0.1,0.1,0.1,0,0.1,0.1c0.1,0,0.4,0,0.3-0.1c0-0.1,0.1,0,0.1,0c0.1,0-0.1-0.1,0.1-0.1c0.4,0,0.8,0,1.2,0c0.1,0,0.1,0,0.1,0
	c0.1,0,0.2,0,0.4,0c0,0,0.1,0,0.2,0c0.2,0,0.3,0,0.5,0c0.5,0,0.9,0.1,1.1,0.2c0,0.1,0,0.1,0.1,0.2c0,0-0.1,0-0.2,0
	c0.1,0-0.1,0.1,0.1,0.2c-0.2,0-0.2,0.1-0.2,0.1c0,0-0.2,0-0.1,0c-0.4-0.1-0.9-0.1-1.4-0.2c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.4,0
	c-0.1,0-0.1,0-0.2,0c-0.2,0-0.3,0.1-0.4,0c0,0,0,0-0.1,0c-0.1,0-0.2,0-0.4,0c-0.1,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.3,0
	c-0.1,0-0.1,0-0.2,0c-0.1,0-0.3,0-0.5,0c-0.1,0-0.1,0-0.1,0c-0.3,0-0.6,0-1,0c-0.1,0,0.1-0.1-0.1-0.1c-0.1,0,0.1,0.1-0.1,0.1
	c-0.4,0-0.5,0-0.8,0c0,0,0.1,0,0.1,0c-0.1-0.1-0.2,0-0.2,0c-0.2,0,0.1,0-0.1-0.1c-0.1,0-0.1,0-0.1,0.1l-0.3,0c-0.1,0,0,0,0-0.1
	l-0.1,0c-0.1,0,0.2,0.1-0.1,0.1c-0.2,0,0,0,0-0.1c-0.1,0-0.1,0-0.2,0c-0.1,0,0,0.1-0.1,0.1l-0.3,0c0,0,0.1,0,0.1-0.1
	c-0.1,0-0.2,0-0.2,0.1c-0.3,0-0.4,0-0.4-0.1c-0.1,0-0.2,0.1-0.2,0c-0.1,0,0.1,0.1-0.1,0.1c0,0,0,0,0.1,0c0,0-0.4,0-0.3,0
	c-0.1,0-0.1,0.1-0.3,0l0-0.1c0,0-0.1,0-0.2,0.1c-0.1,0-0.2,0-0.2-0.1c0,0.1-0.3,0-0.4,0c0,0,0.1,0,0.1,0c-0.2,0.1-0.6,0-0.9,0
	c-0.1-0.1-0.1-0.1-0.3-0.2C24.9,4,25,4,24.8,4c0.1,0.1-0.1,0.1,0,0.1c-0.1,0-0.3,0-0.2,0.1c-0.5,0-1.2,0-1.7,0c0-0.1,0.3,0,0.4,0
	c0.2,0,0.3,0,0.4,0c-0.1,0-0.2,0-0.3-0.1c-0.2,0-0.1,0.1-0.3,0.1l0,0c-0.2,0-0.3,0-0.4-0.1c0,0,0,0-0.1,0l-0.3,0c-0.1,0,0,0-0.1-0.1
	c-0.2,0,0,0-0.1,0.1c-0.1,0-0.3,0-0.2,0.1c-0.3,0-0.7,0-0.9,0c0.1,0,0.5,0,0.7,0c0.1,0,0.1,0,0.1-0.1c-0.1,0-0.2,0-0.2,0.1
	c-0.2,0-0.3,0-0.5,0c0.1,0,0,0,0-0.1c-0.5,0-0.8,0.1-1.2,0.1c-0.1,0,0.1-0.1,0-0.1c-0.2,0,0.1,0.1-0.2,0.1c-0.1,0,0.1-0.1,0.2-0.1
	c0-0.1-0.3,0-0.2,0.1c-0.1,0-0.2,0-0.1,0c-0.9,0.1-2.1,0.1-3,0.3c0,0,0,0,0,0c-0.2,0-0.2,0-0.5,0.1c0-0.1,0.2,0,0-0.1
	c-0.1,0-0.2,0-0.2,0c-0.1,0,0.2,0,0.2-0.1c-0.1,0-0.4,0-0.5,0c0,0.1-0.2,0.1-0.3,0.2c-0.1,0-0.2,0-0.2,0c-0.2,0.1-0.5,0.1-0.9,0.2
	c0,0-0.1,0-0.1-0.1c-0.1,0-0.1,0-0.1,0.1c-0.2,0-0.4,0.1-0.7,0.1c-0.4,0.1-0.8,0.1-1.1,0.2C12.2,5,12.1,5.1,12,5
	c-0.1,0-0.1,0-0.1,0.1c-0.1,0-0.2,0-0.2,0c-0.3,0.2-0.8,0.2-1.3,0.4c-0.1,0,0.1-0.1,0-0.1c-0.2,0.1-0.1,0.2-0.4,0.2
	c0,0,0.1-0.1,0.1-0.1c-0.2,0-0.1,0.1-0.3,0.1c0,0,0.2-0.1,0.2-0.1c-0.1,0-0.2,0.1-0.4,0.2c0,0,0-0.1,0.2-0.1c-0.1,0-0.1,0.1-0.3,0.1
	c0,0-0.2,0.1-0.2,0C9.1,5.7,9,5.8,8.8,5.8c0,0,0-0.1,0.1-0.1c0.1,0,0.1,0,0.2,0c0-0.1-0.3,0-0.3,0.1c0,0,0.1-0.1,0.1-0.1
	c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.2,0-0.2,0C8.6,5.7,9,5.4,9.2,5.5c0.1,0-0.1,0,0-0.1c0.2,0,0.4-0.1,0.6-0.1c0,0,0,0,0,0
	c0.3-0.1,0.4-0.1,0.6-0.2c0.1,0,0.6-0.1,0.7-0.2c0.1,0,0.3,0,0.4,0c0-0.1-0.2,0-0.3,0c0-0.1,0.1,0,0.2-0.1c0.1,0,0-0.1,0-0.1
	c-0.3,0.1-0.3,0.1-0.5,0.2C10.5,4.9,10.2,5,9.8,5.1C9.9,5.1,9.9,5,10,5c0,0-0.2,0.1-0.3,0c0,0,0,0.1,0.1,0c0,0.1-0.2,0.1-0.3,0.1
	c0,0,0-0.1-0.1-0.1c-0.2,0,0,0.1-0.2,0.1c0.1-0.1,0.1-0.1-0.1-0.1c-0.1,0,0,0,0.1,0c0,0-0.1,0-0.1,0.1C9,5.2,8.9,5.3,9,5.2
	c-0.2,0-0.2,0.1-0.4,0.2c-0.2,0.1-0.2,0-0.3,0C8.2,5.4,8,5.5,8.1,5.5C7.9,5.6,7.8,5.7,7.8,5.8c-0.1,0.1-0.1,0-0.2,0.1
	c0,0,0-0.1,0.1-0.1c0-0.1-0.1,0-0.2,0C7.4,5.8,7.4,5.8,7.2,5.8c-0.1,0,0.1-0.1,0.1-0.1c0,0-0.1,0.1-0.1,0.1C7.1,5.8,7,5.9,6.8,5.9
	c0,0,0,0.1,0.1,0.1c0,0,0.2-0.1,0.3-0.1c0,0.1,0.2,0,0.3,0.1C7,6.1,6.1,6.5,5.7,6.6c0-0.1,0.1-0.1,0.1-0.1C6.2,6.4,6.7,6.2,7.1,6
	C7,6,6.8,6.1,6.7,6.1c-0.1,0,0,0.1-0.2,0.1c-0.1,0-0.1,0-0.1,0c-0.1,0-0.1,0.1-0.1,0.1c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.1,0.1
	C5.9,6.5,5.7,6.5,5.6,6.6c-0.1,0-0.1,0-0.1,0c-0.1,0.1,0.1,0,0,0.1c-0.1,0.1-0.1,0-0.2,0c-0.1,0,0,0.1,0,0.1C5.1,7,4.8,7.3,4.6,7.4
	c0,0-0.1,0-0.1,0c0,0-0.1,0.1-0.2,0.2C4.2,7.7,4.1,7.8,4.1,7.8c0,0-0.1,0.1-0.1,0.1C3.9,8,3.6,8.4,3.5,8.7c-0.1,0,0-0.1,0-0.1
	c0.1-0.2,0.1-0.2,0.2-0.4c0-0.1,0-0.1,0-0.2C3.6,8.1,3.6,8.3,3.5,8.4c-0.1,0-0.1,0.3-0.2,0.2c0,0.1,0.1,0,0.1,0.1
	c0,0.1-0.1,0.1-0.1,0.2c0,0.1,0.1-0.1,0.1-0.1c0,0.1,0,0.3-0.1,0.4c0,0.1,0,0.2-0.1,0.3c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.1
	c0,0.1-0.1,0.2,0,0.2c0,0.1,0,0.1,0,0.1c0,0.1,0.1,0.1,0.1,0.2c0,0,0-0.1,0-0.2c0,0,0,0.1-0.1,0.2c0,0.1,0,0.2,0.1,0.2
	c0,0,0,0.3,0.1,0.2c0,0-0.1-0.2,0-0.3c0,0,0.1,0.1,0.1,0.2c0.1,0,0-0.2,0.1-0.3c0-0.2,0-0.2,0-0.3c0,0-0.1,0.2,0,0.2
	c0,0,0-0.1,0-0.1c0-0.1,0.1,0.2,0.1-0.1c0.1,0,0,0.3,0.1,0.3c0-0.1,0-0.2,0-0.4c0-0.1-0.1,0-0.1,0c0-0.1,0.1-0.1,0.1-0.1
	c0-0.1,0-0.2,0-0.2c0.1,0.1,0,0.4,0.1,0.6c0,0,0-0.1-0.1-0.1c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0,0,0c0.1,0.1,0.1,0.3,0.2,0.3
	c0,0.1,0,0.1,0,0.2c0,0.2,0.1,0.2,0.1,0.3c0,0-0.1,0-0.1-0.1c-0.1,0.1,0.1,0.4,0,0.4c0.1,0.1,0.1-0.1,0.1-0.1c0,0,0.1,0.1,0.1,0.2
	c-0.1-0.1-0.1,0.1-0.1,0.1c0.1,0.1,0.2,0.2,0.4,0.4c0.1,0.1,0,0.1,0,0.1c0,0,0,0,0.1,0c0.1,0,0-0.1,0.1,0c0.1,0,0,0.1,0,0.1
	c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0,0.1,0.2,0.2c0,0-0.1-0.1-0.1-0.1c0.1,0,0.5,0.3,0.7,0.4c0.1,0.1,0,0.1,0.1,0.2c0.1,0,0,0,0,0
	c0.2,0.1,0.4,0.2,0.6,0.3c0,0,0,0.1,0.2,0.2c0,0-0.1-0.1,0-0.1c0.1,0,0,0.1,0.1,0.1c0.1,0.1,0.2,0,0.3,0.1c-0.2,0-0.3-0.1-0.5-0.2
	c-0.1,0-0.1,0-0.2-0.1c0.2,0-0.3-0.2-0.3-0.2l-0.1,0c-0.1-0.1-0.1-0.1-0.1-0.2c-0.1,0-0.1-0.1-0.3-0.2c-0.1,0.1,0.2,0.2,0,0.2
	c-0.1-0.1-0.1-0.2-0.1-0.2c-0.1-0.1-0.2-0.1-0.1,0c0,0-0.1,0-0.1-0.1c-0.1-0.1-0.2-0.1-0.2-0.2c0,0-0.1,0,0,0.1
	c-0.1,0-0.1-0.1-0.2-0.1c0,0,0,0,0,0.1c0.1,0,0.2,0.1,0.2,0.1c0.2,0.1,0.1,0.1,0.2,0.2c0,0-0.1,0-0.1,0c0,0,0.1,0,0.1,0
	c0,0,0,0.1,0.1,0.1c0.1,0,0.1,0,0.2,0.1c0.1,0,0.1,0,0.1,0C5.8,13,5.7,13,5.8,13.1c0.1,0.1,0.1,0,0.2,0c0.1,0.1,0.1,0.1,0.1,0.2
	c0.1,0,0.3,0.1,0.5,0.2c-0.1,0,0.1,0.1,0.2,0.1C7,13.7,7,13.6,7.2,13.7c0,0-0.1,0-0.1,0c0,0,0.2,0.1,0.2,0.1c0,0,0-0.1-0.1-0.1
	c-0.1-0.1-0.2-0.1-0.1-0.1l-0.3-0.2c0.1,0,0.2,0.1,0.4,0.2c0.2,0.1,0.3,0.1,0.6,0.3c-0.1,0,0,0.1,0,0.1c0.2,0.1,0.3,0.1,0.5,0.2
	c0,0-0.1,0-0.2,0c0.1,0.1,0.2,0,0.3,0c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0-0.1-0.1-0.1c0.1-0.1,0.1,0.1,0.2,0.1c0.2,0,0.1,0,0.2-0.1
	c0.2,0.1-0.1,0.1,0.1,0.1c0,0,0.2,0.1,0.2,0c0.1,0.1,0.4,0.1,0.2,0.1c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0,0,0,0.1c0.1,0.1,0.1,0,0.2,0
	c0,0.1,0.1,0,0.3,0.1c0,0-0.1,0-0.2,0c-0.8-0.3-1.1-0.3-1.8-0.6c-0.1,0,0,0,0,0.1c-0.2,0-0.3-0.1-0.4-0.1c0,0-0.1-0.1-0.1-0.1
	c0,0-0.1,0-0.1,0c-0.1,0,0.1,0-0.1-0.1C7.7,14,7.9,14.2,8,14.2c-0.1-0.1-0.4-0.3-0.7-0.4c0,0,0.1,0,0.1,0c-0.2-0.1-0.1,0-0.1,0.1
	c0.2,0.1,0.2,0,0.3,0.1c-0.1,0.1-0.3,0.1,0,0.2c-0.3-0.1-0.8-0.3-1.2-0.5c-0.1,0-0.4-0.2-0.6-0.3c-0.1,0-0.1,0-0.2,0
	c-0.1-0.1,0-0.1,0-0.2c-0.2-0.2-0.1-0.1-0.4-0.2c0,0,0,0.1,0,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0.2-0.2,0-0.4-0.1l0.1,0
	c0,0-0.2-0.1-0.2-0.1c-0.2-0.1-0.6-0.4-0.8-0.7c-0.2-0.2-0.3-0.4-0.4-0.6c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.1-0.3-0.2-0.4
	c-0.1,0,0,0.2,0,0.3c0.1,0.2,0.2,0.5,0.1,0.5c0.1,0,0.2,0.1,0.3,0.3c0.1,0,0-0.1,0.1-0.1c0.1,0.2,0,0.2-0.1,0.1c0,0,0.1,0.2,0.2,0.3
	c0.1,0.1,0-0.1,0.1,0c0,0-0.2-0.1-0.2-0.2C4,12,4,12,4.1,12c0.2,0.3,0.4,0.5,0.7,0.8c0.1,0.1-0.2-0.2-0.2-0.1c0,0,0.1,0.1,0.2,0.2
	C4.9,13,4.8,12.9,5,13c-0.1,0,0,0.1,0.1,0.1c0.1,0,0.1-0.1,0.1,0c0,0.1,0.1,0.2,0.2,0.2c0-0.1-0.1-0.1-0.1-0.2
	c0.1,0.1,0.1,0.1,0.1,0.2c0,0,0,0,0.1,0c0.1,0-0.1-0.1-0.1-0.1c0-0.1,0.1,0.1,0.1,0.1c0.1,0,0.1,0,0.2,0.1c0.2,0.1,0.4,0.2,0.6,0.4
	c-0.1,0-0.2-0.1-0.3-0.2c0,0,0.1,0.1,0.2,0.2c0,0,0.1,0.1,0.1,0.2c0.1,0-0.1-0.1,0-0.1c0.2,0.1,0.1,0.2,0.2,0.3
	c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0,0.1,0,0.1,0c0.2,0.1,0.1,0.2,0.4,0.2c-0.1,0.1,0.1,0.1,0,0.2c-0.1-0.1-0.3-0.1-0.4-0.1
	c0,0,0.1,0.1,0.2,0.1c0,0-0.1,0-0.1,0C7,14.6,7,14.6,7,14.6c-0.1,0-0.1,0-0.1,0c0-0.1,0-0.1,0.1,0c-0.1-0.1,0-0.1,0.1-0.1
	c0-0.1-0.2,0-0.3-0.1c0,0,0-0.1,0-0.1c-0.1-0.1-0.1,0.1-0.2,0c0,0,0.1,0.1,0.1,0.1c-0.1,0-0.2-0.1-0.2-0.1c-0.1,0-0.1,0-0.3-0.1
	c0.1,0,0.1-0.1-0.1-0.2c-0.1-0.1,0.1,0,0-0.1c-0.1,0-0.1,0-0.2-0.1c0,0,0,0-0.1-0.1c0,0,0.1-0.1,0.1-0.1c-0.1,0-0.1,0-0.2-0.1
	c0-0.1,0.1,0,0.1-0.1c-0.1-0.1-0.1,0-0.2,0c-0.1,0,0-0.1-0.1-0.1c0,0-0.1,0.1-0.2,0c0,0.1,0.1,0,0.2,0.1c-0.1,0,0.1,0.1,0.1,0.1
	c0,0,0,0,0,0c0,0,0.1,0.1,0,0.1c-0.1,0-0.1,0-0.2-0.1c0,0,0.1,0,0.1,0c-0.1-0.1-0.2-0.1-0.2-0.1c0,0.1,0.2,0.2,0.3,0.2
	c0,0.1-0.1,0-0.2-0.1c0,0.1,0.1,0.1-0.1,0.1c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.2,0,0.2,0.1c-0.1,0.1-0.4-0.2-0.6-0.3
	c-0.1-0.1-0.2-0.1-0.4-0.2c0,0,0.1,0.1,0.2,0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.1-0.1-0.1-0.1c-0.1,0-0.1,0-0.2-0.1
	c-0.1,0-0.1-0.1-0.1-0.1C4.2,13.1,4,13,4,12.8c0.1,0,0.2,0.1,0.4,0.3c0-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.1,0-0.1,0
	C4,12.7,4,12.7,3.9,12.7c-0.1-0.1-0.1-0.2-0.2-0.1c0,0,0.1,0.1,0.1,0.2c-0.2-0.1-0.3-0.4-0.5-0.6c0,0-0.1-0.1-0.1-0.1
	c-0.1-0.2-0.2-0.4-0.3-0.6c-0.1-0.3-0.2-0.7-0.2-1c0,0,0,0,0,0c0,0-0.1-0.1,0-0.2c0.1,0.1,0-0.1,0-0.3c0-0.1,0,0-0.1,0
	c0-0.2,0-0.5,0.1-0.5c0-0.1,0-0.1,0-0.3c0.1,0,0.1,0,0,0.2c0.1,0,0.1-0.1,0.1-0.3c0,0-0.1,0-0.1,0c0.1-0.6,0.4-1.1,0.8-1.6
	C4.1,7,4.5,6.7,5,6.4c0.5-0.3,1-0.5,1.5-0.7C7,5.5,7.5,5.3,8,5.2C8.3,5,8.6,4.9,8.9,4.8C9,4.8,9,4.8,9,4.8c0.2,0,0.5-0.1,0.7-0.2
	c0.1,0,0.3-0.1,0.4-0.1c0.2-0.1,0.8-0.2,1.2-0.3c0.2-0.1,0.5-0.2,0.8-0.2c0.2-0.1,0.4-0.1,0.6-0.1c0,0-0.1,0-0.1,0
	c0.3,0,0.6-0.1,1-0.2c0,0-0.1,0-0.1,0.1c0.2,0,0.1-0.1,0.2-0.1c0.1,0,0,0.1,0,0.1c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.4-0.1
	c0.1,0,0.2,0,0.2,0c0.1,0,0.3-0.1,0.5-0.1c0.1,0,0.1,0,0.1,0c0.1,0,0.1-0.1,0.2,0c0.2,0,0.5-0.1,0.7-0.2c0.1,0,0.2,0,0.2,0
	c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0.2,0,0.5,0,0.7,0c0.1,0,0.1,0,0.2-0.1c0,0,0.1,0,0.1,0c0.1,0,0.3,0,0.4-0.1
	c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.2,0c0.3,0,0.7-0.1,1-0.1c0.1,0,0.1,0,0.1,0c0.3,0,0.6-0.1,0.8-0.1c0.1,0,0.1,0,0.1,0
	c0.2,0,0.5-0.1,0.7-0.1c0,0,0.1,0,0.1,0.1c0.1,0,0,0,0-0.1c0.3,0,0.5-0.1,0.7,0c0.2,0,0.1,0,0.4,0c0.1,0,0.1,0,0.1,0.1
	c0.2,0,0.1,0,0.1-0.1c0.2,0,0.2,0,0.4,0c0.1,0,0-0.1,0.1-0.1c0.2,0,0.5,0,0.9,0c0.1,0,0.2,0,0.3,0c0.3,0,0.8-0.1,1,0
	c0,0,0.1,0,0.1-0.1c0.3,0.1,0.5,0,0.8,0c0.1-0.1,0.2,0,0.4,0c0,0-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.1,0
	c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.3,0-0.5,0c-0.1,0-0.1,0-0.1,0c0,0-0.2,0-0.3,0c-0.2,0-0.2,0-0.4,0
	c-0.2,0-0.4,0-0.6,0c0,0,0,0,0-0.1c-0.1,0-0.3,0-0.3-0.1c-0.1,0,0,0,0,0.1c0,0,0.2,0,0.2,0c-0.1,0.1-0.5,0-0.7,0
	c-0.1,0-0.1,0.1-0.2,0c-0.1,0,0,0,0.1-0.1c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0-0.1,0-0.1,0c0.1,0,0.3,0,0.5,0c0.1-0.1-0.4,0-0.3-0.1
	c-0.3,0-0.3,0.1-0.6,0.1c-0.1,0.1,0.2,0,0.2,0.1c-0.2,0-0.4,0-0.5,0c0.1,0,0.3,0,0.2-0.1c-0.1,0,0,0.1-0.2,0.1c0-0.1-0.3-0.1-0.4,0
	c0,0,0.1,0,0.3,0c0,0.1-0.2,0-0.4,0c0.1-0.1-0.1-0.1-0.2-0.1c-0.1,0.1,0.2,0.1,0,0.1c-0.1,0-0.1-0.1-0.3-0.1c-0.1,0,0.1,0,0.1,0
	c-0.4,0.1-1.1,0.1-1.7,0.1c-0.1,0-0.3,0-0.3,0c-0.3,0-0.8,0-1,0c0.1-0.1,0.3-0.1,0.5-0.1c0.3,0,0.5,0,0.7-0.1c0-0.1-0.1,0,0-0.1
	c-0.2,0-0.2,0-0.3,0c0.1,0,0.1,0,0.2,0c-0.1,0.1-0.3,0-0.5,0c0,0,0.2,0,0.1,0c-1.1,0-2.5,0.1-3.6,0.3c0,0-0.2,0-0.2,0
	c-0.1,0-0.2,0-0.3,0.1c-0.2,0-0.3,0-0.5,0c0,0-0.2,0-0.2,0c-0.1,0,0,0,0,0c-0.8,0.2-1.7,0.2-2.3,0.5c-0.1,0,0,0,0,0
	c-0.2,0.1-0.3,0.1-0.5,0.1c0,0,0.2-0.1,0.2-0.1c-0.1,0-0.3,0.1-0.4,0.1c0-0.1,0.2,0,0.2-0.1c0.1,0,0.2,0,0.3,0c0,0,0,0,0.1,0
	c0,0,0-0.1-0.1-0.1c0.1-0.1,0.2-0.1,0.2-0.2c-0.1-0.1-0.3,0.1-0.4,0c0-0.1,0.1,0,0.2-0.1c0,0,0,0,0,0c0.2-0.1,0.2-0.1,0.5-0.1
	c0.1,0,0.1,0.1,0,0.2c0,0,0.2,0,0.2,0c0.1,0-0.1,0.1-0.1,0.1c0.1,0,0.1,0,0.1-0.1c0.1,0,0.1,0,0.3,0c-0.1,0.1-0.3,0-0.4,0.1
	c-0.2,0-0.4,0-0.5,0.1c0,0.1,0.4-0.1,0.2,0c0.2-0.1,0.3-0.1,0.4-0.1c0.1,0,0.2,0,0.2,0c0.2-0.1,0.6-0.1,0.9-0.2
	C14.4,3.1,14.7,3,14.8,3c0.1,0,0.2-0.1,0.4-0.1c0,0-0.1,0,0,0.1c0.1,0,0.1-0.1,0.2-0.1c0.2,0,0.4,0,0.5-0.1c0,0-0.1,0-0.2,0
	c0.1,0,0.3-0.1,0.4-0.1c0.2,0,0.3,0,0.5,0c0.1,0,0,0,0-0.1c0.2,0,0.2,0,0.5,0c0,0,0,0,0.1,0c0,0,0,0-0.1,0c0.1,0,0.3,0,0.4,0
	c0.1,0-0.1,0,0-0.1c0.2,0,0.1,0.1,0.3,0.1C17.6,2.5,17.6,2.5,17.5,2.5c0.3,0.1,0.3,0,0.4,0c0,0,0,0.1,0,0.1c0,0,0.1-0.1,0,0
	c0-0.1,0.3,0,0.2-0.1c-0.1,0-0.4,0.1-0.4,0c-0.1,0,0,0.1,0,0.1L17.5,2.5z M12.7,3.1c0,0.1,0.1,0.1,0.2,0.1l0-0.1L12.7,3.1z
	 M12.7,3.3l0.1,0c-0.1,0-0.1-0.1-0.3-0.1C12.5,3.3,12.8,3.2,12.7,3.3z M16.2,2.8c-0.1,0,0-0.1-0.3,0c0,0.1,0.1,0,0.1,0.1
	C16,2.8,16.2,2.8,16.2,2.8z M6.4,14.1c0.1,0,0.3,0.1,0.3,0C6.5,14.1,6.6,14.1,6.4,14.1c0.1,0,0.2-0.1,0.1-0.1L6.4,14.1z M4.6,13
	L4.6,13c-0.1,0-0.2-0.1-0.2,0C4.5,13.1,4.5,13,4.6,13z M4.6,13.2c-0.1,0-0.1,0-0.3,0C4.4,13.2,4.7,13.3,4.6,13.2z M5.3,13.5
	c0,0-0.2,0,0,0.1c0-0.1,0.1,0,0.2,0C5.4,13.6,5.4,13.6,5.3,13.5z M5.3,13.4c0,0,0.1,0,0.1,0c0,0-0.1-0.1-0.1-0.1
	C5.3,13.3,5.3,13.4,5.3,13.4z M5,13.5C5,13.5,4.9,13.5,5,13.5c0,0.1,0,0.1,0.1,0.2C5.1,13.6,5.1,13.5,5,13.5z M4.5,12.8
	c0.1,0.1,0.2,0.2,0.4,0.3c0,0,0,0.1,0,0.1c0,0-0.1,0-0.1,0c0.1,0.1,0,0.1,0.2,0.2c0.1,0,0.1,0,0.2,0.1c0-0.1,0-0.2-0.2-0.3
	c0,0,0,0.1,0.1,0.1c0,0,0.1,0.1,0,0.1C5,13.2,4.9,13.1,5,13.1C4.8,13,4.8,13,4.5,12.8z M4.7,12.5c0.1,0.1,0.1,0.1,0.2,0.1
	C4.7,12.5,4.8,12.5,4.7,12.5L4.7,12.5z M4.4,11.7c0,0,0.1,0.3,0.2,0.2C4.5,11.9,4.4,11.7,4.4,11.7z M4,11.5c0.1,0.2,0.1,0.3,0.2,0.5
	c0.1,0,0-0.2-0.1-0.2C4.1,11.6,4.1,11.5,4,11.5z M3.8,11c0,0.1,0.2,0.3,0.2,0.4C4,11.3,3.9,11.1,3.8,11z M4,10.8
	c0,0.2,0,0.4,0.2,0.5C4,11.1,4,10.9,4,10.8z M3.9,12.5C3.9,12.5,3.9,12.5,3.9,12.5c-0.1,0-0.1,0.1,0,0.2C4,12.7,4,12.6,3.9,12.5z
	 M4.2,12.3c-0.1,0,0.1,0.2,0.2,0.3c-0.1,0-0.1,0.1,0.1,0.2C4.5,12.7,4.2,12.4,4.2,12.3z M4,12.4c0.1,0.1,0.1,0.1,0.1,0.2
	c0.1,0.1,0.1,0.1,0.1,0.1c-0.1-0.1-0.1-0.2,0-0.1C4.2,12.5,4,12.3,4,12.4z M3.8,12.5c-0.1-0.1,0-0.1-0.1-0.3c-0.1,0,0,0.1,0,0.1
	c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0.1,0.2C3.7,12.3,3.7,12.5,3.8,12.5z M3.4,11.5c0.1-0.1,0.1,0.3,0.2,0.2c0.1,0.1,0.1,0.2,0.2,0.2
	c0,0.1,0.1,0.3,0.2,0.5l0.1,0c-0.1-0.2-0.2-0.3-0.1-0.2c-0.1-0.2-0.2-0.3-0.3-0.5C3.5,11.7,3.3,11.2,3.4,11.5z M2.9,11.1
	c0.1,0.2,0.2,0.3,0.2,0.4c0,0.2,0.1,0.4,0.3,0.6c0,0,0.1,0,0.1-0.1c-0.1-0.1-0.1-0.2-0.2-0.3c0,0.1,0.1,0.1,0.1,0.2
	c-0.1-0.1-0.1-0.3-0.1-0.4c0.1,0.2,0.1,0,0.2,0.2c0,0.1,0,0.1,0.1,0.2c0,0,0-0.1,0-0.2c0,0,0.1,0.2,0.1,0.1
	c-0.1-0.1-0.2-0.1-0.2-0.3c0,0.1-0.1,0-0.1-0.1c0,0.1-0.1,0-0.1-0.1c0.1,0-0.1-0.3,0-0.4L2.9,11.1c0.1,0.1,0.2,0.2,0.1,0.3
	C3,11.3,3,11,2.9,11.1z M3.2,10.8c0-0.1,0-0.1,0-0.2C3.1,10.6,3.2,10.9,3.2,10.8z M3,10.6c0,0.2,0.1,0,0.1,0.1
	c0,0.1,0.1,0.3,0.1,0.3c0-0.2-0.1-0.3-0.1-0.4C3,10.7,3,10.6,3,10.6z M2.8,10.3c0,0.1,0,0.2,0,0.3c0,0,0-0.1,0-0.1
	C2.9,10.4,2.9,10.3,2.8,10.3z M2.8,10c0-0.2,0.1,0,0-0.2c0-0.1-0.1,0,0-0.2c-0.1-0.1,0,0.2-0.1,0.1c0-0.1,0.1-0.2,0-0.3
	c0,0.1-0.1,0.1-0.1,0.3c0,0.2,0.1,0.3,0.1,0.5c0,0,0.1,0,0.1,0c0,0.2,0-0.1,0-0.1C2.9,10.1,2.9,10,2.8,10C2.9,10,2.9,10.1,2.8,10z
	 M3,9.5c0,0.1,0,0.2,0,0.3C3,9.8,3,9.6,3,9.7c0,0.1,0,0.2,0,0.3c0,0.1,0,0,0.1,0C3.1,9.8,3.1,9.6,3,9.5C3,9.7,3,9.5,3,9.5z M2.9,9.3
	c0,0,0.2-0.1,0.1-0.2C3,9.1,3,9.2,3,9.1C3,9.1,3,8.9,3,8.9C2.9,9.1,2.9,9.2,2.9,9.3c0,0.1-0.1,0.4,0,0.2L2.9,9.3z M3.1,9.4
	c0-0.1,0.1-0.4,0.1-0.3C3.2,9.1,3.1,9.4,3.1,9.4z M3.3,9.1c0,0.1,0.1,0,0,0.2c0,0.1-0.1,0-0.1,0.1l0.1,0c0-0.2,0-0.2,0.1-0.3
	C3.3,9.1,3.3,9.2,3.3,9.1z M3.2,8.7C3.1,8.6,3,9.1,3.1,9C3.1,8.8,3.2,8.9,3.2,8.7z M3.4,8.6C3.3,8.7,3.3,8.8,3.2,8.9
	C3.3,9,3.4,8.7,3.4,8.6z M3.6,8.2C3.5,8.3,3.3,8.4,3.4,8.5C3.4,8.4,3.6,8.2,3.6,8.2z M3.5,7.9C3.5,8,3.3,8.1,3.3,8.1
	C3.4,8.1,3.6,8,3.5,7.9z M3.4,7.8C3.3,7.9,3.1,8.1,3.1,8.3c0,0.1,0.1-0.2,0.2-0.2c0,0.1,0,0.1,0.1,0c0,0-0.1,0-0.1-0.1
	C3.3,8,3.4,7.9,3.4,7.8z M4.7,6.7C4.8,6.6,4.9,6.7,5,6.6C5,6.5,4.7,6.7,4.7,6.7z M3.8,8C3.8,7.9,3.8,7.9,3.8,8c-0.1,0,0,0.1,0,0.1
	l0.1-0.1C3.9,7.9,3.8,8,3.8,8z M3.6,7.6c0,0-0.1,0-0.2,0.1c0,0,0,0.1,0,0.1L3.6,7.6z M4.3,7.5C4.1,7.6,4.1,7.7,4,7.8
	c0,0,0.1-0.1,0.1-0.2c-0.1,0-0.2,0.2-0.2,0.3c0.2-0.2,0.3-0.3,0.4-0.3C4.3,7.5,4.4,7.5,4.3,7.5z M4,7.3C4.4,7,3.9,7.4,3.9,7.5
	C4,7.4,4,7.4,4,7.3z M4.5,7L4.5,7C4.5,6.9,4.7,6.7,4.6,6.8C4.5,6.9,4.3,7,4.2,7C4.2,7.1,4.4,6.9,4.5,7C4.4,7.1,4.4,7.1,4.3,7.1
	c0-0.1-0.4,0.1-0.4,0.3C4,7.3,4.2,7.2,4.2,7.1c0.1,0-0.1,0.1,0,0.2C4.3,7.2,4.3,7.1,4.5,7z M5.2,6.8c-0.1,0.1-0.1,0-0.2,0.1
	C5,7,5.1,6.9,5.1,7c0,0,0,0-0.1,0.1C4.9,7,5,6.9,4.9,7C4.8,7,4.8,7,4.8,7.1c0,0,0,0,0,0C4.6,7.2,4.6,7.2,4.5,7.2
	c0.1,0.1,0,0.1,0.1,0.2c0.1-0.1,0.2-0.1,0.3-0.2c0,0,0,0,0.1-0.1C5,7,5.1,7.1,5.2,7C5.2,6.9,5.3,6.9,5.2,6.8z M5.1,6.7
	C5,6.8,5,6.7,4.9,6.9c0-0.1-0.1,0-0.1,0c-0.1,0-0.1,0.2,0,0.1c0,0,0.1-0.1,0.1-0.1l0,0C4.8,7,4.6,7,4.6,7.1c0.1,0,0.2-0.1,0.3-0.2
	c0.1,0,0.2-0.1,0.4-0.2C5.3,6.7,5.2,6.7,5.1,6.7z M5.5,6.4c-0.1,0-0.2,0.1-0.2,0.1L5.5,6.4c0.1-0.1,0.1-0.1,0.1-0.2
	C5.3,6.3,5.3,6.4,5.1,6.5c0,0,0.1,0,0.1,0c0,0-0.1,0.1-0.1,0.1c0,0,0.1,0,0.1,0c0,0-0.1,0.1,0.1,0C5.2,6.5,5.5,6.4,5.5,6.4z
	 M5.9,6.3c-0.1,0-0.3,0.1-0.4,0.2C5.6,6.6,5.8,6.4,5.9,6.3z M6,6.2C6,6.2,6.1,6.2,6,6.2c0.1,0,0.1,0,0.2,0c0,0,0.2-0.1,0.2-0.1
	c-0.1,0-0.2,0.1-0.3,0.1C6.3,6.1,6.2,6.1,6.3,6c0.1,0,0.2,0,0.3-0.1C6.5,5.9,6.2,6,6.1,6.1c0,0,0,0,0-0.1C6,6,6,6,5.9,6.1
	C5.8,6.2,6.1,6,6,6.2l-0.2,0C5.8,6.3,6,6.1,6,6.2C6.1,6.2,6.1,6.2,6,6.2C6,6.2,6,6.2,6,6.2z M6.7,6C6.5,6.1,6.6,6.1,6.6,6.1
	C6.4,6.2,6.2,6.2,6.1,6.3c0.1,0,0.1,0,0.1,0.1C6.3,6.2,6.5,6.2,6.7,6L6.7,6z M7.9,5.6C7.9,5.6,7.8,5.6,7.9,5.6c-0.2,0-0.2,0-0.2,0.1
	C7.7,5.7,7.8,5.6,7.9,5.6z M8.3,5.3C8.2,5.3,8,5.3,8,5.4C8.1,5.4,8.3,5.3,8.3,5.3z M9.2,4.8c-0.1,0-0.1,0-0.2,0.1
	C9,5,9.2,4.9,9.2,4.8z M9.3,5C9.2,5,8.9,5,8.8,5.1C9,5.1,9.2,5.1,9.3,5z M10.2,5c-0.1,0-0.1,0-0.2,0l0,0.1C10.1,5,10.2,5,10.2,5z
	 M10.5,4.8c-0.2,0-0.3,0.1-0.3,0.2c0,0,0.2,0,0.2-0.1c0,0-0.1,0,0,0C10.5,4.9,10.5,4.9,10.5,4.8z M11.1,4.7c0-0.1-0.2,0-0.3,0.1
	c-0.1,0-0.3,0-0.3,0.1C10.7,4.8,11,4.8,11.1,4.7z M11.4,4.4c-0.1,0-0.3,0-0.3,0.1C11.2,4.5,11.4,4.5,11.4,4.4z M9.2,5.5
	C9,5.6,9,5.5,8.8,5.6C8.8,5.7,9,5.6,9.2,5.5z M9.6,5.4c-0.1,0.1-0.2,0-0.3,0.1c0.2,0,0.1,0,0,0.1C9.5,5.5,9.6,5.5,9.6,5.4z
	 M10.2,5.4c-0.1,0,0-0.1-0.2-0.1C10,5.4,10.1,5.4,10.2,5.4z M10.3,5.3c-0.2,0,0.1-0.2-0.2-0.1C10.1,5.3,10.2,5.4,10.3,5.3z
	 M11.4,4.9C11.5,4.9,11.5,4.9,11.4,4.9c0.1,0,0.2,0,0.2,0C11.7,4.8,11.5,4.9,11.4,4.9z M11.8,5.1C11.8,5,11.8,5,11.8,5.1
	c0,0-0.2,0-0.2,0c0,0,0.1,0,0.1-0.1c-0.2,0-0.4,0.1-0.6,0.2c-0.1,0-0.2,0-0.3,0c0.1,0,0.4-0.2,0.3-0.2C11,5,10.7,5.1,10.7,5.2
	c0,0,0.1,0,0.1,0c-0.1,0-0.2,0.1-0.2,0.1c0,0,0.1,0,0.1-0.1c-0.1,0-0.2,0-0.2,0c0,0,0-0.1-0.1,0c0,0-0.1,0.1-0.1,0.1
	c0.1,0,0.1-0.1,0.2,0l-0.1,0.2l0.2-0.1c0-0.1-0.2,0-0.1-0.1c0,0,0.2-0.1,0.2,0c0,0-0.1,0-0.1,0c0.1,0,0.2-0.1,0.4-0.1
	c0.1,0,0.3,0,0.3-0.1c-0.2,0.1-0.3,0-0.4,0c0.1-0.1,0.3,0,0.4,0c0.1,0,0.3-0.1,0.4-0.1c0,0.1-0.3,0-0.2,0.1
	C11.6,5.1,11.6,5.1,11.8,5.1z M12.6,4.5c0,0-0.2-0.1-0.3,0c0.1,0,0.1,0,0.1,0c-0.1,0-0.2,0.1-0.3,0.1c0-0.1-0.1,0-0.1-0.1
	c-0.2,0-0.2,0.1-0.3,0.1c0,0,0,0,0,0.1C12,4.6,12.3,4.5,12.6,4.5z M13.8,4.1c-0.2,0-0.3,0.1-0.4,0.1c0,0,0.1,0,0.1-0.1
	c0,0-0.1,0-0.1,0l-0.1,0c0,0,0,0.1,0,0.1c-0.1-0.1-0.2,0-0.4,0.1l0-0.1c-0.2,0.1-0.5,0-0.5,0.1c0.2-0.1,0.3,0,0.2,0
	c0.2,0,0.3-0.1,0.4-0.1C13.3,4.2,13.7,4.3,13.8,4.1z M14.8,4.5c-0.1,0-0.2,0-0.2,0.1C14.7,4.6,14.7,4.6,14.8,4.5L14.8,4.5z M15,4.1
	c-0.1,0-0.2,0-0.3,0C14.7,4.2,15,4.2,15,4.1z M15,4C14.7,4,15.1,3.9,15,4c-0.3-0.1-0.4,0-0.6,0c-0.1,0,0.1,0,0,0.1
	c-0.1,0-0.2,0-0.4,0c-0.1,0-0.1,0.1-0.1,0.1c0.2,0,0.4-0.1,0.2-0.1c0.2,0,0.2,0,0.4,0C14.6,4,14.9,4,15,4z M15.1,3.9
	C15.1,3.9,15.1,3.9,15.1,3.9c0.1,0,0.1,0.1,0.2,0.1C15.4,3.9,15.2,3.8,15.1,3.9z M35.2,4.4c0.1,0,0.1,0,0.1,0
	C35.1,4.3,35.4,4.3,35.2,4.4C35.2,4.3,35.2,4.3,35.2,4.4z M33,3.8c0.2,0,0.3,0,0.5,0C33.4,3.8,33,3.8,33,3.8z M30.8,4.2
	c0.1,0,0.2,0,0.3,0C31.1,4.2,30.8,4.1,30.8,4.2z M30.1,4.2c0.1,0,0.3,0,0.4,0C30.4,4.2,30.1,4.1,30.1,4.2z M25.8,4
	C25.8,4,25.8,4,25.8,4L25.8,4C25.7,4,25.7,4,25.8,4z M23.7,3.6c0.2,0,0.1,0.1,0.3,0.1c0-0.1-0.2-0.1,0-0.1
	C23.9,3.6,23.8,3.6,23.7,3.6z M23,4.1c0.1,0,0.1,0,0.2,0C23.2,4.1,23.1,4.1,23,4.1C23.1,4,23.2,4,23.1,4C22.9,4,23,4,23,4.1z
	 M20.5,4.1c0.1,0,0.2,0,0.3,0C20.6,4.1,20.5,4,20.5,4.1z M19.3,3.8l0.2,0C19.6,3.7,19.3,3.7,19.3,3.8z M18.9,4.1
	c0.1,0,0.3,0,0.3-0.1C19.1,4,18.9,4,18.9,4.1z M18.2,4.1c0.2,0,0.4-0.1,0.3-0.1C18.5,4,18.2,4,18.2,4.1z M17.2,3.9
	c0,0.1,0.1,0.1,0.3,0C17.6,3.8,17.4,3.9,17.2,3.9z M16.4,4.4c0.1,0,0.3,0,0.4,0c0-0.1-0.1,0-0.1,0C16.6,4.3,16.4,4.3,16.4,4.4z
	 M15.6,3.9c0.2,0,0.3-0.1,0.4-0.2c-0.1,0-0.3,0-0.4,0C15.7,3.8,15.7,3.9,15.6,3.9z M16.7,3.7c0-0.1-0.2,0-0.4,0c-0.1,0-0.1,0-0.2,0
	c0-0.1,0-0.1-0.1,0c0,0.1,0.1,0,0.1,0.1c-0.2,0-0.2,0.1-0.3,0.1C16.2,3.8,16.5,3.7,16.7,3.7z M19.1,3.5C19.1,3.5,19.1,3.5,19.1,3.5
	c-0.2,0-0.1,0-0.3,0C18.8,3.5,19.1,3.5,19.1,3.5z M20,3.4c-0.1,0-0.2,0-0.2,0C19.8,3.5,19.9,3.5,20,3.4L20,3.4z M20.3,3.4
	c-0.1-0.1-0.1-0.1-0.3-0.1c0,0.1,0.1,0,0.1,0.1c0,0-0.1,0,0,0L20.3,3.4z M23.3,3.2c-0.1,0-0.2,0-0.3,0C23.2,3.3,23.2,3.3,23.3,3.2
	L23.3,3.2z M40.1,4.8c0,0-0.1,0-0.2,0C40,4.8,39.9,4.9,40.1,4.8C40.1,4.9,40.3,4.8,40.1,4.8z M41.1,5.1C41,5.1,41,5.1,41.1,5.1
	C40.9,5,40.9,5,40.8,5C40.9,5,41,5.1,41.1,5.1z M35.2,18.1c0.1,0,0.2,0,0.3,0C35.5,18.1,35.3,18.1,35.2,18.1z M34.6,18.1
	C34.6,18.1,34.7,18.1,34.6,18.1c0.2,0,0.2,0,0.4,0c0,0-0.2,0-0.3,0C34.7,18.1,34.7,18.1,34.6,18.1C34.6,18.1,34.5,18.1,34.6,18.1z
	 M33.8,18.4c0,0.1,0.2,0,0.3,0C34,18.4,33.9,18.4,33.8,18.4z M20.6,20.1C20.4,20.1,20.4,20.2,20.6,20.1L20.6,20.1z M21.8,19.8
	c0-0.1-0.1,0-0.3,0C21.5,19.9,21.7,19.9,21.8,19.8z M22.5,21.2c-0.1,0,0-0.1-0.1-0.1c0,0-0.1,0.1,0,0.1
	C22.4,21.2,22.4,21.2,22.5,21.2z M23.6,21.1C23.6,21,23.6,21,23.6,21.1c-0.1-0.1,0-0.2-0.2-0.1c0,0,0,0,0,0.1c-0.1,0-0.1,0-0.1,0.1
	C23.5,21,23.6,21.1,23.6,21.1z M23.6,19.3c-0.1,0-0.4,0-0.4,0.1c0,0.1,0.1-0.1,0.2,0c0,0,0,0,0.1,0C23.4,19.3,23.6,19.3,23.6,19.3z
	 M24,20.9c-0.1,0-0.3,0.1-0.2,0.1c0.2,0,0.2-0.1,0.4-0.1C24.3,20.8,24.1,20.8,24,20.9z M24.5,20.7c-0.1,0-0.2,0.1-0.2,0.1
	C24.5,20.8,24.7,20.7,24.5,20.7z M27.1,19c0.1,0,0.1,0.1,0.2,0C27.4,18.9,27.2,18.9,27.1,19z M30.5,19.2L30.5,19.2l0.1,0.1
	C30.7,19.2,30.7,19.1,30.5,19.2z M36.2,18.2c-0.1,0-0.4,0.1-0.3,0.1C35.9,18.2,36.3,18.2,36.2,18.2z M36.8,18.1c-0.1,0-0.1,0-0.3,0
	C36.5,18.2,36.7,18.2,36.8,18.1z M37.1,17.8c0,0.1,0.3-0.1,0.3-0.1C37.3,17.7,37.2,17.7,37.1,17.8z M38.7,17.4c-0.1,0.1-0.1,0-0.1,0
	c-0.2,0.1-0.3,0.1-0.4,0.2c0,0-0.1,0-0.1,0c-0.2,0.2-0.6,0.2-0.8,0.3c0.1,0-0.2,0.1-0.1,0.1c0.1,0,0.1,0,0.2-0.1c0,0,0.2,0,0.3,0
	c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.3-0.1,0.4-0.1c0.1,0,0.1,0,0.1-0.1C38.4,17.6,38.8,17.5,38.7,17.4z M43.5,14.8
	c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0.1-0.1,0.1-0.2,0.2c0,0,0,0,0,0c0,0,0,0,0,0c-0.2,0.1-0.3,0.2-0.3,0.3c0.2-0.1,0.3-0.3,0.5-0.4
	C43.3,14.9,43.5,14.9,43.5,14.8z M45.4,9.4c0,0.1,0,0.2,0,0.2C45.5,9.7,45.4,9.4,45.4,9.4z M45.5,10.1C45.6,10,45.4,10,45.5,10.1
	C45.5,10,45.5,10.2,45.5,10.1z M45.6,9.9c0,0.1-0.1,0.1-0.1,0.2C45.6,10.3,45.7,10,45.6,9.9z M43.5,14.6c0,0.1,0.1,0,0.1,0
	c0,0-0.1,0.1-0.1,0.1C43.8,14.6,43.7,14.5,43.5,14.6z M46,13.3c-0.1,0.1-0.2,0.3-0.2,0.3C45.9,13.5,46,13.4,46,13.3z M46.6,12
	c0,0.2-0.1,0.2-0.2,0.3c0,0,0,0.1,0.1,0C46.5,12.3,46.7,12,46.6,12z M46.8,9.1c0,0.1,0,0.3,0.1,0.3C46.9,9.2,46.9,9.1,46.8,9.1z
	 M45.9,9.4c0,0.2,0,0.2,0.1,0.3c-0.1,0,0,0.2,0,0.2c0,0.1,0,0.1-0.1,0.2c0.1,0,0,0.2,0.1,0.3c0,0.1,0,0.2-0.1,0.2
	c0-0.1,0.1-0.2,0-0.2c0,0.1,0,0.1,0,0.2c0,0.1,0.1,0.4,0,0.4c0,0,0-0.1,0,0c0,0.1-0.1,0.2-0.1,0.3c0,0,0-0.2,0.1-0.2
	c0,0.2-0.1,0.3-0.1,0.5c0,0-0.1,0-0.1,0.1c0,0-0.1,0.3-0.1,0.3l0,0c0,0.1,0,0.2-0.1,0.2c0,0,0,0-0.1,0c0,0.1,0.1,0,0.1,0
	c-0.1,0.2,0,0.1,0,0.3c0,0.1-0.1,0-0.1,0.1c0,0,0,0.1-0.1,0.2c-0.1,0.2-0.3,0.5-0.5,0.7c0,0,0,0.1-0.1,0.2c-0.3,0.4-0.5,0.5-0.8,0.9
	c0.1,0.1,0.3-0.2,0.4-0.3c0.2-0.2,0.4-0.4,0.6-0.5c0,0,0-0.1,0-0.1c0.3-0.3,0.4-0.5,0.5-0.8c0.1,0,0,0.1,0.1,0
	c0-0.1,0.1-0.2,0.1-0.2c0.2-0.3,0.3-0.6,0.4-0.7c0-0.2,0.1-0.4,0.2-0.5c-0.1,0,0-0.2,0-0.3c0,0,0,0.1,0,0.1c0-0.2,0.1-0.2,0.1-0.4
	c-0.1-0.1,0,0.2-0.1,0.2c-0.1,0,0-0.2,0-0.4c0,0,0,0,0-0.1c0.1,0.1,0,0,0-0.1c0-0.2,0-0.3,0-0.4c0,0,0.1,0,0.1,0
	c0-0.2,0-0.1-0.1-0.2c0-0.3-0.1-0.5,0-0.6c0,0,0.1,0.1,0.1,0c0-0.1-0.1-0.1-0.1-0.1c0-0.1,0-0.2-0.1-0.3c0,0.2,0.1,0.3,0.1,0.4
	c0-0.1-0.1-0.2-0.1-0.3c-0.1-0.2,0-0.1,0-0.2C45.6,8.5,45.8,9.4,45.9,9.4z M45,7.6c0.1,0,0-0.2-0.1-0.2C44.9,7.4,45,7.6,45,7.6z
	 M43.9,6.9C44,7,44.1,7.1,44.1,7.2c0,0,0-0.2-0.1-0.2c0,0,0,0,0,0C44,7,43.9,6.9,43.9,6.9z M43.8,6.6c0,0.1,0.2,0.2,0.2,0.2
	C44,6.7,43.9,6.6,43.8,6.6z M43.8,6.4c-0.1,0.1,0.1,0.1,0.1,0.2C44,6.6,43.8,6.5,43.8,6.4z M44.4,5.5c0.1,0.1,0.2,0.1,0.3,0.2
	C44.5,5.6,44.4,5.5,44.4,5.5z M44.4,5.4c0.1,0,0-0.1-0.2-0.2C44.1,5.2,44.2,5.3,44.4,5.4z M43.7,5.1c0.1,0.1,0.2,0.1,0.2,0.1
	C43.9,5.1,43.7,5,43.7,5.1z M42.8,5.6c0.1,0.1,0.1,0,0.2,0.1c0.1,0.1,0.3,0.3,0.4,0.3c-0.1-0.1-0.3-0.2-0.3-0.2c0,0,0.1,0.1,0,0
	C43,5.7,42.8,5.5,42.8,5.6z M43.2,4.8c0.1,0.1,0.1,0.1,0.2,0.1c0,0,0-0.1,0-0.1C43.3,4.8,43.2,4.8,43.2,4.8z M41.5,5
	c0.2,0.1,0.3,0.2,0.4,0.2c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1,0-0.1,0,0,0.1C41.6,5,41.5,5,41.5,5z M41.2,4.7c0.1,0,0.1,0,0.2,0
	C41.2,4.7,41.1,4.7,41.2,4.7z M38.8,4C38.8,4,38.9,4,38.8,4c0.2,0.1,0.3,0.1,0.3,0.1c0,0-0.2,0-0.2,0c0.1,0.1,0.2,0,0.3,0.1
	c0-0.1,0-0.1,0-0.1C39.1,4,38.9,4,38.8,4z M36.1,3.7c0.1,0,0.1,0,0.2,0c0-0.1,0.1,0,0.1-0.1C36.2,3.6,36.2,3.6,36.1,3.7z M35.5,3.6
	c0.1,0,0.2,0.1,0.3,0.1c0,0,0,0,0-0.1c0.2,0,0.3,0.1,0.4,0c0-0.1-0.3-0.1-0.4-0.1c0,0,0,0.1-0.1,0.1C35.6,3.6,35.5,3.6,35.5,3.6
	C35.2,3.6,35.4,3.6,35.5,3.6z M29.6,2.2c0.2,0,0.2,0,0.4,0c-0.1,0,0,0,0,0C29.8,2.1,29.7,2,29.6,2.2z M29.5,2.2
	C29.5,2.2,29.6,2.2,29.5,2.2c0,0,0-0.1-0.1-0.1C29.4,2.2,29.5,2.1,29.5,2.2z M28.9,2.1c0.1,0,0.3,0,0.3,0C29.1,2.1,29,2.1,28.9,2.1z
	 M28,2.7c0.1,0,0.3,0,0.3,0C28.1,2.7,28.1,2.7,28,2.7z M27.8,2.1c0.2,0,0.2,0,0.3,0C28,2,27.8,2.1,27.8,2.1z M24.7,2.6
	c0.1,0,0.2,0,0.3,0c0,0,0-0.1-0.1-0.1C24.9,2.6,24.7,2.5,24.7,2.6z M24.4,2.5c0,0,0.1,0.1,0.2,0c-0.1-0.1,0.1,0,0.2,0
	C24.8,2.4,24.5,2.5,24.4,2.5z M22.5,2.2c0.1,0,0.3,0,0.3,0C22.7,2.3,22.6,2.2,22.5,2.2z M20.8,2.3c0,0,0.1,0,0.1,0
	c0-0.1-0.1-0.1-0.1-0.1c0,0,0,0-0.1,0C20.6,2.3,20.7,2.3,20.8,2.3z M20.4,2.2c0,0,0.1,0.1,0.1,0.1C20.5,2.3,20.6,2.2,20.4,2.2z
	 M20.4,2.6c0.2,0,0.5,0,0.5-0.1c-0.1,0-0.1,0-0.1,0C20.6,2.5,20.3,2.5,20.4,2.6z M18.8,2.5c-0.1,0-0.2,0-0.2,0.1
	C18.7,2.6,18.7,2.6,18.8,2.5L18.8,2.5z M19,2.4c0.1,0,0.2,0,0.2,0c0,0-0.1-0.1-0.1-0.1C19.3,2.3,19.1,2.4,19,2.4z M18.3,2.4
	c0.1,0,0.4,0,0.4-0.1C18.6,2.3,18.3,2.3,18.3,2.4z M18.3,2.4c0,0.1,0.4,0,0.6,0c0,0,0.2,0,0.1-0.1C18.7,2.4,18.6,2.4,18.3,2.4z
	 M45.5,11.9c0,0,0,0.1,0,0.3c0,0,0-0.1,0-0.1c0,0,0,0,0,0C45.4,12,45.5,12,45.5,11.9z M5.1,13c-0.1,0-0.2-0.1-0.2-0.1
	C5,12.9,5.1,13,5.1,13z M34.7,3.6c-0.1,0-0.5,0-0.6,0c0-0.1,0.1,0,0.1,0C34.4,3.6,34.6,3.6,34.7,3.6z M28.4,3.4c-0.1,0-0.3,0-0.6,0
	C27.9,3.3,28.2,3.3,28.4,3.4z M2.3,10c-0.1-0.1,0-0.5,0-0.7c0-0.1,0-0.4,0.1-0.4C2.3,9.3,2.3,9.7,2.3,10z M6.1,14.1
	c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.1,0-0.2C6,13.9,6.1,14,6.1,14.1z M2.4,11c-0.1-0.1-0.1-0.5-0.1-0.7C2.4,10.5,2.4,10.9,2.4,11z
	 M3.9,6.8C3.7,7,3.4,7.2,3.2,7.4C3.4,7.2,3.6,7,3.8,6.9C4,6.7,4.2,6.6,4.4,6.4C4.5,6.4,4.1,6.7,3.9,6.8z M6.3,14.3
	c-0.1-0.1-0.3-0.1-0.4-0.2C5.9,14.1,6.3,14.2,6.3,14.3z M7.4,14.8c-0.1,0-0.2,0-0.3-0.1C7.3,14.7,7.3,14.7,7.4,14.8z M6.8,14.5
	c-0.2,0-0.3-0.1-0.4-0.2C6.4,14.3,6.6,14.4,6.8,14.5C6.7,14.5,6.7,14.5,6.8,14.5z M3.8,11.7c-0.1-0.2-0.1-0.1-0.2-0.3
	c0.1-0.1-0.1-0.3-0.1-0.4c0,0,0,0,0.1,0c0,0.1,0.1,0.2,0.1,0.3C3.7,11.5,3.9,11.7,3.8,11.7z M39.6,16.2c0.3-0.1,0.5-0.2,0.9-0.4
	C40.2,16,39.9,16.1,39.6,16.2C39.6,16.2,39.6,16.2,39.6,16.2z M40.3,3.2l0.3,0.1C40.6,3.3,40.3,3.2,40.3,3.2z M32.1,20.1
	c0,0-0.2,0.1-0.3,0.1c-0.1,0-0.3,0-0.2,0C31.8,20.1,31.9,20.1,32.1,20.1z M42.6,4c0.1,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.4,0.2,0.4,0.3
	C43.2,4.3,42.6,4.1,42.6,4z M45,14.7c0.2-0.1-0.2,0.3-0.3,0.3c0,0-0.1,0-0.1,0.1C44.6,15,45,14.7,45,14.7z M45,5.8
	c0.1,0.1,0.2,0.2,0.1,0.2C45.1,6,45,5.9,45,5.8z M42.2,4c0-0.1,0.2,0.1,0.2,0.1c0,0.1,0.4,0.1,0.3,0.2C42.5,4.2,42.4,4.1,42.2,4z
	 M40.7,3.5C41,3.5,41,3.6,41.1,3.7C41.1,3.6,40.7,3.5,40.7,3.5z M42.3,4.1c0.1,0.1,0.3,0.1,0.3,0.2C42.5,4.3,42.2,4.2,42.3,4.1z
	 M42.8,4.5c0.1,0-0.2-0.1-0.1-0.1c0.1,0.1,0.1,0.1,0.3,0.2C43,4.5,42.9,4.5,42.8,4.5z M37.6,17.1c0.1-0.1,0.3-0.1,0.4-0.2
	c0.4-0.1,0.7-0.3,1-0.4c0.2-0.1,0.4-0.2,0.5-0.2c-0.3,0.1-0.7,0.3-1,0.4C38.2,16.8,38,16.9,37.6,17.1z M41.1,15.5
	c0.1,0,0.3-0.2,0.4-0.2C41.4,15.4,41.2,15.5,41.1,15.5z M43.2,14.2c0-0.1,0.1-0.1,0.2-0.2C43.4,14,43.2,14.1,43.2,14.2z M41.5,15.3
	c0-0.1,0.4-0.3,0.5-0.3C41.9,15.1,41.7,15.2,41.5,15.3z M34.4,17.9c0.1-0.1,0.2-0.1,0.4-0.1C34.8,17.9,34.5,17.9,34.4,17.9z
	 M30.9,18.5c0-0.1,0.2,0,0.3,0c0.4-0.1,0.9-0.1,1.3-0.2c-0.1,0.1-0.3,0.1-0.4,0.1C31.7,18.4,31.4,18.5,30.9,18.5z M37,17.2
	c0-0.1,0.5-0.2,0.5-0.1C37.4,17.1,37.3,17.2,37,17.2z M34.9,17.8c0-0.1,0.2-0.1,0.3-0.1C35.2,17.8,35,17.8,34.9,17.8z M36.1,2.3
	c0.1,0,0.4,0,0.6,0C36.5,2.4,36.3,2.4,36.1,2.3z M11.2,3.6c0.1,0,0.2-0.1,0.3-0.1c0,0,0,0-0.1,0c0.1-0.1,0.1,0,0.2,0
	C11.5,3.5,11.3,3.6,11.2,3.6z M10.1,3.8c0-0.1,0.2-0.1,0.4-0.1C10.4,3.7,10.2,3.7,10.1,3.8z M11.5,3.2c0.1,0,0.3-0.1,0.3,0
	C11.8,3.2,11.5,3.3,11.5,3.2z M10.5,3.5c0.1-0.1,0.3-0.1,0.3-0.1c0.1,0,0.1-0.1,0.2-0.1C10.9,3.5,10.7,3.5,10.5,3.5z M11.5,3.4
	c0-0.1,0.1,0,0.2-0.1c0,0,0,0,0,0c0.1,0,0.1,0,0.1,0c0,0,0.1-0.1,0.3-0.1c0.1,0-0.1,0.1-0.2,0.1c0,0.1-0.1,0.1,0.1,0.1
	c0,0.1-0.1,0-0.1,0c-0.1,0,0,0,0,0C11.8,3.4,11.7,3.3,11.5,3.4C11.6,3.4,11.6,3.4,11.5,3.4z M14.1,2.8c0.2,0,0.3-0.1,0.5-0.1
	c0,0,0.1,0,0.1,0c-0.1,0.1-0.4,0.1-0.5,0.1c0,0,0.1,0,0,0.1c0.2,0,0.7-0.1,0.8,0c-0.2,0-0.3,0-0.5,0c-0.1,0-0.2,0.1,0,0.1
	c-0.3,0.1-0.8,0.1-1,0.2c0.1-0.1,0.3-0.1,0.4-0.1c0,0-0.2-0.1-0.2,0c-0.2,0,0.2-0.1,0.3-0.1c0.1,0,0,0.1,0,0.1c0.2,0,0.3,0,0.4,0
	c0-0.1-0.2,0-0.2,0c0,0,0.1,0,0,0C14.1,2.9,14,2.9,14.1,2.8C14.1,2.9,14.1,2.8,14.1,2.8z M11.5,3.1C11.7,3,11.8,2.9,12,2.9
	c0.1,0,0.1,0,0.2,0c0.2-0.1,0.6-0.2,1.1-0.3c0,0,0.2,0,0.2,0c0,0.1-0.3,0.1-0.1,0.2c-0.2,0.1-0.2,0-0.4,0.1c0,0.1,0.3,0,0.5,0
	c0,0-0.1,0.1,0,0.1c-0.2,0-0.5,0.1-0.9,0.2c0,0,0.1,0,0.1-0.1c0.1,0,0.1-0.1,0.2-0.1c-0.2,0-0.1,0-0.2,0.1c-0.4,0.1-0.7,0.1-1,0.2
	C11.6,3.1,11.6,3.1,11.5,3.1z M12.3,2.9c0,0,0.1,0,0.1,0c0-0.1-0.2,0-0.3,0C11.8,2.9,12.2,2.9,12.3,2.9z M13.7,2.9
	C13.7,2.9,13.6,2.9,13.7,2.9c-0.1,0.1,0.1,0,0.2,0C13.8,2.9,13.8,2.9,13.7,2.9C13.7,2.8,13.9,2.8,13.7,2.9c0.1-0.1,0.1-0.1,0.3-0.1
	c0,0-0.1,0-0.1,0c0,0,0.3,0,0.3-0.1c0,0-0.1,0-0.1,0.1C13.9,2.9,13.7,3,13.4,3c0,0,0,0,0-0.1c-0.1,0-0.1,0.1-0.3,0.1
	c0-0.1,0-0.1-0.1,0c0-0.1,0.3-0.1,0.4-0.2c0,0-0.1,0.1,0,0.1C13.6,2.9,13.5,2.9,13.7,2.9z M10,4c0,0,0.1,0,0.1-0.1
	c0.1,0,0.2-0.1,0.4-0.1c0.2,0,0.3-0.1,0.4,0c-0.1,0-0.1,0-0.2,0l0,0.1c0.1,0,0.3-0.1,0.5-0.1c0.1,0.1-0.1,0.1-0.2,0.1
	c0,0-0.2,0.1-0.2,0.1C10.5,4,10.2,4,10.1,4.1c-0.1,0,0.1-0.1,0-0.1c0.1,0.1,0.4,0,0.5-0.1c0,0-0.1-0.1-0.3,0c0,0.1,0,0.1,0.1,0
	C10.4,4,10.2,3.9,10,4z M14.8,2.7c0.2-0.1,0.2,0,0.5-0.1c-0.2,0-0.1-0.1,0.1-0.1c0,0.1,0.2,0,0.3,0.1c0,0-0.2,0.1,0,0.1
	c0,0.1-0.2,0-0.4,0.1c0,0,0.2,0,0.2-0.1c0,0,0,0,0,0l-0.3,0c-0.1,0,0.1,0.1-0.1,0.1C14.9,2.7,15.1,2.6,14.8,2.7z M8.6,4.2
	c-0.2,0-0.1,0-0.3,0.1C8.3,4.2,8.6,4.1,8.6,4.2z M18.9,17.8c0.1,0,0.4,0.1,0.6,0.1C19.4,18,19.1,17.8,18.9,17.8z M8.6,4.2
	c0.1-0.1,0.2-0.1,0.2-0.2C8.9,4,9.1,4,9.2,4C9.1,4,8.9,4.1,8.8,4.2c0,0,0.1,0,0.1,0C8.8,4.2,8.7,4.3,8.6,4.2z M19.3,18.9
	c0,0-0.1,0-0.2,0c0,0,0.1,0.1,0,0.1c-0.2,0-0.3-0.1-0.5-0.1c-0.3-0.1-0.7-0.1-0.9-0.2c-0.1,0-0.1,0-0.1-0.1
	c-0.4-0.1-0.9-0.2-1.3-0.2c-0.1,0-0.2-0.1-0.3-0.1c0,0-0.1,0-0.2,0c0,0-0.2-0.1-0.2-0.1c-0.3-0.1-0.7-0.1-1-0.2c0.1,0,0-0.1,0-0.1
	c-0.2,0,0,0,0,0.1c-0.3-0.1-0.5-0.1-0.6-0.1c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.2,0-0.3-0.1c0,0.1-0.1,0-0.2,0c0.1,0,0.1,0.1,0.2,0.1
	c0.1,0,0-0.1,0.1-0.1c0.1,0,0.1,0.1,0.3,0.1l0,0.1c0.1,0,0.1-0.1,0.3-0.1c0,0-0.1,0-0.1,0.1c0.2,0.1,0.2,0,0.4,0.1
	c0.1,0,0.3,0,0.5,0c-0.1,0-0.2,0-0.3,0c0,0,0.1,0.1,0.2,0.1c0.1,0,0,0,0,0c0,0,0.1,0,0.1,0c0.1,0,0.1-0.1,0.2,0c0,0.1-0.1,0-0.1,0
	c0,0,0,0.1-0.1,0.1c0.1,0.1,0.3,0.1,0.4,0.1c0-0.1-0.2-0.1-0.2-0.1c0-0.1,0.2,0,0.2,0c0.1,0.1,0,0,0,0.1c0.1,0.1,0.1,0.1,0.3,0.1
	c0,0.1-0.2,0.1-0.2,0c-0.1,0,0.1,0.1,0,0.1c-0.1,0-0.2,0-0.3-0.1c0,0-0.1,0-0.2,0c-0.2-0.1-0.4-0.1-0.7-0.2c-0.1,0-0.1,0-0.2,0
	c-0.3-0.1-0.7-0.2-1-0.3c-0.1,0-0.2-0.1-0.4-0.1c-0.7-0.2-1.5-0.4-2.3-0.7c-0.1,0-0.1-0.1-0.2-0.1C10,17,9.8,17,9.7,16.9
	c-0.1,0-0.2-0.1-0.4-0.1c-0.1,0-0.1-0.1-0.2-0.1c-0.2,0-0.5-0.2-0.7-0.2c-0.1,0,0-0.1,0.1,0c-0.1-0.1-0.4-0.1-0.4-0.1
	c-0.2-0.1-0.4-0.2-0.4-0.2c-0.2,0-0.5-0.3-0.8-0.3c-0.1,0-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0c-0.2-0.1-0.5-0.3-0.8-0.4
	c-0.1,0-0.2-0.1-0.2-0.1c0,0,0,0,0-0.1c-0.2-0.1-0.4-0.2-0.6-0.3c0,0,0,0-0.1,0c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.1,0-0.1-0.1
	c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.4-0.2c-0.1-0.1-0.2-0.1-0.3-0.2c-0.3-0.3-0.6-0.7-0.8-1c-0.2-0.3-0.4-0.6-0.4-0.8
	c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.1,0-0.2c0-0.1-0.1-0.3-0.2-0.4c-0.1-0.3-0.1-0.6-0.1-1c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.3
	c0-0.1,0-0.2,0-0.2c0,0,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0.1-0.5,0.3-1,0.5-1.4c0.1-0.1,0.1-0.1,0.1-0.2C2.5,7.3,2.7,7,3,6.8
	c0.1-0.2,0.5-0.5,0.7-0.6c0,0-0.1,0.1-0.1,0.1c0.1-0.1,0.1-0.1,0.1-0.1C3.8,6.1,4,6,4.1,5.9c0.1-0.1,0-0.1,0.1-0.1
	c0.4-0.3,1-0.6,1.3-0.7C5.7,5,5.7,5,5.8,4.9c0.3-0.1,0.6-0.2,0.8-0.3c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.2,0
	c0.1,0,0.1-0.1,0.2-0.1C7.6,4.3,8,4.1,8.4,4c0,0-0.2,0.1-0.3,0.1C8,4.2,7.9,4.3,7.8,4.3c-0.2,0-0.1,0.1-0.2,0.1c0,0,0,0-0.1,0
	c0,0-0.1,0.1-0.3,0.1c0,0.1,0.2,0,0.2,0C7.1,4.7,6.9,4.7,6.7,4.8c0,0.1,0.2,0,0.2,0.1C6.6,4.9,6.4,5.1,6.1,5.1c-0.1,0-0.1,0.1,0,0.1
	C6,5.3,5.9,5.2,6,5.3c-0.1,0,0-0.1,0-0.1C5.7,5.4,5.5,5.4,5.1,5.6c0,0,0.1,0,0.1,0C5,5.6,4.6,5.9,4.4,6C4.3,6.1,4.3,6.2,4.2,6.2
	C4.1,6.3,4,6.3,3.9,6.4c-0.1,0-0.1,0.1-0.1,0.1C3.8,6.5,3.7,6.6,3.7,6.6c0.1,0,0.2-0.1,0.3-0.2c0,0,0.1,0.1,0.2,0c0,0,0,0,0.1-0.1
	c0,0-0.1,0-0.1,0C4.6,6,5,5.8,5.4,5.6c0.4-0.2,0.7-0.3,1.2-0.5c0.1,0,0,0,0,0.1C7,5,7.2,4.9,7.4,4.9c0,0,0,0,0.1-0.1
	c0.1,0,0.3-0.1,0.2,0c0.1-0.1,0.1-0.1,0.1-0.1c0,0,0.1,0,0.1,0c0,0.1-0.2,0.1-0.2,0.2c-0.2,0-0.2,0-0.4,0.1C7.2,5,7.5,4.9,7.4,4.9
	C7.3,4.9,7.1,5,7.2,5C7.1,5.1,7,5.2,6.9,5.2c0,0,0-0.1,0.1-0.1c-0.1,0-0.3,0.1-0.2,0.1C6.5,5.3,6.3,5.4,6,5.5c-0.1,0-0.2,0-0.3,0.1
	c0,0,0,0-0.1,0.1c-0.4,0.1-1,0.5-1.3,0.7c-0.1,0,0.1-0.1,0.2-0.2c-0.2,0-0.3,0.2-0.3,0.3C3.8,6.7,3.3,7.1,2.9,7.7
	c-0.1,0-0.1,0.1-0.1,0C2.7,7.8,2.8,7.8,2.8,7.9C2.5,8.3,2.3,8.7,2.3,9.1c0,0.2,0,0.5,0,0.7c0,0.2,0,0.3,0,0.4c0,0,0,0.2,0,0.3
	c0,0.1,0,0.1,0,0.2c0,0.1,0.1,0.5,0.2,0.6c0,0,0,0,0.1,0.1c0,0.1,0,0.2,0.1,0.3C2.7,11.9,2.7,12,2.8,12c0.1,0.1,0,0.2,0.1,0.3
	c0,0,0-0.1-0.1-0.1C3,12.2,3,12.4,3,12.5c0.2,0.3,0.4,0.5,0.6,0.8c0.1,0.1,0.3,0.2,0.4,0.4c0.1,0.1,0.3,0.2,0.4,0.3
	c0.2,0.1,0.5,0.4,0.8,0.5c0.1,0,0.1,0.1,0.2,0.1c0.2,0.1,0.4,0.2,0.4,0.3C6,14.8,6.4,15,6.4,15c0,0-0.1,0-0.1,0c0,0,0.2,0.1,0.2,0.1
	c0.1,0.1,0.2,0.1,0.3,0.2c0,0,0,0-0.1-0.1c0.2,0,0.5,0.2,0.8,0.3c0.1,0.1,0.3,0.2,0.6,0.3c0,0-0.2-0.1-0.1-0.1
	c0.2,0.1,0.3,0.1,0.6,0.2c0.2,0.1,0.3,0.2,0.5,0.2c0,0-0.1,0-0.1,0c0.1,0.1,0.3,0.1,0.2,0c0.1,0,0.2,0.1,0.2,0.1
	c0.3,0,0.7,0.2,1,0.3c-0.2,0,0.2,0.1,0.3,0.1c0-0.1-0.2,0-0.2-0.1c0.4,0.1,0.9,0.3,1.2,0.4c0,0,0,0,0,0.1l0.1,0
	c0.2,0.1,0.4,0.1,0.6,0.2c0.3,0.1,0.7,0.2,1,0.3c0.1,0,0.2,0,0.3,0c0.4,0.1,1,0.3,1.5,0.4c0.1,0,0.3,0,0.4,0.1
	c0.3,0.1,0.6,0.1,0.8,0.2c0.3,0.1,0.7,0.1,1,0.2c0.1,0,0.1,0,0.2,0.1c0,0,0.2,0,0.2,0c0.1,0,0.2,0.1,0.4,0.1
	c0.6,0.1,0.6,0.1,1.1,0.3c0,0-0.1,0-0.1,0.1l-0.3-0.1c-0.1,0,0,0.1-0.1,0.1c0.1,0,0.1,0,0.1,0c0.1,0,0,0,0.1,0.1c0.1,0,0.1,0,0.1,0
	C19.2,18.8,19.2,18.9,19.3,18.9z M4.6,6.1C4.7,6,4.8,6.1,4.9,6C4.8,6,4.7,6,4.6,6.1z M6.4,5c0.1,0,0.2-0.1,0.3-0.1
	C6.7,4.8,6.4,4.9,6.4,5C6.5,4.9,6.4,5,6.4,5z M5.9,5c0,0,0.3-0.1,0.4-0.1C6.1,4.9,6,4.9,5.9,5z M4.3,5.8c0.1-0.1,0.4-0.2,0.4-0.3
	C4.6,5.6,4.4,5.7,4.3,5.8z M3.6,6.9c0.1,0,0.4-0.3,0.3-0.3C3.8,6.6,3.6,6.8,3.6,6.9z M1.9,10.3c0-0.2,0-0.3,0-0.4
	C1.8,10,1.8,10.3,1.9,10.3z M2.3,11.9c0,0-0.1-0.1-0.1-0.1c0.1,0.2,0.1,0.3,0.2,0.5C2.5,12.2,2.4,12,2.3,11.9z M7.2,15.6
	c-0.1-0.1-0.2-0.1-0.2,0C7.1,15.6,7.2,15.7,7.2,15.6z M7.5,15.6c-0.2-0.1-0.5-0.3-0.6-0.2c0.2,0.1,0.1,0,0.1,0
	C7.2,15.4,7.4,15.7,7.5,15.6z M8.5,15.9c-0.1-0.1-0.3-0.1-0.3,0C8.3,15.9,8.4,15.9,8.5,15.9z M8.5,16c0.1,0,0.1,0.1,0.2,0.1
	C8.9,16.1,8.6,16,8.5,16z M10.3,17L10.1,17C10,17,10.3,17.1,10.3,17z M10.9,17.2c-0.2,0-0.1-0.1-0.3-0.2c0,0.1,0.1,0.1,0.2,0.2
	c0,0.1-0.1,0-0.1,0C10.8,17.3,10.9,17.2,10.9,17.2z M12.3,17.8l0.2,0C12.6,17.7,12.3,17.7,12.3,17.8z M12.6,17.7
	c-0.1,0-0.2-0.1-0.3,0c0,0,0.1,0,0.1,0.1C12.6,17.7,12.6,17.7,12.6,17.7z M14.9,18.4c0.1,0.1,0.1,0.1,0.2,0.2
	C15.2,18.5,15,18.5,14.9,18.4C15,18.4,15,18.4,14.9,18.4C15,18.4,14.9,18.4,14.9,18.4c0,0-0.1-0.1-0.2-0.1
	C14.7,18.3,14.8,18.3,14.9,18.4z M14.6,18.3l-0.2-0.1C14.3,18.3,14.6,18.4,14.6,18.3z M14.2,18.1c0,0.1,0.2,0.1,0.3,0.1
	c0,0,0.1,0,0.1,0C14.5,18.2,14.4,18.2,14.2,18.1z M13.8,18.2c0.1,0.1,0.1,0,0.2,0c0,0,0.2,0.1,0.2,0C14.2,18.2,13.8,18.1,13.8,18.2z
	 M13.1,18c0.1,0,0.2,0,0.2,0C13.2,18,13.1,17.9,13.1,18z M17.8,18.5c0,0,0-0.1-0.1-0.1c-0.1,0-0.1,0-0.1,0.1
	C17.7,18.5,17.7,18.5,17.8,18.5z M18.4,18.6C18.4,18.5,18.3,18.5,18.4,18.6C18.4,18.5,18.4,18.5,18.4,18.6c-0.1,0-0.2-0.1-0.3-0.1
	C18,18.5,18.2,18.5,18.4,18.6C18.3,18.5,18.4,18.6,18.4,18.6z M9.3,4.2C9.5,4.1,9.5,4.1,9.7,4c0.1,0,0.2,0,0.3,0
	C10,4.1,9.8,4.2,9.6,4.3c0.1-0.1,0.1-0.1,0.3-0.2c0-0.1-0.1,0-0.2,0c0,0.1-0.5,0.4-0.6,0.2C9.2,4.3,9.2,4.3,9.3,4.2
	c-0.2,0-0.5,0.3-0.7,0.2l0,0.1c0.2,0,0.2-0.1,0.3-0.1c0.1,0.1-0.4,0.2-0.4,0.2C8.5,4.6,8.4,4.6,8.2,4.7c0,0,0.2-0.1,0.2-0.1
	c0.1,0,0,0,0.1,0c0.1,0,0-0.1,0.1-0.1c0,0-0.1,0-0.2,0c0-0.1,0.2-0.2,0.4-0.2C8.9,4.2,9.1,4.2,9.3,4.2z M9.2,4l0.1,0
	C9.2,4,9.4,3.9,9.4,4C9.3,4,9.2,4,9.2,4z M8.5,4.3c-0.2,0-0.2,0.1-0.3,0.1C8.2,4.4,8.5,4.2,8.5,4.3z"
    />
  </svg>
</template>
<style lang="scss" scoped>
@keyframes dash {
  from {
    stroke-dashoffset: 1000;
  }
  to {
    stroke-dashoffset: 0;
  }
}
.txt3 {
  width: 108px;
  .path {
    stroke: 0;
    fill: transparent;
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    animation: dash 5s linear forwards 1s;
  }
}
</style>

<script>
export default {
  name: 'txt-svg',
}
</script>



